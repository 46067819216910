import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addLocationApi,
  createRoomApi,
  deleteAssessmentQuestionApi,
  deleteRoomApi,
  fetchLocationWithRoomsApi,
  fetchAssessmentQuestionsApi,
  addAssessmentQuestionApi,
  addOptionApi,
  updateLocationDataApi,
  updateRoomApi,
  updateOptionApi,
  deleteOptionApi,
  editAssessmentQuestionApi,
  toggleQuestionAssessmentApi,
} from "../services/assessmentQuestions";
import CustomToaster from "../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../constants/constants";
import { toast } from "react-hot-toast";
import { getTopicByIdApi } from "../services/topic";

const initialState = {
  assessmentQuestionsData: [],
  singleTopicData: [],
  locationError: false,
  roomError: false,
  loading: false,
  error: null,
  assessmentQuestionsLoader: false,
  addAssessmentLoading: false,
  addEditRoomLoading: false,
  addOptionLoading: false,
  updateOptionLoading: false,
  deleteOptionLoading: false,
  deleteAssessmentQuestionLoading: false,
  editAssessmentQuestionLoading: false,
  topicDataLoader: false,
};

export const setAssessmentQuestionsData = createAsyncThunk(
  "setAssessmentQuestionsData",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await fetchAssessmentQuestionsApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const addQuestionAssessment = createAsyncThunk(
  "addQuestionAssessment",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await addAssessmentQuestionApi(data);
      toast.custom(
        <CustomToaster
          message="Assessment Question Added Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const editQuestionAssessment = createAsyncThunk(
  "editQuestionAssessment",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await editAssessmentQuestionApi(data);
      toast.custom(
        <CustomToaster
          message="Assessment Question Updated Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const toggleQuestionAssessment = createAsyncThunk(
  "toggleQuestionAssessment",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await toggleQuestionAssessmentApi(data?.questionId);
      toast.custom(
        <CustomToaster
          message={
            data?.active
              ? "Question Inactivated Successfully"
              : "Question Activated Successfully"
          }
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const addOption = createAsyncThunk(
  "addOption",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await addOptionApi(data);
      toast.custom(
        <CustomToaster
          message="Option Added Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateOption = createAsyncThunk(
  "updateOption",
  async (data, { rejectWithValue }) => {
    const { optionData, isCorrectOptionAvailable } = data;
    try {
      const callApi = await updateOptionApi(optionData);
      toast.custom(
        <CustomToaster
          message={
            isCorrectOptionAvailable
              ? "Option Updated Successfully"
              : "Option updated successfully, but this question will be deactivated since it doesn't have a correct option"
          }
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteOption = createAsyncThunk(
  "deleteOption",
  async (data, { rejectWithValue }) => {
    const { optionId, isCorrectOptionAvailable } = data;
    try {
      const callApi = await deleteOptionApi(optionId);
      toast.custom(
        <CustomToaster
          message={
            isCorrectOptionAvailable
              ? "Option Deleted Successfully"
              : "Option deleted successfully, but this question will be deactivated since it doesn't have a correct option"
          }
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAssessmentQuestion = createAsyncThunk(
  "deleteAssessmentQuestion",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await deleteAssessmentQuestionApi(data);
      toast.custom(
        <CustomToaster
          message="Assessment Question Deleted Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTopicById = createAsyncThunk(
  "getTopicById",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getTopicByIdApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const assessmentQuestions = createSlice({
  name: "assessmentQuestions",
  initialState,
  reducers: {
    clearAssessmentQuestionsData(state) {
      state.assessmentQuestionsData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setAssessmentQuestionsData.pending, (state) => {
        state.assessmentQuestionsLoader = true;
      })
      .addCase(setAssessmentQuestionsData.fulfilled, (state, action) => {
        state.assessmentQuestionsLoader = false;
        state.assessmentQuestionsData = action.payload;
      })
      .addCase(setAssessmentQuestionsData.rejected, (state, action) => {
        state.assessmentQuestionsLoader = false;
        state.error = action.payload;
      })
      .addCase(addQuestionAssessment.pending, (state) => {
        state.addAssessmentLoading = true;
      })
      .addCase(addQuestionAssessment.fulfilled, (state, action) => {
        state.addAssessmentLoading = false;
      })
      .addCase(addQuestionAssessment.rejected, (state, action) => {
        state.addAssessmentLoading = false;
        state.error = action.payload;
      })
      .addCase(addOption.pending, (state) => {
        state.addOptionLoading = true;
      })
      .addCase(addOption.fulfilled, (state, action) => {
        state.addOptionLoading = false;
      })
      .addCase(addOption.rejected, (state, action) => {
        state.addOptionLoading = false;
        state.error = action.payload;
      })
      .addCase(updateOption.pending, (state) => {
        state.updateOptionLoading = true;
      })
      .addCase(updateOption.fulfilled, (state, action) => {
        state.updateOptionLoading = false;
      })
      .addCase(updateOption.rejected, (state, action) => {
        state.updateOptionLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteOption.pending, (state) => {
        state.deleteOptionLoading = true;
      })
      .addCase(deleteOption.fulfilled, (state, action) => {
        state.deleteOptionLoading = false;
      })
      .addCase(deleteOption.rejected, (state, action) => {
        state.deleteOptionLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteAssessmentQuestion.pending, (state) => {
        state.deleteAssessmentQuestionLoading = true;
      })
      .addCase(deleteAssessmentQuestion.fulfilled, (state, action) => {
        state.deleteAssessmentQuestionLoading = false;
      })
      .addCase(deleteAssessmentQuestion.rejected, (state, action) => {
        state.deleteAssessmentQuestionLoading = false;
        state.error = action.payload;
      })
      .addCase(editQuestionAssessment.pending, (state) => {
        state.editAssessmentQuestionLoading = true;
      })
      .addCase(editQuestionAssessment.fulfilled, (state, action) => {
        state.editAssessmentQuestionLoading = false;
      })
      .addCase(editQuestionAssessment.rejected, (state, action) => {
        state.editAssessmentQuestionLoading = false;
        state.error = action.payload;
      })
      .addCase(getTopicById.pending, (state) => {
        state.topicDataLoader = true;
      })
      .addCase(getTopicById.fulfilled, (state, action) => {
        state.topicDataLoader = false;
        state.singleTopicData = action.payload;
      })
      .addCase(getTopicById.rejected, (state, action) => {
        state.topicDataLoader = false;
        state.error = action.payload;
      });
  },
});
export const { clearAssessmentQuestionsData } = assessmentQuestions.actions;
export default assessmentQuestions.reducer;
