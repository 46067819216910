import { hasAnyPermission, ROLE_TYPES } from "../../utils/utils";
import {
  dashboard_active_icon,
  dashboard_default_icon,
  exercises_active_icon,
  exercises_default_icon,
  home_active_icon,
  home_default_icon,
  location_room_active_icon,
  location_room_default_icon,
  performance_active_icon,
  performance_default_icon,
  topic_active_icon,
  topic_default_icon,
  users_active_icon,
  users_default_icon,
} from "../../constants/icons";
import { useSelector } from "react-redux";

export const SidebarList = (roles) => {
  const order = [
    { name: "Home", key: "home" },
    { name: "Dashboard", key: "dashboard" },
    { name: "Location & Rooms", key: "locationsAndRooms" },
    { name: "Topics", key: "topic" },
    { name: "Users", key: "users" },
    { name: "Exercises", key: "exercise" },
    { name: "Assessments", key: "assessment" },
    { name: "Performance", key: "performance" },
  ];
  const { permission } = useSelector((state) => state?.security);
  const isSpoc = permission?.spoc;

  const hasOnlyUserRole =
    !ROLE_TYPES.isHr(roles) &&
    !ROLE_TYPES.isCompetencySpoc(roles) &&
    !ROLE_TYPES.isCompetencyLead(roles) &&
    !ROLE_TYPES.isAdmin(roles) &&
    ROLE_TYPES.isUser(roles);

  const list = [
    ...(permission?.spoc
      ? [
          {
            defaultIcon: topic_default_icon,
            hoverIcon: topic_active_icon,
            name: "Topics",
            linkTo: "/topic",
            isExpandable: false,
          },
        ]
      : []),
    ...(ROLE_TYPES.isCompetencySpoc(roles) ||
    ROLE_TYPES.isHr(roles) ||
    ROLE_TYPES.isCompetencyLead(roles) ||
    ROLE_TYPES.isAdmin(roles) ||
    hasOnlyUserRole
      ? [
          {
            defaultIcon: exercises_default_icon,
            hoverIcon: exercises_active_icon,
            name: "Exercises",
            linkTo: "/exercise",
            isExpandable: true,
            subMenus: [
              permission?.attendee
                ? {
                    name: "As Attendee",
                    linkTo: "/exercises/attendee",
                  }
                : null,
              permission?.exerciseReviewer || permission?.mentor
                ? {
                    name: "As Mentor/Reviewer",
                    linkTo: "/exercises/reviewer",
                  }
                : null,
            ].filter((submenu) => submenu !== null),
          },
          {
            defaultIcon: exercises_default_icon,
            hoverIcon: exercises_active_icon,
            name: "Assessments",
            linkTo: "/assessmentList",
            isExpandable: true,
            subMenus: [
              permission?.attendee
                ? {
                    name: "As Attendee",
                    linkTo: "/assessmentList/attendee",
                  }
                : null,
              permission?.mentor
                ? {
                    name: "As Mentor",
                    linkTo: "/assessmentList/mentor",
                  }
                : null,
              permission?.assessmentInterviewer
                ? {
                    name: "As Interviewer",
                    linkTo: "/assessmentList/interviewer",
                  }
                : null,
            ].filter((submenu) => submenu !== null),
          },
          {
            defaultIcon: performance_default_icon,
            hoverIcon: performance_active_icon,
            name: "Performance",
            linkTo: "/performance",
            isExpandable: true,
            subMenus: [
              permission?.attendee
                ? {
                    name: "As Attendee",
                    linkTo: "/performance/attendee",
                  }
                : null,
              permission?.mentor
                ? {
                    name: "As Mentor",
                    linkTo: "/performance/reviewer",
                  }
                : null,
            ].filter((submenu) => submenu !== null),
          },
        ]
      : []),
    ...(ROLE_TYPES.isAdmin(roles) || isSpoc
      ? [
          {
            defaultIcon: location_room_default_icon,
            hoverIcon: location_room_active_icon,
            name: "Location & Rooms",
            linkTo: "/locations-and-rooms",
            isExpandable: false,
          },
        ]
      : []),

    ...(ROLE_TYPES.isCompetencySpoc(roles) || ROLE_TYPES.isAdmin(roles)
      ? [
          {
            defaultIcon: users_default_icon,
            hoverIcon: users_active_icon,
            name: "Users",
            linkTo: "/users",
            isExpandable: false,
          },
        ]
      : []),
    ...(ROLE_TYPES.isHr(roles) ||
    ROLE_TYPES.isCompetencySpoc(roles) ||
    ROLE_TYPES.isCompetencyLead(roles) ||
    hasAnyPermission(permission) ||
    hasOnlyUserRole
      ? [
          {
            defaultIcon: home_default_icon,
            hoverIcon: home_active_icon,
            name: "Home",
            linkTo: "/",
            isExpandable: false,
          },
        ]
      : []),
    ...(ROLE_TYPES.isUser(roles)
      ? [
          {
            defaultIcon: dashboard_default_icon,
            hoverIcon: dashboard_active_icon,
            name: "Dashboard",
            linkTo: "/dashboard",
            isExpandable: false,
          },
        ]
      : []),
  ];

  const listItems = order.map((item) => {
    const foundItem = list.find((listItem) => listItem.name === item.name);
    return foundItem ? foundItem : null;
  });

  return listItems.filter((item) => item !== null);
};
