import { apiVersion } from "../constants/constants";
import ApiService from "../utils/api-service";

export function addLocationApi(data) {
  const options = {
    method: "POST",
    url: `${apiVersion}/locations?name=${data}`,
    data: data,
  };
  return ApiService(options);
}

export function updateLocationDataApi(data) {
  const options = {
    method: "PATCH",
    url: `${apiVersion}/locations/${data.id}?name=${data.name}`,
    data: data,
  };
  return ApiService(options);
}

export function fetchLocationWithRoomsApi() {
  const options = {
    method: "GET",
    url: `${apiVersion}/locations?includeRooms=true&paginated=false`,
  };
  return ApiService(options);
}

export function deleteLocationApi(id) {
  const options = {
    method: "DELETE",
    url: `${apiVersion}/locations/${id}`,
    data: id,
  };
  return ApiService(options);
}

// ////////////////////  ROOM API STARTS //////////////////////

export function createRoomApi(data) {
  const options = {
    method: "POST",
    url: `${apiVersion}/rooms?locationId=${data.locationId}&name=${data.name}&seatingCapacity=${data.seatingCapacity}`,
    data: data,
  };
  return ApiService(options);
}

export function updateRoomApi(data) {
  const options = {
    method: "PATCH",
    url: `${apiVersion}/rooms/${data.id}?name=${data.name}&seatingCapacity=${data.seatingCapacity}`,
    data: data,
  };
  return ApiService(options);
}

export function deleteRoomApi(id) {
  const options = {
    method: "DELETE",
    url: `${apiVersion}/rooms/${id}`,
    data: id,
  };
  return ApiService(options);
}
