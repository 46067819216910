import React from "react";
import {
  Box,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  down_sort_arrow_icon,
  edit_active_icon,
  edit_disabled_icon,
  reopen_disabled_icon,
  reopen_icon,
  review_disabled_icon,
  review_icon,
  up_sort_arrow_icon,
} from "../../constants/icons";
import IconButtons from "../IconButtons/IconButtons";
import TablePagination from "../TablePagination/TablePagination";
import {
  evaluationStatusChip,
  font_13_icon,
  font_9,
  getStatus,
} from "../../utils/utils";
import { sortInAscending, sortInDescending } from "../../constants/constants";
import dayjs from "dayjs";
import UserTableAvatar from "../UserTableAvatar/UserTableAvatar";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";
import { Link } from "react-router-dom";
import { AttendeeStatus, HelpTexts } from "../../constants/constants";
import { useSelector } from "react-redux";
const { DROPPED, SEPARATED } = AttendeeStatus;
const { NON_ACTIVE_ATTENDEE } = HelpTexts;
const tz = "Asia/Kolkata";

const EvaluationListingTable = ({
  evaluationListing,
  totalPages,
  handlePageChange,
  currentPage,
  handleSorting,
  sortBy,
  orderBy,
  totalResult,
  handleEditEvaluation,
  handleViewEvaluation,
  handleReopenEvaluationModal,
  isTypeInterview,
  isHr,
}) => {
  const attendeeStatuses = useSelector(
    (state) => state?.utility?.constants?.attendeeStatuses
  );
  return (
    <>
      <TableContainer className="table-container">
        <Table stickyHeader>
          <TableHead className="table-head-container">
            <TableRow className="table-head-row" sx={{ height: "2.711rem" }}>
              <TableCell
                align="left"
                sx={{ minWidth: "13rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Attendee Name/Email</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        sortBy === "attendeeName" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "attendeeName" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "attendeeName")}
                      disabled={sortBy === "attendeeName" && orderBy === "ASC"}
                      tooltip={sortInAscending}
                    />
                    {(sortBy !== "attendeeName" || orderBy !== "DESC") && (
                      <IconButtons
                        height={
                          sortBy === "attendeeName" && orderBy === "DESC"
                            ? null
                            : font_9
                        }
                        width={
                          sortBy === "attendeeName" && orderBy === "DESC"
                            ? null
                            : font_9
                        }
                        image={down_sort_arrow_icon}
                        handleClick={() =>
                          handleSorting("DESC", "attendeeName")
                        }
                        disabled={
                          sortBy === "attendeeName" && orderBy === "DESC"
                        }
                        tooltip={sortInDescending}
                      />
                    )}
                  </Box>
                </Typography>
              </TableCell>
              {isHr && (
                <>
                  <TableCell
                    align="left"
                    sx={{ minWidth: "13rem" }}
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Bootcamp</Box>
                      <Box className="table-sort-icon">
                        <IconButtons
                          height={
                            sortBy === "attendeeBootcamp" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "attendeeBootcamp" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          image={up_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("ASC", "attendeeBootcamp")
                          }
                          disabled={
                            sortBy === "attendeeBootcamp" && orderBy === "ASC"
                          }
                          tooltip={sortInAscending}
                        />
                        {(sortBy !== "attendeeBootcamp" ||
                          orderBy !== "DESC") && (
                          <IconButtons
                            height={
                              sortBy === "attendeeBootcamp" &&
                              orderBy === "DESC"
                                ? null
                                : font_9
                            }
                            width={
                              sortBy === "attendeeBootcamp" &&
                              orderBy === "DESC"
                                ? null
                                : font_9
                            }
                            image={down_sort_arrow_icon}
                            handleClick={() =>
                              handleSorting("DESC", "attendeeBootcamp")
                            }
                            disabled={
                              sortBy === "attendeeBootcamp" &&
                              orderBy === "DESC"
                            }
                            tooltip={sortInDescending}
                          />
                        )}
                      </Box>
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ minWidth: "9rem" }}
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Competency</Box>
                      <Box className="table-sort-icon">
                        <IconButtons
                          height={
                            sortBy === "attendeeCompetency" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "attendeeCompetency" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          image={up_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("ASC", "attendeeCompetency")
                          }
                          disabled={
                            sortBy === "attendeeCompetency" && orderBy === "ASC"
                          }
                          tooltip={sortInAscending}
                        />
                        {(sortBy !== "attendeeCompetency" ||
                          orderBy !== "DESC") && (
                          <IconButtons
                            height={
                              sortBy === "attendeeCompetency" &&
                              orderBy === "DESC"
                                ? null
                                : font_9
                            }
                            width={
                              sortBy === "attendeeCompetency" &&
                              orderBy === "DESC"
                                ? null
                                : font_9
                            }
                            image={down_sort_arrow_icon}
                            handleClick={() =>
                              handleSorting("DESC", "attendeeCompetency")
                            }
                            disabled={
                              sortBy === "attendeeCompetency" &&
                              orderBy === "DESC"
                            }
                            tooltip={sortInDescending}
                          />
                        )}
                      </Box>
                    </Typography>
                  </TableCell>{" "}
                </>
              )}
              {isTypeInterview && (
                <>
                  <TableCell
                    align="left"
                    sx={{ minWidth: "8rem" }}
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Lead Interviewer</Box>
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ minWidth: "8rem" }}
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Co Interviewer</Box>
                    </Typography>
                  </TableCell>
                </>
              )}
              <TableCell
                align="left"
                sx={{ minWidth: "9rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Marks</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        sortBy === "marksObtained" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "marksObtained" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "marksObtained")}
                      disabled={sortBy === "marksObtained" && orderBy === "ASC"}
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        sortBy === "marksObtained" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "marksObtained" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() => handleSorting("DESC", "marksObtained")}
                      disabled={
                        sortBy === "marksObtained" && orderBy === "DESC"
                      }
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>

              <TableCell
                align="left"
                sx={{ minWidth: "9rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Status</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        sortBy === "status" && orderBy === "ASC" ? null : font_9
                      }
                      width={
                        sortBy === "status" && orderBy === "ASC" ? null : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "status")}
                      disabled={sortBy === "status" && orderBy === "ASC"}
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        sortBy === "status" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "status" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() => handleSorting("DESC", "status")}
                      disabled={sortBy === "status" && orderBy === "DESC"}
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "9rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Start Date</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        sortBy === "startDate" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "startDate" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "startDate")}
                      disabled={sortBy === "startDate" && orderBy === "ASC"}
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        sortBy === "startDate" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "startDate" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() => handleSorting("DESC", "startDate")}
                      disabled={sortBy === "startDate" && orderBy === "DESC"}
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "9rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Submission Date</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        sortBy === "endDate" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "endDate" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "endDate")}
                      disabled={sortBy === "endDate" && orderBy === "ASC"}
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        sortBy === "endDate" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "endDate" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() => handleSorting("DESC", "endDate")}
                      disabled={sortBy === "endDate" && orderBy === "DESC"}
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>
              <TableCell
                sx={{ minWidth: "9rem", maxWidth: "9rem", width: "9rem" }}
                className="padding-left-1 padding-right-1"
              >
                <Typography variant="font_13_bold">Actions</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {evaluationListing?.map((evaluation, index) => {
              const evaluationId = evaluation?.id;
              const leadInterviewer = evaluation?.leadInterviewer;
              const coInterviewer = evaluation?.coInterviewer;
              const marksObtained = evaluation?.marksObtained;
              const totalMarks = evaluation?.totalMarks;
              const startDate = evaluation?.startDate;
              const submissionDate = evaluation?.endDate;
              const attendee =
                evaluation?.bootcampAttendee?.attendee ||
                evaluation?.moduleAttendee?.attendee?.attendee;
              const bootcamp =
                evaluation?.bootcampAttendee?.bootcamp ||
                evaluation?.moduleAttendee?.attendee?.bootcamp;
              const status = evaluationStatusChip(evaluation?.status);
              const permissions = evaluation?.permission;
              const {
                canViewEditAssessmentEvaluation,
                canEditAssessmentEvaluation,
                editEvaluationMessage,
              } = permissions || {};
              const userDropped =
                evaluation?.bootcampAttendee !== null
                  ? evaluation?.bootcampAttendee?.status === DROPPED
                  : evaluation?.moduleAttendee?.attendee?.status === DROPPED ||
                    evaluation?.moduleAttendee?.status === DROPPED;
              const userSeperated =
                evaluation?.bootcampAttendee !== null
                  ? evaluation?.bootcampAttendee?.status === SEPARATED
                  : evaluation?.moduleAttendee?.attendee?.status ===
                      SEPARATED ||
                    evaluation?.moduleAttendee?.status === SEPARATED;

              const attendeeStatus =
                evaluation?.bootcampAttendee != null
                  ? evaluation?.bootcampAttendee?.status === DROPPED ||
                    evaluation?.bootcampAttendee?.status === SEPARATED
                    ? evaluation?.bootcampAttendee?.status
                    : null
                  : evaluation?.moduleAttendee?.status === DROPPED ||
                    evaluation?.moduleAttendee?.status === SEPARATED
                  ? evaluation?.moduleAttendee?.status
                  : evaluation?.moduleAttendee?.attendee?.status === DROPPED ||
                    evaluation?.moduleAttendee?.attendee?.status === SEPARATED
                  ? evaluation?.moduleAttendee?.attendee?.status
                  : null;

              const statusUpdatedDate =
                evaluation?.bootcampAttendee != null
                  ? evaluation?.bootcampAttendee?.status === DROPPED ||
                    evaluation?.bootcampAttendee?.status === SEPARATED
                    ? evaluation?.bootcampAttendee?.statusUpdatedDate
                    : null
                  : evaluation?.moduleAttendee?.status === DROPPED ||
                    evaluation?.moduleAttendee?.status === SEPARATED
                  ? evaluation?.moduleAttendee?.statusUpdatedDate
                  : evaluation?.moduleAttendee?.attendee?.status === DROPPED ||
                    evaluation?.moduleAttendee?.attendee?.status === SEPARATED
                  ? evaluation?.moduleAttendee?.attendee?.statusUpdatedDate
                  : null;
              const isActiveOrCompleted =
                evaluation?.status === "ACTIVE" ||
                evaluation?.status === "COMPLETED";
              return (
                <TableRow className="table-body-row" hover key={index}>
                  <TableCell align="left" className="padding-left-1">
                    <UserTableAvatar
                      imgAlt={attendee?.fullName}
                      imgSrc={attendee?.profilePictureUrl}
                      href={attendee?.employeeCode}
                      label={attendee?.fullName}
                      subLabel={attendee?.email}
                      deleted={userDropped || userSeperated}
                      tooltip={
                        userDropped || userSeperated
                          ? NON_ACTIVE_ATTENDEE(
                              getStatus(attendeeStatuses, attendeeStatus),
                              dayjs(statusUpdatedDate)
                                ?.tz(tz)
                                ?.format("DD MMM YYYY")
                            )
                          : null
                      }
                    />
                  </TableCell>
                  {isHr && (
                    <>
                      <TableCell align="left" className="padding-left-1">
                        <BootstrapTooltip title="View Bootcamp">
                          <Typography variant="font_12" color="secondary">
                            <Link
                              to={`/`}
                              state={{
                                propsToPass: {
                                  bootcampName: bootcamp?.name,
                                  bootcampId: bootcamp?.id,
                                },
                              }}
                            >
                              <u>{bootcamp?.name}</u>
                            </Link>
                          </Typography>
                        </BootstrapTooltip>
                      </TableCell>
                      <TableCell align="left" className="padding-left-1">
                        <Typography variant="font_12">
                          {attendee?.competency?.name}
                        </Typography>
                      </TableCell>
                    </>
                  )}
                  {isTypeInterview && (
                    <>
                      <TableCell align="left" className="padding-left-1">
                        {!!leadInterviewer ? (
                          <UserTableAvatar
                            imgAlt={leadInterviewer?.fullName}
                            imgSrc={leadInterviewer?.profilePictureUrl}
                            href={leadInterviewer?.employeeCode}
                            label={leadInterviewer?.fullName}
                            subLabel={leadInterviewer?.email}
                            leadInterviewer={leadInterviewer?.deleted}
                          />
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell align="left" className="padding-left-1">
                        {!!coInterviewer ? (
                          <UserTableAvatar
                            imgAlt={coInterviewer?.fullName}
                            imgSrc={coInterviewer?.profilePictureUrl}
                            href={coInterviewer?.employeeCode}
                            label={coInterviewer?.fullName}
                            subLabel={coInterviewer?.email}
                            deleted={coInterviewer?.deleted}
                          />
                        ) : (
                          "-"
                        )}
                      </TableCell>
                    </>
                  )}
                  <TableCell align="left" className="padding-left-1">
                    <Chip
                      className="score-chip"
                      label={`${marksObtained || 0}/${totalMarks}`}
                      variant={
                        marksObtained === null && !isActiveOrCompleted
                          ? "GREY"
                          : !marksObtained
                          ? "RED"
                          : marksObtained === totalMarks
                          ? "GREEN"
                          : "YELLOW"
                      }
                    />
                  </TableCell>
                  <TableCell align="left" className="padding-left-1">
                    <Typography variant="font_12">
                      <Chip
                        className="same-size-chip"
                        label={status?.name}
                        variant={status?.theme}
                      />
                    </Typography>
                  </TableCell>
                  <TableCell align="left" className="padding-left-1">
                    <Typography variant="font_12">
                      {startDate
                        ? dayjs(startDate)?.format("DD/MM/YYYY hh:mm A")
                        : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" className="padding-left-1">
                    <Typography variant="font_12">
                      {submissionDate
                        ? dayjs(submissionDate)?.format("DD/MM/YYYY hh:mm A")
                        : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell className="padding-left-1 padding-right-1">
                    <Box>
                      <IconButtons
                        height={font_13_icon}
                        width={font_13_icon}
                        image={review_icon}
                        classList="margin-right-1"
                        handleClick={() => handleViewEvaluation(evaluationId)}
                        tooltip="View"
                        disabledImage={review_disabled_icon}
                      />
                      {isTypeInterview && canViewEditAssessmentEvaluation && (
                        <IconButtons
                          tooltip="Edit Evaluation"
                          width={font_13_icon}
                          height={font_13_icon}
                          image={edit_active_icon}
                          handleClick={() => handleEditEvaluation(evaluation)}
                          classList="margin-right-1"
                          disabled={!canEditAssessmentEvaluation}
                          disabledImage={edit_disabled_icon}
                          disabledTooltip={
                            !canEditAssessmentEvaluation
                              ? editEvaluationMessage
                              : null
                          }
                        />
                      )}
                      {false && (
                        <IconButtons
                          height={font_13_icon}
                          width={font_13_icon}
                          image={reopen_icon}
                          classList="margin-right-1"
                          handleClick={() =>
                            handleReopenEvaluationModal(evaluationId)
                          }
                          tooltip="Reopen Evaluation"
                          disabledImage={reopen_disabled_icon}
                        />
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container className="table-pagination-container">
        <Typography variant="font_16">Total {totalResult} Records</Typography>
        <TablePagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
        />
      </Grid>
    </>
  );
};

export default EvaluationListingTable;
