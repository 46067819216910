import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Checkbox,
  FormGroup,
  FormControlLabel,
  createFilterOptions,
  FormHelperText,
} from "@mui/material";
import AutoCompleteTagInput from "../AutoCompleteTagInput/AutoCompleteTagInput";
import "./sendEmailModal.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  clearBatchesDetails,
  getBatchesList,
  sendEmail,
} from "../../store/emailModal";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";
import Loader from "../Loader/Loader";
import { Scale } from "@mui/icons-material";

const initialEmailData = {
  sessionId: "",
  batchIds: [],
  toAttendees: false,
  toPresenters: false,
  toSpocs: false,
};

const SendEmailModal = ({
  open,
  handleClose,
  moduleId,
  sessionId,
  batches,
}) => {
  const dispatch = useDispatch();
  const emailData = useSelector((state) => {
    return state?.sendEmail;
  });

  const batchesLoading = emailData?.batchLoading;
  const sendEmailLoading = emailData?.sendEmailLoading;

  const onClose = () => {
    setErrors(initialErrorState);
    setApiError(false);
    handleClose();
  };

  const batchList = emailData?.batches?.data?.results;
  ///////// handle Modal Data Change ///////////////////////////////////
  //////////////////////////////////////////////////////////////////////
  const [emailDetails, setEmailDetails] = useState(initialEmailData);
  const handleEmailDataChange = (name, value) => {
    if (name === "toAttendees" || name === "toPresenters") {
      setErrors((prev) => {
        return {
          ...prev,
          checkbox: false,
        };
      });
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          [name]: false,
        };
      });
    }
    setEmailDetails((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  ///////////////////////////////////////////////////////////////////////
  //////////// handle Submit Email ///////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////

  const initialErrorState = {
    batchIds: null,
    checkbox: null,
  };
  const [apiError, setApiError] = useState(false);
  const [errors, setErrors] = useState(initialErrorState);
  const checkValid = (data) => {
    const { batchIds, toAttendees, toPresenters } = data;
    const newErrors = {
      batchIds: batchIds?.length ? "" : "Select Batch",
      checkbox:
        toAttendees || toPresenters
          ? ""
          : "*One of Attendee/Presenter should be checked",
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const handleSendEmail = async () => {
    let apiValue;
    const data = {
      ...emailDetails,
      sessionId: sessionId,
      batchIds: emailDetails?.batchIds?.map((e) => e?.id),
      toAttendees: emailDetails?.toAttendees,
      toPresenters: emailDetails?.toPresenters,
      toSpocs: emailDetails?.toSpocs,
    };

    if (checkValid(data)) {
      apiValue = await dispatch(sendEmail(data));
      if (apiValue?.error?.message === "Rejected") {
        setApiError(true);
      } else {
        onClose();
      }
    }
  };

  const handleOnClose = async () => {
    await clearBatchesDetails();
    onClose();
  };

  ///////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const fetchData = async () => {
      await setEmailDetails({
        sessionId: sessionId,
        batchIds: batches,
        toAttendees: false,
        toPresenters: false,
        toSpocs: false,
      });
      if (moduleId !== undefined) {
        await dispatch(getBatchesList({ moduleId }));
      }
    };

    fetchData();
  }, [batches, dispatch, moduleId, open]);

  return (
    <>
      {(batchesLoading || sendEmailLoading) && <Loader />}
      <Dialog
        open={open}
        onClose={handleOnClose}
        PaperProps={{
          style: {
            minHeight: "20.875rem",
            width: "27.5rem",
            zIndex: 1300,
            borderRadius: "1rem",
            margin: 0,
          },
        }}
      >
        <DialogTitle className="send-dialog-title">Send Email</DialogTitle>
        <DialogContent sx={{ padding: "1rem 2rem 0 !important" }}>
          <AutoCompleteTagInput
            label="Batches"
            placeholder={emailDetails?.batchIds?.length ? null : "Select Batch"}
            options={batchList}
            selectedValue={emailDetails?.batchIds}
            getOptionLabel={(option) => `${option?.name}`}
            filterOptions={(options, params) => {
              // <<<--- inject the Select All option
              const filter = createFilterOptions();
              const filtered = filter(options, params);
              if (batchList.length === 0) {
                return [{ name: "No options", all: false }];
              }
              return [{ name: "Select All", all: true }, ...filtered];
            }}
            onChange={(event, newValue) => {
              const idCounts = newValue.reduce((counts, { id }) => {
                counts[id] = (counts[id] || 0) + 1;
                return counts;
              }, {});
              const uniqueNewValue = newValue.filter(({ id }) => {
                return idCounts[id] === 1;
              });
              if (newValue.find((option) => option.all)) {
                return handleEmailDataChange(
                  "batchIds",
                  emailDetails?.batchIds?.length === batchList?.length
                    ? []
                    : batchList
                );
              }
              handleEmailDataChange("batchIds", uniqueNewValue);
            }}
            required={true}
            error={!!errors?.batchIds || apiError}
            helperText={errors?.batchIds}
            renderOption={(props, option, value, { selected }) => {
              // Check if option is "No options" and render accordingly
              if (option.name === "No options") {
                return (
                  <Box className="auto-complete-no-options">
                    <Box>{option?.name}</Box>
                  </Box>
                );
              }
              const optionChecked =
                (option.all &&
                  emailDetails?.batchIds?.length === batchList?.length) ||
                emailDetails?.batchIds?.find((e) => e?.id === option?.id) !=
                  null;
              return (
                <li
                  {...props}
                  key={props?.id}
                  style={{
                    paddingBottom: 1,
                    paddingTop: 1,
                  }}
                  className={
                    optionChecked
                      ? "multi-tag-edit-list-selected"
                      : "multi-tag-style"
                  }
                >
                  <Checkbox
                    sx={{
                      marginRight: 0.2,
                      marginTop: "0.2rem",
                      padding: "0.063rem 0.438rem",
                      "& .MuiSvgIcon-root": {
                        fontSize: 15,
                      },
                    }}
                    size="small"
                    checked={optionChecked}
                    color="secondary"
                  />
                  <Box>
                    <Typography variant="font_12">{`${option?.name}`}</Typography>
                  </Box>
                </li>
              );
            }}
          />
          <Box className="email-checkboxes-container">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    size="small"
                    checked={emailDetails?.toAttendees}
                    onChange={(e) =>
                      handleEmailDataChange("toAttendees", e.target.checked)
                    }
                  />
                }
                label="Send emails to Attendees?"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    size="small"
                    checked={emailDetails?.toPresenters}
                    onChange={(e) =>
                      handleEmailDataChange("toPresenters", e.target.checked)
                    }
                  />
                }
                label="Send emails to Presenters?"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    size="small"
                    checked={emailDetails?.toSpocs}
                    onChange={(e) =>
                      handleEmailDataChange("toSpocs", e.target.checked)
                    }
                  />
                }
                label="CC to SPOC"
                disabled={
                  !emailDetails?.toAttendees && !emailDetails?.toPresenters
                }
              />
            </FormGroup>
            <FormHelperText sx={{ marginBottom: "1rem" }}>
              <Typography variant="font_12" color="error">
                {errors?.checkbox}
              </Typography>
            </FormHelperText>
          </Box>
        </DialogContent>
        <DialogActions className="send-dialog-action">
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={handleOnClose}
            className="addTagButtonStyle"
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disableElevation
          >
            <Typography variant="font_12_bold_700">Cancel</Typography>
          </Button>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            onClick={handleSendEmail}
            className="addTagButtonStyle"
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disableElevation
          >
            <Typography variant="font_12_bold_700">Save</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SendEmailModal;
