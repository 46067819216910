import { apiVersion } from "../../constants/constants";
import ApiService from "../../utils/api-service";

export function getAttendeesApi(data) {
  let apiUrl = `${apiVersion}/sessionAttendance?sessionId=${data?.sessionId}&max=20`;

  if (data?.offset !== undefined) {
    apiUrl += `&offset=${data?.offset * 20}`;
  }

  if (data?.sortBy !== undefined && data?.sortBy !== null) {
    apiUrl += `&sortBy=${data?.sortBy}`;
  }

  if (data?.sortOrder !== undefined && data?.sortOrder !== null) {
    apiUrl += `&sortOrder=${data?.sortOrder}`;
  }

  if (
    data?.mentorNameOrEmail !== undefined &&
    data?.mentorNameOrEmail !== null &&
    data?.mentorNameOrEmail !== ""
  ) {
    apiUrl += `&mentorNameOrEmail=${data?.mentorNameOrEmail}`;
  }
  if (
    data?.attendeeNameOrEmail !== undefined &&
    data?.attendeeNameOrEmail !== null &&
    data?.attendeeNameOrEmail !== ""
  ) {
    apiUrl += `&attendeeNameOrEmail=${data?.attendeeNameOrEmail}`;
  }
  if (
    data?.competencyIds !== undefined &&
    data?.competencyIds !== null &&
    data?.competencyIds.length
  ) {
    apiUrl += `&competencyIds=${data?.competencyIds}`;
  }
  if (
    data?.bootcampIds !== undefined &&
    data?.bootcampIds !== null &&
    data?.bootcampIds !== ""
  ) {
    apiUrl += `&bootcampIds=${data?.bootcampIds}`;
  }
  if (
    data?.batchIds !== undefined &&
    data?.batchIds !== null &&
    data?.batchIds.length
  ) {
    apiUrl += `&batchIds=${data?.batchIds}`;
  }
  if (
    data?.status !== undefined &&
    data?.status !== null &&
    data?.status !== ""
  ) {
    apiUrl += `&status=${data?.status}`;
  }
  if (data?.showAttendees) {
    apiUrl += `&showAttendees=${data?.showAttendees}`;
  }
  if (data?.showMentees) {
    apiUrl += `&showMentees=${data?.showMentees}`;
  }
  if (data?.showReviewees) {
    apiUrl += `&showReviewees=${data?.showReviewees}`;
  }
  if (data?.bootcampId) {
    apiUrl += `&bootcampId=${data?.bootcampId}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function markAttendanceApi(data) {
  let apiUrl = `${apiVersion}/sessionAttendance`;

  const options = {
    method: "PUT",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function getCompetencyApi(data) {
  let apiUrl = `${apiVersion}/competencies`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getBatchesApi(data) {
  let apiUrl = `${apiVersion}/moduleBatches?moduleId=${data.moduleId}`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function createAttendancesApi(data) {
  let apiUrl = `${apiVersion}/sessionAttendance?sessionId=${data.sessionId}`;

  const options = {
    method: "POST",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getFiltersForAttendeesTabApi({ sessionId }) {
  let apiUrl = `${apiVersion}/utilities/filtersForSessionAttendanceListing?sessionId=${sessionId}`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}
