import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import IconButtons from "../../../components/IconButtons/IconButtons";
import { font_15, isOnlyTrueForPermissionKey } from "../../../utils/utils";
import { cross_default_icon } from "../../../constants/icons";
import SimpleAutoComplete from "../../../components/SimpleAutoComplete/SimpleAutoComplete";
import AutoCompleteTagInput from "../../../components/AutoCompleteTagInput/AutoCompleteTagInput";
import Loader from "../../../components/Loader/Loader";
import { useSelector } from "react-redux";
const ExerciseTabFilter = ({
  open,
  onClose,
  handleFilterExerciseSubmit,
  handleFilterExerciseRemove,
  handleChangeFilterExercise,
  filterExercise,
  competency,
  bootcamp,
  batches,
  apiLoading,
  isHr,
  permissions,
}) => {
  const isSpoc = permissions?.spoc;
  const sortOptions = useSelector(
    (state) => state?.utility?.constants?.sessionExerciseStatuses
  );

  const isOnlySessionPresenter = isOnlyTrueForPermissionKey(
    permissions,
    "sessionPresenter"
  );
  const isOnlyMentor = isOnlyTrueForPermissionKey(permissions, "mentor");
  const isOnlyReviewer = isOnlyTrueForPermissionKey(
    permissions,
    "exerciseReviewer"
  );

  // Check if any of the three permissions are true
  const hasAnyPermission =
    permissions?.sessionPresenter ||
    permissions?.mentor ||
    permissions?.exerciseReviewer;

  return (
    <>
      {apiLoading && <Loader />}
      <Drawer
        className="filter-drawer-container"
        anchor="right"
        open={open}
        onClose={onClose}
      >
        <Grid container className="filter-main-container">
          <Grid item md={12} pt={2} className="drawer-input-container">
            <Box className="filter-head">
              <Typography variant="font_20" className="filter-title ">
                Filters
              </Typography>
              <IconButtons
                  handleClick={onClose}
                  height={font_15}
                  width={font_15}
                  image={cross_default_icon}
                  tooltip="Close Filter"
              />
            </Box>
            <Box className="filter-padding textfield-box">
              <TextField
                size="small"
                label="Attendee Name/Email"
                color="secondary"
                fullWidth
                className="filter-inputs"
                margin="dense"
                variant="outlined"
                value={filterExercise?.attendeeNameOrEmail}
                onChange={(e) =>
                  handleChangeFilterExercise(
                    "attendeeNameOrEmail",
                    e?.target?.value
                  )
                }
              />
              {(isSpoc || (isSpoc && hasAnyPermission) || isHr) && (
                <TextField
                  size="small"
                  label="Reviewer Name/Email"
                  color="secondary"
                  fullWidth
                  className="filter-inputs"
                  margin="dense"
                  variant="outlined"
                  value={filterExercise?.reviewerNameOrEmail}
                  onChange={(e) =>
                    handleChangeFilterExercise(
                      "reviewerNameOrEmail",
                      e?.target?.value
                    )
                  }
                />
              )}
              {(isSpoc || (isSpoc && hasAnyPermission) || isHr) && (
                <TextField
                  size="small"
                  label="Mentor Name/Email"
                  color="secondary"
                  fullWidth
                  className="filter-inputs"
                  margin="dense"
                  variant="outlined"
                  value={filterExercise?.mentorNameOrEmail}
                  onChange={(e) =>
                    handleChangeFilterExercise(
                      "mentorNameOrEmail",
                      e?.target?.value
                    )
                  }
                />
              )}
              <SimpleAutoComplete
                label="Status"
                placeholder="Select Status"
                options={sortOptions}
                onChange={(event, newValue) =>
                  handleChangeFilterExercise("status", newValue)
                }
                getOptionLabel={(option) => option?.value}
                value={filterExercise?.status}
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        option?.value === filterExercise?.status?.value
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.value}`}</Box>
                      </Box>
                    </li>
                  );
                }}
              />
              {isHr && (
                <AutoCompleteTagInput
                  label="Competency"
                  placeholder={
                    filterExercise?.competencyIds?.length
                      ? null
                      : "Select Competency"
                  }
                  options={competency}
                  selectedValue={filterExercise?.competencyIds}
                  getOptionLabel={(option) => option?.name}
                  filterOptions={(options, params) => {
                    // <<<--- inject the Select All option
                    const filter = createFilterOptions();
                    const filtered = filter(options, params);
                    if (competency.length === 0) {
                      return [{ name: "No options", all: false }];
                    }
                    return [{ name: "Select All", all: true }, ...filtered];
                  }}
                  onChange={(event, newValue) => {
                    const idCounts = newValue.reduce((counts, { id }) => {
                      counts[id] = (counts[id] || 0) + 1;
                      return counts;
                    }, {});
                    const uniqueNewValue = newValue.filter(({ id }) => {
                      return idCounts[id] === 1;
                    });
                    if (newValue.find((option) => option.all)) {
                      return handleChangeFilterExercise(
                        "competencyIds",
                        filterExercise?.competencyIds?.length ===
                          competency?.length
                          ? []
                          : competency
                      );
                    }
                    handleChangeFilterExercise("competencyIds", uniqueNewValue);
                  }}
                  renderOption={(props, option, value) => {
                    // Check if option is "No options" and render accordingly
                    if (option.name === "No options") {
                      return (
                        <Box className="auto-complete-no-options">
                          <Box>{option?.name}</Box>
                        </Box>
                      );
                    }
                    const optionChecked =
                      (option.all &&
                        filterExercise?.competencyIds?.length ===
                          competency?.length) ||
                      filterExercise?.competencyIds?.find(
                        (e) => e?.id === option?.id
                      ) != null;
                    return (
                      <li
                        {...props}
                        key={props?.id}
                        className={
                          optionChecked
                            ? "multi-tag-edit-list-selected"
                            : "multi-tag-style"
                        }
                      >
                        <Box className="multi-tag-input-list-padding">
                          <Checkbox
                            className="auto-complete-checkbox-list"
                            size="small"
                            checked={optionChecked}
                            color="secondary"
                          />
                          {option?.name}
                        </Box>
                      </li>
                    );
                  }}
                />
              )}
              {(hasAnyPermission || isSpoc || isHr) && (
                <SimpleAutoComplete
                  disabled={!isHr}
                  label="Bootcamp"
                  placeholder="Select Bootcamp"
                  options={bootcamp}
                  onChange={(event, newValue) =>
                    handleChangeFilterExercise("bootcampIds", newValue)
                  }
                  getOptionLabel={(option) => option?.name}
                  value={filterExercise?.bootcampIds}
                  renderOption={(props, option, value) => {
                    return (
                      <li
                        {...props}
                        key={props?.id}
                        className={
                          option?.id === filterExercise?.bootcampIds?.id
                            ? "multi-tag-edit-list-selected"
                            : "multi-tag-style"
                        }
                      >
                        <Box lineHeight={1.1}>
                          <Box>{`${option?.name}`}</Box>
                        </Box>
                      </li>
                    );
                  }}
                  tooltipTitle={filterExercise?.bootcampIds?.name}
                />
              )}
              {(hasAnyPermission || isSpoc || isHr) && (
                <AutoCompleteTagInput
                  label="Batches"
                  placeholder={
                    filterExercise?.batchIds?.length ? null : "Select Batch"
                  }
                  options={batches}
                  selectedValue={filterExercise?.batchIds}
                  getOptionLabel={(option) => `${option?.name}`}
                  filterOptions={(options, params) => {
                    // <<<--- inject the Select All option
                    const filter = createFilterOptions();
                    const filtered = filter(options, params);
                    if (batches.length === 0) {
                      return [{ name: "No options", all: false }];
                    }
                    return [{ name: "Select All", all: true }, ...filtered];
                  }}
                  onChange={(event, newValue) => {
                    const idCounts = newValue.reduce((counts, { id }) => {
                      counts[id] = (counts[id] || 0) + 1;
                      return counts;
                    }, {});
                    const uniqueNewValue = newValue.filter(({ id }) => {
                      return idCounts[id] === 1;
                    });
                    if (newValue.find((option) => option.all)) {
                      return handleChangeFilterExercise(
                        "batchIds",
                        filterExercise?.batchIds?.length === batches?.length
                          ? []
                          : batches
                      );
                    }
                    handleChangeFilterExercise("batchIds", uniqueNewValue);
                  }}
                  renderOption={(props, option, value) => {
                    // Check if option is "No options" and render accordingly
                    if (option.name === "No options") {
                      return (
                        <Box className="auto-complete-no-options">
                          <Box>{option?.name}</Box>
                        </Box>
                      );
                    }
                    const optionChecked =
                      (option.all &&
                        filterExercise?.batchIds?.length === batches?.length) ||
                      filterExercise?.batchIds?.find(
                        (e) => e?.id === option?.id
                      ) != null;
                    return (
                      <li
                        {...props}
                        key={props?.id}
                        className={
                          optionChecked
                            ? "multi-tag-edit-list-selected"
                            : "multi-tag-style"
                        }
                      >
                        <Box className="multi-tag-input-list-padding">
                          <Checkbox
                            className="auto-complete-checkbox-list"
                            size="small"
                            checked={optionChecked}
                            color="secondary"
                          />
                          {`${option?.name}`}
                        </Box>
                      </li>
                    );
                  }}
                />
              )}
              {!(isSpoc || (isSpoc && hasAnyPermission) || isHr) && (
                <Box>
                  <FormGroup>
                    {permissions?.sessionPresenter && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="secondary"
                            size="small"
                            checked={filterExercise?.showAttendees}
                            onChange={(e) =>
                              handleChangeFilterExercise(
                                "showAttendees",
                                e.target.checked
                              )
                            }
                          />
                        }
                        label="Show my attendees"
                        disabled={isOnlySessionPresenter}
                      />
                    )}
                    {permissions?.mentor && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="secondary"
                            size="small"
                            checked={filterExercise?.showMentees}
                            onChange={(e) =>
                              handleChangeFilterExercise(
                                "showMentees",
                                e.target.checked
                              )
                            }
                          />
                        }
                        label="Show my mentees"
                        disabled={isOnlyMentor}
                      />
                    )}

                    {permissions?.exerciseReviewer && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="secondary"
                            size="small"
                            checked={filterExercise?.showReviewees}
                            onChange={(e) =>
                              handleChangeFilterExercise(
                                "showReviewees",
                                e.target.checked
                              )
                            }
                          />
                        }
                        label="Show my reviewees"
                        disabled={isOnlyReviewer}
                      />
                    )}
                  </FormGroup>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item md={12} className="filter-padding filter-btn-container">
            <Button
              onClick={handleFilterExerciseSubmit}
              variant="contained"
              color="secondary"
              className="filter-btn margin-right-1"
            >
              <Typography variant="font_12_bold_700">Apply Filters</Typography>
            </Button>

            <Button
              onClick={handleFilterExerciseRemove}
              variant="outlined"
              color="secondary"
              className="filter-btn"
            >
              <Typography variant="font_12_bold_700">Reset</Typography>
            </Button>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};

export default ExerciseTabFilter;
