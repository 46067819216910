import React, { useEffect, useState } from "react";
import AssessmentMCQ from "../AssessmentMCQ/AssessmentMCQ";
import PlayInterview from "../PlayInterview/PlayInterview";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { getAssessmentEvaluationById } from "../../store/assessmentPlay";

const AssessmentPlay = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { evaluationId } = params;
  const [loading, setLoading] = useState(true);
  const evaluation = useSelector((state) => state?.assessmentPlay);
  const assessmentEvaluation = evaluation?.evaluationData?.data;
  const canViewSubmitAssessmentEvaluation =
    assessmentEvaluation?.permission?.canViewSubmitAssessmentEvaluation;
  const assessmentDetails = assessmentEvaluation?.assessment;
  const assessmentType = assessmentDetails?.type;

  useEffect(() => {
    const fetchQuestions = async () => {
      await dispatch(getAssessmentEvaluationById(evaluationId));
      setLoading(false);
    };

    fetchQuestions();
  }, []);

  if (loading || canViewSubmitAssessmentEvaluation === undefined) {
    return <Loader />;
  }

  if (canViewSubmitAssessmentEvaluation === false) {
    navigate("/error", {
      state: { isAccessDenied: true },
    });
    return;
  }

  return (
    <>
      {assessmentType === "MCQ" ? (
        <AssessmentMCQ />
      ) : assessmentType === "INTERVIEW" ? (
        <PlayInterview />
      ) : null}
    </>
  );
};

export default AssessmentPlay;
