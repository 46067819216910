import { apiVersion } from "../constants/constants";
import ApiService from "../utils/api-service";

export function addTopicApi(data) {
  const options = {
    method: "POST",
    url: `${apiVersion}/topics`,
    data: data,
  };
  return ApiService(options);
}

export function getTopicApi(data) {
  let apiUrl = `${apiVersion}/topics?offset=${
    data.offset * 20
  }&includeResources=true&sortBy=name`;
  if (data.name) {
    apiUrl += `&name=${data.name}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };

  return ApiService(options);
}

export function getTopicByIdApi(data) {
  const options = {
    method: "GET",
    url: `${apiVersion}/topics/${data.id}`,
    data: data,
  };
  return ApiService(options);
}

/////////////////////// for filter out data //////////////////////

export function editTopicApi(data) {
  const options = {
    method: "PATCH",
    url: `${apiVersion}/topics`,
    data: data,
  };
  return ApiService(options);
}

export function deleteTopicApi(id) {
  const options = {
    method: "DELETE",
    url: `${apiVersion}/topics/${id}`,
    data: id,
  };
  return ApiService(options);
}

export function lockTopicApi(id, locked) {
  const data = {
    id,
    locked,
  };
  const options = {
    method: "PATCH",
    url: `${apiVersion}/topics`,
    data: data,
  };
  return ApiService(options);
}

///////////////  Resources Data ////////////////////////////

export function addResourceApi(data) {
  const options = {
    method: "POST",
    url: `${apiVersion}/resources`,
    data: data,
  };
  return ApiService(options, true, true);
}

export function editResourceApi(data) {
  const options = {
    method: "PUT",
    url: `${apiVersion}/resources`,
    data: data,
  };
  return ApiService(options, true, true);
}

export function deleteResourceApi(id) {
  const options = {
    method: "DELETE",
    url: `${apiVersion}/resources/${id}`,
    data: id,
  };
  return ApiService(options);
}

export function getResourceByTopicIdApi(id) {
  let apiUrl = `${apiVersion}/resources?type=TOPIC&id=${id}`;
  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function cloneTopicApi(data) {
  const options = {
    method: "POST",
    url: `${apiVersion}/topics/clone`,
    data: data,
  };
  return ApiService(options);
}
