import { createTheme } from "@mui/material";
import { toggle_active_icon, toggle_inactive_icon } from "./constants/icons";

const defaultTheme = createTheme();
export const theme = createTheme({
  palette: {
    ...defaultTheme.palette,
    "@global": {
      body: {
        fontFamily: ["Poppins-regular", "sans-serif"].join(","),
      },
    },
    text: {
      primary: "#2E1C41",
    },
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#de1186",
      contrastText: "#fff",
    },
    offline: {
      main: "#3E2B82",
      contrastText: "#3E2B82",
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
    },
    warning: {
      main: "#FFAF00",
      contrastText: "#fff",
    },
    success: {
      main: "#05B14E",
    },
    lightGrayText: {
      main: "#948B9E",
      contrastText: "#2E1C41",
    },
    scheduled: {
      main: "#ffaf00",
      contrastText: "#fff",
    },
    completed: {
      main: "#05B14E",
      contrastText: "#fff",
    },
    active: {
      main: "#3087E9",
      contrastText: "#fff",
    },
    contrastText: "#2E1C41",
  },
  typography: {
    fontFamily: ["Poppins-regular", "sans-serif"].join(","),
    fontSize: 16,
    htmlFontSize: 16,
    defaultProps: {
      fontFamily: ["Poppins-regular", "sans-serif"].join(","),
    },
    h4: {
      ///////////////////////////////// font 20
      fontSize: "1.25rem",
    },
    body1: {
      /////////////////////////////// font  16
      fontSize: "1rem",
    },
    body2: {
      ////////////////////////////// font 14
      fontSize: "0.875rem",
    },
    caption: {
      ///////////////////////////// font 12
      fontSize: "0.75rem",
    },
    tableHead: {
      //////////////////////////// font 13
      fontSize: "0.813rem",
      fontWeight: 500,
    },
    grayEmail: {
      /////////////////////////// font 9
      fontSize: "0.563rem",
      fontWeight: 400,
      color: "#948B9E",
    },
    outlineBtnLabel: {
      /////////////////////////// font 11
      fontSize: "0.688rem",
      fontWeight: 700,
    },

    uploadFile: {
      fontSize: "1.31rem",
      fontWeight: 500,
      marginRight: "0.8rem",
    },
    //////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////
    font_20: {
      fontSize: "1.25rem",
      fontWeight: 400,
    },
    font_20_bold: {
      fontSize: "1.25rem",
      fontWeight: 500,
    },
    font_20_bold_700: {
      fontSize: "1.25rem",
      fontWeight: 700,
    },
    font_21_bold: {
      fontSize: "1.313rem",
      fontWeight: 500,
    },
    font_16: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      fontSize: "1rem",
      fontWeight: 400,
    },
    font_16_bold: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    font_16_bold_700: {
      fontSize: "1rem",
      fontWeight: 700,
    },
    font_15: {
      fontSize: "0.938rem",
      fontWeight: 400,
    },
    font_15_bold: {
      fontSize: "0.938rem",
      fontWeight: 500,
    },

    font_15_bold_600: {
      fontSize: "0.938rem",
      fontWeight: 600,
    },
    font_14: {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
    font_14_bold: {
      fontSize: "0.875rem",
      fontWeight: 500,
    },
    font_13: {
      fontSize: "0.813rem",
      fontWeight: 400,
    },
    font_13_bold: {
      fontSize: "0.813rem",
      fontWeight: 500,
    },
    font_12: {
      fontSize: "0.75rem",
      fontWeight: 400,
    },
    font_12_bold: {
      fontSize: "0.75rem",
      fontWeight: 500,
    },
    font_12_bold_600: {
      fontSize: "0.75rem",
      fontWeight: 600,
    },
    font_12_bold_700: {
      fontSize: "0.75rem",
      fontWeight: 700,
    },
    font_11: {
      fontSize: "0.688rem",
      fontWeight: 400,
    },
    font_11_bold: {
      fontSize: "0.688rem",
      fontWeight: 500,
    },
    font_11_bold_700: {
      fontSize: "0.688rem",
      fontWeight: 700,
    },
    font_10: {
      fontSize: "0.625rem",
      fontWeight: 400,
    },
    font_10_bold: {
      fontSize: "0.625rem",
      fontWeight: 500,
    },
    font_10_bold_700: {
      fontSize: "0.625rem",
      fontWeight: 700,
    },
    font_9: {
      fontSize: "0.563rem",
      fontWeight: 400,
    },
    font_9_bold: {
      fontSize: "0.563rem",
      fontWeight: 500,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      twoK: 2000,
      fourK: 3800,
    },
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 28,
          height: 16,
          padding: 0,
          margin: 8,
        },
        switchBase: {
          padding: 1,
          transform: "translate(-1px, -1px) !important",
          "&.Mui-checked": {
            transform: "translate(11px, -1px) !important",
            color: "#05B14E",
            "& + .MuiSwitch-track": {
              backgroundColor: "#05B14E",
              opacity: 1,
              border: "2px solid #05B14E",
            },
          },
          "&:not(.Mui-checked)": {
            color: "#fff",
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.5,
          },
        },
        thumb: {
          width: "0.625rem !important",
          height: "0.625rem !important",
          background: "#fff",
          boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        },
        track: {
          borderRadius: 10,
          backgroundColor: "#ababab",
          opacity: 1,
          transition: defaultTheme.transitions.create([
            "background-color",
            "border",
          ]),
          position: "relative",
          "&::before, &::after": {
            content: '""',
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            backgroundRepeat: "no-repeat",
          },
          "&::before": {
            backgroundImage: `url(${toggle_active_icon})`,
            left: 3,
            width: 8,
            height: 6,
          },

          "&::after": {
            backgroundImage: `url(${toggle_inactive_icon})`,
            right: 3,
            width: 8,
            height: 8,
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        fontFamily: ["Poppins", "sans-serif"].join(","),
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1rem",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        label: {
          overflow: "visible",
          textOverflow: "inherit",
          padding: "0 10px",
        },
      },
      variants: [
        {
          props: { variant: "home-ACTIVE" },
          style: {
            textTransform: "none",
            borderRadius: "0.188rem",
            height: "0.938rem",
            backgroundColor: "#05B14E",
            color: "#FFF",
            fontSize: "0.563rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "home-CANCELLED" },
          style: {
            textTransform: "none",
            borderRadius: "0.188rem",
            height: "0.938rem",
            backgroundColor: "#D70C0C",
            color: "#FFF",
            fontSize: "0.563rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "home-SCHEDULED" },
          style: {
            textTransform: "none",
            borderRadius: "0.188rem",
            height: "0.938rem",
            backgroundColor: "#FFAF00",
            color: "#fff",
            fontSize: "0.563rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "home-ONLINE" },
          style: {
            textTransform: "none",
            border: "0.1rem solid #05B14E",
            borderRadius: "0.188rem",
            height: "0.938rem",
            backgroundColor: "transparent",
            color: "#05B14E",
            fontSize: "0.563rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "home-ACTIVE" },
          style: {
            textTransform: "none",
            borderRadius: "0.188rem",
            height: "0.938rem",
            backgroundColor: "#3087E9",
            color: "#FFF",
            fontSize: "0.563rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "home-COMPLETED" },
          style: {
            textTransform: "none",
            borderRadius: "0.188rem",
            height: "0.938rem",
            backgroundColor: "#05B14E",
            color: "#fff",
            fontSize: "0.563rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "home-ROLE" },
          style: {
            textTransform: "none",
            borderRadius: "0.188rem",
            height: "0.938rem",
            fontSize: "0.563rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "ONLINE" },
          style: {
            textTransform: "none",
            border: "0.1rem solid #05B14E",
            borderRadius: "0.188rem",
            height: "0.938rem",
            backgroundColor: "transparent",
            color: "#05B14E",
            fontSize: "0.563rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "IN_REVIEW" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.375rem",
            backgroundColor: "#FFAF00",
            color: "#FFFFFF",
            fontSize: "0.625rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "PENDING" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.375rem",
            backgroundColor: "#3087E9",
            color: "#FFFFFF",
            fontSize: "0.625rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "NOT_MARKED" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.375rem",
            backgroundColor: "#3087E9",
            color: "#000000",
            fontSize: "0.563rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "REVIEWED" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.375rem",
            backgroundColor: "#05B14E",
            color: "#FFFFFF",
            fontSize: "0.625rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "RED" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.188rem",
            backgroundColor: "#D70C0C",
            color: "#FFFFFF",
            fontSize: "0.625rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "RED-FULL-WIDTH" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.875rem",
            backgroundColor: "#D70C0C",
            color: "#FFFFFF",
            fontSize: "0.75rem",
            fontWeight: 500,
            width: "100%",
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "RED-TEXT" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.375rem",
            backgroundColor: "#D70C0C",
            color: "#FFFFFF",
            fontSize: "0.625rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "BLUE" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.188rem",
            backgroundColor: "#3087E9",
            color: "#FFFFFF",
            fontSize: "0.625rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "BLUE-FULL-WIDTH" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.875rem",
            backgroundColor: "#3087E9",
            color: "#FFFFFF",
            fontSize: "0.75rem",
            fontWeight: 500,
            width: "100%",
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "BLUE-TEXT" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.375rem",
            backgroundColor: "#3087E9",
            color: "#FFFFFF",
            fontSize: "0.625rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "GREEN" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.188rem",
            backgroundColor: "#009531",
            color: "#FFFFFF",
            fontSize: "0.625rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "GREEN-FULL-WIDTH" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.875rem",
            backgroundColor: "#009531",
            color: "#FFFFFF",
            fontSize: "0.75rem",
            fontWeight: 500,
            width: "100%",
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "GREEN-TEXT" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.375rem",
            backgroundColor: "#009531",
            color: "#FFFFFF",
            fontSize: "0.625rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "YELLOW" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.188rem",
            backgroundColor: "#FFAF00",
            color: "#FFFFFF",
            fontSize: "0.625rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "YELLOW-TEXT" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.375rem",
            backgroundColor: "#FFAF00",
            color: "#FFFFFF",
            fontSize: "0.625rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "GREY" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.188rem",
            backgroundColor: "#7A6F88",
            color: "#FFFFFF",
            fontSize: "0.625rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "REOPENED" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.375rem",
            backgroundColor: "#D70C0C",
            color: "#FFFFFF",
            fontSize: "0.625rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "HARD" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.188rem",
            backgroundColor: "#D70C0C",
            color: "#FFFFFF",
            fontSize: "0.625rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "EASY" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.188rem",
            backgroundColor: "#009531",
            color: "#FFFFFF",
            fontSize: "0.625rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "MEDIUM" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.188rem",
            backgroundColor: "#FFAF00",
            color: "#FFFFFF",
            fontSize: "0.625rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "QUEST_TYPE" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.188rem",
            border: "1px solid #675B76",
            backgroundColor: "transparent",
            color: "#675B76",
            fontSize: "0.625rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
        {
          props: { variant: "MORE_INFO" },
          style: {
            textTransform: "none",
            borderRadius: "0.313rem",
            height: "1.188rem",
            border: "1px solid #675B76",
            backgroundColor: "transparent",
            color: "#675B76",
            fontSize: "0.625rem",
            fontWeight: 500,
          },
          label: {
            padding: 0,
          },
        },
      ],
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root.Mui-focused": {
            input: {
              "&::placeholder": {
                opacity: 0.5,
              },
            },
            textarea: {
              "&::placeholder": {
                opacity: 0.5,
              },
            },
          },
          "& .MuiInputLabel-root": {
            color: "#2E1C41",
            opacity: 1,
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: "#de1186",
            opacity: 1,
          },
          "& .MuiInputLabel-root.Mui-disabled": {
            color: "#2E1C41",
            opacity: 0.5,
          },
        },
      },
    },

    MuiPickersCalendarHeader: {
      styleOverrides: {
        switchViewIcon: {
          color: "#de1186",
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&.MuiPickersDay-root.Mui-selected": {
            color: "#fff",
            backgroundColor: "#de1186",
          },
          "&:hover": {
            color: "#fff",
            backgroundColor: "#de1186",
          },
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        root: {
          "& .MuiPickersYear-yearButton": {
            "&.Mui-selected": {
              color: "#fff",
              backgroundColor: "#de1186",
            },
          },
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        root: {
          button: {
            color: "#de1186",
          },
        },
      },
    },
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          "& .MuiPickersLayout-actionBar": {
            "& .MuiButton-root": {
              color: "#de1186",
            },
          },
        },
      },
    },
    MuiMultiSectionDigitalClockSection: {
      styleOverrides: {
        root: {
          "& .MuiMultiSectionDigitalClockSection-item.Mui-selected": {
            color: "#fff",
            backgroundColor: "#de1186",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          "& .MuiAutocomplete-option": {
            display: "flex",
            alignItems: "flex-start",
          },
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: "#e3f2fd",
          },
          '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
            backgroundColor: "#bbdefb",
          },
        },
        tag: {
          "&.MuiAutocomplete-tag": {
            height: "auto",
          },
          "& .MuiChip-label": {
            wordBreak: "break-word",
            whiteSpace: "pre-wrap",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          "& .MuiMenuItem-root": {
            "&.Mui-selected": {
              backgroundColor: "#e3f2fd",

              "&:hover": {
                backgroundColor: "#bbdefb",
              },
            },
          },
        },
      },
    },
  },
});
