import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import {
  addSubmitDetailsApi,
  getBootcampListApi,
  getExercisesListApi,
  getFiltersDataApi,
  getOptionsForEditReviewerApi,
} from "../services/exercises";
import CustomToaster from "../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../constants/constants";

const initialState = {
  exercisesList: [],
  bootcampData: [],
  loading: false,
  filtersDataLoading: false,
  FiltersDataList: [],
  addSubmitDetailsLoading: false,
  error: null,
  editReviewerLoading: false,
  optionsForEditReviewer: null,
};

export const getExercisesList = createAsyncThunk(
  "getExercisesList",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getExercisesListApi(data);
      return callApi;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const addSubmitDetails = createAsyncThunk(
  "addSubmitDetails",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await addSubmitDetailsApi(data);
      toast.custom(
        <CustomToaster
          message={
            data?.type === "SUBMIT"
              ? "Exercise Submitted Successfully"
              : data?.type === "REVIEW"
              ? "Exercise Reviewed Successfully"
              : data?.type === "EDIT_SCORE"
              ? "Exercise Score Edited Successfully"
              : data?.type === "EDIT_REVIEWER"
              ? "Reviewer Edited Successfully"
              : data?.type === "REOPEN"
              ? "Exercise Reopened Successfully"
              : "Details Submitted Successfully"
          }
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getFiltersData = createAsyncThunk(
  "getFiltersData",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getFiltersDataApi(data);
      return callApi;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getBootcampList = createAsyncThunk(
  "getBootcampList",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getBootcampListApi(data);
      return callApi;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getOptionsForEditReviewer = createAsyncThunk(
  "getOptionsForEditReviewer",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getOptionsForEditReviewerApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const exercisesList = createSlice({
  name: "exercisesList",
  initialState,
  reducers: {
    clearFilterExercises: (state) => {
      state.FiltersDataList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExercisesList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExercisesList.fulfilled, (state, action) => {
        state.loading = false;
        state.exercisesList = action.payload;
      })
      .addCase(getExercisesList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addSubmitDetails.pending, (state) => {
        state.addSubmitDetailsLoading = true;
      })
      .addCase(addSubmitDetails.fulfilled, (state, action) => {
        state.addSubmitDetailsLoading = false;
      })
      .addCase(addSubmitDetails.rejected, (state, action) => {
        state.addSubmitDetailsLoading = false;
        state.error = action.payload;
      })
      .addCase(getBootcampList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBootcampList.fulfilled, (state, action) => {
        state.loading = false;
        state.bootcampData = action.payload;
      })
      .addCase(getBootcampList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getFiltersData.pending, (state) => {
        state.filtersDataLoading = true;
      })
      .addCase(getFiltersData.fulfilled, (state, action) => {
        state.filtersDataLoading = false;
        state.FiltersDataList = action.payload;
      })
      .addCase(getFiltersData.rejected, (state, action) => {
        state.filtersDataLoading = false;
        state.error = action.payload;
      })
      .addCase(getOptionsForEditReviewer.pending, (state) => {
        state.editReviewerLoading = true;
      })
      .addCase(getOptionsForEditReviewer.fulfilled, (state, action) => {
        state.editReviewerLoading = false;
        state.optionsForEditReviewer = action.payload;
      })
      .addCase(getOptionsForEditReviewer.rejected, (state) => {
        state.editReviewerLoading = false;
      });
  },
});

export const { clearFilterExercises } = exercisesList.actions;
export default exercisesList.reducer;
