import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";
import { Link, useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getDate } from "./../../utils/utils";
const BMVInfo = ({ infoData, isSessionListAllowed, isAttendeeListAllowed }) => {
  const { bootcampId, moduleId } = useParams();

  //////////// handle view more ////////////////////////////////
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const [viewMore, setViewMore] = useState(false);
  const handleViewMoreClick = (value) => {
    setViewMore(!viewMore);
  };

  const wordCount = infoData?.description?.length;
  const countLines = infoData?.description?.split(/\r|\r\n|\n/);
  const lineLength = countLines?.length;
  const attendeeCountForBootcamp = !!infoData?.batchAttendees[bootcampId]
    ? infoData?.batchAttendees[bootcampId]
    : 0;
  /////////////////////////////////////////////////////////////

  return (
    <Box className="margin-bottom-5">
      <Grid container className="bmv-container">
        <Grid item md={7} className="bmv-content">
          <Box className="bmv-info-box">
            <Typography variant="font_12" className="grey-text">
              Description
            </Typography>
            <Collapse
              className={
                !(wordCount > 200 || lineLength > 3) ? "adjusted-height" : null
              }
              in={viewMore}
              collapsedSize={wordCount > 200 || lineLength > 3 ? 50 : "auto"}
            >
              <Typography
                variant="font_13"
                sx={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                }}
              >
                {infoData?.description ? infoData?.description : "-"}
              </Typography>
            </Collapse>
            {wordCount > 200 || lineLength > 3 ? (
              <Box onClick={handleViewMoreClick}>
                <Typography
                  variant="font_9_bold"
                  color="secondary"
                  cursor="pointer"
                  sx={{ fontWeight: 600 }}
                >
                  {viewMore ? "VIEW LESS" : "VIEW MORE"}
                </Typography>
                <ExpandMore
                  expand={viewMore}
                  sx={{ padding: 0, verticalAlign: "sub" }}
                >
                  <ExpandMoreIcon
                    color="secondary"
                    sx={{
                      fontSize: "1rem",
                    }}
                  />
                </ExpandMore>
              </Box>
            ) : null}
          </Box>
        </Grid>
        <Grid item md={5} className="bmv-right-info">
          <Grid container item md={12} sx={{ height: "33.334%" }}>
            <Grid item md={6} className="bmv-info-box1">
              <Box className="bmv-info-box">
                <Typography variant="font_12" className="grey-text">
                  Start Date
                </Typography>
                <Typography variant="font_14">
                  {getDate(infoData?.startDate)}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} className="bmv-info-box2">
              <Box className="bmv-info-box">
                <Typography variant="font_12" className="grey-text">
                  End Date
                </Typography>
                <Typography variant="font_14">
                  {getDate(infoData?.endDate)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item md={12} sx={{ height: "33.3334%" }}>
            <Grid item md={6} className="bmv-info-box3">
              <Box className="bmv-info-box">
                <Typography variant="font_12" className="grey-text">
                  Created By
                </Typography>
                <Typography variant="font_14">
                  {infoData?.createdBy?.fullName}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} className="bmv-info-box4">
              <Box className="bmv-info-box">
                <Typography variant="font_12" className="grey-text">
                  Total Sessions
                </Typography>
                <BootstrapTooltip title="Show Sessions">
                  <Link
                    to={
                      isSessionListAllowed &&
                      `/bootcamp/${bootcampId}/module/${moduleId}/sessions`
                    }
                  >
                    <Typography
                      variant="font_14"
                      color={isSessionListAllowed && "secondary"}
                      sx={{
                        cursor: isSessionListAllowed ? "pointer" : "auto",
                      }}
                    >
                      {isSessionListAllowed ? (
                        <u>{infoData?.sessions?.totalResults}</u>
                      ) : (
                        infoData?.sessions?.totalResults
                      )}
                    </Typography>
                  </Link>
                </BootstrapTooltip>
              </Box>
            </Grid>
          </Grid>
          <Grid container item md={12} sx={{ height: "33.334%" }}>
            <Grid item md={6} className="bmv-info-box5">
              <Box className="bmv-info-box">
                <Typography variant="font_12" className="grey-text">
                  Attendees From Bootcamp
                </Typography>
                {isAttendeeListAllowed && attendeeCountForBootcamp ? (
                  <BootstrapTooltip title="Show Attendees">
                    <Link
                      to={`/bootcamp/${bootcampId}/module/${moduleId}/attendees`}
                    >
                      <Typography variant="font_14" color="secondary">
                        <u>{attendeeCountForBootcamp}</u>
                      </Typography>
                    </Link>
                  </BootstrapTooltip>
                ) : (
                  <Typography variant="font_14">
                    {attendeeCountForBootcamp}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item md={6} className="bmv-info-box6">
              <Box className="bmv-info-box">
                <Typography variant="font_12" className="grey-text">
                  Attendees from Module
                </Typography>
                {
                  <Typography variant="font_14">
                    {infoData?.batchAttendees?.total}
                  </Typography>
                }
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BMVInfo;
