import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import * as securityService from "../services/security";
import Constants, {LOCAL_STORAGE_KEYS} from "../constants/constants";
import { getConstants } from "./utility";

const {authCookieName, cookieDomain, env} = require("../config/config");

export const cookies = new Cookies(null, {path: "/"});

const initialState = {
  isUserLoggedIn: cookies.get(authCookieName) || false,
  loggedInUser: null,
  roles: [],
  permission: null,
  loading: false,
  error: null,
};

export const validateAuthToken = createAsyncThunk(
  "validateAuthToken",
  async (data, {rejectWithValue, dispatch}) => {
    const isValidateToken = await securityService.validateAuthToken(cookies.get(authCookieName));
    try {
      if (isValidateToken?.data?.valid) {
        dispatch(loginUser());
        await dispatch(getLoggedInUser());
        await dispatch(getConstants());
      } else {
        dispatch(logoutUser());
      }
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getLoggedInUser = createAsyncThunk(
  "getLoggedInUser",
  async (data, {rejectWithValue}) => {
    try {
      return await securityService.getLoggedInUser();
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const security = createSlice({
  name: "security",
  initialState,
  reducers: {
    loginUser: (state) => {
      state.isUserLoggedIn = true;
      localStorage.setItem(LOCAL_STORAGE_KEYS.USER_LOGGED_IN, "true");
    },
    logoutUser: (state) => {
      if (authCookieName === Constants.IMPERSONATECOOKIEKEY(env)) {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.BOOTCAMP_SWITCH_USER);
        cookies.remove(authCookieName, {path: "/", domain: cookieDomain});
        window.location.href = "/";
      } else {
        state.isUserLoggedIn && localStorage.setItem(LOCAL_STORAGE_KEYS.USER_LOGOUT, true);
        localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_LOGGED_IN);
        state.isUserLoggedIn = false;
        cookies.remove(authCookieName, {path: "/", domain: cookieDomain});
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(validateAuthToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(validateAuthToken.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(validateAuthToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getLoggedInUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLoggedInUser.fulfilled, (state, action) => {
        state.loading = false;
        state.loggedInUser = action.payload?.data;
        state.roles = action?.payload?.data?.roles?.results || [];
        state.permission = action?.payload?.data?.permission || [];
      })
      .addCase(getLoggedInUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {loginUser, logoutUser} = security.actions;
export default security.reducer;