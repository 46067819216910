import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as utilityService from "../services/utility";

const initialState = {
  constants: {},
  loading: false,
  error: null,
};

export const getConstants = createAsyncThunk(
  "getConstants",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      return await utilityService.getConstants();
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const utility = createSlice({
  name: "utility",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getConstants.pending, (state) => {
        state.loading = true;
      })
      .addCase(getConstants.fulfilled, (state, action) => {
        state.loading = false;
        state.constants = action.payload?.data;
      })
      .addCase(getConstants.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default utility.reducer;
