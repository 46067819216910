import React, { useMemo, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TablePagination from "../../../components/TablePagination/TablePagination";
import NoDataAvailable from "../../../components/NoDataAvailable/NoDataAvailable";
import { backendBaseUrl } from "../../../config/config";
import BootstrapTooltip from "../../../components/BootstrapTooltip/BootstrapTooltip";
import CopyToClipboard from "../../../components/CopyToClipboard/CopyToClipboard";
import { gitUrlPattern } from "../../../utils/utils";

const DetailsTabResources = ({ resources }) => {
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const totalResult = resources?.length;
  const handlePageChange = (value) => {
    setCurrentPage(value);
  };
  const tableList = useMemo(() => {
    setTotalPages(Math.ceil(totalResult / 5));
    const firstPageIndex = (currentPage - 1) * 5;
    const lastPageIndex = firstPageIndex + 5;
    return resources?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, resources]);

  const onResourceViewClick = (url) => window.open(`${url}`, "_blank");

  return (
    <Grid item md={12}>
      <Accordion
        className="accordion"
        sx={{
          "& .MuiAccordion-root.Mui-expanded:last-of-type": {
            marginBottom: "1rem",
          },
        }}
      >
        <AccordionSummary
          className="accordion-summary"
          sx={{ height: "3.938rem !important" }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="font_15_bold_600">Resources</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordion-details">
          {resources?.length ? (
            <>
              <TableContainer className="table-container">
                <Table stickyHeader>
                  <TableHead className="table-head-container">
                    <TableRow className="table-head-row">
                      <TableCell
                        align="left"
                        sx={{ minWidth: "18rem" }}
                        className="padding-left-1"
                      >
                        <Typography variant="font_13_bold">Name</Typography>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ minWidth: "12rem" }}
                        className="padding-left-1"
                      >
                        <Typography variant="font_13_bold">
                          Resource Link
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableList?.map((data) => {
                      const isGitUrl = gitUrlPattern?.test(data?.url);
                      return (
                        <TableRow key={data?.id} className="table-body-row">
                          <TableCell align="left" className="padding-left-1">
                            <Typography variant="font_12">
                              {data?.name}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" className="padding-left-1">
                            <CopyToClipboard text={isGitUrl ? data?.url : ""}>
                              <BootstrapTooltip
                                title={
                                  data?.format === "URL" ? `${data?.url}` : ``
                                }
                              >
                                <Button
                                  onClick={
                                    !isGitUrl
                                      ? () =>
                                          onResourceViewClick(
                                            `${
                                              data?.format === "URL"
                                                ? ""
                                                : backendBaseUrl
                                            }${data?.url}`
                                          )
                                      : undefined
                                  }
                                  TypographyClasses
                                  color="secondary"
                                  rel="noopener noreferrer"
                                  variant="outlined"
                                  className="link-btn"
                                  sx={{
                                    width: "6.5rem",
                                    margin: "0 1rem",
                                  }}
                                >
                                  <Typography
                                    variant="font_10_bold_700"
                                    sx={{
                                      textTransform: "uppercase",
                                      minWidth: "6.25rem",
                                    }}
                                  >
                                    {data?.format === "URL"
                                      ? isGitUrl
                                        ? "Copy Link"
                                        : "View Link"
                                      : "Download File"}
                                  </Typography>
                                </Button>
                              </BootstrapTooltip>
                            </CopyToClipboard>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container className="table-pagination-container">
                <Typography variant="font_16">
                  Total {totalResult} {totalResult > 1 ? "Records" : "Record"}
                </Typography>
                <TablePagination
                  totalPages={totalPages}
                  handlePageChange={handlePageChange}
                  currentPage={currentPage}
                />
              </Grid>
            </>
          ) : (
            <Grid container className="no-data-container">
              <NoDataAvailable
                imgHeight={4}
                imgWidth={4.5}
                message="No Resources Available"
              />
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default DetailsTabResources;
