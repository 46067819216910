import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";

import {
  deleteSessionApi,
  getBatchesApi,
  getDetailsApi,
  getPresentersApi,
  getResourcesApi,
} from "../../services/SessionView/detailsTab";
import CustomToaster from "../../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../../constants/constants";

const initialState = {
  details: [],
  resources: [],
  attendees: [],
  exerciseStatus: [],
  presenters: [],
  loading: false,
  error: null,
  batchIds: [],
};

export const getDetails = createAsyncThunk(
  "getDetails",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getDetailsApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getResources = createAsyncThunk(
  "getResources",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getResourcesApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPresenters = createAsyncThunk(
  "getPresenters",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getPresentersApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSession = createAsyncThunk(
  "deleteSession",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const callApi = await deleteSessionApi(data);
      toast.custom(
        <CustomToaster
          message="Session Deleted Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBatches = createAsyncThunk(
  "getBatches",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getBatchesApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const detailsTab = createSlice({
  name: "detailsTab",
  initialState,
  reducers: {
    clearDetails(state) {
      state.details = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
      })
      .addCase(getDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getResources.pending, (state) => {
        state.loading = true;
      })
      .addCase(getResources.fulfilled, (state, action) => {
        state.loading = false;
        state.resources = action.payload;
      })
      .addCase(getResources.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getPresenters.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPresenters.fulfilled, (state, action) => {
        state.loading = false;
        state.presenters = action.payload;
      })
      .addCase(getPresenters.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBatches.pending, (state) => {
        state.batchLoading = true;
      })
      .addCase(getBatches.fulfilled, (state, action) => {
        state.batchLoading = false;
        state.batchIds = action.payload;
      })
      .addCase(getBatches.rejected, (state, action) => {
        state.batchLoading = false;
        state.error = action.payload;
      });
  },
});

export const { clearDetails } = detailsTab.actions;
export default detailsTab.reducer;
