import React, { useState } from "react";
import "./navbar.scss";
import {
  AppBar,
  Avatar,
  Box,
  Grid,
  IconButton
} from "@mui/material";
import {
  down_arrow_icon,
  drawer_active_icon,
  drawer_default_icon,
  menu_bar_icon,
  nw_bootcamp_logo,
  nw_small_logo,
} from "../../constants/icons";
import { Link } from "react-router-dom";

import AppDrawer from "../AppDrawer/AppDrawer";
import RoundButton from "../RoundButton/RoundButton";
import { Icons, MobileDrawer, StyledToolbar } from "./NavbarUtils";
import UserDrawer from "../UserDrawer/UserDrawer";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";
import { useSelector } from "react-redux";

const Navbar = () => {
  const loggedInUser = useSelector((state) => state?.security?.loggedInUser);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userDrawer, setUserDrawer] = useState(false);

  const drawerHandleClose = () => {
    setDrawerOpen(false);
  };
  const handleDrawerClick = (event) => {
    setDrawerOpen(event.currentTarget);
    setUserDrawer(false);
  };

  const handleUserDrawer = () => {
    setUserDrawer(!userDrawer);
  };

  return (
    <AppBar
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      position="sticky"
      className="navbar-container"
      p={0}
    >
      <StyledToolbar>
        <Link to="/">
          <Grid className="navbar-logo">
            <Box
              className="nw-full-logo"
              sx={{
                display: { xs: "none", sm: "none", md: "block" },
                backgroundImage: `url(${nw_bootcamp_logo})`,
              }}
            ></Box>
            <Box
              className="nw-small-logo"
              sx={{
                display: { xs: "block", sm: "block", md: "none" },
                backgroundImage: `url(${nw_small_logo})`,
              }}
            ></Box>
          </Grid>
        </Link>

        <Icons>
          <BootstrapTooltip
            title="Application Drawer"
            className="drawer-tooltip"
          >
            <IconButton
              id="appDrawer"
              className="icon-button"
              onClick={handleDrawerClick}
            >
              <RoundButton
                height={2.5}
                width={2.5}
                imageDefault={drawer_default_icon}
                imageHover={drawer_active_icon}
              />
            </IconButton>
          </BootstrapTooltip>

          <Grid className="nav-user-logo" onClick={handleUserDrawer}>
            <Avatar id="user-avatar" src={loggedInUser?.profilePictureUrl} />
            <Box
              className="user-avatar-down-icon"
              sx={{
                backgroundImage: `url(${down_arrow_icon})`,
              }}
            ></Box>
          </Grid>
        </Icons>

        <MobileDrawer>
          <Box
            className="mobile-drawer-icon"
            sx={{
              backgroundImage: `url(${menu_bar_icon})`,
            }}
          ></Box>
        </MobileDrawer>
      </StyledToolbar>

      <AppDrawer
        id="appDrawer"
        open={drawerOpen}
        handleClose={drawerHandleClose}
      />
      <UserDrawer open={userDrawer} onClose={handleUserDrawer} />
    </AppBar>
  );
};

export default Navbar;