import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addResourceApi,
  addTopicApi,
  cloneTopicApi,
  deleteResourceApi,
  deleteTopicApi,
  editResourceApi,
  editTopicApi,
  getResourceByTopicIdApi,
  getTopicApi,
  getTopicByIdApi,
  lockTopicApi,
} from "../services/topic";
import { toast } from "react-hot-toast";
import CustomToaster from "../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../constants/constants";

const initialState = {
  topicData: [],
  loading: false,
  topicError: false,
  error: null,
  resources: [],
  resourcesLoading: false,
  singleTopicData: [],
};

export const getTopic = createAsyncThunk(
  "getTopic",
  async (data, { rejectWithValue }) => {
    const { offset, name } = data;
    try {
      const callApi = await getTopicApi({ offset: offset, name: name });
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addTopic = createAsyncThunk(
  "addTopic",
  async (data, { dispatch, rejectWithValue }) => {
    const { id, name, description, agenda, offset, filterBy } = data;
    try {
      const callApi = await addTopicApi({ id, name, description, agenda });
      if (callApi) {
        await dispatch(getTopic({ offset: offset, name: filterBy }));
        toast.custom(
          <CustomToaster
            message="Topic Added Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTopicById = createAsyncThunk(
  "getTopicById",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getTopicByIdApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editTopic = createAsyncThunk(
  "editTopic",
  async (data, { dispatch, rejectWithValue }) => {
    const { id, name, description, agenda, offset, filterBy } = data;
    try {
      const callApi = await editTopicApi({ id, name, description, agenda });
      if (callApi) {
        await dispatch(getTopic({ offset: offset, name: filterBy }));
        toast.custom(
          <CustomToaster
            message="Topic Updated Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTopic = createAsyncThunk(
  "deleteTopic",
  async (data, { dispatch, rejectWithValue }) => {
    const { getListId, offset, filterBy } = data;
    try {
      const callApi = await deleteTopicApi(getListId);
      if (callApi) {
        await dispatch(getTopic({ offset: offset, name: filterBy }));
        toast.custom(
          <CustomToaster
            message="Topic Deleted Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const lockTopic = createAsyncThunk(
  "lockTopic",
  async (data, { dispatch, rejectWithValue }) => {
    const { id, locked, offset, filterBy } = data;
    try {
      const callApi = await lockTopicApi(id, locked);
      if (callApi) {
        await dispatch(getTopic({ offset: offset, name: filterBy }));
        toast.custom(
          <CustomToaster
            message={
              !!locked
                ? "Topic Locked Successfully"
                : "Topic UnLocked Successfully"
            }
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addResource = createAsyncThunk(
  "addResource",
  async (data, { dispatch, rejectWithValue }) => {
    const { json, files, offset, filterBy } = data;
    try {
      const callApi = await addResourceApi({ json, files });
      if (callApi) {
        await dispatch(getTopic({ offset: offset, name: filterBy }));
        toast.custom(
          <CustomToaster
            message="Resource Added Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteResource = createAsyncThunk(
  "deleteResource",
  async (data, { dispatch, rejectWithValue }) => {
    const { getListId, offset, filterBy } = data;
    try {
      const callApi = await deleteResourceApi(getListId);
      if (callApi) {
        await dispatch(getTopic({ offset: offset, name: filterBy }));
        toast.custom(
          <CustomToaster
            message="Resource Deleted Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getResourceByTopicId = createAsyncThunk(
  "getResourceByTopicId",
  async (id, { rejectWithValue }) => {
    try {
      const callApi = await getResourceByTopicIdApi(id);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const cloneTopic = createAsyncThunk(
  "cloneTopic",
  async (data, { dispatch, rejectWithValue }) => {
    const {
      id,
      name,
      description,
      agenda,
      offset,
      filterBy,
      cloneAssessmentQuestions,
      cloneExerciseQuestions,
      cloneResources,
    } = data;
    try {
      const callApi = await cloneTopicApi({
        id,
        name,
        description,
        agenda,
        cloneAssessmentQuestions,
        cloneExerciseQuestions,
        cloneResources,
      });
      if (callApi) {
        await dispatch(getTopic({ offset: offset, name: filterBy }));
        toast.custom(
          <CustomToaster
            message="Topic Cloned Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editResource = createAsyncThunk(
  "editResource",
  async (data, { dispatch, rejectWithValue }) => {
    const { json, files } = data;
    try {
      const callApi = await editResourceApi({ json, files });

      if (callApi) {
        await dispatch(getResourceByTopicId(json?.id));
        toast.custom(
          <CustomToaster
            message="Resource Edited Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const topic = createSlice({
  name: "topic",
  initialState,
  reducers: {
    clearTopicData(state) {
      state.topicData = [];
    },
    clearResources(state) {
      state.resources = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTopic.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTopic.fulfilled, (state, action) => {
        state.loading = false;
        state.topicData = action.payload;
      })
      .addCase(getTopic.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addTopic.pending, (state) => {
        state.topicError = false;
        state.loading = true;
      })
      .addCase(addTopic.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addTopic.rejected, (state, action) => {
        state.loading = false;
        state.topicError = true;
      })
      .addCase(getTopicById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTopicById.fulfilled, (state, action) => {
        state.loading = false;
        state.singleTopicData = action.payload;
      })
      .addCase(getTopicById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(editTopic.pending, (state) => {
        state.topicError = false;
        state.loading = true;
      })
      .addCase(editTopic.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(editTopic.rejected, (state, action) => {
        state.loading = false;
        state.topicError = true;
      })
      .addCase(deleteTopic.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTopic.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteTopic.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addResource.pending, (state) => {
        state.loading = true;
      })
      .addCase(addResource.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addResource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteResource.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteResource.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteResource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getResourceByTopicId.pending, (state) => {
        state.resourcesLoading = true;
      })
      .addCase(getResourceByTopicId.fulfilled, (state, action) => {
        state.resourcesLoading = false;
        state.resources = action.payload;
      })
      .addCase(getResourceByTopicId.rejected, (state, action) => {
        state.resourcesLoading = false;
        state.error = action.payload;
      })
      .addCase(cloneTopic.pending, (state) => {
        state.loading = true;
      })
      .addCase(cloneTopic.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(cloneTopic.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(editResource.pending, (state) => {
        state.loading = true;
      })
      .addCase(editResource.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(editResource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearTopicData, clearResources } = topic.actions;
export default topic.reducer;
