import React from "react";
import {
  Box,
  Button,
  Drawer,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { cross_default_icon } from "../../constants/icons";
import IconButtons from "../../components/IconButtons/IconButtons";
import SimpleAutoComplete from "../../components/SimpleAutoComplete/SimpleAutoComplete";
import dayjs from "dayjs";
import { ROLE_TYPES, font_15 } from "../../utils/utils";
import DateTimePickerComponent from "../../components/DateTimePickerComponent/DateTimePickerComponent";
import { useSelector } from "react-redux";

const FilterBootcamp = ({
  open,
  onClose,
  filterBootcamp,
  handleChangeFilterBootcamp,
  handleFilterBootcampSubmit,
  handleFilterBootcampRemove,
  getBootcampData,
}) => {
  const security = useSelector((state) => state?.security);
  const roles = security?.roles;
  const isHr = ROLE_TYPES.isHr(roles);

  return (
    <>
      <Drawer
        className="filter-drawer-container"
        anchor="right"
        open={open}
        onClose={onClose}
      >
        <Grid container className="filter-main-container">
          <Grid item md={12} pt={2} className="drawer-input-container">
            <Box className="filter-head">
              <Typography variant="font_20" className="filter-title ">
                Filters
              </Typography>
              <IconButtons
                handleClick={onClose}
                height={font_15}
                width={font_15}
                image={cross_default_icon}
                tooltip="Close Filter"
              />
            </Box>
            <Box className="filter-padding textfield-box">
              <TextField
                size="small"
                label="Name"
                color="secondary"
                fullWidth
                className="filter-inputs"
                margin="dense"
                variant="outlined"
                value={filterBootcamp?.name}
                onChange={(e) =>
                  handleChangeFilterBootcamp("name", e?.target?.value)
                }
              />
              {isHr ? (
                <Box mb={1} mt={1} className="width-100">
                  <SimpleAutoComplete
                    label="Competency"
                    placeholder="Select Competency"
                    options={
                      getBootcampData?.filtersForBootcamp?.data?.competencies
                        ?.results
                    }
                    onChange={(event, newValue) =>
                      handleChangeFilterBootcamp("competencyId", newValue)
                    }
                    getOptionLabel={(option) => option?.name}
                    value={filterBootcamp?.competencyId}
                    renderOption={(props, option, value) => {
                      return (
                        <li
                          {...props}
                          key={props?.id}
                          className={
                            option?.id === filterBootcamp?.competencyId?.id
                              ? "multi-tag-edit-list-selected"
                              : "multi-tag-style"
                          }
                        >
                          <Box lineHeight={1.1}>
                            <Box>{`${option?.name}`}</Box>
                          </Box>
                        </li>
                      );
                    }}
                  />
                </Box>
              ) : null}
              <Box mb={1} mt={1} className="width-100">
                <DateTimePickerComponent
                  label="Starts On/After"
                  format="DD/MM/YYYY"
                  onChange={(e) => handleChangeFilterBootcamp("startDate", e)}
                  value={filterBootcamp?.startDate}
                  maxDate={
                    filterBootcamp?.endDate ? filterBootcamp?.endDate : null
                  }
                  views={["year", "day"]}
                  error={false}
                  closeOnSelect={true}
                  viewRenderers={{
                    hours: null,
                    minutes: null,
                    seconds: null,
                  }}
                  enableClear={true}
                />
              </Box>
              <Box mb={1} mt={1} className="width-100">
                <DateTimePickerComponent
                  label="Starts On/Before"
                  format="DD/MM/YYYY"
                  onChange={(e) => handleChangeFilterBootcamp("endDate", e)}
                  value={filterBootcamp?.endDate}
                  minDate={
                    filterBootcamp?.startDate ? filterBootcamp?.startDate : null
                  }
                  views={["year", "day"]}
                  error={false}
                  closeOnSelect={true}
                  viewRenderers={{
                    hours: null,
                    minutes: null,
                    seconds: null,
                  }}
                  enableClear={true}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={12} className="filter-padding filter-btn-container">
            <Button
              onClick={handleFilterBootcampSubmit}
              variant="contained"
              color="secondary"
              className="filter-btn margin-right-1"
            >
              <Typography variant="font_12_bold_700">Apply Filters</Typography>
            </Button>

            <Button
              onClick={handleFilterBootcampRemove}
              variant="outlined"
              color="secondary"
              className="filter-btn"
            >
              <Typography variant="font_12_bold_700">Reset</Typography>
            </Button>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};

export default FilterBootcamp;
