import React from "react";
import { Pagination, PaginationItem } from "@mui/material";
import "./tablePagination.scss";
import {
  left_arrow_pagination_default_icon,
  right_arrow_pagination_default_icon,
} from "../../constants/icons";
import IconButtons from "../IconButtons/IconButtons";

const TablePagination = ({ totalPages, handlePageChange, currentPage }) => {
  return (
    <Pagination
      count={totalPages}
      defaultPage={1}
      size="small"
      className="pagination-main-container"
      onChange={(event, page) => handlePageChange(page)}
      renderItem={(item) => (
        <PaginationItem
          {...item}
          color="secondary"
          shape="rounded"
          slots={{
            previous: () => (
              <IconButtons
                height={1.375}
                width={1.375}
                image={left_arrow_pagination_default_icon}
                tooltip={item?.disabled ? "No previous pages" : "Previous"}
                style={{
                  ...(item?.disabled && {
                    pointerEvents: "auto",
                    cursor: "default",
                  }),
                }}
              />
            ),
            next: () => (
              <IconButtons
                height={1.375}
                width={1.375}
                image={right_arrow_pagination_default_icon}
                tooltip={item?.disabled ? "No next pages" : "Next"}
                style={{
                  ...(item?.disabled && {
                    pointerEvents: "auto",
                    cursor: "default",
                  }),
                }}
              />
            ),
          }}
        />
      )}
      page={currentPage}
    />
  );
};

export default TablePagination;
