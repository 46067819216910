import React, { useEffect, useState } from "react";
import "./playInterview.scss";
import { Box, Button, Chip, Grid, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Constants from "../../constants/constants";
import TopicsList from "../ViewAssessmentEvaluation/TopicsList";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import TopicRelatedQuestionsListOnPlay from "../../components/TopicRelatedQuestionsListOnPlay/TopicRelatedQuestionsListOnPlay";
import SubmitFeedbackModal from "../../components/SubmitFeedbackModal/SubmitFeedbackModal";
import { updateAssessmentEvaluation } from "../../store/assessmentPlay";

const { COMPLETED } = Constants;

const UpperSection = ({
  instruction,
  isStarted,
  intervieweeName,
  interviewerName,
  coInterviewerName,
  handleSubmitFeedback,
}) => {
  return (
    <>
      <Box className="margin-bottom-5 instructions">
        <Grid container className="upper-container">
          <Grid item md={!isStarted ? 12 : 6}>
            <Box className="instruction-info">
              <Typography variant="font_12" className="grey-text">
                Instructions
              </Typography>
              <Box
                dangerouslySetInnerHTML={{ __html: instruction }}
                sx={{
                  wordBreak: "break-word",
                  whiteSpace: "pre-wrap",
                  fontSize: "0.813rem",
                  fontWeight: 400,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="margin-bottom-5 interview-details">
        {interviewerName && (
          <Typography variant="font_12" align="left">
            <Chip
              label={`Interviewer: ${interviewerName}${
                coInterviewerName ? `, ${coInterviewerName}` : ""
              }`}
              variant="QUEST_TYPE"
              sx={{ fontSize: "0.75rem", height: "2rem" }}
            />
          </Typography>
        )}
        {intervieweeName && isStarted && (
          <Button
            onClick={handleSubmitFeedback}
            variant="contained"
            color="secondary"
          >
            <Typography variant="font_12_bold_700">
              {`Submit feedback for ${intervieweeName}`}
            </Typography>
          </Button>
        )}
      </Box>
    </>
  );
};

const PlayInterview = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const evaluationId = +params?.evaluationId;
  const evaluation = useSelector((state) => state?.assessmentPlay);
  const assessmentEvaluation = evaluation?.evaluationData?.data;
  const assessmentEvaluationStartDate = assessmentEvaluation?.startDate;
  const updatedAssessmentEvaluation = evaluation?.updatedEvaluationData?.data;
  const updatedAssessmentEvaluationStartDate =
    updatedAssessmentEvaluation?.startDate;
  const assessmentEvaluationStatus = assessmentEvaluation?.status;
  const isAssessmentStarted = !!assessmentEvaluation?.startDate;
  const assessmentDetails = assessmentEvaluation?.assessment;
  const instruction = assessmentDetails?.instruction;
  const [isStarted, setIsStarted] = useState(false);
  const [isFinalSubmit, setIsFinalSubmit] = useState(false);
  const [responses, setResponses] = useState([]);
  const intervieweeName =
    assessmentEvaluation?.bootcampAttendee?.attendee?.fullName ||
    assessmentEvaluation?.moduleAttendee?.attendee?.attendee?.fullName;
  const interviewerName = assessmentEvaluation?.leadInterviewer?.fullName;
  const coInterviewerName = assessmentEvaluation?.coInterviewer?.fullName;

  //-------------------Submit feedback ------------------------
  const [submitModalOpen, setSubmitModalOpen] = useState(false);

  const handleSubmitModalClose = () => {
    setSubmitModalOpen(false);
  };
  const handleSubmitFeedback = () => {
    setSubmitModalOpen(true);
  };

  const submitFeedback = (interviewerFeedback) => {
    submitQuiz(interviewerFeedback);
    setIsStarted(false);
    setSubmitModalOpen(false);
  };
  //-------------------------------------------

  const savedData = assessmentEvaluation?.topics?.results?.flatMap((topic) =>
    topic?.evaluationQuestions?.results?.map((question) => ({
      questionId: question?.id,
      interviewerEvaluation: question?.interviewerEvaluation,
      interviewerFeedback: question?.interviewerFeedback || "",
    }))
  );

  useEffect(() => {
    if (savedData) {
      const filteredData = savedData.filter(
        (data) => data?.interviewerEvaluation !== null
      );
      setResponses(filteredData);
    }
  }, [assessmentEvaluation?.topics]);

  useEffect(() => {
    if (assessmentEvaluationStatus === COMPLETED || isFinalSubmit) {
      const timer = setTimeout(() => {
        window.location.href = "/";
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [assessmentEvaluationStatus, isFinalSubmit]);

  const saveResponse = (questiondetails, removeAnswer = false) => {
    let answeredQuestions;
    setResponses((prevResponses) => {
      const existingResponseIndex = prevResponses?.findIndex(
        (response) => response?.questionId === questiondetails?.questionId
      );

      let updatedResponses;
      if (existingResponseIndex !== -1) {
        updatedResponses = [...prevResponses];
        updatedResponses[existingResponseIndex] = {
          ...updatedResponses[existingResponseIndex],
          interviewerEvaluation: questiondetails?.interviewerEvaluation, // Ensure optionId is always an array
          interviewerFeedback: questiondetails?.interviewerFeedback, // Ensure optionId is always an array
          removeAnswer,
        };
      } else {
        updatedResponses = [
          ...prevResponses,
          { ...questiondetails, removeAnswer },
        ];
      }
      answeredQuestions = updatedResponses;
      return updatedResponses;
    });
    dispatch(
      updateAssessmentEvaluation({
        evaluationId,
        questions: answeredQuestions,
      })
    );
  };

  const submitQuiz = async (interviewerFeedback) => {
    await dispatch(
      updateAssessmentEvaluation({
        evaluationId,
        finalSubmit: true,
        interviewerFeedback: interviewerFeedback,
        questions: responses,
      })
    );
    setIsFinalSubmit(true);
  };

  const handleStart = () => {
    setIsStarted(true);
    if (!isAssessmentStarted) {
      dispatch(updateAssessmentEvaluation({ evaluationId }));
    }
  };

  const [topicId, setTopicId] = useState("all");

  const totalMarks =
    updatedAssessmentEvaluation?.totalMarks != null
      ? updatedAssessmentEvaluation?.totalMarks
      : assessmentEvaluation?.totalMarks;
  const marksObtained =
    updatedAssessmentEvaluation?.marksObtained != null
      ? updatedAssessmentEvaluation?.marksObtained
      : assessmentEvaluation?.marksObtained;

  return (
    <>
      <Grid container className="main-container">
        <Grid item md={12} className="body-container">
          <Grid container className="table-parent-container">
            <Paper elevation={0} className="interview-paper-container">
              <Grid className="interview-main-container">
                <Box sx={{ display: "flex", height: "100%" }}>
                  <Box className="contained-items">
                    <UpperSection
                      instruction={instruction}
                      handleSubmitFeedback={handleSubmitFeedback}
                      isStarted={isStarted}
                      updatedAssessmentEvaluationStartDate={
                        updatedAssessmentEvaluationStartDate ||
                        assessmentEvaluationStartDate
                      }
                      intervieweeName={intervieweeName}
                      interviewerName={interviewerName}
                      coInterviewerName={coInterviewerName}
                    />

                    {isFinalSubmit ? (
                      <Box className="start-button">
                        <Typography
                          variant="font_12_bold_700"
                          className="assessment-submitted"
                        >
                          Your assessment has been successfully submitted. You
                          will be redirected to the homepage in 5 seconds
                        </Typography>
                      </Box>
                    ) : !isStarted ? (
                      assessmentEvaluationStatus === COMPLETED ? (
                        <Box className="start-button">
                          <Typography
                            variant="font_12_bold_700"
                            className="assessment-submitted"
                          >
                            The assessment has already been submitted, and you
                            will be redirected to the home page in 5 seconds
                          </Typography>
                        </Box>
                      ) : (
                        <Box className="start-button">
                          <Button
                            onClick={handleStart}
                            variant="contained"
                            color="secondary"
                            className="block-button"
                            sx={{ minWidth: "12.5rem" }}
                          >
                            <Typography variant="font_12_bold_700">
                              {!isAssessmentStarted
                                ? "Start the Assessment"
                                : "Resume the Assessment"}
                            </Typography>
                          </Button>
                        </Box>
                      )
                    ) : (
                      <>
                        <Box className="interview-section">
                          <Box className="margin-bottom-5">
                            <Grid
                              container
                              className="assessment-panel-container"
                            >
                              <Grid
                                item
                                md={12}
                                sx={{ padding: "0.5rem 1rem" }}
                              >
                                <Box>
                                  <Typography variant="font_20">
                                    Assessment Questions
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item md={4} sm={12}>
                                <Grid
                                  container
                                  item
                                  md={12}
                                  className="interview-parent-container"
                                >
                                  {assessmentEvaluation?.topics?.results
                                    ?.length ? (
                                    <TopicsList
                                      topicId={topicId}
                                      topics={
                                        updatedAssessmentEvaluation?.topics ||
                                        assessmentEvaluation?.topics
                                      }
                                      setTopicId={setTopicId}
                                      totalMarks={totalMarks}
                                      marksObtained={marksObtained}
                                    />
                                  ) : (
                                    <Grid
                                      container
                                      className="no-data-container"
                                    >
                                      <NoDataAvailable
                                        imgHeight={4}
                                        imgWidth={4.5}
                                        message="No Topics Available"
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid item md={8} sm={12}>
                                <Grid container item md={12} className="">
                                  <TopicRelatedQuestionsListOnPlay
                                    saveResponse={saveResponse}
                                    submitQuiz={submitQuiz}
                                    topics={
                                      updatedAssessmentEvaluation?.topics ||
                                      assessmentEvaluation?.topics
                                    }
                                    assessment={
                                      assessmentEvaluation?.assessment
                                    }
                                    topicId={topicId}
                                    responses={responses}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <SubmitFeedbackModal
        open={submitModalOpen}
        handleClose={handleSubmitModalClose}
        submitFeedback={submitFeedback}
        intervieweeName={intervieweeName}
      />
    </>
  );
};

export default PlayInterview;
