import { apiVersion } from "../../constants/constants";
import ApiService from "../../utils/api-service";
export function getAllAttendeesApi(data) {
  let apiUrl = `${apiVersion}/attendees?populateScores=true&paginated=false`;

  if (data?.bootcampId !== undefined && data?.bootcampId !== null) {
    apiUrl += `&bootcampIds=${data?.bootcampId}`;
  }
  if (data?.moduleId !== undefined) {
    apiUrl += `&moduleId=${data?.moduleId}`;
  }
  if (data?.batchId !== undefined) {
    apiUrl += `&batchIds=${data?.batchId}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function getAttendeesApi(data) {
  let apiUrl = `${apiVersion}/attendees?populateScores=true&max=20`;

  if (data?.bootcampId !== undefined && data?.bootcampId !== null) {
    apiUrl += `&bootcampIds=${data?.bootcampId}`;
  }
  if (data?.moduleId !== undefined) {
    apiUrl += `&moduleId=${data?.moduleId}`;
  }
  if (data?.batchId !== undefined) {
    apiUrl += `&batchIds=${data?.batchId}`;
  }
  if (data?.offset !== undefined) {
    apiUrl += `&offset=${data?.offset * 20}`;
  }
  if (data?.sortBy !== undefined && data?.sortBy !== null) {
    apiUrl += `&sortBy=${data?.sortBy}`;
  }
  if (data?.sortOrder !== undefined && data?.sortOrder !== null) {
    apiUrl += `&sortOrder=${data?.sortOrder}`;
  }
  if (data?.attendeeName !== undefined && data?.attendeeName !== "") {
    apiUrl += `&attendeeName=${data?.attendeeName}`;
  }
  if (data?.attendeeEmail !== undefined && data?.attendeeEmail !== "") {
    apiUrl += `&attendeeEmail=${data?.attendeeEmail}`;
  }
  if (data?.mentorName !== undefined && data?.mentorName !== "") {
    apiUrl += `&mentorName=${data?.mentorName}`;
  }
  if (data?.mentorEmail !== undefined && data?.mentorEmail !== "") {
    apiUrl += `&mentorEmail=${data?.mentorEmail}`;
  }
  if (data?.status) {
    apiUrl += `&status=${data?.status}`;
  }
  if (
    data?.exerciseStatuses !== undefined &&
    data?.exerciseStatuses !== null &&
    data?.exerciseStatuses.length > 0
  ) {
    apiUrl += `&exerciseStatuses=${data?.exerciseStatuses}`;
  }
  if (
    data?.batchIds !== undefined &&
    data?.batchIds !== null &&
    data?.batchIds?.length > 0
  ) {
    apiUrl += `&batchIds=${data?.batchIds}`;
  }
  if (
    data?.competencyIds !== undefined &&
    data?.competencyIds !== null &&
    data?.competencyIds?.length > 0
  ) {
    apiUrl += `&competencyIds=${data?.competencyIds}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function getUsersApi(data) {
  let apiUrl = `${apiVersion}/users?paginated=false&mentorExists=true`;

  if (data?.competencyId) {
    apiUrl += `&competencyIds=${[data?.competencyId]}`;
  }
  if (data?.designationId) {
    apiUrl += `&designationIds=${data?.designationId}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}
export function getUsersForAddAttendeeApi(data) {
  let apiUrl = `${apiVersion}/users?competencyIds=${[
    data?.competencyId,
  ]}&paginated=false`;
  if (data?.designationId) {
    apiUrl += `&designationIds=${[data?.designationId]}`;
  }
  if (data?.bootcampId) {
    apiUrl += `&notAttendeeInBootcampId=${data?.bootcampId}`;
  }
  if (data?.moduleId) {
    apiUrl += `&notAttendeeInModuleId=${data?.moduleId}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function updateAttendeeApi(data) {
  let apiUrl = `${apiVersion}/attendees`;

  const options = {
    method: "PATCH",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function getCompetenciesApi(data) {
  let apiUrl = `${apiVersion}/competencies`;
  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function getDesignationApi(data) {
  let apiUrl = `${apiVersion}/designations?competencyId=${data?.competencyIds}`;

  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function addAttendeeApi(data) {
  let apiUrl = `${apiVersion}/attendees`;

  const options = {
    method: "POST",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function getBatchesApi(data) {
  const { moduleId } = data;
  let apiUrl = `${apiVersion}/moduleBatches`;
  if (moduleId !== undefined && moduleId !== null) {
    apiUrl += `?moduleId=${moduleId}&paginated=false`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}
export function deleteAttendeeApi(attendeeId) {
  let apiUrl = `${apiVersion}/attendees/${attendeeId}`;

  const options = {
    method: "DELETE",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getBootcampApi({ bootcampId }) {
  let apiUrl = `${apiVersion}/bootcamps/${bootcampId}`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getOptionsForCreateAttendeeApi({ bootcampId }) {
  let apiUrl = `${apiVersion}/utilities/optionsForCreateAttendee?bootcampId=${bootcampId}`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getOptionsForEditAttendeeApi({ bootcampId, moduleId }) {
  let apiUrl = `${apiVersion}/utilities/optionsForEditAttendee`;
  if (bootcampId) {
    apiUrl += `?bootcampId=${bootcampId}`;
  } else if (moduleId) {
    apiUrl += `?moduleId=${moduleId}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getFiltersForAttendeesApi({ bootcampId, moduleId }) {
  let apiUrl = `${apiVersion}/utilities/filtersForAttendeesListing`;
  if (bootcampId) {
    apiUrl += `?bootcampId=${bootcampId}`;
  } else if (moduleId) {
    apiUrl += `?moduleId=${moduleId}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getModuleAttendeesApi(data) {
  let apiUrl = `${apiVersion}/moduleAttendees?populateScores=true&max=20`;

  if (data?.bootcampId !== undefined && data?.bootcampId !== null) {
    apiUrl += `&bootcampIds=${data?.bootcampId}`;
  }
  if (data?.moduleId !== undefined) {
    apiUrl += `&moduleId=${data?.moduleId}`;
  }
  if (data?.batchId !== undefined) {
    apiUrl += `&batchIds=${data?.batchId}`;
  }
  if (data?.offset !== undefined) {
    apiUrl += `&offset=${data?.offset * 20}`;
  }
  if (data?.sortBy !== undefined && data?.sortBy !== null) {
    apiUrl += `&sortBy=${data?.sortBy}`;
  }
  if (data?.sortOrder !== undefined && data?.sortOrder !== null) {
    apiUrl += `&sortOrder=${data?.sortOrder}`;
  }
  if (data?.attendeeName !== undefined && data?.attendeeName !== "") {
    apiUrl += `&attendeeName=${data?.attendeeName}`;
  }
  if (data?.attendeeEmail !== undefined && data?.attendeeEmail !== "") {
    apiUrl += `&attendeeEmail=${data?.attendeeEmail}`;
  }
  if (data?.mentorName !== undefined && data?.mentorName !== "") {
    apiUrl += `&mentorName=${data?.mentorName}`;
  }
  if (data?.mentorEmail !== undefined && data?.mentorEmail !== "") {
    apiUrl += `&mentorEmail=${data?.mentorEmail}`;
  }
  if (data?.status) {
    apiUrl += `&status=${data?.status}`;
  }
  if (
    data?.exerciseStatuses !== undefined &&
    data?.exerciseStatuses !== null &&
    data?.exerciseStatuses.length > 0
  ) {
    apiUrl += `&exerciseStatuses=${data?.exerciseStatuses}`;
  }
  if (
    data?.batchIds !== undefined &&
    data?.batchIds !== null &&
    data?.batchIds?.length > 0
  ) {
    apiUrl += `&batchIds=${data?.batchIds}`;
  }
  if (
    data?.competencyIds !== undefined &&
    data?.competencyIds !== null &&
    data?.competencyIds?.length > 0
  ) {
    apiUrl += `&competencyIds=${data?.competencyIds}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function deleteModuleAttendeeApi(attendeeId) {
  let apiUrl = `${apiVersion}/moduleAttendees/${attendeeId}`;

  const options = {
    method: "DELETE",
    url: apiUrl,
  };
  return ApiService(options);
}

export function updateModuleAttendeeApi(data) {
  let apiUrl = `${apiVersion}/moduleAttendees`;

  const options = {
    method: "PATCH",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}
