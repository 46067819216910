import React, { useEffect, useState } from "react";
import "./viewAssessmentEvaluation.scss";
import "./viewAssessmentEvaluationPanel.scss";
import { Box, Chip, Grid, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import Loader from "../../components/Loader/Loader";
import { useLocation, useParams } from "react-router-dom";
import ViewAssessmentEvaluationPanel from "./ViewAssessmentEvaluationPanel";
import TopicsList from "./TopicsList";
import TopicRelatedQuestionsList from "../../components/TopicRelatedQuestionsList/TopicRelatedQuestionsList";
import {
  clearAssessmentEvaluationData,
  getAssessmentEvaluationById,
  getBootcampById,
} from "../../store/viewAssessmentEvaluation";
import BreadCrumb from "../../components/Breadcrumb/BreadCrumb";
import dayjs from "dayjs";
import { evaluationStatusChip } from "../../utils/utils";

const ViewAssessmentEvaluation = () => {
  const location = useLocation();
  const { viaAssessmentList } = location?.state || {};
  const dispatch = useDispatch();
  const security = useSelector((state) => state?.security);
  const assessmentEvaluation = useSelector(
    (state) => state?.viewAssessmentEvaluation
  );
  const assessmentEvaluationLoading =
    assessmentEvaluation?.getAssessmentEvaluationLoader;
  const assessmentEvaluationData =
    assessmentEvaluation?.assessmentEvaluationData?.data;

  const [isLoading, setIsLoading] = useState(true);
  const bootcampData = assessmentEvaluation?.bootcampData?.data;
  const module = assessmentEvaluationData?.assessment?.module;
  const params = useParams();
  const { bootcampId, moduleId, assessmentId, evaluationId } = params;
  const status = evaluationStatusChip(assessmentEvaluationData?.status);

  ///////////
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(getBootcampById({ bootcampId }));
      await dispatch(getAssessmentEvaluationById(evaluationId));
      setIsLoading(false);
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearAssessmentEvaluationData());
    };
  }, []);

  const [topicId, setTopicId] = useState("all");

  //------------------------------ Breadcrumbs ------------------------------//
  const breadcrumbs = moduleId
    ? [
        { label: "Home", url: "/" },
        {
          label: bootcampData?.name ? bootcampData?.name : "Bootcamps",
          url: "/",
          isBootcamp: true,
          bootcampId: bootcampId,
        },
        {
          label: module?.name ? module?.name : "Module",
          url: `/bootcamp/${bootcampId}/module/${module?.id}` || "/",
        },
        {
          label: "Assessments",
          ...(viaAssessmentList
            ? {}
            : {
                url:
                  `/bootcamp/${bootcampId}/module/${moduleId}/assessments` ||
                  "/",
              }),
        },
        {
          label: "Evaluations",
          ...(viaAssessmentList
            ? {}
            : {
                url:
                  `/bootcamp/${bootcampId}/module/${moduleId}/assessment/${assessmentId}/evaluations` ||
                  "/",
              }),
        },
      ]
    : [
        { label: "Home", url: "/" },
        {
          label: bootcampData?.name ? bootcampData?.name : "Bootcamps",
          url: "/",
          isBootcamp: true,
          bootcampId: bootcampId,
        },
        {
          label: "Assessments",
          ...(viaAssessmentList
            ? {}
            : {
                url: `/bootcamp/${bootcampId}/assessments` || "/",
              }),
        },
        {
          label: "Evaluations",
          ...(viaAssessmentList
            ? {}
            : {
                url:
                  `/bootcamp/${bootcampId}/assessment/${assessmentId}/evaluations` ||
                  "/",
              }),
        },
      ];

  const attendee =
    assessmentEvaluationData?.bootcampAttendee?.attendee ||
    assessmentEvaluationData?.moduleAttendee?.attendee?.attendee;
  const loggedInUserId = security?.loggedInUser?.id;

  const openedByAttendee = loggedInUserId === attendee?.id;

  if (assessmentEvaluationLoading || isLoading || assessmentEvaluationLoading)
    return <Loader />;
  return (
    <>
      <BreadCrumb breadcrumbs={breadcrumbs} />
      <Grid container className="main-container">
        <Grid item className="header-container" md={12}>
          <Box className="view-evaluation-header">
            <Typography variant="font_21_bold" className="margin-right-2">
              {assessmentEvaluationData?.assessment?.title}
            </Typography>
            <Chip
              label={assessmentEvaluationData?.assessment?.type}
              variant="QUEST_TYPE"
              className="margin-right-2 module-chip-status"
            />
            <Chip
              className="same-size-chip"
              label={status?.name}
              variant={status?.theme}
            />
          </Box>
          <Box className="header-right-icons">
            {assessmentEvaluationData?.endDate && (
              <>
                <Typography variant="body2" className="margin-right-1">
                  Submitted On:
                </Typography>{" "}
                <Chip
                  label={dayjs(assessmentEvaluationData?.endDate)?.format(
                    "DD/MM/YYYY hh:mm A"
                  )}
                  variant="QUEST_TYPE"
                  className="margin-right-2 module-chip-status"
                />
              </>
            )}
          </Box>
        </Grid>
        <Grid item md={12}>
          <Paper
            elevation={0}
            className="flex-container-column assessment-paper-container"
          >
            <ViewAssessmentEvaluationPanel
              assessmentInfo={assessmentEvaluationData}
              openedByAttendee={openedByAttendee}
            />

            <Box className="margin-bottom-5">
              <Grid container className="assessment-panel-container">
                <Grid item md={12} sx={{ padding: "0.5rem 1rem" }}>
                  <Box>
                    <Typography variant="font_20">
                      Assessment Questions
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={4} sm={12}>
                  <Grid
                    container
                    item
                    md={12}
                    className="topics-parent-container"
                  >
                    {assessmentEvaluationData?.topics?.results?.length ? (
                      <TopicsList
                        topicId={topicId}
                        topics={assessmentEvaluationData?.topics}
                        assessmentEvaluationStatus={
                          assessmentEvaluationData?.status
                        }
                        setTopicId={setTopicId}
                        totalMarks={assessmentEvaluationData?.totalMarks}
                        marksObtained={assessmentEvaluationData?.marksObtained}
                        openedByAttendee={openedByAttendee}
                      />
                    ) : (
                      <Grid container className="no-data-container">
                        <NoDataAvailable
                          imgHeight={4}
                          imgWidth={4.5}
                          message="No Topics Available"
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item md={8} sm={12}>
                  <Grid container item md={12} className="">
                    <TopicRelatedQuestionsList
                      topics={assessmentEvaluationData?.topics}
                      assessment={assessmentEvaluationData?.assessment}
                      assessmentEvaluationStatus={
                        assessmentEvaluationData?.status
                      }
                      topicId={topicId}
                      openedByAttendee={openedByAttendee}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewAssessmentEvaluation;
