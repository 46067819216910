import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import CustomToaster from "../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../constants/constants";
import { getPerformanceDataApi } from "../services/performance";

const initialState = {
  performanceData: [],
  loading: false,
  error: null,
  performanceDataLoading: false,
};

export const getPerformanceData = createAsyncThunk(
  "getBatchesList",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getPerformanceDataApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const performance = createSlice({
  name: "performance",
  initialState,
  reducers: {
    clearPerformanceData(state) {
      state.performanceData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPerformanceData.pending, (state) => {
        state.performanceDataLoading = true;
      })
      .addCase(getPerformanceData.fulfilled, (state, action) => {
        state.performanceDataLoading = false;
        state.performanceData = action.payload;
      })
      .addCase(getPerformanceData.rejected, (state, action) => {
        state.performanceDataLoading = false;
        state.error = action.payload;
      });
  },
});

export const { clearPerformanceData } = performance.actions;
export default performance.reducer;
