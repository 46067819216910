import React, { useState } from "react";
import { Avatar, Box, Link, Typography } from "@mui/material";
import {
  font_13_icon,
  getNewersWorldProfileUrl,
  isValidString,
} from "../../utils/utils";
import ImageModal from "../ImageModal";
import "./userModuleBootcamp.scss";
import IconButtons from "../IconButtons/IconButtons";
import { user_module_bootcamp_icon } from "../../constants/icons";
import UserTableAvatar from "../UserTableAvatar/UserTableAvatar";
const UserModuleBootcamp = ({ module, bootcamp, moduleId }) => {
  const [imageOpen, setImageOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const handleOpen = (imgUrl) => {
    if (isValidString(imgUrl)) {
      setImageUrl(imgUrl);
      setImageOpen(true);
    } else {
      handleClose();
    }
  };
  const handleClose = () => {
    setImageUrl(null);
    setImageOpen(false);
  };

  const imgAlt = module ? module?.fullName : bootcamp?.fullName;
  const imgSrc = module
    ? module?.profilePictureUrl
    : bootcamp?.profilePictureUrl;

  const deleted = module ? module?.deleted : bootcamp?.deleted;
  const label = module ? module?.fullName : bootcamp?.fullName;

  const subLabel = module ? module?.email : bootcamp?.email;
  const href = module ? module?.employeeCode : bootcamp?.employeeCode;

  const bootcampAlt = bootcamp?.fullName;
  const bootcampImg = bootcamp?.profilePictureUrl;
  const bootcampDeleted = bootcamp?.deleted;
  const bootcampLabel = bootcamp?.fullName;
  const bootcampSubLabel = bootcamp?.email;
  const bootcampHref = bootcamp?.employeeCode;

  return (
    <>
      {module || bootcamp ? (
        <Box className="boot-module-cell" sx={{ flexWrap: "nowrap" }}>
          <Box className="user-cell" sx={{ flexWrap: "nowrap" }}>
            <Avatar
              alt={imgAlt}
              className="user-avatar"
              src={imgSrc ? imgSrc : imgAlt}
              onClick={() => handleOpen(imgSrc)}
            />

            <Box className="user-cell-info">
              <Link
                href={deleted ? null : getNewersWorldProfileUrl(href)}
                target={"_blank"}
                rel="noopener noreferrer"
                color="inherit"
                sx={{
                  textDecoration: "none",
                  "&:hover": { textDecoration: "none" },
                }}
              >
                <Typography
                  variant="font_12"
                  sx={{
                    color: deleted ? "red" : null,
                    wordBreak: "keep-all !important",
                  }}
                >
                  {label}
                </Typography>
              </Link>
              <Typography
                variant="grayEmail"
                sx={{
                  color: deleted ? "red" : null,
                  wordBreak: "keep-all !important",
                }}
              >
                {subLabel}
              </Typography>
            </Box>
          </Box>

          {module && moduleId ? (
            <IconButtons
              width={font_13_icon}
              height={font_13_icon}
              image={user_module_bootcamp_icon}
              classList="padding-left-half"
              tooltip={
                <UserTableAvatar
                  imgAlt={bootcampAlt}
                  imgSrc={bootcampImg}
                  href={bootcampHref}
                  label={bootcampLabel}
                  subLabel={bootcampSubLabel}
                  deleted={bootcampDeleted}
                />
              }
            />
          ) : null}
        </Box>
      ) : (
        <Typography variant="font_12">-</Typography>
      )}
      <ImageModal
        open={imageOpen}
        handleClose={handleClose}
        imageUrl={imageUrl}
      />
    </>
  );
};

export default UserModuleBootcamp;
