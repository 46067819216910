import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CustomToaster from "../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../constants/constants";
import { toast } from "react-hot-toast";
import {
  getAssessmentEvaluationByIdApi,
  updateAssessmentEvaluationApi,
} from "../services/assessmentPlay";

const initialState = {
  evaluationData: [],
  updatedEvaluationData: {},
  loading: false,
  error: null,
  getAssessmentEvaluationLoader: false,
  updateEvaluationLoader: false,
};

export const getAssessmentEvaluationById = createAsyncThunk(
  "getAssessmentEvaluationById",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getAssessmentEvaluationByIdApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAssessmentEvaluation = createAsyncThunk(
  "updateAssessmentEvaluation",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await updateAssessmentEvaluationApi(data);
      {
        !data?.finalSubmit &&
          data?.questions?.length &&
          toast.custom(
            <CustomToaster
              message="Record Saved Successfully"
              title={TOAST_TITLE.SUCCESS}
              time={TOAST_TIME.FIVE}
              type={TOAST_TYPE.SUCCESS}
            />
          );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const assessmentPlay = createSlice({
  name: "assessmentPlay",
  initialState,
  reducers: {
    clearEvaluationData(state) {
      state.evaluationData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssessmentEvaluationById.pending, (state) => {
        state.getAssessmentEvaluationLoader = true;
      })
      .addCase(getAssessmentEvaluationById.fulfilled, (state, action) => {
        state.getAssessmentEvaluationLoader = false;
        state.evaluationData = action.payload;
      })
      .addCase(getAssessmentEvaluationById.rejected, (state, action) => {
        state.getAssessmentEvaluationLoader = false;
        state.error = action.payload;
      })
      .addCase(updateAssessmentEvaluation.pending, (state) => {
        state.updateEvaluationLoader = true;
      })
      .addCase(updateAssessmentEvaluation.fulfilled, (state, action) => {
        state.updateEvaluationLoader = false;
        state.updatedEvaluationData = action.payload;
      })
      .addCase(updateAssessmentEvaluation.rejected, (state, action) => {
        state.updateEvaluationLoader = false;
        state.error = action.payload;
      });
  },
});
export const { clearEvaluationData } = assessmentPlay.actions;
export default assessmentPlay.reducer;
