import { apiVersion } from "../../constants/constants";
import ApiService from "../../utils/api-service";

export function getPresentersApi(data) {
  let apiUrl = `${apiVersion}/presenter?sessionId=${data?.sessionId}&paginated=false`;

  if (data?.sortBy !== undefined && data?.sortBy !== null) {
    apiUrl += `&sortBy=${data?.sortBy}`;
  }
  if (data?.sortOrder !== undefined && data?.sortOrder !== null) {
    apiUrl += `&sortOrder=${data.sortOrder}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getUsersListApi(data) {
  let apiUrl = `${apiVersion}/users?paginated=false&notAttendeeInModuleId=${data?.notAttendeeInModuleId}&getReducedDetails=true`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function updatePresenterListApi(data) {
  let apiUrl = `${apiVersion}/presenter`;

  const options = {
    method: "PATCH",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function getRoomListApi(data) {
  let apiUrl = `${apiVersion}/rooms`;

  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}
