import React, { useEffect, useState } from "react";
import "./locationsAndRooms.scss";
import { Button, Grid, Paper, Typography } from "@mui/material";
import LocationsAndRoomCard from "../../components/LocationsAndRoomCard/LocationsAndRoomCard";
import SingleInputModal from "../../components/SingleInputModal/SingleInputModal";
import RoomModal from "../../components/RoomModal/RoomModal";
import DeleteModal from "../../components/DeleteModal/DeleteModal";

import { useDispatch, useSelector } from "react-redux";
import {
  addLocation,
  addRooms,
  clearLocationData,
  deleteLocation,
  deleteRoom,
  editLocation,
  editRoomData,
  setLocationData,
} from "../../store/locationAndRooms";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import Loader from "../../components/Loader/Loader";
import { ROLE_TYPES, checkSpecialChar, regex } from "../../utils/utils";
import { useNavigate } from "react-router-dom/dist";

const initialLocationDetails = {
  id: "",
  name: "",
};
const initialRoomDetails = {
  locationId: "",
  rooms: {
    id: "",
    name: "",
    seatingCapacity: 0,
  },
};

const LocationsAndRooms = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const security = useSelector((state) => state?.security);
  const roles = security?.roles;
  const isAdmin = ROLE_TYPES.isAdmin(roles);
  const locationAndRoomsData = useSelector((state) => state?.locationAndRoom);
  const loading = locationAndRoomsData?.loading;
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      let locationDataApiValue;
      setIsLoading(true);
      locationDataApiValue = await dispatch(setLocationData());
      setIsLoading(false);
    };
    fetchData();

    return () => {
      dispatch(clearLocationData());
    };
  }, [dispatch]);

  useEffect(() => {
    setIsError(locationAndRoomsData?.error);
  }, [locationAndRoomsData.error, isError]);

  const [isEditLocation, setIsEditLocation] = useState(false);
  const [isEditRoom, setIsEditRoom] = useState(false);
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const [roomModalOpen, setRoomModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [locationId, setLocationId] = useState(0);
  const [roomId, setRoomId] = useState(0);

  const [roomDetails, setRoomDetails] = useState(initialRoomDetails);
  const [locationDetails, setLocationDetails] = useState(
    initialLocationDetails
  );

  const [locationNameLengthError, setLocationNameLengthError] = useState(false);
  const [roomNameLengthError, setRoomNameLengthError] = useState(false);

  const handleLocationModalOpen = () => {
    setLocationError(false);
    setIsEditLocation(false);
    setLocationModalOpen(true);
    setLocationErrors(initialLocationErrorState);
  };

  const handleRoomModalOpen = (location) => {
    setIsRoomError(false);
    setIsEditRoom(false);
    setLocationId(location.id);
    setRoomModalOpen(true);
    setRoomErrors(initialRoomErrorState);
    setSeatAlphabetError(false);
  };
  const handleRoomModalClose = () => {
    setIsRoomError(false);
    setRoomModalOpen(false);
    setRoomNameLengthError(false);
    setRoomDetails(initialRoomDetails);
    setRoomErrors(initialRoomErrorState);
  };
  const handleEditRoomModalOpen = (location, room) => {
    setIsEditRoom(true);
    setLocationId(location);
    setRoomDetails({
      locationId: locationId,
      rooms: {
        id: room.id,
        name: room.name,
        seatingCapacity: room?.seatingCapacity,
      },
    });
    setRoomModalOpen(true);
  };
  const handleEditLocationModalOpen = (event, id, name) => {
    setLocationErrors(initialLocationErrorState);
    setLocationError(false);
    event.stopPropagation();
    setIsEditLocation(true);
    setLocationModalOpen(true);
    setLocationDetails({
      id: id,
      name: name,
    });
  };

  const handleDeleteModalOpen = (event, location, room = null) => {
    event.stopPropagation();
    setDeleteModalOpen(true);
    setLocationId(location.id);
    setRoomId(room ? room.id : null);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    if (roomId) {
      await dispatch(deleteRoom(roomId));
    } else {
      await dispatch(deleteLocation(locationId));
    }
    setDeleteModalOpen(false);
  };

  const handleLocationDetails = (name) => {
    setLocationErrors(initialLocationErrorState);
    setLocationError(false);
    if (name?.trim().length <= 50) {
      setLocationNameLengthError(false);
      setLocationSpecialChar(checkSpecialChar(name));
      setLocationDetails((prevDetails) => ({
        ...prevDetails,
        name: name,
      }));
    } else {
      setLocationNameLengthError(true);
    }
  };

  const handleRoomDetailsChange = (name, value) => {
    setIsRoomError(false);
    setRoomErrors((prev) => ({
      ...prev,
      [name]: false,
    }));

    if (name === "seatingCapacity") {
      setSeatAlphabetError(false);
      if ((value === "" || regex.test(value)) && Number(value) >= 0) {
        setSeatAlphabetError(false);
        setRoomDetails((prevDetails) => ({
          ...prevDetails,
          locationId: locationId,
          rooms: {
            ...prevDetails.rooms,
            [name]: value === "" ? "" : Number(value),
          },
        }));
      } else if (!Number(value)) {
        setSeatAlphabetError(true);
      }
    } else {
      setRoomSpecialChar(checkSpecialChar(value));
      if (value.trim().length <= 50) {
        setRoomNameLengthError(false);
        setRoomDetails((prevDetails) => ({
          ...prevDetails,
          locationId: locationId,
          rooms: {
            ...prevDetails.rooms,
            [name]: value,
          },
        }));
      } else {
        setRoomNameLengthError(true);
      }
    }
  };

  const handleLocationModalClose = () => {
    setLocationError(false);
    setLocationModalOpen(false);
    setLocationDetails(initialLocationDetails);
    setLocationNameLengthError(false);
    setLocationError(false);
    setLocationErrors(initialLocationErrorState);
  };
  //////////////////// validate location /////////////////////////
  const initialLocationErrorState = {
    name: false,
  };

  const [locationErrors, setLocationErrors] = useState(
    initialLocationErrorState
  );
  const [locationSpecialChar, setLocationSpecialChar] = useState(false);
  const validateLocationFields = (sessionDetails) => {
    const { name } = sessionDetails;
    const newErrors = {
      name: name.trim() ? "" : "Name is required",
    };
    setLocationErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  /////////////////////////////////////////////////////////////////
  //////////////////// validate room /////////////////////////
  const initialRoomErrorState = {
    name: false,
    seatingCapacity: false,
  };

  const [roomErrors, setRoomErrors] = useState(initialRoomErrorState);
  const [roomSpecialChar, setRoomSpecialChar] = useState(false);
  const [seatAlphabetError, setSeatAlphabetError] = useState(false);
  const validateRoomFields = (sessionDetails) => {
    const { name, seatingCapacity } = sessionDetails.rooms;
    const newErrors = {
      name: name ? "" : "Name is required",
      seatingCapacity:
        seatingCapacity > 0
          ? ""
          : "Capacity is required and should be greater than 0",
    };
    setRoomErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  /////////////////////////////////////////////////////////////////

  const [locationError, setLocationError] = useState(false);
  const handleLocationSubmit = async () => {
    const data = {
      id: locationDetails?.id,
      name: locationDetails?.name.trim(),
    };

    let apiValue;
    if (validateLocationFields(data) && !locationSpecialChar) {
      if (locationDetails.id) {
        apiValue = await dispatch(editLocation(data));
      } else {
        apiValue = await dispatch(addLocation(data?.name));
      }
      if (apiValue?.error?.message === "Rejected") {
        setLocationError(true);
      } else {
        handleLocationModalClose();
      }
    }
  };

  const [isRoomError, setIsRoomError] = useState(false);
  const handleRoomModalSubmit = async () => {
    let apiValue;
    const data = {
      locationId: roomDetails?.locationId,
      rooms: {
        id: roomDetails?.rooms?.id,
        name: roomDetails?.rooms?.name?.trim(),
        seatingCapacity: roomDetails?.rooms?.seatingCapacity,
      },
    };

    if (validateRoomFields(data) && !roomSpecialChar) {
      if (roomDetails?.rooms?.id) {
        apiValue = await dispatch(editRoomData(data));
      } else {
        apiValue = await dispatch(addRooms(data));
      }
      if (apiValue?.error?.message === "Rejected") {
        setIsRoomError(true);
      } else {
        handleRoomModalClose();
      }
    }
  };

  if (loading && isLoading) return <Loader />;

  return (
    <Grid container className="main-container">
      <Grid item className="header-container" md={12}>
        <Typography variant="font_21_bold">Location & Rooms</Typography>
        {isAdmin && (
          <Button
            className="action-head-btn"
            variant="outlined"
            color="secondary"
            onClick={handleLocationModalOpen}
          >
            <Typography variant="outlineBtnLabel">Create Location</Typography>
          </Button>
        )}
      </Grid>
      <Grid item md={12} className="body-container">
        <Paper elevation={0} container className="location-parent-container">
          {!loading &&
          !locationAndRoomsData?.locationData?.data?.results?.length ? (
            <Grid container className="no-data-container">
              <NoDataAvailable
                imgHeight={4}
                imgWidth={4.5}
                message="No Locations Available"
              />
            </Grid>
          ) : (
            locationAndRoomsData?.locationData?.data?.results?.map(
              (e, index) => (
                <LocationsAndRoomCard
                  index={index}
                  key={e.id}
                  locationModalOpen={handleLocationModalOpen}
                  roomModalOpen={() => handleRoomModalOpen(e)}
                  locationEditModal={(event, id, locationName) =>
                    handleEditLocationModalOpen(event, id, locationName)
                  }
                  roomEditModal={handleEditRoomModalOpen}
                  deleteModalOpenLocation={(event) =>
                    handleDeleteModalOpen(event, e)
                  }
                  deleteModalOpenRoom={(event, room) =>
                    handleDeleteModalOpen(event, e, room)
                  }
                  locationName={e.name}
                  roomData={e.rooms}
                  id={e.id}
                  isDeleted={e.deleted}
                  isAdmin={isAdmin}
                />
              )
            )
          )}
        </Paper>
      </Grid>
      <SingleInputModal
        label={"Location Name"}
        handleClose={handleLocationModalClose}
        open={locationModalOpen}
        isEditLocation={isEditLocation}
        locationDetails={locationDetails}
        locationDetailsChange={handleLocationDetails}
        handleLocationSubmit={handleLocationSubmit}
        locationError={locationError}
        locationErrors={locationErrors}
        locationSpecialChar={locationSpecialChar}
        locationNameLengthError={locationNameLengthError}
      />
      <RoomModal
        handleClose={handleRoomModalClose}
        open={roomModalOpen}
        isEditRoom={isEditRoom}
        roomDetails={roomDetails}
        handleRoomDetailsChange={handleRoomDetailsChange}
        handleRoomModalSubmit={handleRoomModalSubmit}
        isRoomError={isRoomError}
        roomSpecialChar={roomSpecialChar}
        roomErrors={roomErrors}
        seatAlphabetError={seatAlphabetError}
        roomNameLengthError={roomNameLengthError}
      />
      <DeleteModal
        open={deleteModalOpen}
        handleClose={handleDeleteModalClose}
        handleDelete={handleDelete}
      />
    </Grid>
  );
};

export default LocationsAndRooms;
