import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";

import {
  addBootcampApi,
  deleteBootcampApi,
  editBootcampApi,
  getAllModulesApi,
  getBootcampApi,
  getCompetenciesApi,
  getModulesApi,
  getUsersApi,
  getBootcampOptionsApi,
  getBootcampByIdApi,
  getCreateEditBootcampOptionsApi,
  getCreateEditModuleOptionsApi,
  getFiltersForBootcampApi,
  getCloneModuleOptionsApi,
} from "../services/bootcamp";
import CustomToaster from "../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../constants/constants";

const initialState = {
  competencies: [],
  spocs: [],
  bootcampData: [],
  getAllModuleOptions: [],
  modules: [],
  loading: false,
  error: null,
  moduleLoading: false,
  getBootcampOptions: [],
  getBootcampOptionsLoader: false,
  ModalApiLoading: false,
  moduleOptionLoading: false,
  getBootcampDataById: null,
  editBootcampLoading: false,
  addBootcamploading: false,
  deleteBootcamploading: false,
  getCreateEditBootcampOptions: null,
  filtersForBootcamp: null,
  cloneModuleApiLoading: false,
  getCloneModuleOptions: null,
};

export const getBootcamp = createAsyncThunk(
  "getBootcamp",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getBootcampApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBootcampOptions = createAsyncThunk(
  "getBootcampOptions",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getBootcampOptionsApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addBootcamp = createAsyncThunk(
  "addBootcamp",
  async (data, { dispatch, rejectWithValue }) => {
    const { addDeleteData, getbootcampdetails } = data;
    try {
      const callApi = await addBootcampApi(addDeleteData);
      if (callApi) {
        await dispatch(clearBootcamps());
        await dispatch(getBootcamp(getbootcampdetails));
        toast.custom(
          <CustomToaster
            message="Bootcamp Added Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteBootcamp = createAsyncThunk(
  "deleteBootcamp",
  async (data, { dispatch, rejectWithValue }) => {
    const { getId, getbootcampdetails } = data;
    try {
      const callApi = await deleteBootcampApi(getId);
      if (callApi) {
        await dispatch(getBootcamp(getbootcampdetails));
        toast.custom(
          <CustomToaster
            message="Bootcamp Deleted Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editBootcamp = createAsyncThunk(
  "editBootcamp",
  async (data, { dispatch, rejectWithValue }) => {
    const { addDeleteData, getbootcampdetails } = data;
    try {
      const callApi = await editBootcampApi(addDeleteData);
      if (callApi) {
        await dispatch(clearBootcamps());
        await dispatch(getBootcamp(getbootcampdetails));
        toast.custom(
          <CustomToaster
            message="Bootcamp Updated Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCompetencies = createAsyncThunk(
  "getCompetencies",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getCompetenciesApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSpocs = createAsyncThunk(
  "getSpocs",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getUsersApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getModules = createAsyncThunk(
  "getModules",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getModulesApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getAllModuleOptions = createAsyncThunk(
  "getAllModuleOptions",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getAllModulesApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getBootcampById = createAsyncThunk(
  "getBootcampById",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getBootcampByIdApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getCreateEditBootcampOptions = createAsyncThunk(
  "getCreateEditBootcampOptions",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getCreateEditBootcampOptionsApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCreateEditModuleOptions = createAsyncThunk(
  "getCreateEditModuleOptions",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getCreateEditModuleOptionsApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFiltersForBootcamp = createAsyncThunk(
  "getFiltersForBootcamp",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getFiltersForBootcampApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCloneModuleOptions = createAsyncThunk(
  "getCloneModuleOptions",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getCloneModuleOptionsApi();
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const bootcamp = createSlice({
  name: "bootcamp",
  initialState,
  reducers: {
    clearBootcamps(state) {
      state.bootcampData = [];
    },
    clearModules(state) {
      state.modules = [];
    },
    clearCloneModuleOptions(state) {
      state.getCloneModuleOptions = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBootcamp.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBootcamp.fulfilled, (state, action) => {
        state.loading = false;
        state.bootcampData = action.payload;
      })
      .addCase(getBootcamp.rejected, (state) => {
        state.loading = false;
      })
      .addCase(addBootcamp.pending, (state) => {
        state.addBootcamploading = true;
      })
      .addCase(addBootcamp.fulfilled, (state, action) => {
        state.addBootcamploading = false;
      })
      .addCase(addBootcamp.rejected, (state, action) => {
        state.addBootcamploading = false;
        state.error = action.payload;
      })
      .addCase(deleteBootcamp.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBootcamp.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteBootcamp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(editBootcamp.pending, (state) => {
        state.editBootcampLoading = true;
      })
      .addCase(editBootcamp.fulfilled, (state) => {
        state.editBootcampLoading = false;
      })
      .addCase(editBootcamp.rejected, (state, action) => {
        state.editBootcampLoading = false;
        state.error = action.payload;
      })
      .addCase(getCompetencies.pending, (state) => {
        state.ModalApiLoading = true;
      })
      .addCase(getCompetencies.fulfilled, (state, action) => {
        state.ModalApiLoading = false;
        state.competencies = action.payload;
      })
      .addCase(getCompetencies.rejected, (state, action) => {
        state.ModalApiLoading = false;
        state.error = action.payload;
      })
      .addCase(getSpocs.pending, (state) => {
        state.ModalApiLoading = true;
      })
      .addCase(getSpocs.fulfilled, (state, action) => {
        state.ModalApiLoading = false;
        state.spocs = action.payload;
      })
      .addCase(getSpocs.rejected, (state, action) => {
        state.ModalApiLoading = false;
        state.error = action.payload;
      })
      .addCase(getModules.pending, (state) => {
        state.moduleLoading = true;
      })
      .addCase(getModules.fulfilled, (state, action) => {
        state.moduleLoading = false;
        state.modules = action.payload;
      })
      .addCase(getModules.rejected, (state, action) => {
        state.moduleLoading = false;
        state.error = action.payload;
      })
      .addCase(getAllModuleOptions.pending, (state) => {
        state.moduleOptionLoading = true;
      })
      .addCase(getAllModuleOptions.fulfilled, (state, action) => {
        state.moduleOptionLoading = false;
        state.getAllModuleOptions = action.payload;
      })
      .addCase(getAllModuleOptions.rejected, (state, action) => {
        state.moduleOptionLoading = false;
        state.error = action.payload;
      })
      .addCase(getBootcampOptions.pending, (state) => {
        state.getBootcampOptionsLoader = true;
      })
      .addCase(getBootcampOptions.fulfilled, (state, action) => {
        state.getBootcampOptionsLoader = false;
        state.getBootcampOptions = action.payload;
      })
      .addCase(getBootcampOptions.rejected, (state, action) => {
        state.getBootcampOptionsLoader = false;
        state.error = action.payload;
      })
      .addCase(getBootcampById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBootcampById.fulfilled, (state, action) => {
        state.loading = false;
        state.getBootcampDataById = action.payload;
      })
      .addCase(getBootcampById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCreateEditBootcampOptions.pending, (state) => {
        state.getBootcampOptionsLoader = true;
      })
      .addCase(getCreateEditBootcampOptions.fulfilled, (state, action) => {
        state.getBootcampOptionsLoader = false;
        state.getCreateEditBootcampOptions = action.payload;
      })
      .addCase(getCreateEditBootcampOptions.rejected, (state, action) => {
        state.getBootcampOptionsLoader = false;
        state.error = action.payload;
      })
      .addCase(getCreateEditModuleOptions.pending, (state) => {
        state.moduleOptionLoading = true;
      })
      .addCase(getCreateEditModuleOptions.fulfilled, (state, action) => {
        state.moduleOptionLoading = false;
        state.getCreateEditModuleOptions = action.payload;
      })
      .addCase(getCreateEditModuleOptions.rejected, (state, action) => {
        state.moduleOptionLoading = false;
        state.error = action.payload;
      })
      .addCase(getFiltersForBootcamp.pending, (state) => {
        state.ModalApiLoading = true;
      })
      .addCase(getFiltersForBootcamp.fulfilled, (state, action) => {
        state.ModalApiLoading = false;
        state.filtersForBootcamp = action.payload;
      })
      .addCase(getFiltersForBootcamp.rejected, (state, action) => {
        state.ModalApiLoading = false;
        state.error = action.payload;
      })
      .addCase(getCloneModuleOptions.pending, (state) => {
        state.cloneModuleApiLoading = true;
      })
      .addCase(getCloneModuleOptions.fulfilled, (state, action) => {
        state.cloneModuleApiLoading = false;
        state.getCloneModuleOptions = action.payload;
      })
      .addCase(getCloneModuleOptions.rejected, (state, action) => {
        state.cloneModuleApiLoading = false;
      });
  },
});

export const { clearModules, clearBootcamps, clearCloneModuleOptions } = bootcamp.actions;
export default bootcamp.reducer;
