import React, { useState } from "react";
import "./topicRelatedQuestionsListOnPlay.scss";
import { Grid, Paper } from "@mui/material";

import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import TopicRelatedQuestionsListOnPlayCard from "../TopicRelatedQuestionsListOnPlayCard/TopicRelatedQuestionsListOnPlayCard";

const TopicRelatedQuestionsListOnPlay = ({
  submitQuiz,
  saveResponse,
  topics,
  topicId,
  assessment,
  responses,
}) => {
  const [expandedId, setExpandedId] = useState(null);
  const selectedTopic = topics?.results?.find((topic) => topic?.id === topicId);

  const allQuestions = topics?.results?.flatMap(
    (topic) => topic?.evaluationQuestions?.results || []
  );
  const questionsToDisplay =
    topicId === "all"
      ? allQuestions
      : selectedTopic?.evaluationQuestions?.results;

  return (
    <Grid container className="main-container">
      <Grid item md={12} className="body-container">
        <Paper elevation={0} className="interview-question-parent-container">
          {topicId === null ? (
            <Grid container className="no-data-container">
              <NoDataAvailable
                imgHeight={4}
                imgWidth={4.5}
                message="No Topic selected"
              />
            </Grid>
          ) : !questionsToDisplay?.length ? (
            <Grid container className="no-data-container">
              <NoDataAvailable
                imgHeight={4}
                imgWidth={4.5}
                message="No Questions Available"
              />
            </Grid>
          ) : (
            questionsToDisplay?.map((evaluationQuestion, index) => (
              <TopicRelatedQuestionsListOnPlayCard
                key={index}
                evaluationQuestion={evaluationQuestion}
                assessment={assessment}
                expandedId={expandedId}
                setExpandedId={setExpandedId}
                topicId={topicId}
                saveResponse={saveResponse}
                submitQuiz={submitQuiz}
                responses={responses}
              />
            ))
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TopicRelatedQuestionsListOnPlay;
