import React, { useEffect, useState } from "react";
import "./Attendees.scss";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import BreadCrumb from "../../components/Breadcrumb/BreadCrumb";
import IconButtons from "../../components/IconButtons/IconButtons";
import {
  download_active_icon,
  download_disabled_icon,
  filter_active_icon,
} from "../../constants/icons";
import { useDispatch, useSelector } from "react-redux";
import AttendeeTable from "./AttendeeTable";
import {
  addAttendee,
  clearAttendees,
  clearBatches,
  clearFilters,
  clearOptions,
  deleteAttendee,
  deleteModuleAttendee,
  getAttendees,
  getBootcamp,
  getFiltersForAttendees,
  getOptionsForCreateAttendee,
  getOptionsForEditAttendee,
  getModuleAttendees,
  updateAttendee,
  updateModuleAttendee,
} from "../../store/AttendeesPage/attendees";
import FilterAttendee from "./FilterAttendee";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EditTrainee from "./EditTrainee";
import AddAttendee from "./AddAttendee";
import Loader from "../../components/Loader/Loader";
import ReportDownloadModal from "../../components/ReportDownloadModal/ReportDownloadModal";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import {
  ROLE_TYPES,
  calculateFilterCount,
  font_13_icon,
  font_16,
} from "../../utils/utils";
import { getBootcampModuleView } from "../../store/bootcampModuleView";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import { backendBaseUrl } from "../../config/config";

const initialEditTraineeDetails = {
  attendeeId: null,
  bootcampId: null,
  leadMentorId: null,
  coMentorId: null,
  status: "",
  batchId: null,
  mentorCompetencyId: null,
  mentorDesignationId: null,
  coMentorCompetencyId: null,
  coMentorDesignationId: null,
};

const Attendees = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const attendees = useSelector((state) => {
    return state?.attendees;
  });
  const security = useSelector((state) => state?.security);
  const userEmail = security?.loggedInUser?.email;
  const userCompetency = security?.loggedInUser?.competency;
  const userLoading = attendees?.userLoading;
  const roles = security?.roles;
  const isHr = ROLE_TYPES?.isHr(roles);
  const isAdminOrSpoc =
    ROLE_TYPES.isAdmin(roles) || ROLE_TYPES.isCompetencySpoc(roles);
  const isCompetencySPOC = ROLE_TYPES.isCompetencySpoc(roles);
  const isBootcampSpoc = attendees?.bootcamp?.data?.permission?.spoc;
  const loading = attendees?.loading;
  const [filterCount, setFilterCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const attendeesList = attendees?.attendee?.data?.results;
  const moduleAttendeeList = attendees?.moduleAttendee?.data?.results;
  const btnLoader = attendees?.btnLoading;
  const [showColumnFlag, setShowColumnFlag] = useState(false);
  const [isModuleId, setIsModuleId] = useState(false);
  const [firstApiCall, setFirstApiCall] = useState(true);

  const moduleView = useSelector((state) => {
    return state?.bootcampModuleView;
  });
  const moduleCompetencyList =
    moduleView?.moduleData?.data?.bootcamps?.results?.map(
      (it) => it?.competency
    );
  const uniqueModuleCompetencyList = Array.from(
    new Set(moduleCompetencyList?.map((s) => s?.id))
  ).map((id) => {
    return moduleCompetencyList.find((it) => it?.id === id);
  });
  const moduleBootcampList = moduleView?.moduleData?.data?.bootcamps?.results;
  const isModuleSpoc = moduleView?.moduleData?.data?.permission?.spoc;
  const isSPOC = isModuleSpoc || isBootcampSpoc;

  const permission = moduleView?.moduleData?.data?.permission;
  const isMentor = permission?.mentor;
  const initialAddAttendee = {
    competencyIds: null,
    designationIds: null,
    attendees: [],
  };

  const { bootcampId, moduleId = false } = params;
  const bootcamp = attendees?.bootcamp?.data;
  const bootcampName = attendees?.bootcamp?.data?.name;
  const bootcampIdToBeMatched = attendees?.bootcamp?.data?.id;

  const bootcampModules = attendees?.bootcamp?.data?.modules?.results;
  const [moduleName, setModuleName] = useState(null);

  const bootcampIsNotConcluded =
    attendees?.bootcamp?.data?.status === "SCHEDULED" ||
    attendees?.bootcamp?.data?.status === "ACTIVE";
  const isModuleActive = moduleView?.moduleData?.data?.status === "ACTIVE";
  const canAddAttendees = !moduleId && params?.bootcampId && isBootcampSpoc;

  const canViewActions = moduleId ? isModuleSpoc : isBootcampSpoc;

  const currentBootcamp = !moduleId
    ? bootcamp
    : moduleBootcampList?.find((it) => it?.id === Number(bootcampId));
  const matchedModule = bootcampModules?.find((item) => item.id === +moduleId);
  useEffect(() => {
    if (matchedModule) {
      setModuleName(matchedModule?.name);
    }
  }, [bootcampModules]);

  const breadcrumbs = [
    { label: "Home", url: "/" },
    {
      label: bootcampName ? bootcampName : "Bootcamp",
      url: "/",
      isBootcamp: true,
      bootcampId: bootcampId,
    },
    moduleId
      ? {
          label: moduleName ? moduleName : "Module",
          url: `/bootcamp/${bootcampId}/module/${moduleId}` || "/",
          isModule: true,
          moduleId: moduleId,
        }
      : null,
    { label: "Attendees" },
  ];

  const filteredBreadcrumbs = breadcrumbs.filter((item) => item !== null);

  const location = useLocation();
  const { batchtoFilter } = location?.state || {};
  const initialFilterAttendees = {
    attendeeName: "",
    attendeeEmail: "",
    mentorName: !isSPOC && isMentor ? userEmail : "",
    mentorEmail: "",
    exerciseStatuses: [],
    competencyIds: [],
    batchIds: batchtoFilter && firstApiCall ? [batchtoFilter] : [],
    status: null,
  };

  //////////////////// validate attendee /////////////////////////
  const initialAttendeeErrorState = {
    competencyIds: false,
    designationIds: false,
    attendees: false,
  };
  const [attendeeErrors, setAttendeeErrors] = useState(
    initialAttendeeErrorState
  );
  const [apiAttendeeError, setApiAttendeeError] = useState(false);
  const validateAttendee = (attendeeDetails) => {
    const { competencyIds, attendees } = attendeeDetails;
    const newErrors = {
      competencyIds: competencyIds ? "" : "Competency is required",
      attendees: attendees.length ? "" : "Name is required",
    };
    setAttendeeErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };
  //////////////////////////////////////////////////////////////////
  //////////////////// validate trainee /////////////////////////
  const initialTraineeErrorState = {
    attendeeId: false,
    bootcampId: false,
    leadMentorId: false,
    coMentorId: false,
    status: false,
  };
  const [traineeErrors, setTraineeErrors] = useState(initialTraineeErrorState);
  const [apiTraineeError, setApiTraineeError] = useState(false);

  const validateTrainee = (traineeDetails) => {
    const { leadMentorId } = traineeDetails;
    const newErrors = {
      leadMentorId: leadMentorId ? "" : "Lead Mentor is required",
    };
    setTraineeErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };
  const validateEditTrainee = (traineeDetails) => {
    const { leadMentorId } = traineeDetails;
    const newErrors = {
      leadMentorId: moduleId || leadMentorId ? "" : "Lead Mentor is required",
    };
    setTraineeErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };
  useEffect(() => {
    if (attendees?.error?.length) {
      setApiTraineeError(true);
    }
  }, [attendees?.error]);
  //////////////////////////////////////////////////////////////////
  ////////////// sort table ///////////////////////////////////
  const [sortBy, setSortBy] = useState("attendeeName");
  const [orderBy, setOrderBy] = useState("ASC");

  const handleSorting = (order, sort) => {
    setSortBy(sort);
    setOrderBy(order);
    setOffsetChangedByUser(true);
  };
  ////////////////////////////////////////////////////////////
  /////////  pagination //////////////////////////////////////
  const [offset, setOffset] = useState(0);
  const [offsetChangedByUser, setOffsetChangedByUser] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterActivated, setFilterActivated] = useState(false);

  const totalResult = moduleId
    ? attendees?.moduleAttendee?.data?.totalResults
    : attendees?.attendee?.data?.totalResults;

  const handlePageChange = (value) => {
    setOffset(value - 1);
    setCurrentPage(value);
    setOffsetChangedByUser(true);
  };

  ////////////////////////////////////////////////////////////////////
  ///////////////// filter Modal //////////////////////////////////////
  const [filterOpen, setFilterOpen] = useState(false);
  const [filterAttendees, setFilterAttendees] = useState(
    initialFilterAttendees
  );
  const handleFilterOpen = () => {
    setFilterOpen(true);
  };
  const handleFilterClose = () => {
    setFilterOpen(false);
  };
  const handleFilterAttendeeSubmit = async () => {
    setIsLoading(true);
    const newFilterCount = !params?.moduleId
      ? calculateFilterCount(filterAttendees) - 1
      : isHr
      ? calculateFilterCount(filterAttendees)
      : !isSPOC
      ? isMentor
        ? filterAttendees?.bootcampId
          ? calculateFilterCount(filterAttendees) - 1
          : calculateFilterCount(filterAttendees)
        : calculateFilterCount(filterAttendees) - 1
      : calculateFilterCount(filterAttendees);
    const data = {
      ...filterAttendees,
      batchId: params?.batchId,
      moduleId: params?.moduleId,
      bootcampId:
        filterAttendees?.bootcampId?.map((item) => item?.id) ||
        params?.bootcampId,
      offset: 0,
      attendeeName: filterAttendees?.attendeeName.trim(),
      attendeeEmail: filterAttendees?.attendeeEmail.trim(),
      mentorName: filterAttendees?.mentorName.trim(),
      mentorEmail: filterAttendees?.mentorEmail.trim(),
      status: filterAttendees?.status?.key,
      exerciseStatuses: filterAttendees?.exerciseStatuses?.map(
        (item) => item?.key
      ),
      competencyIds: filterAttendees?.competencyIds?.map((item) => item?.id),
      batchIds: filterAttendees?.batchIds?.map((item) => item?.id),
      sortBy: sortBy,
      sortOrder: orderBy,
    };
    if (newFilterCount || filterActivated) {
      !moduleId
        ? await dispatch(getAttendees(data))
        : await dispatch(getModuleAttendees(data));
      setFilterCount(newFilterCount);
      setFilterActivated(newFilterCount);
      setOffsetChangedByUser(false);
      setOffset(0);
      setCurrentPage(1);
    }
    setFilterOpen(false);
    setIsLoading(false);
  };
  const handleFilterAttendeeRemove = async () => {
    setIsLoading(true);
    if (filterCount) {
      const data = {
        batchId: params?.batchId,
        moduleId: params?.moduleId,
        bootcampId: params?.bootcampId,
        ...(!isSPOC && isMentor && { mentorName: userEmail }),
        offset: 0,
        sortBy: sortBy,
        sortOrder: orderBy,
      };
      !moduleId
        ? await dispatch(getAttendees(data))
        : await dispatch(getModuleAttendees(data));
      const newFilterCount = params?.moduleId && (isMentor || isSPOC) ? 1 : 0;
      setFilterCount(newFilterCount);
      setFilterActivated(newFilterCount);
    }
    setOffsetChangedByUser(!filterCount);
    setOffset(0);
    setCurrentPage(1);
    setFilterAttendees({
      ...initialFilterAttendees,
      ...(params?.moduleId && !isHr ? { bootcampId: [bootcamp] } : {}),
      ...(!params?.moduleId && (isSPOC || isHr)
        ? { bootcampId: [bootcamp] }
        : {}),
    });
    setFilterOpen(false);
    setIsLoading(false);
  };

  const handleChangeFilterAttendee = (name, value) => {
    setFilterAttendees({ ...filterAttendees, [name]: value });
  };

  ///////////////////////////////////////////////////////////////////////
  ////////////////////////  Edit Attendee ////////////////////////////////////
  const [editTraineeOpen, setEditTraineeOpen] = useState(false);
  const [batchDetails, setBatchDetails] = useState(null);
  const [editTraineeDetails, setEditTraineeDetails] = useState(
    initialEditTraineeDetails
  );
  const [isEditTrainee, setIsEditTrainee] = useState(false);
  const [previousDetails, setPreviousDetails] = useState(
    initialEditTraineeDetails
  );

  const handleEditTraineeOpen = (data) => {
    setIsEditTrainee(true);
    if (!attendees.optionsForEditAttendee?.data?.competencies?.results) {
      dispatch(
        getOptionsForEditAttendee({
          bootcampId: !moduleId && bootcampId,
          moduleId: moduleId,
        })
      );
    }
    const leadMentor = data?.leadMentor;
    const coMentor = data?.coMentor;
    setEditTraineeDetails({
      ...editTraineeDetails,
      attendeeId: data?.id,
      leadMentorId: leadMentor?.deleted ? null : leadMentor,
      coMentorId: coMentor?.deleted ? null : coMentor,
      bootcampId: !moduleId ? data?.bootcamp?.id : null,
      status: data?.status,
      batchId: data?.batch,
    });
    setPreviousDetails((prevDetails) => ({
      ...prevDetails,
      leadMentorId: leadMentor?.deleted ? null : leadMentor,
      coMentorId: coMentor?.deleted ? null : coMentor,
    }));
    setEditTraineeOpen(true);
  };
  const handleEditTraineeClose = () => {
    setEditTraineeOpen(false);
    setTraineeErrors(initialTraineeErrorState);
    setEditTraineeDetails(initialEditTraineeDetails);
    setPreviousDetails(initialEditTraineeDetails);
    setIsEditTrainee(false);
  };
  const handleEditTraineeChange = (name, value) => {
    setTraineeErrors((prev) => {
      return {
        ...prev,
        [name]: false,
      };
    });
    setEditTraineeDetails({ ...editTraineeDetails, [name]: value });
  };
  const handleEditTraineeSubmit = async () => {
    const data = {
      attendeeId: editTraineeDetails?.attendeeId,
      bootcampId: editTraineeDetails?.bootcampId,
      leadMentorId: editTraineeDetails?.leadMentorId?.id,
      coMentorId: editTraineeDetails?.coMentorId?.id,
      status: editTraineeDetails?.status,
      batchId: moduleId ? Number(editTraineeDetails?.batchId?.id) : null,
      moduleId: moduleId
        ? Number(editTraineeDetails?.batchId?.module?.id || moduleId)
        : null,
    };

    if (validateEditTrainee(data)) {
      !moduleId
        ? await dispatch(updateAttendee(data))
        : await dispatch(updateModuleAttendee(data));

      const data2 = {
        ...filterAttendees,
        batchId: params?.batchId,
        moduleId: params?.moduleId,
        bootcampId: params?.bootcampId,
        offset,
        sortBy: sortBy,
        sortOrder: orderBy,
        attendeeName: filterAttendees?.attendeeName,
        attendeeEmail: filterAttendees?.attendeeEmail,
        mentorName: filterAttendees?.mentorName,
        mentorEmail: filterAttendees?.mentorEmail,
        exerciseStatuses: filterAttendees?.exerciseStatuses?.map(
          (item) => item?.key
        ),
        competencyIds: params?.moduleId
          ? filterAttendees?.competencyIds.map((item) => item?.id)
          : null,
        batchIds: filterAttendees?.batchIds?.map((item) => item?.id),
        status: filterAttendees?.status?.key,
      };
      !moduleId
        ? await dispatch(getAttendees(data2))
        : await dispatch(getModuleAttendees(data2));
      setEditTraineeOpen(false);
      setAddAttendeeDetails(initialAddAttendee);
      dispatch(clearOptions());
    }
  };
  //////////////////////////////////////////////////////////////////////////
  ////////////////////////  Add Attendee ////////////////////////////////////
  const [addAttendeeOpen, setAddAttendeeOpen] = useState(false);
  const [addAttendeeDetails, setAddAttendeeDetails] =
    useState(initialAddAttendee);

  const handleAddAttendeeOpen = async (data) => {
    setAddAttendeeOpen(true);
    if (!attendees?.optionsForCreateAttendee?.data?.competencies?.results) {
      await dispatch(getOptionsForCreateAttendee({ bootcampId }));
    }
  };
  const handleAddAttendeeClose = () => {
    setAddAttendeeOpen(false);
    setAddAttendeeDetails(initialAddAttendee);
    setAttendeeErrors(initialAttendeeErrorState);
  };
  const handleAddAttendeeChange = (name, value) => {
    if (name == "competencyIds") {
      setAddAttendeeDetails({
        ...addAttendeeDetails,
        [name]: value,
        designationIds: null,
      });
    } else {
      setAddAttendeeDetails({ ...addAttendeeDetails, [name]: value });
    }
    setAttendeeErrors((prev) => {
      return {
        ...prev,
        [name]: false,
      };
    });
  };
  const handleAddAttendeeSubmit = async () => {
    let apiValue;
    const data = {
      ...(params?.moduleId ? {} : { bootcampId: Number(params?.bootcampId) }),
      attendees: [
        ...addAttendeeDetails?.attendees?.map((attendee) => ({
          attendeeId: attendee?.id,
          leadMentorId: attendee?.mentor?.id,
        })),
      ],
    };

    if (validateAttendee(addAttendeeDetails)) {
      apiValue = await dispatch(addAttendee(data));
      if (apiValue?.error?.message === "Rejected") {
        setApiAttendeeError(true);
      } else {
        setAddAttendeeOpen(false);
        dispatch(
          getAttendees({
            ...filterAttendees,
            batchId: params?.batchId,
            moduleId: params?.moduleId,
            bootcampId:
              !!moduleId && isMentor && !isSPOC ? null : params?.bootcampId,
            offset,
            sortBy: sortBy,
            sortOrder: orderBy,
            attendeeName: filterAttendees?.attendeeName,
            attendeeEmail: filterAttendees?.attendeeEmail,
            mentorName: filterAttendees?.mentorName,
            mentorEmail: filterAttendees?.mentorEmail,
            status: filterAttendees?.status?.key,
            exerciseStatuses: filterAttendees?.exerciseStatuses?.map(
              (item) => item?.key
            ),
            competencyIds: params?.moduleId
              ? filterAttendees?.competencyIds?.map((item) => item?.id)
              : null,
            batchIds: filterAttendees?.batchIds?.map((item) => item?.id),
          })
        );
        dispatch(clearOptions());
        setAddAttendeeDetails(initialAddAttendee);
      }
    }
  };
  //////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (userCompetency && !isHr) {
      if (params?.moduleId) {
        setFilterCount(batchtoFilter && (isMentor || isSPOC) ? 2 : 1);
        setFilterActivated(true);
      }
    } else if (params?.moduleId && isHr) {
      setFilterCount(batchtoFilter && (isMentor || isHr) ? 2 : 1);
      setFilterActivated(true);
    }
  }, [userCompetency, dispatch]);

  useEffect(() => {
    if (
      editTraineeOpen &&
      attendees.optionsForEditAttendee?.data?.competencies?.results
    ) {
      setBatchDetails(editTraineeDetails?.batchId);
      const defaultCompetency =
        attendees.optionsForEditAttendee?.data?.competencies?.results?.find(
          (it) => it?.id === currentBootcamp?.competency?.id
        );
      const mentorCompetency = editTraineeDetails?.leadMentorId
        ? attendees.optionsForEditAttendee?.data?.competencies?.results?.find(
            (it) => it?.id === editTraineeDetails?.leadMentorId?.competency?.id
          )
        : defaultCompetency;
      const mentorDesignation = editTraineeDetails?.leadMentorId
        ? mentorCompetency?.designations?.results?.find(
            (it) => it?.id === editTraineeDetails?.leadMentorId?.designation?.id
          )
        : null;

      const coMentorCompetency = editTraineeDetails?.coMentorId
        ? attendees.optionsForEditAttendee?.data?.competencies?.results?.find(
            (it) => it?.id === editTraineeDetails?.coMentorId?.competency?.id
          )
        : defaultCompetency;
      const coMentorDesignation = editTraineeDetails?.coMentorId
        ? coMentorCompetency?.designations?.results?.find(
            (it) => it?.id === editTraineeDetails?.coMentorId?.designation?.id
          )
        : null;

      const data = {
        mentorCompetencyId: mentorCompetency,
        mentorDesignationId: mentorDesignation,
        coMentorCompetencyId: coMentorCompetency,
        coMentorDesignationId: coMentorDesignation,
      };
      setEditTraineeDetails({
        ...editTraineeDetails,
        ...data,
      });
      setPreviousDetails({
        ...previousDetails,
        ...data,
      });
    }
  }, [
    editTraineeOpen,
    attendees?.optionsForEditAttendee?.data?.competencies?.results,
  ]);

  useEffect(() => {
    const competency = editTraineeDetails?.mentorCompetencyId?.id;
    const previousCompetency = previousDetails?.mentorCompetencyId?.id;
    if (competency && previousCompetency) {
      if (competency === previousCompetency) {
        setEditTraineeDetails({
          ...editTraineeDetails,
          mentorDesignationId: previousDetails?.mentorDesignationId,
          leadMentorId: previousDetails?.leadMentorId,
        });
      }
      if (competency !== previousCompetency) {
        setEditTraineeDetails({
          ...editTraineeDetails,
          mentorDesignationId: null,
          leadMentorId: null,
        });
      }
    }
  }, [editTraineeDetails?.mentorCompetencyId]);

  useEffect(() => {
    const designation = editTraineeDetails?.mentorDesignationId?.id;
    const previousDesignation = previousDetails?.mentorDesignationId?.id;
    if (designation === previousDesignation && !!previousDesignation) {
      setEditTraineeDetails({
        ...editTraineeDetails,
        leadMentorId: previousDetails?.leadMentorId,
      });
    }
    if (designation !== previousDesignation && !!designation) {
      setEditTraineeDetails({
        ...editTraineeDetails,
        leadMentorId: null,
      });
    }
  }, [editTraineeDetails?.mentorDesignationId]);

  useEffect(() => {
    const designation = editTraineeDetails?.coMentorDesignationId?.id;
    const previousDesignation = previousDetails?.coMentorDesignationId?.id;

    if (designation === previousDesignation && !!previousDesignation) {
      setEditTraineeDetails({
        ...editTraineeDetails,
        coMentorId: previousDetails?.coMentorId,
      });
    }
    if (designation !== previousDesignation && !!designation) {
      setEditTraineeDetails({
        ...editTraineeDetails,
        coMentorId: null,
      });
    }
  }, [editTraineeDetails?.coMentorDesignationId]);

  useEffect(() => {
    const competency = editTraineeDetails?.coMentorCompetencyId?.id;
    const previousCompetency = previousDetails?.coMentorCompetencyId?.id;
    if (competency && previousCompetency) {
      if (competency === previousCompetency) {
        setEditTraineeDetails({
          ...editTraineeDetails,
          coMentorDesignationId: previousDetails?.coMentorDesignationId,
          coMentorId: previousDetails?.coMentorId,
        });
      }
      if (competency !== previousCompetency) {
        setEditTraineeDetails({
          ...editTraineeDetails,
          coMentorDesignationId: null,
          coMentorId: null,
        });
      }
    }
  }, [editTraineeDetails?.coMentorCompetencyId]);

  ////////////////// Incorrect URL Check //////////////////

  useEffect(() => {
    if (
      (matchedModule && matchedModule?.id !== +params?.moduleId) ||
      (bootcampIdToBeMatched && bootcampIdToBeMatched !== +params?.bootcampId)
    ) {
      navigate("/error", { replace: true });
    }
  }, [matchedModule, bootcampIdToBeMatched]);

  ////////////////////////////////////////////////////////

  useEffect(() => {
    if (
      filterOpen &&
      !attendees?.filtersForAttendees?.data?.competencies?.results &&
      (isHr || !!moduleId)
    ) {
      dispatch(
        getFiltersForAttendees({
          bootcampId: !moduleId && bootcampId,
          moduleId,
        })
      );
    }
  }, [filterOpen]);

  const [attendeeView, setAttendeeView] = useState(null);
  useEffect(() => {
    if (!moduleId && params?.bootcampId) {
      setShowColumnFlag(true);
    }
    if (params?.moduleId) {
      setIsModuleId(true);
    }
    const fetchData = async () => {
      let attendeesApiValue;
      setIsLoading(true);
      if (
        (!params?.moduleId || userCompetency) &&
        sortBy &&
        orderBy &&
        offsetChangedByUser
      ) {
        const data = {
          ...filterAttendees,
          batchId: params?.batchId,
          moduleId: params?.moduleId,
          bootcampId: params?.bootcampId,
          offset,
          attendeeName: filterAttendees?.attendeeName,
          attendeeEmail: filterAttendees?.attendeeEmail,
          mentorName: filterAttendees?.mentorName,
          mentorEmail: filterAttendees?.mentorEmail,
          status: filterAttendees?.status?.key,
          exerciseStatuses: filterAttendees?.exerciseStatuses?.map(
            (item) => item?.key
          ),
          competencyIds: null,

          batchIds: filterAttendees?.batchIds?.map((item) => item?.id),
          sortBy: sortBy,
          sortOrder: orderBy,
        };
        if (moduleId ? isNaN(+params?.moduleId) : isNaN(+params?.bootcampId)) {
          navigate("/error", { replace: true });
          return;
        }
        !moduleId
          ? await dispatch(getAttendees(data))
          : await dispatch(getModuleAttendees(data));
        setFilterAttendees({
          ...filterAttendees,
          ...((isSPOC || isHr) && { bootcampId: [bootcamp] }),
          ...(!isSPOC && isMentor && { mentorName: userEmail }),
        });
        setFirstApiCall(false);
      }
      setIsLoading(false);
    };
    if (attendeeView !== null && !attendeeView?.error) {
      fetchData();
    }
  }, [orderBy, offset, sortBy, attendeeView]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalResult / 20));
  }, [totalResult]);

  useEffect(() => {
    const fetchBootcampModuleView = async () => {
      setIsLoading(true);
      let bootcampApiValue, apiValue;
      if (params?.bootcampId && !isNaN(+params?.bootcampId)) {
        bootcampApiValue = await dispatch(
          getBootcamp({ bootcampId: params?.bootcampId })
        );
      }
      if (
        params?.moduleId &&
        !isNaN(+params?.bootcampId) &&
        !isNaN(+params?.moduleId)
      ) {
        apiValue = await dispatch(
          getBootcampModuleView({
            bootcampId: Number(params?.bootcampId),
            moduleId: Number(params?.moduleId),
          })
        );
      }
      setAttendeeView(apiValue);
    };

    if (security?.loggedInUser?.id) {
      fetchBootcampModuleView();
    }

    return () => {
      dispatch(clearBatches());
      dispatch(clearAttendees());
      dispatch(clearOptions());
      dispatch(clearFilters());
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      !isHr &&
      addAttendeeOpen &&
      attendees?.optionsForCreateAttendee?.data?.competencies?.results &&
      !addAttendeeDetails?.competencyIds
    ) {
      const findCompetency =
        attendees?.optionsForCreateAttendee?.data?.competencies?.results?.find(
          (it) => it?.id === currentBootcamp?.competency?.id
        );
      setAddAttendeeDetails({
        ...addAttendeeDetails,
        competencyIds: findCompetency,
      });
    }
  }, [
    addAttendeeOpen,
    attendees?.optionsForCreateAttendee?.data?.competencies?.results,
  ]);

  useEffect(() => {
    if (
      addAttendeeOpen &&
      attendees?.optionsForCreateAttendee?.data?.competencies?.results &&
      addAttendeeDetails?.competencyIds
    ) {
      const findDesignation =
        addAttendeeDetails?.competencyIds.designations?.results?.find(
          (it) => it?.name === "Trainee"
        );
      if (findDesignation) {
        setAddAttendeeDetails({
          ...addAttendeeDetails,
          designationIds: findDesignation,
        });
      }
    }
  }, [addAttendeeDetails?.competencyIds]);

  //////////////////////// delete Modal ///////////////////////
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteAttendeeId, setDeleteAttendeeId] = useState();

  const handleDeleteModalOpen = (data) => {
    setDeleteModalOpen(true);
    setDeleteAttendeeId(data?.id);
  };

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleAttendeeDelete = async () => {
    !moduleId
      ? await dispatch(deleteAttendee({ attendeeId: deleteAttendeeId }))
      : await dispatch(deleteModuleAttendee({ attendeeId: deleteAttendeeId }));

    const data = {
      ...filterAttendees,
      batchId: params?.batchId,
      moduleId: params?.moduleId,
      bootcampId: params?.bootcampId,
      offset,
      sortBy: sortBy,
      sortOrder: orderBy,
      attendeeName: filterAttendees?.attendeeName,
      attendeeEmail: filterAttendees?.attendeeEmail,
      mentorName: filterAttendees?.mentorName,
      mentorEmail: filterAttendees?.mentorEmail,
      exerciseStatuses: filterAttendees?.exerciseStatuses?.map(
        (item) => item?.key
      ),
      competencyIds: params?.moduleId
        ? filterAttendees?.competencyIds?.map((item) => item?.id)
        : null,
      batchIds: filterAttendees?.batchIds?.map((item) => item?.id),
      status: filterAttendees?.status?.key,
    };
    !moduleId
      ? await dispatch(getAttendees(data))
      : await dispatch(getModuleAttendees(data));
    setDeleteModalOpen(false);
    await dispatch(clearOptions());
    setAddAttendeeDetails(initialAddAttendee);
  };

  const [reportDownloadOpen, setReportDownloadOpen] = useState(false);
  const [reportDownloadData, setReportDownloadData] = useState({});

  const downloadFile = (data) => {
    let fileUrl = `${backendBaseUrl}/api/v1/${
      moduleId ? "moduleAttendees" : "attendees"
    }/report?`;
    if (data?.bootcampId) {
      fileUrl = fileUrl + `bootcampId=${data?.bootcampId}`;
    } else if (data?.moduleId) {
      fileUrl = fileUrl + `moduleId=${data?.moduleId}`;
    }
    if (data?.bootcamps?.length) {
      fileUrl =
        fileUrl + `&bootcampIds=${data?.bootcamps?.map((it) => it?.id)}`;
    }
    window.open(fileUrl, "_blank");
  };
  const handleDownloadIconClick = () => {
    if (moduleId) {
      if (isHr) {
        setReportDownloadOpen(true);
        setReportDownloadData({
          moduleId: moduleId,
          bootcamps: moduleBootcampList.filter(
            (it) => it?.id === Number(bootcampId)
          ),
        });
      } else {
        downloadFile({ moduleId });
      }
    } else {
      downloadFile({ bootcampId });
    }
  };

  const handlReportBootcampChange = (value) => {
    setReportDownloadData({
      ...reportDownloadData,
      bootcamps: value,
    });
  };
  const handlReportBootcampModalClose = async () => {
    await setReportDownloadOpen(false);
    setReportDownloadData({});
  };

  const handlReportBootcampSubmit = () => {
    downloadFile(reportDownloadData);
    handlReportBootcampModalClose();
  };

  //////////////////////////////////////////////////////////

  if ((loading && isLoading) || isLoading) return <Loader />;

  return (
    <>
      <Grid container className="main-container">
        <BreadCrumb breadcrumbs={filteredBreadcrumbs} />
        <Grid item className="header-container" md={16}>
          <Typography variant="font_21_bold">Attendees</Typography>
          <Box className="header-right-icons">
            {(isHr || isSPOC) && (
              <Box
                className={
                  moduleId || isHr ? "margin-right-1" : "margin-right-2"
                }
              >
                <IconButtons
                  size="small"
                  height={font_16}
                  width={font_16}
                  image={download_active_icon}
                  disabledImage={download_disabled_icon}
                  handleClick={handleDownloadIconClick.bind(this)}
                  tooltip="Download Attendee Report"
                  disabled={
                    !(!moduleId ? attendeesList : moduleAttendeeList)?.length
                  }
                  disabledTooltip={
                    !(!moduleId ? attendeesList : moduleAttendeeList)?.length &&
                    `There are no attendees in this ${
                      moduleId ? "Module" : "Bootcamp"
                    } `
                  }
                />
              </Box>
            )}
            {canAddAttendees && (
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                className="action-head-btn"
                onClick={handleAddAttendeeOpen}
                disabled={loading || !bootcampIsNotConcluded}
              >
                <Typography variant="outlineBtnLabel">Add Attendees</Typography>
              </Button>
            )}

            <Box
              className="filterButton margin-left-1"
              sx={{
                border: filterActivated
                  ? "2px solid #de1186"
                  : "1px solid #de118680",
              }}
            >
              <IconButtons
                height={font_13_icon}
                width={font_13_icon}
                image={filter_active_icon}
                handleClick={handleFilterOpen}
                tooltip="Add Filters"
                count={filterCount}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={12} className="body-container">
          <Grid container className="table-parent-container">
            <Paper
              elevation={0}
              className="flex-container-column attendees-paper-container"
            >
              {(!moduleId ? attendeesList : moduleAttendeeList)?.length > 0 ? (
                <AttendeeTable
                  attendeesList={!moduleId ? attendeesList : moduleAttendeeList}
                  handlePageChange={handlePageChange}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  handleSorting={handleSorting}
                  totalResult={totalResult}
                  showColumnFlag={showColumnFlag}
                  handleEditTraineeOpen={handleEditTraineeOpen}
                  handleDeleteModalOpen={handleDeleteModalOpen}
                  canViewActions={canViewActions}
                  sortBy={sortBy}
                  orderBy={orderBy}
                  isHr={isHr}
                />
              ) : (
                <Grid container className="no-data-container">
                  <NoDataAvailable
                    imgHeight={4}
                    imgWidth={4.5}
                    message="No Attendees Available"
                  />
                </Grid>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <FilterAttendee
        open={filterOpen}
        onClose={handleFilterClose}
        filterAttendees={filterAttendees}
        handleFilterAttendeeSubmit={handleFilterAttendeeSubmit}
        handleFilterAttendeeRemove={handleFilterAttendeeRemove}
        handleChangeFilterAttendee={handleChangeFilterAttendee}
        isModuleId={isModuleId}
        filtersForAttendees={attendees?.filtersForAttendees}
        isHr={isHr}
        isSPOC={isSPOC}
        isMentor={isMentor}
      />
      <EditTrainee
        batchDetails={batchDetails}
        setBatchDetails={setBatchDetails}
        title="Edit Attendee"
        open={editTraineeOpen}
        handleClose={handleEditTraineeClose}
        editTraineeDetails={editTraineeDetails}
        handleEditTraineeChange={handleEditTraineeChange}
        handleSubmit={handleEditTraineeSubmit}
        loader={btnLoader}
        errors={traineeErrors}
        batchesList={attendees?.optionsForEditAttendee?.data?.batches?.results}
        competencyList={
          attendees?.optionsForEditAttendee?.data?.competencies?.results
        }
        userCompetency={userCompetency}
        userLoading={userLoading}
        isEditTrainee={isEditTrainee}
        isModuleActive={isModuleActive}
      />
      <AddAttendee
        title="Add Attendee"
        open={addAttendeeOpen}
        addAttendeeDetails={addAttendeeDetails}
        handleAddAttendeeChange={handleAddAttendeeChange}
        handleClose={handleAddAttendeeClose}
        handleSubmit={handleAddAttendeeSubmit}
        competencyList={
          attendees?.optionsForCreateAttendee?.data?.competencies?.results || []
        }
        loader={btnLoader}
        userNDesignationLoading={userLoading}
        errors={attendeeErrors}
      />

      <DeleteModal
        open={deleteModalOpen}
        handleClose={handleDeleteModalClose}
        handleDelete={handleAttendeeDelete}
      />
      <ReportDownloadModal
        open={reportDownloadOpen}
        handleClose={handlReportBootcampModalClose}
        bootcampList={moduleBootcampList}
        handleBootcampChange={handlReportBootcampChange}
        selectedBootcamps={reportDownloadData?.bootcamps || []}
        handleDownloadFile={handlReportBootcampSubmit}
      />
    </>
  );
};

export default Attendees;
