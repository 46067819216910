import React from "react";

import { Modal, Box, IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { placeholder_user_image } from "../../constants/icons";
import { isValidString } from "../../utils/utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "transparent",
  boxShadow: "none",
  outline: "none",
};

const ImageModal = ({ open, handleClose, imageUrl }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="zoomed-image"
      aria-describedby="modal-with-zoomed-image"
      sx={{ zIndex: 9999 }}
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: -14,
            top: -14,
            color: "black",
            background: "white",
            borderRadius: "50%",
            "&:hover": {
              backgroundColor: "white",
              color: "black",
            },

            width: "28px",
            height: "28px",
            minWidth: "25px",
            minHeight: "25px",
          }}
        >
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
        <img
          src={isValidString(imageUrl) ? imageUrl : placeholder_user_image}
          loading="lazy"
          alt="Zoomed-profile-pic"
          style={{ maxHeight: "40vh", maxWidth: "40vw" }}
        />
      </Box>
    </Modal>
  );
};

export default ImageModal;
