import React from "react";
import loader from "../../assets/gifs/loader.gif";
import "./loader.scss";

const Loader = () => {
  return (
    <div id="cover-spin">
      <div className="loader">
        <img src={loader} alt="loader" />
      </div>
    </div>
  );
};

export default Loader;
