import React, { useEffect } from "react";
import "./loginPage.scss";
import { Box, Button, Grid, Typography } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { ttn_logo_icon } from "../../constants/icons";
import { getLoginEndpoint } from "../../utils/utils";
import { LOCAL_STORAGE_KEYS } from "../../constants/constants";

const onLoginClick = () => window.location.href = getLoginEndpoint();

const LoginPage = () => {
  useEffect(() => {
    if (localStorage.getItem(LOCAL_STORAGE_KEYS.USER_LOGOUT)) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_LOGOUT);
    }
  }, []);
  return (
    <Grid container className="login-page-container">
      <Grid item className="login-card">
        <Box
          className="ttn-image"
          sx={{ backgroundImage: `url(${ttn_logo_icon})` }}
        ></Box>
        <Typography className="login-card-text" variant="h4">
          Welcome to Bootcamp Portal!
        </Typography>
        <Button
          className="login-card-button"
          variant="contained"
          startIcon={<GoogleIcon />}
          onClick={onLoginClick}
        >
          Login with Google
        </Button>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
