import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ROUTE_CONSTANTS } from "./route-constants";
import LoginPage from "../features/LogInPage/LoginPage";
import Home from "../features/Home/Home";
import LocationsAndRooms from "../features/LocationsAndRooms/LocationsAndRooms";
import CallbackAPI from "../features/CallbackAPI/CallbackAPI";
import Topic from "../features/Topic/Topic";
import SessionView from "../features/SessionView/SessionView";
import Users from "../features/Users/Users";
import Sessions from "../features/Sessions/Sessions";
import Questions from "../components/Questions/Questions";
import BootcampModuleView from "../features/BootcampModuleView/BootcampModuleView";
import Attendees from "../features/AttendeesPage/Attendees";
import Dashboard from "../features/Dashboard/Dashboard";
import { useSelector } from "react-redux";
import { hasAnyPermission, ROLE_TYPES } from "../utils/utils";
import Exercises from "../features/Exercises/Exercises";
import AccessDenied from "../features/AccessDenied/AccessDenied";
import Performance from "../features/Performance/Performance";
import AssessmentQuestions from "../features/Assessment Questions/AssessmentQuestions";
import AssessmentListing from "../features/AssessmentListing/AssessmentListing";
import CreateAssessment from "../features/CreateAssessment/CreateAssessment";
import ViewAssessment from "../features/ViewAssessment/ViewAssessment";
import EvaluationListing from "../features/EvaluationListing/EvaluationListing";
import ViewAssessmentEvaluation from "../features/ViewAssessmentEvaluation/ViewAssessmentEvaluation";
import Assessments from "../features/Assessments/Assessments";
import AssessmentPlay from "../features/AssessmentPlay/AssessmentPlay";
import NetworkError from "../features/NetworkError/NetworkError";

const AppSwitch = ({ isUserLoggedIn }) => {
  const isUserLoggedOut = !isUserLoggedIn;
  const { roles, permission } = useSelector((state) => state?.security);
  const isAdminOnly = ROLE_TYPES.isOnlyRole(roles, "ADMIN");

  return (
    <Routes>
      <Route path="/login/success/callback" element={<CallbackAPI />} />

      {isUserLoggedOut && <Route path="*" element={<Navigate to="/login" />} />}

      <Route
        path={ROUTE_CONSTANTS.LOGIN}
        element={isUserLoggedOut ? <LoginPage /> : <Navigate to="/" />}
      />

      {(ROLE_TYPES.isHr(roles) ||
        ROLE_TYPES.isCompetencySpoc(roles) ||
        ROLE_TYPES.isCompetencyLead(roles) ||
        (ROLE_TYPES.isUser(roles) && !ROLE_TYPES.isAdmin(roles)) ||
        hasAnyPermission(permission)) && (
        <>
          <Route
            path={ROUTE_CONSTANTS.HOME}
            element={isUserLoggedOut ? <LoginPage /> : <Home />}
          />
          <Route
            path={ROUTE_CONSTANTS.BOOTCAMP_ASSESSMENT_VIEW_EVALUATION_LISTING}
            element={
              isUserLoggedOut ? (
                <Navigate to="/login" />
              ) : (
                <ViewAssessmentEvaluation />
              )
            }
          />
          <Route
            path={ROUTE_CONSTANTS.MODULE_ASSESSMENT_VIEW_EVALUATION_LISTING}
            element={
              isUserLoggedOut ? (
                <Navigate to="/login" />
              ) : (
                <ViewAssessmentEvaluation />
              )
            }
          />
        </>
      )}

      {(ROLE_TYPES.isHr(roles) ||
        ROLE_TYPES.isCompetencySpoc(roles) ||
        ROLE_TYPES.isCompetencyLead(roles) ||
        ROLE_TYPES.isAdmin(roles) ||
        permission?.spoc) && (
        <>
          <Route
            path={ROUTE_CONSTANTS.USERS}
            element={isUserLoggedOut ? <Navigate to="/login" /> : <Users />}
          />
          <Route
            path={ROUTE_CONSTANTS.LOCATIONS_AND_ROOMS}
            element={
              isUserLoggedOut ? <Navigate to="/login" /> : <LocationsAndRooms />
            }
          />
          <Route
            path={ROUTE_CONSTANTS.TOPIC}
            element={isUserLoggedOut ? <Navigate to="/login" /> : <Topic />}
          />
          <Route
            path={ROUTE_CONSTANTS.QUESTION_VIEW}
            element={isUserLoggedOut ? <Navigate to="/login" /> : <Questions />}
          />
          <Route
            path={ROUTE_CONSTANTS.ASSESSMENT_QUESTIONS}
            element={
              isUserLoggedOut ? (
                <Navigate to="/login" />
              ) : (
                <AssessmentQuestions />
              )
            }
          />
          <Route
            path={ROUTE_CONSTANTS.MODULE_CREATE_ASSESSMENT}
            element={
              isUserLoggedOut ? <Navigate to="/login" /> : <CreateAssessment />
            }
          />
          <Route
            path={ROUTE_CONSTANTS.BOOTCAMP_CREATE_ASSESSMENT}
            element={
              isUserLoggedOut ? <Navigate to="/login" /> : <CreateAssessment />
            }
          />
          <Route
            path={ROUTE_CONSTANTS.MODULE_VIEW_ASSESSMENT}
            element={
              isUserLoggedOut ? <Navigate to="/login" /> : <ViewAssessment />
            }
          />
          <Route
            path={ROUTE_CONSTANTS.BOOTCAMP_VIEW_ASSESSMENT}
            element={
              isUserLoggedOut ? <Navigate to="/login" /> : <ViewAssessment />
            }
          />
          <Route
            path={ROUTE_CONSTANTS.BOOTCAMP_ASSESSMENT_LISTING}
            element={
              isUserLoggedOut ? <Navigate to="/login" /> : <AssessmentListing />
            }
          />
          <Route
            path={ROUTE_CONSTANTS.MODULE_ASSESSMENT_LISTING}
            element={
              isUserLoggedOut ? <Navigate to="/login" /> : <AssessmentListing />
            }
          />
          <Route
            path={ROUTE_CONSTANTS.SESSIONS_QUESTION_VIEW}
            element={isUserLoggedOut ? <Navigate to="/login" /> : <Questions />}
          />
          <Route
            path={ROUTE_CONSTANTS.BOOTCAMP_ASSESSMENT_EVALUATION_LISTING}
            element={
              isUserLoggedOut ? <Navigate to="/login" /> : <EvaluationListing />
            }
          />
          <Route
            path={ROUTE_CONSTANTS.MODULE_ASSESSMENT_EVALUATION_LISTING}
            element={
              isUserLoggedOut ? <Navigate to="/login" /> : <EvaluationListing />
            }
          />
          <Route
            path={ROUTE_CONSTANTS.BOOTCAMP_ASSESSMENT_VIEW_EVALUATION_LISTING}
            element={
              isUserLoggedOut ? (
                <Navigate to="/login" />
              ) : (
                <ViewAssessmentEvaluation />
              )
            }
          />
          <Route
            path={ROUTE_CONSTANTS.MODULE_ASSESSMENT_VIEW_EVALUATION_LISTING}
            element={
              isUserLoggedOut ? (
                <Navigate to="/login" />
              ) : (
                <ViewAssessmentEvaluation />
              )
            }
          />
        </>
      )}

      {permission?.spoc && (
        <>
          <Route
            path={ROUTE_CONSTANTS.TOPIC}
            element={isUserLoggedOut ? <Navigate to="/login" /> : <Topic />}
          />
        </>
      )}

      {(ROLE_TYPES.isHr(roles) ||
        ROLE_TYPES.isCompetencySpoc(roles) ||
        ROLE_TYPES.isCompetencyLead(roles) ||
        (ROLE_TYPES.isUser(roles) && !ROLE_TYPES.isAdmin(roles)) ||
        isAdminOnly) && (
        <Route
          path={ROUTE_CONSTANTS.DASHBOARD}
          element={isUserLoggedOut ? <Navigate to="/login" /> : <Dashboard />}
        />
      )}

      <Route
        path={ROUTE_CONSTANTS.BOOTCAMP_MODULE}
        element={
          isUserLoggedOut ? <Navigate to="/login" /> : <BootcampModuleView />
        }
      />
      <Route
        path={ROUTE_CONSTANTS.SESSIONS}
        element={isUserLoggedOut ? <Navigate to="/login" /> : <Sessions />}
      />
      <Route
        path={ROUTE_CONSTANTS.TOPIC_SESSIONS}
        element={isUserLoggedOut ? <Navigate to="/login" /> : <Sessions />}
      />
      <Route
        path={ROUTE_CONSTANTS.TOPIC_SESSIONS}
        element={isUserLoggedOut ? <Navigate to="/" /> : <Sessions />}
      />
      <Route
        path={ROUTE_CONSTANTS.TOPIC_SESSION_VIEW}
        element={isUserLoggedOut ? <Navigate to="/login" /> : <SessionView />}
      />
      <Route
        path={ROUTE_CONSTANTS.SESSION_VIEW}
        element={isUserLoggedOut ? <Navigate to="/login" /> : <SessionView />}
      />
      <Route
        path={ROUTE_CONSTANTS.ATTENDEES_BOOTCAMP}
        element={isUserLoggedOut ? <Navigate to="/login" /> : <Attendees />}
      />
      <Route
        path={ROUTE_CONSTANTS.ATTENDEES_MODULE}
        element={isUserLoggedOut ? <Navigate to="/login" /> : <Attendees />}
      />
      <Route
        path={ROUTE_CONSTANTS.EXERCISES}
        element={isUserLoggedOut ? <Navigate to="/login" /> : <Exercises />}
      />
      <Route
        path={ROUTE_CONSTANTS.ASSESSMENTS}
        element={isUserLoggedOut ? <Navigate to="/login" /> : <Assessments />}
      />
      <Route
        path={ROUTE_CONSTANTS.MODULE_SESSIONS_QUESTION_VIEW}
        element={isUserLoggedOut ? <Navigate to="/login" /> : <Questions />}
      />
      <Route
        path={ROUTE_CONSTANTS.PERFORMANCE}
        element={isUserLoggedOut ? <Navigate to="/login" /> : <Performance />}
      />
      <Route
        path={ROUTE_CONSTANTS.ASSESSMENT_PLAY}
        element={
          isUserLoggedOut ? <Navigate to="/login" /> : <AssessmentPlay />
        }
      />

      {(ROLE_TYPES.isHr(roles) ||
        ROLE_TYPES.isCompetencySpoc(roles) ||
        ROLE_TYPES.isCompetencyLead(roles) ||
        (ROLE_TYPES.isUser(roles) && !ROLE_TYPES.isAdmin(roles))) && (
        <>
          <Route
            path={ROUTE_CONSTANTS.USERS}
            element={
              isUserLoggedOut ? (
                <Navigate to="/login" />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
          <Route
            path={ROUTE_CONSTANTS.LOCATIONS_AND_ROOMS}
            element={
              isUserLoggedOut ? (
                <Navigate to="/login" />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
          <Route
            path={ROUTE_CONSTANTS.TOPIC}
            element={
              isUserLoggedOut ? (
                <Navigate to="/login" />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
          <Route
            path={ROUTE_CONSTANTS.QUESTION_VIEW}
            element={
              isUserLoggedOut ? (
                <Navigate to="/login" />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
          <Route
            path={ROUTE_CONSTANTS.SESSIONS_QUESTION_VIEW}
            element={
              isUserLoggedOut ? (
                <Navigate to="/login" />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
          <Route
            path={ROUTE_CONSTANTS.MODULE_SESSIONS_QUESTION_VIEW}
            element={
              isUserLoggedOut ? (
                <Navigate to="/login" />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
          <Route
            path="*"
            element={
              isUserLoggedOut ? (
                <Navigate to="/login" />
              ) : (
                <Navigate to="/error" />
              )
            }
          />
        </>
      )}

      {isAdminOnly && (
        <Route
          path="*"
          element={
            isUserLoggedOut ? (
              <Navigate to="/login" />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
      )}

      <Route
        path={ROUTE_CONSTANTS.NETWORK_ERROR}
        element={
          isUserLoggedOut ? <NetworkError /> : <Navigate to="/" replace />
        }
      />

      {!isUserLoggedOut && (
        <Route
          path={ROUTE_CONSTANTS.ACCESS_DENIED}
          element={<AccessDenied />}
        />
      )}
    </Routes>
  );
};

export default AppSwitch;
