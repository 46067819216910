import React, { useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Collapse,
  styled,
  IconButton,
} from "@mui/material";
import TablePagination from "../../components/TablePagination/TablePagination";
import IconButtons from "../../components/IconButtons/IconButtons";
import {
  associate_question_default_icon,
  associate_question_disabled_icon,
  delete_active_icon,
  delete_disabled_icon,
  edit_active_icon,
  edit_disabled_icon,
} from "../../constants/icons";

import { SingleWordCount, font_13_icon, getDate } from "../../utils/utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";
import UserTableAvatar from "../UserTableAvatar/UserTableAvatar";
import { HelpTexts } from "../../constants/constants";
const { ASSOCIATED_QUESTION_NOT_EDITABLE } = HelpTexts;

const QuestionTable = ({
  questionList,
  currentPage,
  handlePageChange,
  handleDeleteModalOpen,
  totalPages,
  handleQuestionModalOpen,
  questionEditModal,
  canViewActions,
  isDisabled,
  exerciseCount,
  handleOpenDisassociate,
  moduleIsConcluded,
  viaSession,
  viaTopic,
}) => {
  //////////// handle view more ////////////////////////////////
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const [viewMore, setViewMore] = useState(false);
  const [viewMoreId, setViewMoreId] = useState(null);

  const handleViewMoreClick = (value) => {
    if (viewMoreId === value) {
      setViewMoreId(-1);
      setViewMore(false);
    } else {
      setViewMoreId(value);
      setViewMore(true);
    }
  };
  /////////////////////////////////////////////////////////////
  return (
    <>
      <TableContainer className="table-container">
        <Table stickyHeader aria-label="sticky table">
          <TableHead className="table-head-container">
            <TableRow className="table-head-row">
              <TableCell
                align="center"
                sx={{ width: "4rem", maxWidth: "4rem", minWidth: "4rem" }}
                className="padding-left-1"
              >
                <Typography variant="tableHead">S.No.</Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "35rem" }}
                className="padding-left-1"
              >
                <Typography variant="tableHead">Questions</Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ width: "13rem", maxWidth: "13rem", minWidth: "13rem" }}
                className="padding-left-1"
              >
                <Typography variant="tableHead">Added By</Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ width: "6rem", maxWidth: "6rem", minWidth: "6rem" }}
                className="padding-left-1"
              >
                <Typography variant="tableHead">Added on</Typography>
              </TableCell>
              {(viaTopic || canViewActions) && (
                <TableCell
                  align="center"
                  sx={{ width: "6rem", maxWidth: "6rem", minWidth: "6rem" }}
                  className="padding-left-1"
                >
                  <Typography variant="tableHead">Actions</Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {questionList?.map((row, index) => {
              const wordCount = row?.question?.length;
              const countLines = row?.question?.trim()?.split(/\r|\r\n|\n/);
              const lineLength = countLines?.length;

              const singleWord = SingleWordCount(row?.question);
              const createdDate = dayjs(row?.dateCreated)?.isValid()
                ? getDate(row?.dateCreated)
                : "-";
              const hasTopicId = row?.topic?.id;
              return (
                !row?.deleted && (
                  <TableRow className="table-body-row" hover key={index}>
                    <TableCell align="center" className="padding-left-1">
                      <Typography variant="font_12">
                        {index + 1 + (currentPage - 1) * 20}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className="padding-left-1">
                      <Collapse
                        in={viewMore && viewMoreId === row?.id}
                        collapsedSize={
                          wordCount > 100 || lineLength > 2
                            ? singleWord?.length < 3 && wordCount > 100
                              ? 20
                              : 60
                            : lineLength * 20
                        }
                        sx={{
                          height:
                            singleWord?.length < 3 && wordCount > 100
                              ? "max-content"
                              : 0,
                        }}
                      >
                        <Typography
                          sx={{ whiteSpace: "pre-wrap" }}
                          variant="font_12"
                        >
                          {row?.question}
                        </Typography>
                      </Collapse>
                      {wordCount > 200 || lineLength > 3 ? (
                        <Box onClick={() => handleViewMoreClick(row?.id)}>
                          <Typography
                            variant="font_9_bold"
                            color="secondary"
                            cursor="pointer"
                            sx={{ fontWeight: 600 }}
                          >
                            {viewMore && viewMoreId === row?.id
                              ? "VIEW LESS"
                              : "VIEW MORE"}
                          </Typography>
                          <ExpandMore
                            expand={viewMoreId === row?.id}
                            sx={{ padding: 0 }}
                          >
                            <ExpandMoreIcon color="secondary" />
                          </ExpandMore>
                        </Box>
                      ) : null}
                    </TableCell>
                    <TableCell align="left" className="padding-left-1">
                      <UserTableAvatar
                        imgAlt={row?.createdBy?.fullName}
                        imgSrc={row?.createdBy?.profilePictureUrl}
                        href={row?.createdBy?.employeeCode}
                        label={row?.createdBy?.fullName}
                        subLabel={row?.createdBy?.email}
                        deleted={row?.createdBy?.deleted}
                      />
                    </TableCell>
                    <TableCell align="left" className="padding-left-1">
                      <Typography variant="font_12">{createdDate}</Typography>
                    </TableCell>
                    {canViewActions && (
                      <TableCell align="center" className="padding-left-1">
                        {((viaTopic && row?.permission?.canViewEditQuestion) ||
                          viaSession) && (
                          <IconButtons
                            classList="margin-right-1"
                            width={font_13_icon}
                            height={font_13_icon}
                            disabledImage={edit_disabled_icon}
                            image={edit_active_icon}
                            handleClick={() =>
                              questionEditModal(row?.id, row?.question)
                            }
                            tooltip="Edit Question"
                            disabled={
                              (viaTopic && !row?.permission?.canEditQuestion) ||
                              (viaSession && hasTopicId) ||
                              isDisabled
                            }
                            disabledTooltip={
                              viaTopic && !row?.permission?.canEditQuestion
                                ? `This Question can be edited by SPOCs of ${row?.topic?.competency?.name} competency`
                                : viaSession && hasTopicId
                                ? ASSOCIATED_QUESTION_NOT_EDITABLE
                                : isDisabled
                                ? "Module is completed"
                                : null
                            }
                          />
                        )}
                        {viaSession && hasTopicId && (
                          <IconButtons
                            width={font_13_icon}
                            height={font_13_icon}
                            image={associate_question_default_icon}
                            handleClick={() => handleOpenDisassociate(row?.id)}
                            tooltip="Disassociate Question"
                            disabled={isDisabled}
                            disabledImage={associate_question_disabled_icon}
                            disabledTooltip={
                              isDisabled ? "Module is completed" : null
                            }
                          />
                        )}
                        {((viaTopic &&
                          row?.permission?.canViewDeleteQuestion) ||
                          (viaSession && !hasTopicId)) && (
                          <IconButtons
                            width={font_13_icon}
                            height={font_13_icon}
                            disabledImage={delete_disabled_icon}
                            image={delete_active_icon}
                            handleClick={() => handleDeleteModalOpen(row?.id)}
                            tooltip="Delete Question"
                            disabled={
                              (viaTopic &&
                                !row?.permission?.canDeleteQuestion) ||
                              (viaSession && isDisabled)
                            }
                            disabledTooltip={
                              viaTopic && !row?.permission?.canDeleteQuestion
                                ? `This Question can be deleted by SPOCs of ${row?.topic?.competency?.name} competency`
                                : isDisabled
                                ? "Module is completed"
                                : null
                            }
                          />
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                )
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container className="table-pagination-container">
        <Typography variant="font_16">
          Total {exerciseCount} {exerciseCount > 1 ? "Records" : "Record"}
        </Typography>
        <TablePagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
        />
      </Grid>
    </>
  );
};

export default QuestionTable;
