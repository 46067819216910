import React, { useEffect, useState } from "react";
import "./assessmentOptionModal.scss";
import { Box, Checkbox, TableCell, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addOption,
  setAssessmentQuestionsData,
  updateOption,
} from "../../store/assessmentQuestions";
import { useParams } from "react-router-dom";
import IconButtons from "../IconButtons/IconButtons";
import { font_13_icon, hasDetailsChanged } from "../../utils/utils";
import {
  circle_save_change_icon,
  cross_red_icon,
  disabled_circle_save_change_icon,
} from "../../constants/icons";

const AssessmentOptionModal = ({
  questionId,
  setOptionModalOpen,
  optionId,
  setOptionId,
  locationNameLengthError,
  option,
  questionOptions,
  setAddNewOption,
  addNewOption,
}) => {
  const updateQuestionOptions = (questionOptions, changedOptionData) => {
    return questionOptions?.results?.map((option) => {
      if (option?.id === changedOptionData?.optionId) {
        return {
          ...option,
          correct: changedOptionData?.correct,
        };
      }
      return option;
    });
  };

  const dispatch = useDispatch();
  const params = useParams();
  const initialInputDetails = {
    optionId: "",
    questionId: "",
    optionText: "",
    correct: false,
  };
  const [optionDetails, setOptionDetails] = useState(initialInputDetails);

  const [initialOptionDetails, setInitialOptionDetails] =
    useState(optionDetails);

  useEffect(() => {
    if (optionId) {
      setOptionDetails((prev) => ({
        ...prev,
        optionId: option?.id,
        optionText: option?.optionText,
        correct: option?.correct,
      }));
      setInitialOptionDetails((prev) => ({
        ...prev,
        optionId: option?.id,
        optionText: option?.optionText,
        correct: option?.correct,
      }));
    }
  }, [optionId]);

  const initialOptionErrorState = {
    optionText: false,
  };
  const [optionError, setOptionError] = useState(false);
  const [optionErrors, setOptionErrors] = useState(initialOptionErrorState);

  const validateOptionFields = (sessionDetails) => {
    const { optionText } = sessionDetails;
    const newErrors = {
      name: optionText?.trim() ? "" : "Option is required",
    };
    setOptionErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const handleOptionModalClose = () => {
    setOptionModalOpen(false);
    setOptionDetails(initialInputDetails);
    setOptionError(false);
    setOptionErrors(initialOptionErrorState);
    setOptionId(null);
    if (addNewOption) {
      setAddNewOption(false);
    }
  };
  const handleOptionSubmit = async () => {
    const optionData = {
      optionId,
      ...(optionId ? {} : { questionId }),
      optionText: optionDetails?.optionText.trim(),
      correct: optionDetails?.correct,
    };

    let apiValue;
    if (validateOptionFields(optionData)) {
      if (!optionId) {
        apiValue = await dispatch(addOption(optionData));
      } else {
        const updatedOptions = updateQuestionOptions(
          questionOptions,
          optionData
        );
        const isCorrectOptionAvailable = updatedOptions?.some(
          (option) => option?.correct === true
        );
        apiValue = await dispatch(
          updateOption({ optionData, isCorrectOptionAvailable })
        );
      }
      if (apiValue?.error?.message === "Rejected") {
        setOptionError(true);
      } else {
        handleOptionModalClose();
        await dispatch(setAssessmentQuestionsData({ topicId: params.topicId }));
      }
    }
  };
  const handleOptionDetailsChange = (name, value) => {
    setOptionErrors(initialOptionErrorState);
    setOptionDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  const optionDetailsChanged = hasDetailsChanged(
    initialOptionDetails,
    optionDetails
  );

  return (
    <>
      <TableCell align="left" className="padding-left-1">
        <TextField
          multiline
          minRows={2}
          autoFocus
          id="option"
          shrink={false}
          size="small"
          placeholder="Option*"
          type="text"
          fullWidth
          margin="dense"
          variant="outlined"
          value={optionDetails?.optionText}
          onChange={(e) =>
            handleOptionDetailsChange("optionText", e.target.value)
          }
          color="secondary"
          error={!!optionErrors.name || optionError || locationNameLengthError}
          helperText={optionErrors.name}
          className="create-option-input"
          required={true}
        />
      </TableCell>
      <TableCell align="center">
        <Checkbox
          color="secondary"
          size="small"
          checked={optionDetails?.correct}
          onChange={(e) =>
            handleOptionDetailsChange("correct", e.target.checked)
          }
        />
      </TableCell>
      <TableCell align="center">
        <Box>
          <IconButtons
            height={font_13_icon}
            width={font_13_icon}
            image={circle_save_change_icon}
            disabledImage={disabled_circle_save_change_icon}
            disabled={!optionDetailsChanged}
            classList="margin-right-1"
            handleClick={handleOptionSubmit}
          />
          <IconButtons
            height={font_13_icon}
            width={font_13_icon}
            image={cross_red_icon}
            handleClick={handleOptionModalClose}
            classList="padding-right-1"
          />
        </Box>
      </TableCell>
    </>
  );
};

export default AssessmentOptionModal;
