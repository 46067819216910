import React, { forwardRef } from "react";

import { Alert, AlertTitle, Snackbar, Typography, Box } from "@mui/material";

import styles from "./style.module.scss";
import {
  toast_success_icon,
  toast_info_icon,
  toast_warning_icon,
  toast_error_icon,
  close_icon,
} from "../../constants/icons";
import { TOAST_TYPE } from "../../constants/constants";

const SnackbarAlert = forwardRef(function SnackbarAlert(props, ref) {
  return <Alert elevation={6} ref={ref} {...props} />;
});

const CustomToaster = ({ title, message, type, time }) => {
  const [open, setOpen] = React.useState(true);

  let icon;
  let color;
  let AlertBorder;
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  switch (type) {
    case "error":
      icon = toast_error_icon;
      color = "#FDEEEC";
      AlertBorder = "#E7D2CA";
      break;

    case "success":
      icon = toast_success_icon;
      color = "#F1F9F4";
      AlertBorder = "#D0E8D4";
      break;

    case "info":
      icon = toast_info_icon;
      color = "#E7EEFA";
      AlertBorder = "#B6CCED";
      break;

    case "warning":
      icon = toast_warning_icon;
      color = "#FEF7E8";
      AlertBorder = "#F3E0BC";
      break;

    default:
      icon = toast_success_icon;
      color = "#F1F9F4";
      break;
  }

  const CustomCloseIcon = () => {
    return (
      <img
        src={close_icon}
        onClick={handleClose}
        className={styles.closeButton}
        alt="custom-close-icon"
      />
    );
  };
  if (Object.values(TOAST_TYPE)?.includes(type)) {
    return (
      <Box className={styles.toastContainer}>
        <Box className={styles.customToast}>
          <Snackbar
            open={open}
            autoHideDuration={time}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <SnackbarAlert
              onClose={handleClose}
              severity={type}
              variant="outlined"
              sx={{
                bgcolor: color,
                borderColor: AlertBorder,
                minWidth: "200px",
                padding: "0.438rem 3.313rem 0.438rem 1.25rem",
                borderRadius: "0.625rem",
              }}
              icon={<img src={icon} fontSize="inherit" />}
              action={
                <CustomCloseIcon src={close_icon} onClick={handleClose} />
              }
            >
              <AlertTitle>
                {" "}
                <Typography
                  fontWeight="700"
                  color="#2E1C41"
                  fontSize="14px"
                  lineHeight="21px"
                >
                  {title}
                </Typography>
              </AlertTitle>
              <Typography
                fontWeight="400px"
                fontSize="12px"
                lineHeight="18px"
                color="#2E1C41"
              >
                {message}
              </Typography>
            </SnackbarAlert>
          </Snackbar>
        </Box>
      </Box>
    );
  }
};

export default CustomToaster;
