import React, { useEffect, useState } from "react";
import "./bootcampModuleView.scss";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import BreadCrumb from "../../components/Breadcrumb/BreadCrumb";
import BootcampModuleViewActionItems from "./BootcampModuleViewActionItems";
import BMVInfo from "./BMVInfo";
import BMVTable from "./BMVTable";
import {
  addBatchCreate,
  deleteBootcampModuleBatch,
  deleteBootcampModuleView,
  editBootcampModule,
  editBootcampModuleBatch,
  getBatchAttendees,
  getBootcamp,
  getBootcampModuleBatch,
  getBootcampModuleView,
  removeAttendees,
  getRoomList,
  clearModuleBatch,
  clearModuleData,
} from "../../store/bootcampModuleView";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
import DeleteModal from "./../../components/DeleteModal/DeleteModal";
import BatchEdit from "./BatchEdit";
import Loader from "../../components/Loader/Loader";
import dayjs from "dayjs";
import CreateBootcampModule from "../Home/CreateBootcampModule";
import {
  ROLE_TYPES,
  addWorkingDays,
  checkSpecialChar,
  countWorkingDays,
  encode,
  hasAnyPermission,
  toEndDate,
  toStartDate,
  toWorkingDay,
} from "../../utils/utils";
import CreateBatchModal from "./CreateBatchModal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  getBootcampById,
  getCreateEditModuleOptions,
} from "../../store/bootcamp";
import Constants from "../../constants/constants";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);
const tz = "Asia/Kolkata";

const initialModuleData = {
  moduleId: "",
  name: "",
  startDate: null,
  description: "",
  mode: "",
  bootcampIds: [],
  batchSize: 0,
};

const initialBatchDetails = {
  batchId: "",
  name: "",
  mode: null,
  meetingUrl: "",
  roomId: "",
};

const initialBatchError = {
  batchId: null,
  name: null,
  mode: null,
  meetingUrl: null,
  roomId: null,
};

const { COMPLETED, ACTIVE } = Constants;

const BootcampModuleView = () => {
  const { bootcampId, moduleId } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const moduleView = useSelector((state) => {
    return state?.bootcampModuleView;
  });
  const loading = moduleView?.loading;
  const bootcampLoading = moduleView?.bootcampLoading;
  const roomLoading = moduleView?.roomLoading;
  const moduleBatchLoading = moduleView?.moduleBatchLoading;
  const [isLoading, setIsLoading] = useState(true);

  const getBootcampData = moduleView?.moduleData?.data?.bootcamps?.results;
  const moduleData = moduleView?.moduleData?.data;
  const moduleBatch = moduleView?.moduleBatch?.data?.results;
  const isSpoc = moduleData?.permission?.spoc;
  const isModuleCompleted = moduleData?.status === COMPLETED;
  const isModuleActive = moduleData?.status === ACTIVE;
  const isMentor = moduleData?.permission?.mentor;
  const moduleBootcamps = moduleData?.bootcamps?.results;
  const [bootcampName, setBootcampName] = useState(null);
  const [isBootcampCompleted, setIsBootcampCompleted] = useState(null);
  const attendeesList = moduleView?.attendees?.data;
  const attendeesListLoading = moduleView?.batchAttendeesLoading;
  const addBatchCreateLoading = moduleView?.addBatchCreateLoading;
  const editBootcampModuleBatchLoading =
    moduleView?.editBootcampModuleBatchLoading;

  const holidaysList = useSelector(
    (state) => state?.utility?.constants?.holidays
  );
  const modeOptions = useSelector(
    (state) => state?.utility?.constants?.bootcampModes
  );

  const getBootcampInfo = useSelector((state) => {
    return state?.bootcamp;
  });

  const [bootcampsForCreateEditModule, setBootcampsForCreateEditModule] =
    useState([]);

  // /////////// Permission ////////////////////////////

  const security = useSelector((state) => state?.security);
  const roles = security?.roles;
  const isHr = ROLE_TYPES?.isHr(roles);

  const permission = moduleView?.moduleData?.data?.permission;
  const isSessionListAllowed = isHr || hasAnyPermission(permission);
  const isAttendeeListAllowed = isHr || isSpoc || isMentor;

  // /////////// Edit Module ////////////////////////////
  const [showEditModule, setShowEditModule] = useState(false);
  const [moduleApiError, setModuleApiError] = useState(false);
  const [bootcampModuleDetails, setBootcampModuleDetails] =
    useState(initialModuleData);
  const [disabledBootcampsId, setDisabledBootcampsId] = useState([]);

  const handleOpenEditModule = () => {
    if (isModuleActive) {
      setDisabledBootcampsId(
        moduleData?.bootcamps?.results
          ?.filter((item) => item.status === ACTIVE)
          ?.map((item) => item?.id)
      );
    }
    setBootcampModuleDetails({
      moduleId: moduleData?.id,
      name: moduleData?.name,
      startDate: dayjs(moduleData?.startDate),
      endDate: dayjs(moduleData?.endDate),
      description: moduleData?.description,
      mode: moduleData?.mode,
      bootcampIds: moduleData?.bootcamps?.results,
    });
    setShowEditModule(true);
  };
  const handleCloseEditModule = () => {
    setModuleApiError(false);
    setBootcampModuleDetails(initialModuleData);
    setShowEditModule(false);
    setDisabledBootcampsId([]);
    setmoduleNameLengthError(false);
  };

  const [moduleSpeChar, setModuleSpeChar] = useState(false);
  const [moduleNameLengthError, setmoduleNameLengthError] = useState(false);
  const [openPropogationWarning, setOpenPropogationWarning] = useState(false);
  const [propogationWarningText, setpropogationWarningText] = useState([]);

  const handlePropogationWarningOpen = (text) => {
    setOpenPropogationWarning(true);
    setpropogationWarningText(text);
  };

  const handlePropogationWarningClose = () => {
    setOpenPropogationWarning(false);
    setpropogationWarningText([]);
  };

  const handlePropogationWarningSubmit = () => {
    handleModuleSubmit();
    handlePropogationWarningClose();
  };

  const handleEditModuleChange = (name, value) => {
    const moduleDurationInDays = countWorkingDays(
      moduleData?.startDate,
      moduleData?.endDate,
      holidaysList
    );
    const newModuleDurationInDays = countWorkingDays(
      bootcampModuleDetails?.startDate,
      bootcampModuleDetails?.endDate,
      holidaysList
    );
    setModuleApiError(false);
    if (name === "startDate" && dayjs(value).isValid()) {
      setBootcampsForCreateEditModule(
        getBootcampInfo?.getCreateEditModuleOptions?.data?.bootcamps?.results.filter(
          (it) =>
            !dayjs(it?.startDate).tz(tz).isAfter(dayjs(value).tz(tz), "date")
        )
      );
      let endDate = dayjs(bootcampModuleDetails?.endDate);

      if (endDate.isValid() && !endDate.isAfter(dayjs(value), "day")) {
        endDate = addWorkingDays(
          dayjs(value),
          newModuleDurationInDays,
          holidaysList
        );
      } else if (endDate.isValid()) {
        endDate = addWorkingDays(
          dayjs(value),
          newModuleDurationInDays,
          holidaysList
        );
      } else {
        endDate = toWorkingDay(dayjs(value).add(1, "day"), holidaysList);
      }
      setBootcampModuleDetails((prev) => {
        return {
          ...prev,
          bootcampIds: bootcampModuleDetails?.bootcampIds
            ? bootcampModuleDetails?.bootcampIds.filter(
                (it) =>
                  !dayjs(it?.startDate)
                    .tz(tz)
                    .isAfter(dayjs(value).tz(tz), "date")
              )
            : null,
          endDate: endDate.isValid() ? endDate : null,
          [name]: value,
        };
      });
    } else if (name === "bootcampIds") {
      let bootcampIds = value;
      if (!bootcampIds?.length) {
        const toRetain = bootcampModuleDetails?.bootcampIds?.filter(
          (item) =>
            item?.status === ACTIVE && disabledBootcampsId?.includes(item?.id)
        );
        bootcampIds = [...toRetain];
      }
      setBootcampModuleDetails((prev) => {
        return {
          ...prev,
          [name]: bootcampIds,
        };
      });
    } else if (name === "name") {
      if (value?.trim().length <= 50) {
        setModuleSpeChar(checkSpecialChar(value));
        setmoduleNameLengthError(false);
        setBootcampModuleDetails((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      } else {
        setmoduleNameLengthError(true);
      }
    } else {
      setBootcampModuleDetails((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  const handleModuleSubmit = async () => {
    setIsLoading(true);
    let apiValue;
    const data = {
      ...bootcampModuleDetails,
      name: bootcampModuleDetails?.name?.trim(),
      bootcampIds: bootcampModuleDetails?.bootcampIds?.map((e) => e?.id),
      startDate: toStartDate(bootcampModuleDetails?.startDate)?.format(),
      endDate: toEndDate(dayjs(bootcampModuleDetails?.endDate).format()),
      bootcampId: bootcampId,
    };

    apiValue = await dispatch(editBootcampModule(data));
    if (apiValue?.error?.message === "Rejected") {
      setModuleApiError(true);
    } else {
      handleCloseEditModule();
      await dispatch(getBootcampModuleView({ bootcampId, moduleId }));
      await dispatch(
        getBootcampModuleBatch({ moduleId: moduleId, bootcampId: bootcampId })
      );
      setModuleApiError(false);
    }
    setIsLoading(false);
  };

  ////////////////////////////////////////////////////////
  //////////// Edit Batch ///////////////////////////////
  const [batchId, setBatchId] = useState(null);
  const [isBatchEdit, setIsBatchEdit] = useState(false);

  const totalModuleBatch = moduleView?.moduleBatch?.data?.totalResults;
  const initialCreateBatchDetails = {
    moduleId: "",
    bootcampIds: [],
    name: "",
    attendeeIds: [],
    mode: "",
    meetingUrl: "",
    room: "",
  };
  const [createBatchDetails, setCreateBatchDetails] = useState(
    initialCreateBatchDetails
  );
  const [checkedRows, setCheckedRows] = useState([]);
  const [createBatchModalOpen, setCreateBatchModalOpen] = useState(false);
  const nextBatchName = `Batch ${totalModuleBatch + 1}`;
  const [oldCreateBatchName, setOldCreateBatchName] = useState(nextBatchName);

  const handleBatchOpen = (value) => {
    setIsBatchEdit(true);
    setOldCreateBatchName(value?.name);
    setCreateBatchApiError(false);
    setCreateBatchDetails({
      batchId: value?.id,
      name: value?.name,
      mode: modeOptions.filter((e) => e?.key === value?.mode)[0],
      meetingUrl: value?.mode === "ONLINE" ? value?.meetingUrl : null,
      room: value?.mode === "OFFLINE" ? value?.room : null,
      bootcampIds: [],
    });
    setPreviousDetails((prevDetails) => ({
      ...prevDetails,
      room: value?.mode === "OFFLINE" ? value?.room : null,
      meetingUrl: value?.mode === "ONLINE" ? value?.meetingUrl : null,
    }));
    setCreateBatchModalOpen(true);
  };

  ///////////////////////// validate batch ////////////////////////////
  useEffect(() => {
    if (showEditModule) {
      if (
        bootcampModuleDetails?.startDate &&
        dayjs(bootcampModuleDetails?.startDate).isValid()
      )
        dispatch(
          getCreateEditModuleOptions({
            startDate: encode(dayjs(bootcampModuleDetails?.startDate).format()),
          })
        );

      if (
        !getBootcampInfo?.getBootcampDataById?.data ||
        getBootcampInfo?.getBootcampDataById?.data?.id !== Number(bootcampId)
      ) {
        dispatch(getBootcampById({ bootcampId }));
      }
    }
  }, [showEditModule, bootcampModuleDetails?.startDate]);
  //////////////////////////////////////////////////////////////
  //////////// Delete Modal /////////////////////////////////////
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteFor, setDeleteFor] = useState("");

  const handleDeleteModalOpen = (deleteFor, batchId) => {
    setOpenDeleteModal(true);
    setBatchId(batchId);
    setDeleteFor(deleteFor);
  };
  const handleDeleteModalClose = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = async () => {
    if (deleteFor === "module") {
      await dispatch(deleteBootcampModuleView({ moduleId: moduleId }));
      await navigate("/");
    } else if (deleteFor === "batch") {
      await dispatch(deleteBootcampModuleBatch({ batchId: batchId }));
      await dispatch(
        getBootcampModuleBatch({ moduleId: moduleId, bootcampId: bootcampId })
      );
    }
    handleDeleteModalClose();
  };
  /////////////////////////////////////////////////////
  // //// handle create batch /////////////////////////
  const [editName, setEditName] = useState(false);
  const handleEditNameToggle = () => {
    setEditName(!editName);
  };
  const handleEditNameCancel = () => {
    if (editName === true) {
      setCreateBatchDetails((prev) => {
        return {
          ...prev,
          name: oldCreateBatchName,
        };
      });
    }
    setCreateBatchErrors({ ...createBatchErrors, name: "" });
    setEditName(!editName);
  };
  const handleCreateBatchNameAccept = () => {
    setCreateBatchErrors({ ...createBatchErrors, name: "" });
    setCreateBatchDetails((prev) => {
      return {
        ...prev,
        name: createBatchDetails?.name?.trim(),
      };
    });
    setOldCreateBatchName(createBatchDetails?.name?.trim());
    setEditName(!editName);
  };

  const handleCreateBatchOpen = async () => {
    setIsBatchEdit(false);
    setCreateBatchDetails((prev) => {
      return {
        ...prev,
        moduleId: moduleId,
        name: nextBatchName,
        mode: modeOptions.filter((e) => e?.key === "OFFLINE")[0],
        meetingUrl: "",
        room: "",
      };
    });
    setCreateBatchModalOpen(true);
  };
  const handleCreateBatchClose = async () => {
    setCreateBatchModalOpen(false);
    setCreateBatchDetails(initialCreateBatchDetails);
    setPreviousDetails(initialCreateBatchDetails);
    await dispatch(removeAttendees());
    setCreateBatchApiError(false);
    setCreateBatchErrors(initialCreateBatchError);
    setCheckedRows([]);
    setEditName(false);
    setIsBatchEdit(false);
  };

  const handleCreateBatchDetailsChange = (name, value) => {
    setCreateBatchApiError(false);
    setCreateBatchErrors((prev) => {
      return {
        ...prev,
        [name]: false,
      };
    });
    if (
      name === "bootcampIds" &&
      value?.length < createBatchDetails?.bootcampIds?.length
    ) {
      const removedBootcampIds = createBatchDetails?.bootcampIds?.filter(
        (id) => !value?.includes(id)
      );
      setCheckedRows((prev) =>
        prev?.filter((it) => !removedBootcampIds.includes(it?.bootcamp?.id))
      );
    }
    if (name === "name") {
      if (value?.trim().length <= 50) {
        setCreateBatchDetails((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      } else {
        setCreateBatchErrors((prev) => {
          return {
            ...prev,
            name: "Maximum 50 characters allowed",
          };
        });
      }
    } else if (name === "mode" && isBatchEdit) {
      setCreateBatchDetails((prev) => {
        return {
          ...prev,
          [name]: value,
          room: value?.key === "ONLINE" ? null : prev?.room,
          meetingUrl: value?.key === "OFFLINE" ? null : prev?.meetingurl,
        };
      });
    } else {
      setCreateBatchDetails((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    }
  };

  const handleCreateBatchDetailsBlur = (name, value) => {
    if (createBatchModalOpen) {
      if (createBatchDetails?.bootcampIds.length) {
        dispatch(
          getBatchAttendees({
            moduleId: moduleId,
            bootcampIds: createBatchDetails?.bootcampIds?.map((e) => e?.id),
          })
        );
      } else {
        dispatch(removeAttendees());
        setCheckedRows([]);
      }
    }
  };

  /////////////////////////////////////////////////////
  ////////////// validate create batch ///////////////
  const initialCreateBatchError = {
    name: null,
    attendeeIds: null,
    mode: null,
    room: null,
    meetingUrl: null,
  };
  const [createBatchApiError, setCreateBatchApiError] = useState(false);
  const [createBatchErrors, setCreateBatchErrors] = useState(
    initialCreateBatchError
  );
  const validateCreateBatchFields = (createBatch, checkedRows) => {
    const { name, mode, meetingUrl, room } = createBatch;
    const newErrors = {
      name: editName
        ? "Batch name is not saved"
        : name
        ? ""
        : "Name is required",
      mode: mode ? "" : "Mode is required",
      meetingUrl:
        mode?.key === "ONLINE" ? (meetingUrl ? "" : "URL is required") : "",
      room: mode?.key === "OFFLINE" ? (room ? "" : "Room is required") : "",
    };
    setCreateBatchErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };
  ////////////////////////////////////////////////////////

  const handleCreateBatchSubmit = async () => {
    let apiValue;
    if (validateCreateBatchFields(createBatchDetails, checkedRows)) {
      const data = {
        name: editName ? oldCreateBatchName : createBatchDetails?.name,
        mode: createBatchDetails?.mode?.key,
        ...(createBatchDetails?.mode?.key === "ONLINE" && {
          meetingUrl: createBatchDetails?.meetingUrl,
        }),

        ...(createBatchDetails?.mode?.key === "OFFLINE" && {
          roomId: createBatchDetails?.room?.id,
        }),
        attendeeIds: checkedRows?.map((it) => it?.id),
      };
      if (!isBatchEdit) {
        apiValue = await dispatch(
          addBatchCreate({
            ...data,
            moduleId: Number(createBatchDetails?.moduleId),
          })
        );
      } else {
        apiValue = await dispatch(
          editBootcampModuleBatch({
            ...data,
            batchId: createBatchDetails?.batchId,
          })
        );
      }

      if (apiValue?.error?.message === "Rejected") {
        setCreateBatchApiError(true);
      } else {
        handleCreateBatchClose();
        await dispatch(
          getBootcampModuleBatch({ moduleId: moduleId, bootcampId: bootcampId })
        );
      }
    }
  };
  /////////////////////////////////////////////////////

  useEffect(() => {
    const matchedBootcamp = moduleBootcamps?.find(
      (item) => item.id === +bootcampId
    );
    if (matchedBootcamp) {
      setBootcampName(matchedBootcamp?.name);
      setIsBootcampCompleted(matchedBootcamp?.status === COMPLETED);
    }
  }, [moduleBootcamps]);

  const breadcrumbs = [
    { label: "Home", url: "/" },
    {
      label: bootcampName ? bootcampName : "Bootcamps",
      url: "/",
      isBootcamp: true,
      bootcampId: bootcampId,
    },
    { label: moduleData?.name },
  ];

  const [allBootcampAttendee, setAllBootcampAttendee] = useState(false);
  useEffect(() => {
    const checkBootcampAttendee = moduleBootcamps?.some(
      (e) =>
        e?.attendees?.totalResults !== null && e?.attendees?.totalResults !== 0
    );
    setAllBootcampAttendee(checkBootcampAttendee);
  }, [moduleBatch]);

  useEffect(() => {
    const fetchData = async () => {
      let bootcampApiValue, moduleApiValue;
      setIsLoading(true);
      if (isNaN(+bootcampId)) {
        navigate("/error", { replace: true });
        return;
      }
      if (!isNaN(+moduleId) && !isNaN(+bootcampId)) {
        moduleApiValue = await dispatch(
          getBootcampModuleView({ bootcampId, moduleId })
        );
      } else if (isNaN(+moduleId)) {
        navigate("/error", { replace: true });
        return;
      }
      if (!isNaN(+moduleId) && !isNaN(+bootcampId)) {
        dispatch(
          getBootcampModuleBatch({ moduleId: moduleId, bootcampId: bootcampId })
        );
      }
      setIsLoading(false);
    };

    if (security?.loggedInUser?.id) {
      fetchData();
    }

    return () => {
      dispatch(clearModuleBatch());
      dispatch(clearModuleData());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isSpoc && createBatchModalOpen && !moduleView?.rooms?.data?.results)
      dispatch(getRoomList());
  }, [createBatchModalOpen]);

  ///////////////////// Handling mode previous value ////////////////////////////
  const [previousDetails, setPreviousDetails] = useState(
    initialCreateBatchDetails
  );

  useEffect(() => {
    if (isBatchEdit) {
      if (createBatchDetails?.mode?.key === "ONLINE") {
        setCreateBatchDetails({
          ...createBatchDetails,
          meetingUrl: previousDetails?.meetingUrl,
          room: null,
        });
      }
      if (createBatchDetails?.mode?.key === "OFFLINE") {
        setCreateBatchDetails({
          ...createBatchDetails,
          room: previousDetails?.room,
          meetingUrl: null,
        });
      }
    }
  }, [createBatchDetails?.mode?.key]);

  useEffect(() => {
    setBootcampsForCreateEditModule(
      getBootcampInfo?.getCreateEditModuleOptions?.data?.bootcamps?.results
    );
  }, [getBootcampInfo?.getCreateEditModuleOptions?.data?.bootcamps?.results]);

  //-----------------Handle List Assessment----------------------------
  const handleListAssessments = () => {
    navigate(`/bootcamp/${bootcampId}/module/${moduleId}/assessments`);
  };

  if (
    (loading && isLoading) ||
    isLoading ||
    moduleBatchLoading ||
    getBootcampInfo?.loading
  )
    return <Loader />;

  return (
    <>
      {!loading && !moduleData ? (
        <Grid container className="module-no-data-container">
          <NoDataAvailable
            imgHeight={4}
            imgWidth={4.5}
            message="Module Data not Available"
          />
        </Grid>
      ) : (
        <>
          <BreadCrumb breadcrumbs={breadcrumbs} />

          <Grid
            item
            className="header-container bmv-header margin-bottom "
            md={12}
          >
            <Box className="bmv-header">
              <Typography variant="font_21_bold" className="margin-right-2">
                {moduleData?.name}
              </Typography>
              <Chip
                label={moduleData?.status}
                variant={`home-${moduleData?.status}`}
                className="margin-right-2 module-chip-status"
              />
            </Box>
            <Box className="header-right-icons">
              <BootcampModuleViewActionItems
                handleDeleteModalOpen={handleDeleteModalOpen}
                handleOpenEditModule={handleOpenEditModule}
                enableEdit={isSpoc}
                isModuleCompleted={isModuleCompleted}
                enableDelete={isSpoc}
                isModuleActive={isModuleActive}
                allBootcampAttendee={allBootcampAttendee}
                handleCreateBatchOpen={handleCreateBatchOpen}
                isBootcampCompleted={isBootcampCompleted}
                handleListAssessments={handleListAssessments}
                canListAssessmentQuestions={isSpoc || isHr}
              />
            </Box>
          </Grid>

          <Grid item md={12} className="body-container">
            <Paper
              elevation={0}
              className="flex-container-column bmv-paper-container"
            >
              <BMVInfo
                infoData={moduleData}
                isSessionListAllowed={isSessionListAllowed}
                isAttendeeListAllowed={isAttendeeListAllowed}
              />

              <Accordion
                className="accordion"
                sx={{
                  "& .MuiAccordion-root.Mui-expanded:last-of-type": {
                    marginBottom: "1rem",
                  },
                }}
                defaultExpanded
              >
                <AccordionSummary
                  className="accordion-summary"
                  sx={{ height: "3.938rem !important" }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography variant="font_15_bold_600">Batches</Typography>
                </AccordionSummary>
                <AccordionDetails className="accordion-details batches-ov">
                  <BMVTable
                    moduleBatch={moduleBatch}
                    handleDeleteModalOpen={handleDeleteModalOpen}
                    handleBatchOpen={handleBatchOpen}
                    enableActions={isSpoc}
                    isModuleCompleted={isModuleCompleted}
                    isHr={isHr}
                    isBootcampCompleted={isBootcampCompleted}
                  />
                </AccordionDetails>
              </Accordion>
            </Paper>
          </Grid>
        </>
      )}

      <DeleteModal
        open={openDeleteModal}
        handleClose={handleDeleteModalClose}
        handleDelete={handleDelete}
      />
      <CreateBootcampModule
        isEdit={true}
        open={showEditModule}
        onClose={handleCloseEditModule}
        bootcampModuleDetails={bootcampModuleDetails}
        handleBootcampModuleDetailsChange={handleEditModuleChange}
        handleBootcampModuleSubmit={handleModuleSubmit}
        bootcampModuleApiError={moduleApiError}
        attendeesListLoading={attendeesListLoading}
        moduleSpeChar={moduleSpeChar}
        bootcamp={getBootcampInfo?.getBootcampDataById?.data}
        bootcampModuleList={
          getBootcampInfo?.getBootcampDataById?.data?.modules?.results
        }
        getBootcampData={bootcampsForCreateEditModule}
        moduleNameLengthError={moduleNameLengthError}
        disabledBootcampsId={disabledBootcampsId}
        isModuleActive={isModuleActive}
        handlePropogationWarningOpen={handlePropogationWarningOpen}
        module={moduleView?.moduleData?.data}
      />

      <CreateBatchModal
        isEdit={isBatchEdit}
        open={createBatchModalOpen}
        onClose={handleCreateBatchClose}
        createBatchDetails={createBatchDetails}
        handleCreateBatchSubmit={handleCreateBatchSubmit}
        createBatchErrors={createBatchErrors}
        createBatchApiError={createBatchApiError}
        getBootcampData={getBootcampData}
        handleCreateBatchDetailsChange={handleCreateBatchDetailsChange}
        handleCreateBatchDetailsBlur={handleCreateBatchDetailsBlur}
        attendeesList={attendeesList}
        checkedRows={checkedRows}
        bootcampLoading={bootcampLoading}
        editName={editName}
        handleEditNameCancel={handleEditNameCancel}
        handleEditNameToggle={handleEditNameToggle}
        setCheckedRows={setCheckedRows}
        roomsList={moduleView?.rooms?.data?.results}
        loader={addBatchCreateLoading || editBootcampModuleBatchLoading}
        handleCreateBatchNameAccept={handleCreateBatchNameAccept}
      />

      <DeleteModal
        open={openPropogationWarning}
        title={
          "Following Module/s and Their Respective Sessions, Assessments will also be moved:"
        }
        content={{
          items: propogationWarningText,
          warning: "Bootcamp Assessments will need to be moved manually",
        }}
        buttonText={"Update"}
        handleClose={handlePropogationWarningClose}
        handleDelete={handlePropogationWarningSubmit}
      />
    </>
  );
};

export default BootcampModuleView;
