import React from "react";
import IconButtons from "../../../components/IconButtons/IconButtons";
import {
  download_active_icon,
  download_disabled_icon,
  filter_active_icon,
  purple_down_arrow_icon,
} from "../../../constants/icons";
import { Button, MenuItem, Typography, Box, Menu } from "@mui/material";
import {
  checkDateTimeStatus,
  font_13,
  font_16,
  isOnlyTrueForPermissionKey,
} from "../../../utils/utils";
import { useSelector } from "react-redux";
import BootstrapTooltip from "../../../components/BootstrapTooltip/BootstrapTooltip";
import Loader from "../../../components/Loader/Loader";
import Constants from "../../../constants/constants";

const { ACTIVE, COMPLETED } = Constants;
const AttendeesActionItems = ({
  handleAttendeesFilterOpen,
  handleSubmitAttendance,
  checkedRows,
  handleCreateAttendences,
  attendeesAvailable,
  creatingAttendance,
  currentBootcamp,
  isHr,
  handleDownloadIconClick,
}) => {
  const attendees = useSelector((state) => {
    return state?.attendeesTab;
  });
  const Details = useSelector((state) => {
    return state?.detailsTab;
  });
  const permission = Details?.details?.data?.permission;
  const isSpoc = permission?.spoc;
  const isPresenter = permission?.sessionPresenter;
  const isAttendee = isOnlyTrueForPermissionKey(permission, "attendee");

  const moduleIsOngoing = Details?.details?.data?.module?.status === ACTIVE;
  const moduleIsCompleted =
    Details?.details?.data?.module?.status === COMPLETED;

  const scheduledSession =
    checkDateTimeStatus(
      Details?.details?.data?.startDate,
      Details?.details?.data?.endDate
    ) === "future";

  const isBootcampCompleted = currentBootcamp?.status === COMPLETED;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApiCall = (value) => {
    handleSubmitAttendance(value);
    handleClose();
  };

  const disableButton = !(
    checkedRows?.length &&
    (moduleIsOngoing || moduleIsCompleted) &&
    !scheduledSession
  );

  const attendanceStoreOptions = useSelector(
    (state) => state?.utility?.constants?.sessionAttendanceStatuses
  );
  const attendanceOptions = attendanceStoreOptions?.filter(
    (e) => e?.key !== "NOT_MARKED"
  );
  return (
    <>
      {creatingAttendance && <Loader />}
      {(isHr || isSpoc) && (
        <Box className={isHr ? "margin-right-1" : "margin-right-2"}>
          <IconButtons
            size="small"
            height={font_16}
            width={font_16}
            image={download_active_icon}
            disabledImage={download_disabled_icon}
            handleClick={handleDownloadIconClick.bind(this)}
            tooltip="Download Attendance Report"
            disabled={!attendees?.attendees?.data?.results?.length}
            disabledTooltip={
              !attendees?.attendees?.data?.results?.length &&
              `There are no Attendances for this Session`
            }
          />
        </Box>
      )}
      {(isSpoc || isPresenter) && (
        <>
          <BootstrapTooltip
            title={
              isBootcampCompleted
                ? "Bootcamp is completed"
                : scheduledSession
                ? "Session is not started"
                : !(moduleIsOngoing || moduleIsCompleted)
                ? "Module status is Scheduled"
                : !attendeesAvailable
                ? "No data available to mark attendance"
                : attendeesAvailable && !checkedRows?.length
                ? "Select atleast one attendee."
                : null
            }
            disableHoverListener={!disableButton}
            disableFocusListener={!disableButton}
            disableTouchListener={!disableButton}
          >
            <Box>
              <Button
                id="attendance-menu"
                onClick={handleClick}
                size="small"
                variant="outlined"
                className="margin-right-1 action-head-btn"
                sx={{
                  textTransform: "capitalize",
                  color: "#2E1C41",
                  backgroundColor: "#fff",
                  border: open ? "1px solid #DE1186" : "1px solid #675B76",
                  ":hover": {
                    backgroundColor: "#fff",
                    border: "1px solid #675B76",
                  },
                }}
                endIcon={
                  <IconButtons
                    height={font_13}
                    width={font_13}
                    image={purple_down_arrow_icon}
                  />
                }
                disabled={isBootcampCompleted || disableButton}
              >
                Mark Attendance
              </Button>
            </Box>
          </BootstrapTooltip>
          <Menu
            color="secondary"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              "& .MuiMenu-paper": {
                maxWidth: "none !important",
                width: "10.438rem",
              },
            }}
          >
            {attendanceOptions?.map((attendance, index) => (
              <MenuItem
                onClick={() => handleApiCall(attendance?.key)}
                key={index}
              >
                <Typography variant="font_12">{attendance?.value}</Typography>
              </MenuItem>
            ))}
          </Menu>
          <BootstrapTooltip
            title={
              isBootcampCompleted
                ? "Bootcamp is completed"
                : scheduledSession
                ? "Session is scheduled"
                : null
            }
          >
            <Box>
              <Button
                onClick={handleCreateAttendences}
                variant="outlined"
                color="secondary"
                className="action-head-btn"
                disabled={isBootcampCompleted || scheduledSession}
              >
                <Typography variant="outlineBtnLabel">
                  Create Attendances
                </Typography>
              </Button>
            </Box>
          </BootstrapTooltip>
        </>
      )}
      {!isAttendee && (
        <Box
          className="filterButton margin-left-1"
          sx={{
            border:
              attendees?.filterCount > 0
                ? "2px solid #de1186"
                : "1px solid #de118680",
          }}
        >
          <IconButtons
            height={font_16}
            width={font_16}
            image={filter_active_icon}
            tooltip="Add Filters"
            handleClick={handleAttendeesFilterOpen}
            count={attendees?.filterCount}
          />
        </Box>
      )}
    </>
  );
};

export default AttendeesActionItems;
