import React, { useEffect, useState } from "react";
import "./singleInputModal.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { cross_default_icon } from "../../constants/icons";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";
import { hasDetailsChanged, hasTrimmedDetailsChanged } from "../../utils/utils";

const SingleInputModal = ({
  open,
  handleClose,
  locationDetails,
  handleLocationSubmit,
  locationDetailsChange,
  isEditLocation,
  locationError,
  label,
  locationErrors,
  locationSpecialChar,
  locationNameLengthError,
}) => {
  const loader = useSelector(
    (state) => state?.locationAndRoom.addEditLocationLoading
  );
  const [initialLocationDetails, setInitialLocationDetails] =
    useState(locationDetails);

  useEffect(() => {
    setInitialLocationDetails(locationDetails);
  }, [locationDetails?.id]);

  const locationDetailsChanged =
    initialLocationDetails?.name.trim() !== locationDetails?.name?.trim();

  return (
    <>
      {loader && <Loader />}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "22.125rem",
            zIndex: 1300,
            borderRadius: "0.6rem",
            margin: 0,
          },
        }}
      >
        <DialogTitle className="add-location-modal-title">
          <Typography variant="font_16_bold">
            {isEditLocation ? "Edit Location" : "Create Location"}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ padding: "0.5rem 2rem 0" }}>
          <TextField
            autoFocus
            id="name"
            shrink={false}
            size="small"
            label="Location Name"
            type="text"
            fullWidth
            margin="dense"
            variant="outlined"
            value={locationDetails?.name}
            onChange={(e) => locationDetailsChange(e.target.value)}
            color="secondary"
            error={
              !!locationErrors.name ||
              locationError ||
              locationSpecialChar ||
              locationNameLengthError
            }
            helperText={
              locationNameLengthError
                ? "Maximum 50 characters allowed"
                : locationSpecialChar
                ? "Should not contain special characters"
                : locationErrors.name
            }
            className="add-location-input"
            required={true}
          />
        </DialogContent>
        <DialogActions sx={{ padding: "1rem 2rem 1.2rem" }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            className="add-location-btn"
          >
            <Typography variant="font_12_bold_700">Cancel</Typography>
          </Button>
          <Button
            onClick={handleLocationSubmit}
            variant="contained"
            color="secondary"
            className="add-location-btn"
            disabled={isEditLocation && !locationDetailsChanged}
          >
            <Typography variant="font_12_bold_700">
              {isEditLocation ? "Update" : "Save"}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SingleInputModal;
