import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addLocationApi,
  createRoomApi,
  deleteLocationApi,
  deleteRoomApi,
  fetchLocationWithRoomsApi,
  updateLocationDataApi,
  updateRoomApi,
} from "../services/locationsAndRooms";
import CustomToaster from "../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../constants/constants";
import { toast } from "react-hot-toast";

const initialState = {
  locationData: [],
  locationError: false,
  roomError: false,
  loading: false,
  error: null,
  addEditLocationLoading: false,
  addEditRoomLoading: false,
};

export const addLocation = createAsyncThunk(
  "addLocation",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const callApi = await addLocationApi(data);
      if (callApi) {
        await dispatch(setLocationData());
        toast.custom(
          <CustomToaster
            message="Location Added Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const setLocationData = createAsyncThunk(
  "setLocationData",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await fetchLocationWithRoomsApi();
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editLocation = createAsyncThunk(
  "editLocation",
  async (data, { dispatch, rejectWithValue }) => {
    const { id, name } = data;

    try {
      const callApi = await updateLocationDataApi({
        id: id,
        name: name,
      });
      if (callApi) {
        await dispatch(setLocationData());
        toast.custom(
          <CustomToaster
            message="Location Updated Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteLocation = createAsyncThunk(
  "deleteLocation",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const callApi = await deleteLocationApi(data);
      if (callApi) {
        await dispatch(setLocationData());
        toast.custom(
          <CustomToaster
            message="Location Deleted Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addRooms = createAsyncThunk(
  "addRooms",
  async (data, { dispatch, rejectWithValue }) => {
    const { locationId, rooms } = data;
    try {
      const callApi = await createRoomApi({
        locationId: locationId,
        name: rooms.name,
        seatingCapacity: rooms.seatingCapacity,
      });
      if (callApi) {
        await dispatch(setLocationData());
        toast.custom(
          <CustomToaster
            message="Room Added Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editRoomData = createAsyncThunk(
  "editRoomData",
  async (data, { dispatch, rejectWithValue }) => {
    const { location, rooms } = data;

    try {
      const callApi = await updateRoomApi({
        id: rooms.id,
        name: rooms.name,
        seatingCapacity: rooms.seatingCapacity,
      });
      if (callApi) {
        await dispatch(setLocationData());
        toast.custom(
          <CustomToaster
            message="Room Updated Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteRoom = createAsyncThunk(
  "deleteRoom",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const callApi = await deleteRoomApi(data);
      if (callApi) {
        await dispatch(setLocationData());
        toast.custom(
          <CustomToaster
            message="Room Deleted Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const locationAndRoom = createSlice({
  name: "locationAndRooms",
  initialState,
  reducers: {
    clearLocationData(state) {
      state.locationData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setLocationData.pending, (state) => {
        state.loading = true;
      })
      .addCase(setLocationData.fulfilled, (state, action) => {
        state.loading = false;
        state.locationData = action.payload;
      })
      .addCase(setLocationData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addLocation.pending, (state) => {
        state.locationError = false;
        state.addEditLocationLoading = true;
      })
      .addCase(addLocation.fulfilled, (state, action) => {
        state.locationError = false;
        state.addEditLocationLoading = false;
      })
      .addCase(addLocation.rejected, (state, action) => {
        state.addEditLocationLoading = false;
        state.locationError = true;
      })
      .addCase(editLocation.pending, (state) => {
        state.locationError = false;
        state.addEditLocationLoading = true;
      })
      .addCase(editLocation.fulfilled, (state, action) => {
        state.locationError = false;
        state.addEditLocationLoading = false;
      })
      .addCase(editLocation.rejected, (state, action) => {
        state.addEditLocationLoading = false;
        state.locationError = true;
      })
      .addCase(deleteLocation.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteLocation.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteLocation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addRooms.pending, (state) => {
        state.roomError = false;
        state.addEditRoomLoading = true;
      })
      .addCase(addRooms.fulfilled, (state, action) => {
        state.addEditRoomLoading = false;
        state.roomError = false;
      })
      .addCase(addRooms.rejected, (state, action) => {
        state.addEditRoomLoading = false;
        state.roomError = true;
      })
      .addCase(editRoomData.pending, (state) => {
        state.roomError = false;
        state.addEditRoomLoading = true;
      })
      .addCase(editRoomData.fulfilled, (state, action) => {
        state.addEditRoomLoading = false;
        state.roomError = false;
      })
      .addCase(editRoomData.rejected, (state, action) => {
        state.addEditRoomLoading = false;
        state.roomError = true;
      })
      .addCase(deleteRoom.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteRoom.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteRoom.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { clearLocationData } = locationAndRoom.actions;
export default locationAndRoom.reducer;
