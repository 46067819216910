import React, { useEffect } from "react";
import {
  Box,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import "./questionPoolTable.scss";
import dayjs from "dayjs";
import { calculateAssessmentCounts } from "../../utils/utils";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

const QuestionPoolTable = ({
  handleAssessmentDetailsChange,
  selectedSessions,
  assessmentTopicQuestions,
  errors,
  apiError = false,
  assessmentDetails,
  setAssessmentDetails,
  setInitialFormDetails,
  viewAssessment,
  isEvaluationActive,
}) => {
  const topicList = selectedSessions
    ?.map((session) => session?.topic)
    ?.filter(
      (topic, index, self) =>
        topic && index === self.findIndex((t) => t?.id === topic.id) // Filter unique topics by id
    );
  const topicListSelectData = topicList?.map((item) => ({
    topicId: item?.id,
    name: item?.name,
    easyQuestionsCount:
      assessmentDetails?.topicQuestions?.find(
        (topic) => topic.topicId === item.id
      )?.easyQuestionsCount || 0,
    mediumQuestionsCount:
      assessmentDetails?.topicQuestions?.find(
        (topic) => topic.topicId === item.id
      )?.mediumQuestionsCount || 0,
    hardQuestionsCount:
      assessmentDetails?.topicQuestions?.find(
        (topic) => topic.topicId === item.id
      )?.hardQuestionsCount || 0,
    activeAssessmentQuestionCount: item?.activeAssessmentQuestionCount,
  }));

  useEffect(() => {
    if (topicListSelectData && selectedSessions?.length) {
      setAssessmentDetails((prev) => ({
        ...prev,
        topicQuestions: topicListSelectData,
      }));
      setInitialFormDetails((prev) => ({
        ...prev,
        topicQuestions: topicListSelectData,
      }));
    }
  }, [selectedSessions]);

  return (
    <>
      <TableContainer className="table-container question-pool-table-container">
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ minWidth: "100%", marginBottom: "0.75rem" }}
        >
          <TableHead className="table-head-container">
            <TableRow className="table-head-row">
              <TableCell align="left">
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                  sx={{ minWidth: "20rem" }}
                >
                  <Box className="table-sort-head">Topic</Box>
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "10rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Easy</Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "10rem" }}
                className="padding-left-1"
              >
                <Typography sx={{ minWidth: "10rem" }} variant="font_13_bold">
                  Medium
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "10rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                  sx={{ minWidth: "10rem" }}
                >
                  Hard
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(viewAssessment
              ? assessmentTopicQuestions
              : topicListSelectData
            )?.map((topic, index) => {
              const topicName = topic?.name || topic?.topic?.name;
              const topicId = topic?.topicId || topic?.topic?.id;
              const easyQuestionsCount =
                assessmentDetails?.topicQuestions?.find(
                  (topic) => (topic?.topicId || topic?.topic?.id) === topicId
                )?.easyQuestionsCount;
              const mediumQuestionsCount =
                assessmentDetails?.topicQuestions?.find(
                  (topic) => (topic?.topicId || topic?.topic?.id) === topicId
                )?.mediumQuestionsCount;
              const hardQuestionsCount =
                assessmentDetails?.topicQuestions?.find(
                  (topic) => (topic?.topicId || topic?.topic?.id) === topicId
                )?.hardQuestionsCount;
              const assessmentQuestions = viewAssessment
                ? topic?.topic?.activeAssessmentQuestionCount
                : topic?.activeAssessmentQuestionCount;
              const topicErrors = errors?.topicQuestions?.[topicId] || {};
              return (
                <TableRow className="table-body-row" key={index}>
                  <TableCell className="padding-left-1">
                    <Typography variant="font_12">{topicName}</Typography>
                    {!viewAssessment &&
                      !!errors.topicQuestions &&
                      topicErrors?.noData && (
                        <>
                          <br />
                          <Typography
                            color={
                              topicErrors?.noData || apiError
                                ? "error"
                                : "offline"
                            }
                            variant="font_12"
                            sx={{ marginBottom: "1rem" }}
                          >
                            {topicErrors?.noData}
                          </Typography>
                        </>
                      )}
                  </TableCell>
                  <TableCell align="left" className="padding-left-1">
                    <TextField
                      disabled={viewAssessment || isEvaluationActive}
                      size="small"
                      color="secondary"
                      sx={{ maxWidth: "12.25rem" }}
                      className="filter-inputs"
                      margin="dense"
                      variant="outlined"
                      value={easyQuestionsCount}
                      onChange={(e) =>
                        handleAssessmentDetailsChange(
                          "easyQuestionsCount",
                          +e?.target?.value,
                          topicId,
                          assessmentQuestions
                        )
                      }
                      error={
                        apiError ||
                        topicErrors?.easyQuestionsCount ||
                        (viewAssessment &&
                          calculateAssessmentCounts(
                            assessmentDetails?.type?.key,
                            assessmentQuestions
                          )?.EASY < easyQuestionsCount)
                      }
                      helperText={
                        topicErrors?.easyQuestionsCount ||
                        (viewAssessment &&
                          calculateAssessmentCounts(
                            assessmentDetails?.type?.key,
                            assessmentQuestions
                          )?.EASY < easyQuestionsCount &&
                          "Exceeds maximum count")
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{
                              "& .MuiTypography-root": { color: "#948B9E" },
                            }}
                          >
                            {`/${
                              calculateAssessmentCounts(
                                assessmentDetails?.type?.key,
                                assessmentQuestions
                              )?.EASY
                            }`}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </TableCell>
                  <TableCell className="padding-left-1">
                    <TextField
                      disabled={viewAssessment || isEvaluationActive}
                      size="small"
                      color="secondary"
                      sx={{ maxWidth: "12.25rem" }}
                      className="filter-inputs"
                      margin="dense"
                      variant="outlined"
                      value={mediumQuestionsCount}
                      onChange={(e) =>
                        handleAssessmentDetailsChange(
                          "mediumQuestionsCount",
                          +e?.target?.value,
                          topicId,
                          assessmentQuestions
                        )
                      }
                      error={
                        apiError ||
                        topicErrors?.mediumQuestionsCount ||
                        (viewAssessment &&
                          calculateAssessmentCounts(
                            assessmentDetails?.type?.key,
                            assessmentQuestions
                          )?.MEDIUM < mediumQuestionsCount)
                      }
                      helperText={
                        topicErrors?.mediumQuestionsCount ||
                        (viewAssessment &&
                          calculateAssessmentCounts(
                            assessmentDetails?.type?.key,
                            assessmentQuestions
                          )?.MEDIUM < mediumQuestionsCount &&
                          "Exceeds maximum count")
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{
                              "& .MuiTypography-root": { color: "#948B9E" },
                            }}
                          >
                            {`/${
                              calculateAssessmentCounts(
                                assessmentDetails?.type?.key,
                                assessmentQuestions
                              )?.MEDIUM
                            }`}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </TableCell>
                  <TableCell className="padding-left-1">
                    <TextField
                      disabled={viewAssessment || isEvaluationActive}
                      size="small"
                      color="secondary"
                      sx={{ maxWidth: "12.25rem" }}
                      className="filter-inputs"
                      margin="dense"
                      variant="outlined"
                      value={hardQuestionsCount}
                      onChange={(e) =>
                        handleAssessmentDetailsChange(
                          "hardQuestionsCount",
                          +e?.target?.value,
                          topicId,
                          assessmentQuestions
                        )
                      }
                      error={
                        apiError ||
                        topicErrors?.hardQuestionsCount ||
                        (viewAssessment &&
                          calculateAssessmentCounts(
                            assessmentDetails?.type?.key,
                            assessmentQuestions
                          )?.HARD < hardQuestionsCount)
                      }
                      helperText={
                        topicErrors?.hardQuestionsCount ||
                        (viewAssessment &&
                          calculateAssessmentCounts(
                            assessmentDetails?.type?.key,
                            assessmentQuestions
                          )?.HARD < hardQuestionsCount &&
                          "Exceeds maximum count")
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{
                              "& .MuiTypography-root": { color: "#948B9E" },
                            }}
                          >
                            {`/${
                              calculateAssessmentCounts(
                                assessmentDetails?.type?.key,
                                assessmentQuestions
                              )?.HARD
                            }`}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default QuestionPoolTable;
