import { apiVersion } from "../constants/constants";
import ApiService from "../utils/api-service";

export function getBootcampByIdOnAssessmentApi({ bootcampId }) {
  let apiUrl = `${apiVersion}/bootcamps/${bootcampId}`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getModuleByIdOnAssessmentApi(data) {
  let apiUrl = `${apiVersion}/modules/${data.moduleId}`;

  if (
    data?.bootcampId !== undefined &&
    data?.bootcampId !== null &&
    data?.bootcampId !== ""
  ) {
    apiUrl += `?bootcampId=${data?.bootcampId}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getAssessmentApi(data) {
  let apiUrl = `${apiVersion}/assessment`;

  if (
    data?.bootcampId !== undefined &&
    data?.bootcampId !== null &&
    data?.bootcampId !== ""
  ) {
    apiUrl += `?bootcampId=${data?.bootcampId}`;
  }

  if (
    data?.moduleId !== undefined &&
    data?.moduleId !== null &&
    data?.moduleId !== ""
  ) {
    apiUrl += `?moduleId=${data?.moduleId}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function getAssessmentByIdApi(id) {
  const options = {
    method: "GET",
    url: `${apiVersion}/assessment/${id}`,
    data: id,
  };
  return ApiService(options);
}

export function addAssessmentApi(data) {
  const options = {
    method: "POST",
    url: `${apiVersion}/assessment`,
    data: data,
  };
  return ApiService(options);
}

export function editAssessmentApi(data) {
  const options = {
    method: "PATCH",
    url: `${apiVersion}/assessment`,
    data: data,
  };
  return ApiService(options);
}

export function fetchModulesListApi(id) {
  const options = {
    method: "GET",
    url: `${apiVersion}/utilities/optionsForCreateEditAssessment?bootcampId=${id}`,
    data: id,
  };
  return ApiService(options);
}

export function fetchSessionsListApi(id) {
  const options = {
    method: "GET",
    url: `${apiVersion}/utilities/optionsForCreateEditAssessment?moduleId=${id}`,
    data: id,
  };
  return ApiService(options);
}

export function fetchAssessmentQuestionsApi(data) {
  let apiUrl = `${apiVersion}/assessmentQuestion`;
  if (
    data?.assessmentId !== undefined &&
    data?.assessmentId !== null &&
    data?.assessmentId !== ""
  ) {
    apiUrl += `?assessmentId=${data?.assessmentId}`;
  }
  if (data?.title !== undefined && data?.title !== null && data?.title !== "") {
    apiUrl += `&title=${data?.title}`;
  }
  if (
    data?.type?.length &&
    data?.type !== undefined &&
    data?.type !== null &&
    data?.type !== ""
  ) {
    apiUrl += `&type=${data?.type}`;
  }
  if (
    data?.difficultyLevel?.length &&
    data?.difficultyLevel !== undefined &&
    data?.difficultyLevel !== null &&
    data?.difficultyLevel !== ""
  ) {
    apiUrl += `&difficultyLevel=${data?.difficultyLevel}`;
  }
  if (data?.showActive !== undefined && data?.showActive !== "") {
    apiUrl += `&showActive=${data?.showActive}`;
  }
  if (data?.offset !== undefined && data?.offset !== "") {
    apiUrl += `&offset=${data?.offset * 20}`;
  }
  if (data?.sortBy !== undefined && data?.sortBy !== null) {
    apiUrl += `&sortBy=${data?.sortBy}`;
  }

  if (data?.sortOrder !== undefined && data?.sortOrder !== null) {
    apiUrl += `&sortOrder=${data?.sortOrder}`;
  }
  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}
