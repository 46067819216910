import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import NoDataAvailable from "../NoDataAvailable/NoDataAvailable";
import IconButtons from "../IconButtons/IconButtons";
import { cross_default_icon } from "../../constants/icons";

const SimpleModal = ({ data, open, handleClose, message, isAgenda }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          height: "auto",
          width: "61.91rem",
          zIndex: 1300,
          borderRadius: "1rem",
          padding: "0rem",
          margin: 0,
        },
      }}
    >
      <DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="font_16_bold">
            {isAgenda ? "Agenda" : "Description"}
          </Typography>
          <IconButtons
            height={1}
            width={1}
            image={cross_default_icon}
            handleClick={handleClose}
          />
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          padding: "1.75rem 1.25rem",
        }}
      >
        {!data ? (
          <NoDataAvailable imgHeight={4} imgWidth={4.5} message={message} />
        ) : (
          <DialogContentText sx={{ fontSize: "0.75rem" }}>
            <Box
              className="agenda-modal"
              sx={{
                backgroundColor: "#F2F3F5",
                padding: "0.875rem 0.688rem",
                borderRadius: "0.4rem",
              }}
            >
              <Box
                dangerouslySetInnerHTML={{ __html: data }}
                sx={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}
              />
            </Box>
          </DialogContentText>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SimpleModal;
