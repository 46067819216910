import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addUserRoleApi,
  deleteUserRoleApi,
  getBussinessUnitsApi,
  getCompetenciesApi,
  getDesignationApi,
  getFiltersForUsersApi,
  getUsersApi,
  syncDataApi,
} from "../services/users";
import { toast } from "react-hot-toast";
import CustomToaster from "../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../constants/constants";

const initialState = {
  userData: [],
  competencies: [],
  designation: [],
  businessUnits: [],
  loading: false,
  addUserRoleLoading: false,
  syncDataLoading: false,
  error: null,
  userLoading: false,
  modalApiLoading: false,
  filtersForUsers: null,
};

export const getUsers = createAsyncThunk(
  "getUsers",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getUsersApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCompetencies = createAsyncThunk(
  "getCompetencies",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getCompetenciesApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDesignation = createAsyncThunk(
  "getDesignation",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getDesignationApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteUserRole = createAsyncThunk(
  "deleteUserRole",
  async (data, { dispatch, rejectWithValue }) => {
    const { userId, roleName } = data;
    try {
      const callApi = await deleteUserRoleApi({ userId, roleName });
      if (callApi) {
        toast.custom(
          <CustomToaster
            message="User Role Deleted Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addUserRole = createAsyncThunk(
  "addUserRole",
  async (data, { dispatch, rejectWithValue }) => {
    const { userId, roleName } = data;
    try {
      const callApi = await addUserRoleApi({ userId, roleName });
      if (callApi) {
        toast.custom(
          <CustomToaster
            message="Role Added to User Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const syncData = createAsyncThunk(
  "syncData",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await syncDataApi();
      toast.custom(
        <CustomToaster
          message="Data synchronization completed successfully."
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBussinessUnits = createAsyncThunk(
  "getBussinessUnits",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getBussinessUnitsApi();
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFiltersForUsers = createAsyncThunk(
  "getFiltersForUsers",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getFiltersForUsersApi();
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearUserData(state) {
      state.userData = [];
    },
  },
  extraReducers: {
    [getUsers.pending]: (state) => {
      state.userLoading = true;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.userLoading = false;
      state.userData = action.payload;
    },
    [getUsers.rejected]: (state, action) => {
      state.userLoading = false;
      state.error = action.payload;
    },
    [getCompetencies.pending]: (state) => {
      state.modalApiLoading = true;
    },
    [getCompetencies.fulfilled]: (state, action) => {
      state.modalApiLoading = false;
      state.competencies = action.payload;
    },
    [getCompetencies.rejected]: (state, action) => {
      state.modalApiLoading = false;
      state.error = action.payload;
    },
    [getDesignation.pending]: (state) => {
      state.modalApiLoading = true;
    },
    [getDesignation.fulfilled]: (state, action) => {
      state.modalApiLoading = false;
      state.designation = action.payload;
    },
    [getDesignation.rejected]: (state, action) => {
      state.modalApiLoading = false;
      state.error = action.payload;
    },
    [deleteUserRole.pending]: (state) => {
      state.loading = true;
    },
    [deleteUserRole.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteUserRole.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [addUserRole.pending]: (state) => {
      state.addUserRoleLoading = true;
    },
    [addUserRole.fulfilled]: (state, action) => {
      state.addUserRoleLoading = false;
    },
    [addUserRole.rejected]: (state, action) => {
      state.addUserRoleLoading = false;
      state.error = action.payload;
    },
    [syncData.pending]: (state) => {
      state.syncDataLoading = true;
      toast.custom(
        <CustomToaster
          message="Data sync in progress. This process may take few minutes to complete."
          title={TOAST_TITLE.INFO}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.INFO}
        />
      );
    },
    [syncData.fulfilled]: (state) => {
      state.syncDataLoading = false;
    },
    [syncData.rejected]: (state, action) => {
      state.syncDataLoading = false;
      state.error = action.payload;
    },
    [getBussinessUnits.pending]: (state) => {
      state.modalApiLoading = true;
    },
    [getBussinessUnits.fulfilled]: (state, action) => {
      state.modalApiLoading = false;
      state.businessUnits = action.payload;
    },
    [getBussinessUnits.rejected]: (state, action) => {
      state.modalApiLoading = false;
      state.error = action.payload;
    },
    [getFiltersForUsers.pending]: (state) => {
      state.modalApiLoading = true;
    },
    [getFiltersForUsers.fulfilled]: (state, action) => {
      state.modalApiLoading = false;
      state.filtersForUsers = action.payload;
    },
    [getFiltersForUsers.rejected]: (state, action) => {
      state.modalApiLoading = false;
      state.error = action.payload;
    },
  },
});

export const { clearUserData } = users.actions;
export default users.reducer;
