import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";

const SimpleAutoComplete = ({
  options,
  value,
  onChange,
  label,
  placeholder,
  getOptionLabel,
  disabled,
  renderOption,
  error,
  helperText,
  required,
  disableClearable,
  tooltipTitle,
  filterOptions,
}) => {
  return (
    <Autocomplete
      disabled={disabled}
      filterOptions={filterOptions}
      size="small"
      color="secondary"
      options={options || []}
      className="width-100"
      getOptionLabel={getOptionLabel}
      value={value}
      onChange={onChange}
      renderOption={renderOption}
      componentsProps={{ paper: { sx: { wordBreak: "break-word" } } }}
      renderInput={(params) =>
        tooltipTitle ? (
          <BootstrapTooltip title={tooltipTitle}>
            <TextField
              {...params}
              label={label}
              color="secondary"
              placeholder={placeholder}
              className="width-100"
              margin="dense"
              error={error}
              helperText={helperText}
              required={required}
            />
          </BootstrapTooltip>
        ) : (
          <TextField
            {...params}
            label={label}
            color="secondary"
            placeholder={placeholder}
            className="width-100"
            margin="dense"
            error={error}
            helperText={helperText}
            required={required}
          />
        )
      }
      disableClearable={disableClearable}
    />
  );
};

export default SimpleAutoComplete;
