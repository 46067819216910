const config = require("../config.json");
const { default: Constants, LOCAL_STORAGE_KEYS } = require("../constants/constants");

const Cookies =
  require("universal-cookie").default || require("universal-cookie");
  
const cookies = new Cookies();

const isValidJsonKeyValue = (value) => {
  return (value !== null && value !== undefined && value.toString().trim() !== "");
};

const checkAndReturnValueIfKeyExists = (key) => {
  for (let container of [config]) {
    if (
      container.hasOwnProperty(key) ||
      container[key] ||
      isValidJsonKeyValue(container[key])
    ) {
      return container[key];
    }
  }
  return null;
};

const env = checkAndReturnValueIfKeyExists(`env`);

const getActiveAuthCookieKey = () => {
  const authCookieKey = Constants.AUTHCOOKIEKEY(env);
  const impersonateCookieKey = Constants.IMPERSONATECOOKIEKEY(env);

  if (cookies.get(impersonateCookieKey)) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.BOOTCAMP_SWITCH_USER, "true");
  }
  return (cookies.get(authCookieKey) && (cookies.get(impersonateCookieKey) ? impersonateCookieKey : authCookieKey));
};

module.exports = {
  backendBaseUrl: checkAndReturnValueIfKeyExists(`backendBaseUrl`),
  authCookieName: getActiveAuthCookieKey(),
  env: env,
  cookieDomain: checkAndReturnValueIfKeyExists(`cookieDomain`),
  enableJugaadLogin: checkAndReturnValueIfKeyExists("enableJugaadLogin")
};
