import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import "./questionTypeTable.scss";

const QuestionTypeTable = ({
  handleAssessmentDetailsChange,
  errors,
  apiError = false,
  assessmentDetails,
  easyError,
  mediumError,
  HardError,
  viewAssessment,
  isEvaluationActive,
}) => {
  return (
    <>
      <TableContainer className="table-container question-type-table-container">
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ minWidth: "100%", marginBottom: "0.75rem" }}
        >
          <TableBody>
            <TableRow className="table-body-row question-type-table-row">
              <TableCell className="padding-left-1 cell-border">
                <Typography variant="font_14">Difficulty Level</Typography>
                {!!errors?.markHierarchy && (
                  <>
                    <br />
                    <Typography
                      color={
                        !!errors?.markHierarchy || apiError
                          ? "error"
                          : "offline"
                      }
                      variant="font_12"
                      sx={{ marginBottom: "1rem" }}
                    >
                      {errors?.markHierarchy}
                    </Typography>
                  </>
                )}
              </TableCell>
              <TableCell align="left" className="padding-left-1 cell-border">
                <Box className="type-box">
                  <Typography variant="font_14_bold">Easy</Typography>
                  <TextField
                    disabled={viewAssessment || isEvaluationActive}
                    size="small"
                    color="secondary"
                    sx={{ width: "12.25rem" }}
                    className="filter-inputs"
                    margin="dense"
                    variant="outlined"
                    value={assessmentDetails?.markPerEasyQuestion}
                    onChange={(e) =>
                      handleAssessmentDetailsChange(
                        "markPerEasyQuestion",
                        +e?.target?.value
                      )
                    }
                    error={
                      !!errors?.markPerEasyQuestion || easyError || apiError
                    }
                    helperText={
                      easyError
                        ? "Should only contain number"
                        : errors?.markPerEasyQuestion
                    }
                  />
                </Box>
              </TableCell>
              <TableCell className="padding-left-1 cell-border">
                <Box className="type-box">
                  <Typography variant="font_14_bold">Medium</Typography>
                  <TextField
                    disabled={viewAssessment || isEvaluationActive}
                    size="small"
                    color="secondary"
                    sx={{ width: "12.25rem" }}
                    className="filter-inputs"
                    margin="dense"
                    variant="outlined"
                    value={assessmentDetails?.markPerMediumQuestion}
                    onChange={(e) =>
                      handleAssessmentDetailsChange(
                        "markPerMediumQuestion",
                        +e?.target?.value
                      )
                    }
                    error={
                      !!errors?.markPerMediumQuestion || mediumError || apiError
                    }
                    helperText={
                      mediumError
                        ? "Should only contain number"
                        : errors?.markPerMediumQuestion
                    }
                  />
                </Box>
              </TableCell>
              <TableCell className="padding-left-1 cell-border">
                <Box className="type-box">
                  <Typography variant="font_14_bold">Hard</Typography>
                  <TextField
                    disabled={viewAssessment || isEvaluationActive}
                    size="small"
                    color="secondary"
                    sx={{ width: "12.25rem" }}
                    className="filter-inputs"
                    margin="dense"
                    variant="outlined"
                    value={assessmentDetails?.markPerHardQuestion}
                    onChange={(e) =>
                      handleAssessmentDetailsChange(
                        "markPerHardQuestion",
                        +e?.target?.value
                      )
                    }
                    error={
                      !!errors?.markPerHardQuestion || HardError || apiError
                    }
                    helperText={
                      HardError
                        ? "Should only contain number"
                        : errors?.markPerHardQuestion
                    }
                  />
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default QuestionTypeTable;
