import { apiVersion } from "../constants/constants";
import ApiService from "../utils/api-service";

export function getBootcampModulesApi(bootcampId) {
  let apiUrl = `${apiVersion}/modules?paginated=false`;

  if (bootcampId !== undefined) {
    apiUrl += `&bootcampId=${bootcampId}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
    data: bootcampId,
  };
  return ApiService(options);
}

export function addBootcampModulesApi(data) {
  let apiUrl = `${apiVersion}/modules?paginated=false`;

  const options = {
    method: "POST",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function deleteBootcampModuleApi(moduleId) {
  const options = {
    method: "DELETE",
    url: `${apiVersion}/modules/${moduleId}`,
  };
  return ApiService(options);
}

export function editBootcampModuleApi(data) {
  const options = {
    method: "PATCH",
    url: `${apiVersion}/modules`,
    data: data,
  };
  return ApiService(options);
}

export function cloneBootcampModulesApi(data) {
  let apiUrl = `${apiVersion}/modules/clone`;

  const options = {
    method: "POST",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}
