import React from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom/dist";

const BreadCrumb = ({ breadcrumbs }) => {
  const navigate = useNavigate();

  const isLast = (index) => {
    return index === breadcrumbs?.length;
  };

  const renderBreadcrumbItem = (item, index) => {
    if (
      (item?.isBootcamp && isNaN(item?.bootcampId)) ||
      (item?.isModule && (isNaN(item?.moduleId) || !item?.moduleId)) ||
      (item?.isSession && isNaN(item?.sessionId)) ||
      (item?.isTopic && isNaN(item?.topicId)) ||
      (item?.isSessionView && isNaN(item?.sessionViewId))
    ) {
      navigate("/error", {
        state: { isAccessDenied: false },
      });
    }
    const isBootcamp = item?.isBootcamp || false;
    const disableClick = isLast(index);
    const propsToPass = {
      bootcampName: item?.label,
      bootcampId: item?.bootcampId,
    };

    const handleClick = (event) => {
      event.preventDefault();
      if (isBootcamp) {
        navigate(item?.url, { state: { propsToPass } });
      } else {
        navigate(item?.url);
      }
    };
    if (!disableClick && item.url) {
      return (
        <Typography
          key={index}
          onClick={handleClick}
          style={{
            fontSize: "0.625rem",
            cursor: "pointer",
          }}
          className="grey-text"
        >
          {item?.label}
        </Typography>
      );
    } else {
      return (
        <Typography
          key={index}
          color="text.primary"
          variant="font_10"
          style={{
            display: "flex",
          }}
        >
          {item?.label}
        </Typography>
      );
    }
  };
  const breadcrumb = [...(breadcrumbs || [])];

  return (
    <Breadcrumbs
      separator={
        <NavigateNextIcon
          className="grey-text"
          fontSize="small"
          sx={{ fontSize: "0.625rem" }}
        />
      }
      aria-label="breadcrumb"
      sx={{ marginBottom: "1rem", lineHeight: "0.938rem" }}
    >
      {breadcrumb?.map((item, index) => renderBreadcrumbItem(item, index))}
    </Breadcrumbs>
  );
};

export default BreadCrumb;
