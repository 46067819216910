import React, { useEffect, useState } from "react";
import "./submitModal.scss";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import IconButtons from "../IconButtons/IconButtons";
import {
  cross_default_icon,
  delete_active_icon,
  plus_active_icon,
  plus_dashed_active_icon,
  upload_files_icon,
} from "../../constants/icons";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import { font_13_icon, font_9, isValidUrlFormat } from "../../utils/utils";
import { HelpTexts, TOAST_TYPE } from "../../constants/constants";
import WarningToast from "../MessagePopup/WarningToast";

const { ENTER_VALID_URL } = HelpTexts;

const SubmitModal = ({
  open,
  handleClose,
  handleSubmit,
  exerciseDetails,
  handleInputCommentChange,
  commentDetails,
  loader,
}) => {
  const isLoading = useSelector((state) => state?.commentsList?.loading);
  const [isCommentEmpty, setIsCommentEmpty] = useState(false);
  const initialCommentInfo = {
    commentId: null,
    commentDesc: "",
    deleteCommentId: null,
  };
  const [commentInfo, setCommentInfo] = useState(initialCommentInfo);
  const [submitDetails, setSubmitDetails] = useState({
    json: {
      status: "IN_REVIEW",
      score: null,
      comment: "",
      urls: [],
    },
    files: [],
  });

  //////////////////// Form Validation ////////////////////////////////////
  const initialErrorState = {
    json: {
      comment: "",
      urls: [],
    },
    files: [],
  };

  const [errors, setErrors] = useState(initialErrorState);

  const validateFields = () => {
    const hasNonEmptyUrl = urls?.some((url) => url?.trim() !== "");
    const hasUrlErrors =
      hasNonEmptyUrl && urls?.some((url) => url?.trim() === "");
    const hasFiles = files.length > 0;
    const hasErrorInUrl = urlError.some((error) => error);

    const urlEmptyErrors = hasUrlErrors
      ? urls?.map((url) => (url?.trim() === "" ? "URL cannot be empty" : ""))
      : [];

    const urlValidityErrors = hasErrorInUrl
      ? urls?.map((_, index) => (urlError[index] ? ENTER_VALID_URL : ""))
      : [];

    const newErrors = {
      errorResource:
        !hasNonEmptyUrl && !hasFiles
          ? "Either URL or file must be provided."
          : "",
      errorComment: !commentDetails ? "Comment cannot be empty." : "",
      errorUrls: urlEmptyErrors,
      errorUrlValidity: urlValidityErrors,
    };

    setErrors(newErrors);
    return Object.values(newErrors).every(
      (error) =>
        error === "" || (Array.isArray(error) && error.every((e) => e === ""))
    );
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 2,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 2,
  });

  const FileItem = styled("div")({
    display: "flex",
    alignItems: "center",
    margin: "0.5rem 0 0.5rem 0px",
  });

  const onSubmit = async () => {
    if (validateFields()) {
      setIsCommentEmpty(!commentDetails);
      setSubmitDetails({
        ...submitDetails,
        json: {
          ...submitDetails.json,
          score: exerciseDetails?.score,
          comment: commentDetails,
          urls: urls,
        },
        files: files,
      });
    }
  };

  useEffect(() => {
    if (submitDetails?.json?.comment) {
      handleSubmit(commentDetails, submitDetails);
      handleOnClose();
    }
  }, [submitDetails]);

  const handleInputChange = (comment) => {
    setErrors((prev) => {
      return {
        ...prev,
        errorComment: "",
      };
    });
    setIsCommentEmpty(false);
    handleInputCommentChange(comment);
  };

  const handleOnClose = () => {
    setErrors(initialErrorState);
    setCommentInfo({ initialCommentInfo });
    setUrlError(Array(urls.length).fill(false));
    setUrls([""]);
    setFiles([]);
    setFileError("");
    handleClose();
    handleInputChange("");
  };

  ///////////////////////////////////////////////////////////////////////////////////////////

  const [urls, setUrls] = useState([""]);
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState("");
  const [urlError, setUrlError] = useState(Array(urls.length).fill(false));

  const handleAddUrl = () => {
    setUrls([...urls, ""]);
  };

  const handleUrlChange = (index, value) => {
    if (value.trim() === "") {
      setUrlError((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = false;
        return newErrors;
      });
    } else {
      const isValidUrl = isValidUrlFormat(value.trim());
      setUrlError((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = !isValidUrl;
        return newErrors;
      });
    }

    const newUrls = [...urls];
    newUrls[index] = value;
    setErrors((prev) => {
      return {
        ...prev,
        errorResource: "",
        errorUrls: prev?.errorUrls?.map((urlError, i) =>
          i === index ? "" : urlError
        ),
      };
    });
    setUrls(newUrls);
  };

  const handleRemoveUrl = (index) => {
    if (index === 0 && urls.length === 1) return;
    const newUrls = [...urls];
    newUrls.splice(index, 1);
    setUrls(newUrls);
    const newUrlError = [...urlError];
    newUrlError.splice(index, 1);
    setUrlError(newUrlError);

    const newErrors = { ...errors };
    newErrors?.errorUrls?.splice(index, 1);
    setErrors(newErrors);
  };

  const processFiles = (fileList) => {
    setErrors((prev) => {
      return {
        ...prev,
        errorResource: "",
      };
    });
    let invalidFiles = [];
    let validFiles = [];

    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "application/pdf",
      "application/msword",
      "text/plain",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/x-zip-compressed",
    ];

    const maxSize = 4 * 1024 * 1024; // 4MB

    fileList?.forEach((file) => {
      if (!allowedTypes?.includes(file?.type)) {
        invalidFiles?.push({ file, error: "type" });
      } else if (file?.size > maxSize) {
        invalidFiles?.push({ file, error: "size" });
      } else {
        validFiles?.push(file);
      }
    });

    // Handle invalid files: group by error type
    const sizeErrorFiles = invalidFiles
      ?.filter((invalidFile) => invalidFile?.error === "size")
      ?.map((invalidFile) => invalidFile?.file?.name);

    const typeErrorFiles = invalidFiles
      ?.filter((invalidFile) => invalidFile?.error === "type")
      ?.map((invalidFile) => invalidFile?.file?.name);

    const errorMessages = [];

    if (sizeErrorFiles?.length > 0) {
      errorMessages.push(
        `File(s) ${sizeErrorFiles?.join(", ")} exceed the maximum size.`
      );
    }

    if (typeErrorFiles?.length > 0) {
      const fileLabel = typeErrorFiles?.length > 1 ? "Files" : "File";
      const verb = typeErrorFiles?.length > 1 ? "are" : "is";
      errorMessages.push(
        `${fileLabel} ${typeErrorFiles?.join(
          ", "
        )} ${verb} of an unsupported file type.`
      );
    }

    // Show error for invalid files
    if (errorMessages?.length > 0) {
      setFileError(errorMessages.join("\n"));
    } else {
      setFileError("");
    }

    // Set valid files even if there are invalid files
    if (validFiles?.length > 0) {
      const allFiles = {};
      [...files, ...validFiles].forEach((it) => (allFiles[it?.name] = it));
      setFiles(Object.values(allFiles));
    }
  };

  const handleFileChange = (event) => {
    const fileList = Array.from(event.target.files);
    processFiles(fileList);
  };

  const handleRemoveFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const fileList = Array.from(event.dataTransfer.files);
    processFiles(fileList);
  };

  return (
    <>
      {(isLoading || loader) && <Loader />}
      <Dialog
        open={open}
        onClose={handleOnClose}
        PaperProps={{
          style: {
            minHeight: "28.563rem",
            height: "40rem",
            width: "35rem",
            zIndex: 1300,
            borderRadius: "1rem",
            margin: 0,
            padding: 0,
          },
        }}
      >
        <>
          <DialogTitle className="submit-dialog-title">
            Submit Exercise
          </DialogTitle>
          <DialogContent
            sx={{
              minHeight: "20rem",
              zIndex: 1300,
              borderRadius: "1rem",
              margin: 0,
              padding: "1rem 1rem 1.25rem 2rem !important",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box className="submit-main-container">
              {urls.map((url, index) => (
                <Box key={index} display="flex" alignItems="center">
                  <TextField
                    autoFocus
                    fullWidth
                    size="small"
                    id={`url-${index}`}
                    label="Enter Submission URL"
                    type="link"
                    className="input-file-width"
                    margin="dense"
                    color="secondary"
                    value={url}
                    onChange={(e) => handleUrlChange(index, e.target.value)}
                    error={urlError[index] || errors?.errorUrls?.[index]}
                    helperText={
                      urlError[index]
                        ? ENTER_VALID_URL
                        : errors?.errorUrls?.[index] || ""
                    }
                  />
                  {index !== 0 && (
                    <IconButtons
                      height={font_13_icon}
                      width={font_13_icon}
                      image={delete_active_icon}
                      handleClick={() => handleRemoveUrl(index)}
                    />
                  )}
                </Box>
              ))}
              <Box
                className="input-file-width"
                sx={{
                  marginTop: "0.6rem",
                  marginBottom: "1.125rem",
                }}
              >
                <Button
                  fullWidth
                  className="block-dashed-btn"
                  color="secondary"
                  image={plus_dashed_active_icon}
                  onClick={handleAddUrl}
                  startIcon={
                    <IconButtons
                      width={font_13_icon}
                      height={font_13_icon}
                      image={plus_active_icon}
                    />
                  }
                />
              </Box>

              <Box
                className="input-file-width"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                sx={{
                  marginBottom: "0.688rem",
                }}
              >
                <Button
                  multiple
                  className="file-upload-area"
                  fullWidth
                  color="lightGrayText"
                  component="label"
                  variant="outlined"
                  startIcon={<img alt="upload" src={upload_files_icon} />}
                >
                  <Box className="file-upload-instruction">
                    <Box>
                      <Typography variant="font_12_bold" color="contrastText">
                        Drag and Drop file or{" "}
                      </Typography>

                      <Typography variant="font_12_bold_600" color="secondary">
                        BROWSE
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="font_10">
                        Upto 4mb (jpg, jpeg, png, pdf, doc, docx, zip, txt)
                      </Typography>
                    </Box>
                  </Box>
                  <VisuallyHiddenInput
                    onChange={handleFileChange}
                    type="file"
                    multiple
                  />
                </Button>
                {!!errors?.errorResource ? (
                  <Box className="fileError">
                    <Typography variant="font_12">
                      {errors?.errorResource}
                    </Typography>
                  </Box>
                ) : (
                  fileError && (
                    <WarningToast
                      message={fileError}
                      title="Warning!"
                      type={TOAST_TYPE.WARNING}
                    />
                  )
                )}
                {files.map((file, index) => (
                  <FileItem key={index}>
                    <Chip
                      label={file?.name}
                      variant="outlined"
                      size="small"
                      color="secondary"
                      className="input-files-chip"
                      deleteIcon={
                        <IconButtons
                          height={font_9}
                          width={font_9}
                          image={cross_default_icon}
                          handleClick={() => handleRemoveFile(index)}
                          classList="user-role-cross"
                        />
                      }
                      onDelete={true}
                    />
                  </FileItem>
                ))}
              </Box>
            </Box>

            <TextField
              multiline
              minRows={3}
              disabled={commentInfo?.commentId}
              id="comment"
              shrink={false}
              size="small"
              label="Comment"
              type="text"
              className="input-comment-width"
              margin="dense"
              variant="outlined"
              value={commentDetails}
              onChange={(e) => handleInputChange(e?.target?.value)}
              color="secondary"
              error={!!errors.errorComment}
              helperText={errors.errorComment}
            />
          </DialogContent>
          <DialogActions className="submit-dialog-action">
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleOnClose}
              className="submitButtonStyle"
              disableFocusRipple
              disableRipple
              disableTouchRipple
              disableElevation
            >
              <Typography variant="outlineBtnLabel">Cancel</Typography>
            </Button>
            <Button
              fullWidth
              disabled={commentInfo?.commentId}
              color="secondary"
              variant="contained"
              onClick={onSubmit}
              className="submitButtonStyle"
              disableFocusRipple
              disableRipple
              disableTouchRipple
              disableElevation
            >
              <Typography variant="outlineBtnLabel">Submit</Typography>
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </>
  );
};

export default SubmitModal;
