import React from "react";
import { Box, Chip, Grid, Typography } from "@mui/material";

const TopicsList = ({
  topics,
  setTopicId,
  topicId,
  totalMarks,
  marksObtained,
  openedByAttendee,
  assessmentEvaluationStatus,
}) => {
  const handleSelectedTopic = (topicId) => {
    setTopicId(topicId);
  };
  const allTopicsOption = { id: "all", name: "All", totalMarks, marksObtained };

  return (
    <Grid item md={12} className="topic-list-container">
      {topics?.totalResults
        ? [allTopicsOption, ...topics?.results]?.map((topic, index) => {
            const TopicTotalMarks = topic?.totalMarks || 0;
            const TopicMarksObtained = topic?.marksObtained || 0;

            return (
              <Box
                key={index}
                className="topic-details"
                onClick={() => handleSelectedTopic(topic?.id)}
                sx={{
                  cursor: "pointer",
                  background: topic?.id === topicId && "#f7f0ff !important",
                  border: topic?.id === topicId && "0.1rem solid #e6dbf0",
                }}
              >
                <Box className="topic-section">
                  <Typography className="eval-topic-btn">
                    {topic?.name}
                  </Typography>
                </Box>
                <Box className="score-section">
                  {(!openedByAttendee ||
                    (openedByAttendee &&
                      assessmentEvaluationStatus === "COMPLETED")) && (
                    <Typography variant="font_12">
                      <Chip
                        className="score-chip"
                        label={`${TopicMarksObtained}/${TopicTotalMarks}`}
                        variant={
                          topic?.marksObtained === null
                            ? "GREY"
                            : TopicMarksObtained === 0
                            ? "RED"
                            : TopicMarksObtained === TopicTotalMarks
                            ? "GREEN"
                            : "YELLOW"
                        }
                      />
                    </Typography>
                  )}
                </Box>
              </Box>
            );
          })
        : null}
    </Grid>
  );
};

export default TopicsList;
