import { apiVersion } from "../../constants/constants";
import ApiService from "../../utils/api-service";

export function getExerciseStatusApi(data) {
  let apiUrl = `${apiVersion}/sessionExercise?sessionId=${data?.sessionId}&max=20`;

  if (data?.offset !== undefined) {
    apiUrl += `&offset=${data?.offset * 20}`;
  }

  if (data?.sortBy !== undefined && data?.sortBy !== null) {
    apiUrl += `&sortBy=${data?.sortBy}`;
  }

  if (data?.sortOrder !== undefined && data?.sortOrder !== null) {
    apiUrl += `&sortOrder=${data?.sortOrder}`;
  }

  if (
    data?.reviewerNameOrEmail !== undefined &&
    data?.reviewerNameOrEmail !== null &&
    data?.reviewerNameOrEmail !== ""
  ) {
    apiUrl += `&reviewerNameOrEmail=${data?.reviewerNameOrEmail}`;
  }
  if (
    data?.attendeeNameOrEmail !== undefined &&
    data?.attendeeNameOrEmail !== null &&
    data?.attendeeNameOrEmail !== ""
  ) {
    apiUrl += `&attendeeNameOrEmail=${data?.attendeeNameOrEmail}`;
  }
  if (
    data?.mentorNameOrEmail !== undefined &&
    data?.mentorNameOrEmail !== null &&
    data?.mentorNameOrEmail !== ""
  ) {
    apiUrl += `&mentorNameOrEmail=${data?.mentorNameOrEmail}`;
  }
  if (
    data?.competencyIds !== undefined &&
    data?.competencyIds !== null &&
    data?.competencyIds.length
  ) {
    apiUrl += `&competencyIds=${data?.competencyIds}`;
  }
  if (
    data?.bootcampIds !== undefined &&
    data?.bootcampIds !== null &&
    data?.bootcampIds !== ""
  ) {
    apiUrl += `&bootcampIds=${data?.bootcampIds}`;
  }
  if (
    data?.batchIds !== undefined &&
    data?.batchIds !== null &&
    data?.batchIds.length
  ) {
    apiUrl += `&batchIds=${data?.batchIds}`;
  }
  if (
    data?.status !== undefined &&
    data?.status !== null &&
    data?.status !== ""
  ) {
    apiUrl += `&status=${data?.status}`;
  }
  if (data?.showAttendees) {
    apiUrl += `&showAttendees=${data?.showAttendees}`;
  }
  if (data?.showMentees) {
    apiUrl += `&showMentees=${data?.showMentees}`;
  }
  if (data?.showReviewees) {
    apiUrl += `&showReviewees=${data?.showReviewees}`;
  }
  if (data?.bootcampId) {
    apiUrl += `&bootcampId=${data?.bootcampId}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function deleteExerciseStatusApi(data) {
  let apiUrl = `${apiVersion}/sessionExercise/${data?.exerciseId}`;

  const options = {
    method: "DELETE",
    url: apiUrl,
  };
  return ApiService(options);
}

export function createAllExerciseStatusesApi(data) {
  let apiUrl = `${apiVersion}/sessionExercise?sessionId=${data?.sessionId}`;

  const options = {
    method: "POST",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getCompetencyApi(data) {
  let apiUrl = `${apiVersion}/competencies`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getBatchesApi(data) {
  let apiUrl = `${apiVersion}/moduleBatches?moduleId=${data.moduleId}`;
  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function addSubmitDetailsApi(data) {
  const { submitDetails, exerciseEditId } = data;

  let apiUrl = `${apiVersion}/sessionExercise`;

  if (exerciseEditId !== undefined) {
    apiUrl += `/${exerciseEditId}`;
  }

  const options = {
    method: "PATCH",
    url: apiUrl,
    data: submitDetails,
  };
  return ApiService(options, true, true);
}

export function getUsersApi(data) {
  let apiUrl = `${apiVersion}/users?paginated=false`;

  if (data?.competencyId) {
    apiUrl += `&competencyIds=${[data?.competencyId]}`;
  }
  if (data?.designationId) {
    apiUrl += `&designationIds=${data?.designationId}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getFiltersForExerciseTabApi({ sessionId }) {
  let apiUrl = `${apiVersion}/utilities/filtersForSessionExerciseStatusListing?sessionId=${sessionId}`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}
export function sendExerciseReminderApi({ sessionId }) {
  let apiUrl = `${apiVersion}/sessionExercise/sendEmail?sessionId=${sessionId}`;

  const options = {
    method: "POST",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getOptionsForEditReviewerApi(data) {
  let apiUrl = `${apiVersion}/utilities/optionsForEditReviewer?sessionId=${data?.sessionId}`;
  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}
