export const status = {
  statuses: [
    {
      id: 0,
      title: "Dropped",
      value: "DROPPED",
      variant: "RED-TEXT",
    },
    {
      id: 1,
      title: "Active",
      value: "ACTIVE",
      variant: "GREEN-TEXT",
    },
    {
      id: 2,
      title: "Separated",
      value: "SEPARATED",
      variant: "RED-TEXT",
    },
  ],
  getVariant: (value) =>
    status.statuses
      .filter((it) => it?.value === value)
      .map((it) => it?.variant)[0] || "",
  getTitle: (value) =>
    status.statuses
      .filter((it) => it?.value === value)
      .map((it) => it?.title)[0] || "",
};
