import {
  Box,
  Checkbox,
  Chip,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TablePagination from "../../../components/TablePagination/TablePagination";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router";
import {
  clearAttendees,
  countFilter,
  getAttendees,
  markAttendance,
} from "../../../store/SessionView/attendeesTab";
import Constants, {
  attendance,
  AttendeeStatus,
  HelpTexts,
  sortInAscending,
  sortInDescending,
} from "../../../constants/constants";
import IconButtons from "../../../components/IconButtons/IconButtons";
import {
  down_sort_arrow_icon,
  up_sort_arrow_icon,
} from "../../../constants/icons";
import {
  calculateFilterCount,
  font_9,
  getStatus,
  selectionOptions,
} from "../../../utils/utils";
import Loader from "../../../components/Loader/Loader";
import NoDataAvailable from "../../../components/NoDataAvailable/NoDataAvailable";
import FilterAttendeesTab from "./FilterAttendeesTab";
import UserTableAvatar from "../../../components/UserTableAvatar/UserTableAvatar";
import UserModuleBootcamp from "../../../components/UserModuleBootcamp/UserModuleBootcamp";
import dayjs from "dayjs";
import BootstrapTooltip from "../../../components/BootstrapTooltip/BootstrapTooltip";
import { Link } from "react-router-dom";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);
const tz = "Asia/Kolkata";

const { COMPLETED } = Constants;
const { DROPPED, SEPARATED } = AttendeeStatus;
const { NON_ACTIVE_ATTENDEE } = HelpTexts;

const AttendeesTab = ({
  filterOpen,
  handleAttendeesFilterClose,
  handleCheckboxChange,
  checkedRows,
  setCheckedRows,
  isHr,
  setFilterData,
  filterAttendee,
  setFilterAttendee,
  isMentor,
  setCurrentPage,
  currentPage,
  initialFilterValues,
  setInitialFilterValues,
  isRestrictedUser,
  currentBootcamp,
  initialFilterAttendee,
}) => {
  const security = useSelector((state) => state?.security);
  const userId = security?.loggedInUser?.id;
  const dispatch = useDispatch();
  const attendees = useSelector((state) => {
    return state?.attendeesTab;
  });
  const param = useParams();
  const attendeesList = attendees?.attendees?.data?.results;
  const notMarkedAttendeesList = attendeesList?.filter(
    (attendee) => attendee?.status === "NOT_MARKED"
  );
  const loading = attendees?.attendeesLoading;
  const [isLoading, setIsLoading] = useState(false);
  const attendeeStatuses = useSelector(
    (state) => state?.utility?.constants?.attendeeStatuses
  );
  const Details = useSelector((state) => {
    return state?.detailsTab;
  });
  const batches = attendees?.filtersForAttendeesTab?.data?.batches?.results;
  const bootcamp = Details?.details?.data?.module?.bootcamps?.results;
  const competency =
    attendees?.filtersForAttendeesTab?.data?.competencies?.results;
  const bootcampList =
    attendees?.filtersForAttendeesTab?.data?.bootcamps?.results;
  const apiLoading = attendees?.apiLoading;
  const attendanceLoading = attendees?.attendanceLoading;

  const permission = Details?.details?.data?.permission;
  const presenters = Details?.details?.data?.presenters?.results;
  const isSpoc = permission?.spoc;
  const isPresenter = permission?.sessionPresenter;
  const isReviewer = permission?.exerciseReviewer;

  const moduleStatusIsOngoingOrCompleted =
    Details?.details?.data?.module?.status === "ACTIVE" ||
    Details?.details?.data?.module?.status === "COMPLETED";

  const moduleId = param?.moduleId;
  const bootcampId = param?.bootcampId;
  const isBootcampCompleted = currentBootcamp?.status === COMPLETED;

  ////////////// sort table ///////////////////////////////////
  const [sortBy, setSortBy] = useState("attendeeName");
  const [orderBy, setOrderBy] = useState("ASC");

  const handleSorting = (order, sort) => {
    setSortBy(sort);
    setOrderBy(order);
    setOffsetChangedByUser(true);
  };
  ////////////////////////////////////////////////////////////
  /////////  pagination //////////////////////////////////////
  const [offset, setOffset] = useState(0);
  const [offsetChangedByUser, setOffsetChangedByUser] = useState(true);
  const [totalPages, setTotalPages] = useState(0);

  const totalResult = attendees?.attendees?.data?.totalResults;
  const handlePageChange = (value) => {
    setCheckAll(false);
    setOffsetChangedByUser(true);
    setOffset(value - 1);
    setCurrentPage(value);
  };
  //////////////////////////////////////////////////////////////////////////////
  //////////////  handle Attendance ///////////////////////////////////////////
  const handleAttendance = async (value, id) => {
    setIsLoading(true);
    await dispatch(
      markAttendance({
        sessionId: Number(param?.sessionId),
        attendeeAttendances: [
          {
            attendeeId: id,
            status: value,
          },
        ],
      })
    );
    setCheckedRows([]);
    const data = {
      ...filterAttendee,
      sessionId: param?.sessionId,
      attendeeNameOrEmail: filterAttendee?.attendeeNameOrEmail.trim(),
      mentorNameOrEmail: filterAttendee?.mentorNameOrEmail.trim(),
      competencyIds: filterAttendee?.competencyIds?.map((e) => e?.id),
      bootcampIds: filterAttendee?.bootcampIds
        ? filterAttendee?.bootcampIds?.id
        : null,
      batchIds: filterAttendee?.batchIds?.map((e) => e?.id),
      sortBy: sortBy,
      sortOrder: orderBy,
      offset,
      status: filterAttendee?.status?.key ? filterAttendee?.status?.key : null,
      bootcampId: bootcampId,
    };

    await dispatch(getAttendees(data));
    setIsLoading(false);
  };
  /////////////////////////////////////////////////////////////////////////////
  ////// filter attendees tab ///////////////////////////////////////////////////////
  const handleFilterClose = () => {
    handleAttendeesFilterClose();
  };
  const handleFilterAttendeeSubmit = async () => {
    setIsLoading(true);
    const filterCount = calculateFilterCount(filterAttendee);
    const data = {
      ...filterAttendee,
      sessionId: param?.sessionId,
      attendeeNameOrEmail: filterAttendee?.attendeeNameOrEmail.trim(),
      mentorNameOrEmail: filterAttendee?.mentorNameOrEmail.trim(),
      competencyIds: filterAttendee?.competencyIds?.map((e) => e?.id),
      bootcampIds: filterAttendee?.bootcampIds
        ? filterAttendee?.bootcampIds?.id
        : null,
      batchIds: filterAttendee?.batchIds?.map((e) => e?.id),
      sortBy: sortBy,
      sortOrder: orderBy,
      offset: 0,
      status: filterAttendee?.status?.key ? filterAttendee?.status?.key : null,
      bootcampId: bootcampId,
    };

    if (filterCount) {
      await dispatch(getAttendees(data));
      await dispatch(countFilter(filterCount));
      setOffsetChangedByUser(false);
      setOffset(0);
      setCurrentPage(1);
    }
    handleAttendeesFilterClose();
    setIsLoading(false);
    fetchedData();
  };
  const handleFilterAttendeeRemove = async () => {
    setIsLoading(true);
    await setFilterAttendee({
      ...initialFilterValues,
      ...(!isHr
        ? {
            bootcampIds: bootcamp?.filter(
              (e) => e?.id === Number(param?.bootcampId)
            )[0],
          }
        : { bootcampIds: null }),
    });
    await dispatch(
      getAttendees({
        sessionId: param?.sessionId,
        offset: 0,
        sortBy: sortBy,
        sortOrder: orderBy,
        ...(!isHr
          ? {
              bootcampIds: bootcamp?.filter(
                (e) => e?.id === Number(param?.bootcampId)
              )[0]?.id,
            }
          : { bootcampIds: null }),
        ...(isMentor && !isSpoc && !isHr && { showMentees: true }),
        ...(isReviewer && !isSpoc && !isHr && { showReviewees: true }),
        ...(isPresenter && !isSpoc && !isHr && { showAttendees: true }),
        bootcampId: bootcampId,
      })
    );
    setOffsetChangedByUser(false);
    setOffset(0);
    setCurrentPage(1);
    handleAttendeesFilterClose();
    await dispatch(
      countFilter(!isHr ? calculateFilterCount(initialFilterValues) : 0)
    );
    setIsLoading(false);
    fetchedData();
  };

  const handleChangeFilterAttendee = (name, value) => {
    setFilterAttendee({ ...filterAttendee, [name]: value });
  };
  /////////////////////////////////////////////////////////////////////////////////////
  const [initialFilterApplied, setInitialFilterApplied] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      if (offsetChangedByUser) {
        if (!initialFilterApplied) {
          setIsLoading(true);
          let bootcampFilter;
          if (param?.bootcampId) {
            bootcampFilter =
              (await bootcamp?.filter(
                (e) => e?.id === Number(param?.bootcampId)
              )[0]) || null;
          } else {
            bootcampFilter =
              (await bootcamp?.filter((e) => e.permission?.spoc === true))[0] ||
              null;
          }
          const updatedFilterAttendee = {
            ...filterAttendee,
            bootcampIds: bootcampFilter,
            ...(isMentor && !isSpoc && !isHr && { showMentees: true }),
            ...(isReviewer && !isSpoc && !isHr && { showReviewees: true }),
            ...(isPresenter && !isSpoc && !isHr && { showAttendees: true }),
          };
          setInitialFilterValues(updatedFilterAttendee);
          setFilterAttendee(updatedFilterAttendee);
          const filterCount = calculateFilterCount(updatedFilterAttendee);
          await dispatch(
            getAttendees({
              sessionId: param?.sessionId,
              offset,
              sortBy: sortBy,
              sortOrder: orderBy,
              bootcampIds: [bootcampFilter?.id],
              ...(isMentor && !isSpoc && !isHr && { showMentees: true }),
              ...(isReviewer && !isSpoc && !isHr && { showReviewees: true }),
              ...(isPresenter && !isSpoc && !isHr && { showAttendees: true }),
              bootcampId: bootcampId,
            })
          );
          await dispatch(countFilter(filterCount));
          setIsLoading(false);
          fetchedData();
          setInitialFilterApplied(true);
        } else {
          const data = {
            ...filterAttendee,
            sessionId: param?.sessionId,
            sortBy: sortBy,
            sortOrder: orderBy,
            offset,
            competencyIds: filterAttendee?.competencyIds?.map((e) => e?.id),
            bootcampIds: filterAttendee?.bootcampIds?.id,
            ...(isMentor &&
              !isSpoc &&
              !isHr && { showMentees: filterAttendee?.showMentees }),
            ...(isReviewer &&
              !isSpoc &&
              !isHr && { showReviewees: filterAttendee?.showReviewees }),
            ...(isPresenter &&
              !isSpoc &&
              !isHr && { showAttendees: filterAttendee?.showAttendees }),
            bootcampId: bootcampId,
            status: filterAttendee?.status?.key,
          };
          await dispatch(getAttendees(data));
        }
      }
    };
    fetchData();
  }, [dispatch, orderBy, offset, sortBy]);

  useEffect(() => {
    return () => {
      dispatch(clearAttendees());
      setFilterAttendee(initialFilterAttendee);
    };
  }, [dispatch]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalResult / 20));
  }, [totalResult]);

  ///////////////////////////////////////////////////////////////////////////

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(""); // To track selected option
  const open = Boolean(anchorEl);

  const handleSelectAllClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectAllChange = (optionKey) => {
    setFilterData({ filterAttendee, offset, sortBy, orderBy });
    if (selectedOption === optionKey) {
      setSelectedOption("");
      setCheckAll(false);
    } else {
      setSelectedOption(optionKey);
      setCheckAll(true);
    }

    if (
      (selectedOption && selectedOption !== optionKey) ||
      (!selectedOption &&
        (optionKey === "NOT_MARKED" || optionKey === "SELECT_ALL"))
    ) {
      const newCheckedRows = [
        ...(optionKey === "NOT_MARKED"
          ? notMarkedAttendeesList
          : attendeesList || []
        ).filter((row, index) => {
          const batchName = row?.attendee?.batch?.name
            ? row?.attendee?.batch?.name
            : null;
          const isBatchPresenter = isBatchAndPresenterMatch(batchName);
          const showAttendanceDropDown =
            (isSpoc || isBatchPresenter) &&
            moduleStatusIsOngoingOrCompleted &&
            !isBootcampCompleted;
          return !(
            row?.attendee?.attendee?.attendee?.deleted ||
            row?.attendee?.deleted ||
            row?.attendee?.status === DROPPED ||
            row?.attendee?.status === SEPARATED ||
            row?.attendee?.attendee?.status === DROPPED ||
            row?.attendee?.attendee?.status === SEPARATED ||
            !showAttendanceDropDown
          );
        }),
      ];
      setCheckedRows(newCheckedRows);
    } else {
      setCheckedRows((prevCheckedRows) =>
        prevCheckedRows.filter(
          (checked) =>
            !(attendeesList || []).some(
              (attendee) => attendee.id === checked.id
            )
        )
      );
    }
    handleClose(); // Close the menu after selection
  };

  const [isCheckAll, setCheckAll] = useState(false);
  useEffect(() => {
    fetchedData();
  }, [checkedRows]);
  ///////////////////////////////////////////////////////////////////////////
  const fetchedData = async () => {
    const listToCheck =
      selectedOption === "NOT_MARKED" ? notMarkedAttendeesList : attendeesList;
    let checkAll =
      listToCheck?.length > 0 &&
      listToCheck
        ?.filter((row) => {
          const batchName = row?.attendee?.batch?.name
            ? row?.attendee?.batch?.name
            : null;
          const isBatchPresenter = isBatchAndPresenterMatch(batchName);
          const showAttendanceDropDown =
            (isSpoc || isBatchPresenter) &&
            moduleStatusIsOngoingOrCompleted &&
            !isBootcampCompleted;
          return !(
            row?.attendee?.attendee?.attendee?.deleted ||
            row?.attendee?.deleted ||
            row?.attendee?.status === DROPPED ||
            row?.attendee?.status === SEPARATED ||
            row?.attendee?.attendee?.status === DROPPED ||
            row?.attendee?.attendee?.status === SEPARATED ||
            !showAttendanceDropDown
          );
        })
        ?.every((attendee) =>
          checkedRows?.map((it) => it?.id).includes(attendee?.id)
        );
    if (!checkAll) {
      setCheckAll(false);
      setSelectedOption("");
    }
  };

  ///////////////////////////////////// To check batch and presenter //////////////////////////////////////////
  const isBatchAndPresenterMatch = (batchName) => {
    return (presenters ?? []).some(
      (presenter) =>
        presenter?.batch?.name === batchName &&
        (presenter?.leadPresenter?.id === userId ||
          presenter?.coPresenter?.id === userId)
    );
  };
  ///////////////////////////////////////////////////////////////////////////////

  if ((loading && isLoading) || attendanceLoading || loading) return <Loader />;

  return (
    <>
      {attendeesList?.length > 0 ? (
        <Box
          className="flex-container-column"
          sx={{ justifyContent: "space-between" }}
        >
          <TableContainer
            className="table-container"
            component={Paper}
            elevation={1}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead className="table-head-container">
                <TableRow className="table-head-row">
                  {!isHr && !isRestrictedUser && !isBootcampCompleted && (
                    <TableCell
                      sx={{ minWidth: "3rem" }}
                      align="center"
                      className="padding-left-1"
                    >
                      <Checkbox
                        color="secondary"
                        size="small"
                        checked={isCheckAll}
                        onChange={handleSelectAllClick}
                      />
                      <Menu
                        color="secondary"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        sx={{
                          "& .MuiMenu-paper": {
                            maxWidth: "none !important",
                            width: "10.438rem",
                          },
                        }}
                      >
                        {selectionOptions?.map((option, index) => (
                          <MenuItem
                            selected={selectedOption === option?.key}
                            onClick={() => handleSelectAllChange(option?.key)}
                            key={index}
                          >
                            <Typography variant="font_12">
                              {option?.value}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Menu>
                    </TableCell>
                  )}

                  <TableCell
                    sx={{ minWidth: "10rem" }}
                    align="left"
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Attendee</Box>
                      <Box className="table-sort-icon">
                        <IconButtons
                          height={
                            sortBy === "attendeeName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "attendeeName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          image={up_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("ASC", "attendeeName")
                          }
                          disabled={
                            sortBy === "attendeeName" && orderBy === "ASC"
                          }
                          tooltip={sortInAscending}
                        />
                        <IconButtons
                          height={
                            sortBy === "attendeeName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "attendeeName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          image={down_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("DESC", "attendeeName")
                          }
                          disabled={
                            sortBy === "attendeeName" && orderBy === "DESC"
                          }
                          tooltip={sortInDescending}
                        />
                      </Box>
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ minWidth: "10rem" }}
                    align="left"
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Mentor</Box>
                      <Box className="table-sort-icon">
                        <IconButtons
                          height={
                            sortBy === "leadMentorName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "leadMentorName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          image={up_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("ASC", "leadMentorName")
                          }
                          disabled={
                            sortBy === "leadMentorName" && orderBy === "ASC"
                          }
                          tooltip={sortInAscending}
                        />
                        <IconButtons
                          height={
                            sortBy === "leadMentorName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "leadMentorName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          image={down_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("DESC", "leadMentorName")
                          }
                          disabled={
                            sortBy === "leadMentorName" && orderBy === "DESC"
                          }
                          tooltip={sortInDescending}
                        />
                      </Box>
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ minWidth: "10rem" }}
                    align="left"
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Co-Mentor</Box>
                      <Box className="table-sort-icon">
                        <IconButtons
                          height={
                            sortBy === "coMentorName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "coMentorName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          image={up_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("ASC", "coMentorName")
                          }
                          disabled={
                            sortBy === "coMentorName" && orderBy === "ASC"
                          }
                          tooltip={sortInAscending}
                        />
                        <IconButtons
                          height={
                            sortBy === "coMentorName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "coMentorName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          image={down_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("DESC", "coMentorName")
                          }
                          disabled={
                            sortBy === "coMentorName" && orderBy === "DESC"
                          }
                          tooltip={sortInDescending}
                        />
                      </Box>
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ minWidth: "8rem" }}
                    align="left"
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Batch</Box>
                      <Box className="table-sort-icon">
                        <IconButtons
                          height={
                            sortBy === "batchName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "batchName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          image={up_sort_arrow_icon}
                          handleClick={() => handleSorting("ASC", "batchName")}
                          disabled={sortBy === "batchName" && orderBy === "ASC"}
                          tooltip={sortInAscending}
                        />
                        <IconButtons
                          height={
                            sortBy === "batchName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "batchName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          image={down_sort_arrow_icon}
                          handleClick={() => handleSorting("DESC", "batchName")}
                          disabled={
                            sortBy === "batchName" && orderBy === "DESC"
                          }
                          tooltip={sortInDescending}
                        />
                      </Box>
                    </Typography>
                  </TableCell>
                  {isHr && (
                    <TableCell
                      align="left"
                      sx={{ minWidth: "13rem" }}
                      className="padding-left-1"
                    >
                      <Typography
                        variant="font_13_bold"
                        className="flex-table-sort-head"
                      >
                        <Box className="table-sort-head">Bootcamp</Box>
                        <Box className="table-sort-icon">
                          <IconButtons
                            height={font_9}
                            width={font_9}
                            image={up_sort_arrow_icon}
                            handleClick={() =>
                              handleSorting("ASC", "bootcampName")
                            }
                            disabled={
                              sortBy === "bootcampName" && orderBy === "ASC"
                            }
                            tooltip={sortInAscending}
                          />
                          <IconButtons
                            height={font_9}
                            width={font_9}
                            image={down_sort_arrow_icon}
                            handleClick={() =>
                              handleSorting("DESC", "bootcampName")
                            }
                            disabled={
                              sortBy === "bootcampName" && orderBy === "DESC"
                            }
                            tooltip={sortInDescending}
                          />
                        </Box>
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell
                    sx={{ minWidth: "7rem" }}
                    align="left"
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head"
                    >
                      <Box className="table-sort-head">Attendance</Box>
                      <Box className="table-sort-icon">
                        <IconButtons
                          height={
                            sortBy === "status" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "status" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          image={up_sort_arrow_icon}
                          handleClick={() => handleSorting("ASC", "status")}
                          disabled={sortBy === "status" && orderBy === "ASC"}
                          tooltip={sortInAscending}
                        />
                        <IconButtons
                          height={
                            sortBy === "status" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "status" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          image={down_sort_arrow_icon}
                          handleClick={() => handleSorting("DESC", "status")}
                          disabled={sortBy === "status" && orderBy === "DESC"}
                          tooltip={sortInDescending}
                        />
                      </Box>
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{ minWidth: "8rem" }}
                    align="left"
                    className="padding-left-1"
                  >
                    <Typography
                      variant="font_13_bold"
                      className="flex-table-sort-head padding-right-1"
                    >
                      <Box className="table-sort-head">Competency</Box>
                      <Box className="table-sort-icon">
                        <IconButtons
                          height={
                            sortBy === "competencyName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "competencyName" && orderBy === "ASC"
                              ? null
                              : font_9
                          }
                          image={up_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("ASC", "competencyName")
                          }
                          disabled={
                            sortBy === "competencyName" && orderBy === "ASC"
                          }
                          tooltip={sortInAscending}
                        />
                        <IconButtons
                          height={
                            sortBy === "competencyName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          width={
                            sortBy === "competencyName" && orderBy === "DESC"
                              ? null
                              : font_9
                          }
                          image={down_sort_arrow_icon}
                          handleClick={() =>
                            handleSorting("DESC", "competencyName")
                          }
                          disabled={
                            sortBy === "competencyName" && orderBy === "DESC"
                          }
                          tooltip={sortInDescending}
                        />
                      </Box>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attendeesList?.map((attendee, index) => {
                  const attendeeName =
                    attendee?.attendee?.attendee?.attendee?.fullName;
                  const attendeePicture =
                    attendee?.attendee?.attendee?.attendee?.profilePictureUrl;
                  const attendeeId =
                    attendee?.attendee?.attendee?.attendee?.employeeCode;
                  const attendeeEmail =
                    attendee?.attendee?.attendee?.attendee?.email;
                  const attendeeCompetency =
                    attendee?.attendee?.attendee?.attendee?.competency?.name;
                  const leadMentor = !attendee?.attendee?.leadMentor
                    ? attendee?.attendee?.attendee?.leadMentor
                    : attendee?.attendee?.leadMentor;
                  const coMentor = !attendee?.attendee?.coMentor
                    ? attendee?.attendee?.attendee?.coMentor
                    : attendee?.attendee?.coMentor;

                  const moduleMentor = attendee?.attendee?.leadMentor;
                  const bootcampMentor =
                    attendee?.attendee?.attendee?.leadMentor;
                  const moduleCoMentor = attendee?.attendee?.coMentor;
                  const bootcampCoMentor =
                    attendee?.attendee?.attendee?.coMentor;

                  const batchName = attendee?.attendee?.batch?.name
                    ? attendee?.attendee?.batch?.name
                    : null;
                  const isBatchPresenter = isBatchAndPresenterMatch(batchName);
                  const showAttendanceDropDown =
                    (isSpoc || isBatchPresenter) &&
                    moduleStatusIsOngoingOrCompleted &&
                    !isBootcampCompleted;
                  const handleCheckbox = (event) => {
                    handleCheckboxChange(attendee, event.target.checked, {
                      filterAttendee,
                      offset,
                      sortBy,
                      orderBy,
                    });
                  };

                  const isChecked = checkedRows
                    ?.map((it) => it?.id)
                    .includes(attendee?.id);
                  const userDeleted =
                    attendee?.attendee?.attendee?.attendee?.deleted ||
                    attendee?.attendee?.deleted;
                  const userDropped =
                    attendee?.attendee?.status === DROPPED ||
                    attendee?.attendee?.attendee?.status === DROPPED;
                  const userSeperated =
                    attendee?.attendee?.status === SEPARATED ||
                    attendee?.attendee?.attendee?.status === SEPARATED;
                  const statusUpdatedDate =
                    attendee?.attendee?.statusUpdatedDate ||
                    attendee?.attendee?.attendee?.statusUpdatedDate;
                  const status =
                    attendee?.attendee?.status === DROPPED ||
                    attendee?.attendee?.status === SEPARATED
                      ? attendee?.attendee?.status
                      : attendee?.attendee?.attendee?.status === DROPPED ||
                        attendee?.attendee?.attendee?.status === SEPARATED
                      ? attendee?.attendee?.attendee?.status
                      : null;
                  return (
                    <TableRow key={index} className="table-body-row">
                      {!isHr && !isRestrictedUser && !isBootcampCompleted && (
                        <TableCell align="center" className="padding-left-1">
                          <Checkbox
                            color="secondary"
                            size="small"
                            checked={isChecked}
                            onChange={handleCheckbox}
                            disabled={
                              userSeperated ||
                              userDropped ||
                              userDeleted ||
                              !showAttendanceDropDown
                            }
                          />
                        </TableCell>
                      )}
                      <TableCell align="left" className="padding-left-1">
                        <UserTableAvatar
                          imgAlt={attendeeName}
                          imgSrc={attendeePicture}
                          href={attendeeId}
                          label={attendeeName}
                          subLabel={attendeeEmail}
                          deleted={userSeperated || userDropped || userDeleted}
                          tooltip={
                            userDropped || userSeperated
                              ? NON_ACTIVE_ATTENDEE(
                                  getStatus(attendeeStatuses, status),
                                  dayjs(statusUpdatedDate)
                                    ?.tz(tz)
                                    ?.format("DD MMM YYYY")
                                )
                              : null
                          }
                        />
                      </TableCell>
                      <TableCell align="left" className="padding-left-1">
                        <UserModuleBootcamp
                          module={moduleMentor}
                          moduleId={moduleId}
                          bootcamp={bootcampMentor}
                        />
                      </TableCell>

                      <TableCell align="left" className="padding-left-1">
                        <UserModuleBootcamp
                          module={moduleCoMentor}
                          moduleId={moduleId}
                          bootcamp={bootcampCoMentor}
                        />
                      </TableCell>
                      <TableCell align="left" className="padding-left-1">
                        <Typography variant="font_12">
                          {batchName || "-"}
                        </Typography>
                      </TableCell>
                      {isHr && (
                        <TableCell align="left" className="padding-left-1">
                          <BootstrapTooltip title="View Bootcamp">
                            <Typography variant="font_12" color="secondary">
                              <Link
                                to={`/`}
                                state={{
                                  propsToPass: {
                                    bootcampName:
                                      attendee?.attendee?.attendee?.bootcamp
                                        ?.name,
                                    bootcampId:
                                      attendee?.attendee?.attendee?.bootcamp
                                        ?.id,
                                  },
                                }}
                              >
                                <u>
                                  {attendee?.attendee?.attendee?.bootcamp
                                    ?.name || "-"}
                                </u>
                              </Link>
                            </Typography>
                          </BootstrapTooltip>
                        </TableCell>
                      )}
                      <TableCell align="left" className="padding-left-1">
                        {showAttendanceDropDown &&
                        !userSeperated &&
                        !userDropped &&
                        !userDeleted ? (
                          <TextField
                            fullWidth
                            select
                            size="small"
                            defaultValue="Absent"
                            variant="outlined"
                            color="secondary"
                            margin="none"
                            className="attendance-input"
                            sx={{
                              "& .MuiTypography-root": {
                                color:
                                  attendee?.status === "NOT_MARKED"
                                    ? "#D5CDDE"
                                    : null,
                              },
                            }}
                            onChange={(e) =>
                              handleAttendance(
                                e?.target?.value,
                                attendee?.attendee?.id
                              )
                            }
                            value={attendee?.status}
                          >
                            {attendance?.map((status) => {
                              return (
                                <MenuItem
                                  key={status?.id}
                                  value={status?.value}
                                  disabled={status?.value === "NOT_MARKED"}
                                >
                                  <Typography variant="font_12">
                                    {status?.title}
                                  </Typography>
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        ) : (
                          <Typography variant="font_12" align="left">
                            <Chip
                              label={
                                attendance?.filter(
                                  (status) => status?.value === attendee?.status
                                )[0]?.title
                              }
                              variant={
                                attendance?.filter(
                                  (status) => status?.value === attendee?.status
                                )[0]?.variant + "-FULL-WIDTH"
                              }
                            />
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="left" className="padding-left-1">
                        <Typography variant="font_12">
                          {attendeeCompetency}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container className="table-pagination-container">
            <Typography variant="font_16">
              Total {totalResult} Records
            </Typography>
            <TablePagination
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              currentPage={currentPage}
            />
          </Grid>
        </Box>
      ) : (
        <Grid container className="no-data-container tabs-no-data">
          <NoDataAvailable
            imgHeight={4}
            imgWidth={4.5}
            message="No Attendees Available"
          />
        </Grid>
      )}
      <FilterAttendeesTab
        open={filterOpen}
        onClose={handleFilterClose}
        handleFilterAttendeeSubmit={handleFilterAttendeeSubmit}
        handleFilterAttendeeRemove={handleFilterAttendeeRemove}
        handleChangeFilterAttendee={handleChangeFilterAttendee}
        filterAttendee={filterAttendee}
        competency={competency}
        bootcamp={bootcampList}
        batches={batches}
        apiLoading={apiLoading}
        isMentor={isMentor}
        isHr={isHr}
        isSpoc={isSpoc}
        isPresenter={isPresenter}
        isReviewer={isReviewer}
        permissions={permission}
      />
    </>
  );
};

export default AttendeesTab;
