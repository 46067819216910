import React, { useState } from "react";
import { Avatar, Box, Link, Typography } from "@mui/material";
import { getNewersWorldProfileUrl, isValidString } from "../../utils/utils";
import ImageModal from "../ImageModal";
import "./userTableAvatar.scss";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";
const UserTableAvatar = ({
  imgAlt,
  imgSrc,
  href,
  deleted,
  label,
  subLabel,
  tooltip,
}) => {
  const [imageOpen, setImageOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const handleOpen = (imgUrl) => {
    if (isValidString(imgUrl)) {
      setImageUrl(imgUrl);
      setImageOpen(true);
    } else {
      handleClose();
    }
  };
  const handleClose = () => {
    setImageUrl(null);
    setImageOpen(false);
  };

  return (
    <>
      {label && label !== "undefined" ? (
        <Box className="user-cell">
          <Avatar
            alt={imgAlt}
            className="user-avatar"
            src={imgSrc ? imgSrc : label}
            onClick={() => handleOpen(imgSrc)}
          />

          <Box className="user-cell-info">
            <Link
              href={deleted ? null : getNewersWorldProfileUrl(href)}
              target={"_blank"}
              rel="noopener noreferrer"
              color="inherit"
              sx={{
                textDecoration: "none",
                "&:hover": { textDecoration: "none" },
              }}
            >
              <BootstrapTooltip title={tooltip ? tooltip : null}>
                <Typography
                  variant="font_12"
                  sx={{ color: deleted ? "red" : null }}
                >
                  {label}
                </Typography>
              </BootstrapTooltip>
            </Link>
            <Typography
              variant="grayEmail"
              sx={{ color: deleted ? "red" : null }}
            >
              {subLabel}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Typography variant="font_12">-</Typography>
      )}
      <ImageModal
        open={imageOpen}
        handleClose={handleClose}
        imageUrl={imageUrl}
      />
    </>
  );
};

export default UserTableAvatar;
