import React, { useEffect, useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import calendarIcon from "../../assets/icons/calendar.svg";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { isInIST } from "../../utils/utils";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);
const tz = "Asia/Kolkata";

export const CalendarIcon = () => {
  return <img src={calendarIcon} alt="calendar-icon"></img>;
};

const DateTimePickerComponent = ({
  label,
  value,
  onChange,
  views,
  error,
  helperText,
  required,
  disablePast,
  minDate,
  maxDate,
  classList,
  format,
  disabled,
  disableHighlightToday,
  viewRenderers,
  closeOnSelect,
  enableClear,
  shouldDisableDate,
  defaultValue,
  minTime,
  selectedSections,
  minDateTime,
  maxDateTime,
}) => {
  const isIST = isInIST();
  const [open, setOpen] = useState(false);
  const [dateInIST, setDateInIST] = useState(null);
  const holidaysList = useSelector(
    (state) => state?.utility?.constants?.holidays
  );
  const isHoliday = (date) => {
    return holidaysList?.some((holiday) =>
      dayjs(date)?.isBetween(holiday?.start, holiday?.end, null, "[]")
    );
  };

  useEffect(() => {
    if (value && !isIST) {
      const containsHoursAndMinutes = ["hours", "minutes"].every((unit) =>
        views?.includes(unit)
      );

      let formattedDate;
      if (containsHoursAndMinutes) {
        formattedDate = dayjs(value)?.tz(tz)?.format("DD/MM/YYYY hh:mm A");
      } else {
        formattedDate = dayjs(value)?.tz(tz)?.format("DD/MM/YYYY");
      }
      setDateInIST(formattedDate);
    }
  }, [value]);

  return (
    <>
      <DateTimePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        disableToolbar
        orientation="portrait"
        ampm={true}
        value={value}
        onChange={onChange}
        label={label}
        format={format}
        formatDensity="dense"
        views={views}
        className={`width-100 ${classList}`}
        disablePast={disablePast}
        minDate={minDate}
        maxDate={maxDate}
        minDateTime={minDateTime}
        maxDateTime={maxDateTime}
        closeOnSelect={closeOnSelect}
        showTime={{ user12hours: true }}
        disableHighlightToday={disableHighlightToday}
        viewRenderers={viewRenderers}
        disabled={disabled}
        slots={{ openPickerIcon: CalendarIcon }}
        sx={{
          "& .MuiIconButton-root": {
            padding: 0,
          },
        }}
        slotProps={{
          textField: {
            label: label,
            variant: "outlined",
            color: "secondary",
            size: "small",
            margin: "dense",
            required: required,
            error: error,
            helperText: helperText
              ? helperText
              : value && dateInIST
              ? `${label} in IST: ${dateInIST}`
              : "",
            readOnly: true,
            disabled: disabled,
            onClick: () => setOpen(disabled ? false : true),
          },
          openPickerIcon: {
            onClick: () => {
              setOpen(disabled ? false : true);
            },
          },
          actionBar: { actions: [enableClear && "clear", "accept"] },
          popper: {
            placement: "left",
          },
        }}
        shouldDisableDate={(date) => {
          return (
            (shouldDisableDate && shouldDisableDate(date)) || isHoliday(date)
          );
        }}
        defaultValue={defaultValue}
        minTime={minTime}
        selectedSections={selectedSections}
      />
    </>
  );
};

export default DateTimePickerComponent;
