import React from "react";
import {
  Avatar,
  Box,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import IconButtons from "../../components/IconButtons/IconButtons";
import TablePagination from "../../components/TablePagination/TablePagination";
import {
  down_sort_arrow_icon,
  up_sort_arrow_icon,
} from "../../constants/icons";
import "../Exercises/exercisesTable.scss";
import ExerciseActions from "./ExerciseActions";
import {
  attendanceStatusChip,
  exerciseStatusChip,
  font_9,
  getStatus,
} from "../../utils/utils";
import UserTableAvatar from "../../components/UserTableAvatar/UserTableAvatar";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import {
  AttendeeStatus,
  HelpTexts,
  sortInAscending,
  sortInDescending,
} from "../../constants/constants";
import UserModuleBootcamp from "../../components/UserModuleBootcamp/UserModuleBootcamp";
import { Link } from "react-router-dom";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);
const tz = "Asia/Kolkata";

const { DROPPED, SEPARATED } = AttendeeStatus;
const { NON_ACTIVE_ATTENDEE } = HelpTexts;

const ExercisesTable = ({
  getExercisesData,
  totalPages,
  handlePageChange,
  currentPage,
  isAttendee,
  handleSorting,
  handleCommentOpen,
  handleSubmitOpen,
  submitReviewOpen,
  handleEditReviewModalOpen,
  handleReopenModalOpen,
  sortBy,
  order,
  isBootcampCompleted,
  handleEditExerciseTabOpen,
}) => {
  const security = useSelector((state) => state?.security);
  const attendeeStatuses = useSelector(
    (state) => state?.utility?.constants?.attendeeStatuses
  );
  const userId = security?.loggedInUser?.id;
  return (
    <>
      <TableContainer className="table-container exercises-table-container">
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ minWidth: "100%" }}
        >
          <TableHead className="table-head-container">
            <TableRow className="table-head-row">
              {!isAttendee ? (
                <TableCell align="left" sx={{ minWidth: "10rem" }}>
                  <Typography
                    variant="font_13_bold"
                    className="flex-table-sort-head"
                  >
                    <Box className="table-sort-head">Submitted By</Box>
                    <Box className="table-sort-icon">
                      <IconButtons
                        height={
                          order === "ASC" && sortBy === "attendeeName"
                            ? null
                            : font_9
                        }
                        width={
                          order === "ASC" && sortBy === "attendeeName"
                            ? null
                            : font_9
                        }
                        image={up_sort_arrow_icon}
                        handleClick={() => handleSorting("ASC", "attendeeName")}
                        disabled={order === "ASC" && sortBy === "attendeeName"}
                        tooltip={sortInAscending}
                      />
                      <IconButtons
                        height={
                          order === "DESC" && sortBy === "attendeeName"
                            ? null
                            : font_9
                        }
                        width={
                          order === "DESC" && sortBy === "attendeeName"
                            ? null
                            : font_9
                        }
                        image={down_sort_arrow_icon}
                        handleClick={() =>
                          handleSorting("DESC", "attendeeName")
                        }
                        disabled={order === "DESC" && sortBy === "attendeeName"}
                        tooltip={sortInDescending}
                      />
                    </Box>
                  </Typography>
                </TableCell>
              ) : null}
              <TableCell align="left">
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                  sx={{ minWidth: "10rem" }}
                >
                  <Box className="table-sort-head">Session</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        order === "ASC" && sortBy === "sessionName"
                          ? null
                          : font_9
                      }
                      width={
                        order === "ASC" && sortBy === "sessionName"
                          ? null
                          : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "sessionName")}
                      disabled={order === "ASC" && sortBy === "sessionName"}
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        order === "DESC" && sortBy === "sessionName"
                          ? null
                          : font_9
                      }
                      width={
                        order === "DESC" && sortBy === "sessionName"
                          ? null
                          : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() => handleSorting("DESC", "sessionName")}
                      disabled={order === "DESC" && sortBy === "sessionName"}
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "10rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Module</Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "10rem" }}
                className="padding-left-1"
              >
                <Typography sx={{ minWidth: "10rem" }} variant="font_13_bold">
                  Bootcamp
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "8rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                  sx={{ minWidth: "10rem" }}
                >
                  <Box className="table-sort-head">Mentor</Box>
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "8rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                  sx={{ minWidth: "10rem" }}
                >
                  <Box className="table-sort-head">Co-Mentor</Box>
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "8rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                  sx={{ minWidth: "6rem" }}
                >
                  <Box className="table-sort-head">Attendance</Box>
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "8rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Status</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        order === "ASC" && sortBy === "status" ? null : font_9
                      }
                      width={
                        order === "ASC" && sortBy === "status" ? null : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "status")}
                      disabled={order === "ASC" && sortBy === "status"}
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        order === "DESC" && sortBy === "status" ? null : font_9
                      }
                      width={
                        order === "DESC" && sortBy === "status" ? null : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() => handleSorting("DESC", "status")}
                      disabled={order === "DESC" && sortBy === "status"}
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>
              <TableCell sx={{ minWidth: "8rem" }} className="padding-left-1">
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Score</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        order === "ASC" && sortBy === "score" ? null : font_9
                      }
                      width={
                        order === "ASC" && sortBy === "score" ? null : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "score")}
                      disabled={order === "ASC" && sortBy === "score"}
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        order === "DESC" && sortBy === "score" ? null : font_9
                      }
                      width={
                        order === "DESC" && sortBy === "score" ? null : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() => handleSorting("DESC", "score")}
                      disabled={order === "DESC" && sortBy === "score"}
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>

              <TableCell
                sx={{ minWidth: "8rem" }}
                align="center"
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Start Date</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        order === "ASC" && sortBy === "sessionStartDate"
                          ? null
                          : font_9
                      }
                      width={
                        order === "ASC" && sortBy === "sessionStartDate"
                          ? null
                          : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() =>
                        handleSorting("ASC", "sessionStartDate")
                      }
                      disabled={
                        order === "ASC" && sortBy === "sessionStartDate"
                      }
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        order === "DESC" && sortBy === "sessionStartDate"
                          ? null
                          : font_9
                      }
                      width={
                        order === "DESC" && sortBy === "sessionStartDate"
                          ? null
                          : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() =>
                        handleSorting("DESC", "sessionStartDate")
                      }
                      disabled={
                        order === "DESC" && sortBy === "sessionStartDate"
                      }
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{ minWidth: "8rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Due Date</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        order === "ASC" &&
                        sortBy === "exerciseSubmissionDueDate"
                          ? null
                          : font_9
                      }
                      width={
                        order === "ASC" &&
                        sortBy === "exerciseSubmissionDueDate"
                          ? null
                          : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() =>
                        handleSorting("ASC", "exerciseSubmissionDueDate")
                      }
                      disabled={
                        order === "ASC" &&
                        sortBy === "exerciseSubmissionDueDate"
                      }
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        order === "DESC" &&
                        sortBy === "exerciseSubmissionDueDate"
                          ? null
                          : font_9
                      }
                      width={
                        order === "DESC" &&
                        sortBy === "exerciseSubmissionDueDate"
                          ? null
                          : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() =>
                        handleSorting("DESC", "exerciseSubmissionDueDate")
                      }
                      disabled={
                        order === "DESC" &&
                        sortBy === "exerciseSubmissionDueDate"
                      }
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "10rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Submission Date</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        order === "ASC" && sortBy === "dateSubmitted"
                          ? null
                          : font_9
                      }
                      width={
                        order === "ASC" && sortBy === "dateSubmitted"
                          ? null
                          : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "dateSubmitted")}
                      disabled={order === "ASC" && sortBy === "dateSubmitted"}
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        order === "DESC" && sortBy === "dateSubmitted"
                          ? null
                          : font_9
                      }
                      width={
                        order === "DESC" && sortBy === "dateSubmitted"
                          ? null
                          : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() => handleSorting("DESC", "dateSubmitted")}
                      disabled={order === "DESC" && sortBy === "dateSubmitted"}
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "8rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Review Date</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        order === "ASC" && sortBy === "dateReviewed"
                          ? null
                          : font_9
                      }
                      width={
                        order === "ASC" && sortBy === "dateReviewed"
                          ? null
                          : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "dateReviewed")}
                      disabled={order === "ASC" && sortBy === "dateReviewed"}
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        order === "DESC" && sortBy === "dateReviewed"
                          ? null
                          : font_9
                      }
                      width={
                        order === "DESC" && sortBy === "dateReviewed"
                          ? null
                          : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() => handleSorting("DESC", "dateReviewed")}
                      disabled={order === "DESC" && sortBy === "dateReviewed"}
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>

              <TableCell
                align="left"
                sx={{ minWidth: "10rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Reviewer</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        order === "ASC" && sortBy === "reviewerName"
                          ? null
                          : font_9
                      }
                      width={
                        order === "ASC" && sortBy === "reviewerName"
                          ? null
                          : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "reviewerName")}
                      disabled={order === "ASC" && sortBy === "reviewerName"}
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        order === "DESC" && sortBy === "reviewerName"
                          ? null
                          : font_9
                      }
                      width={
                        order === "DESC" && sortBy === "reviewerName"
                          ? null
                          : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() => handleSorting("DESC", "reviewerName")}
                      disabled={order === "DESC" && sortBy === "reviewerName"}
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>

              <TableCell sx={{ minWidth: "8rem" }}>
                <Typography variant="font_13_bold" className="padding-right-2">
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getExercisesData?.data?.results?.map((exercise, index) => {
              const bootcampName = exercise?.bootcamp?.name;
              const attendanceStatus = exercise?.attendanceStatus;
              const exerciseId = exercise?.exercise?.id;
              const sessionName = exercise?.exercise?.session?.name;
              const sessionPermission = exercise?.exercise?.session?.permission;
              const dueDate =
                exercise?.exercise?.session?.exerciseSubmissionDueDate;
              const maxExerciseScore =
                exercise?.exercise?.session?.maxExerciseScore;

              const attendee = exercise?.exercise?.attendee?.attendee?.attendee;
              const moduleMentor = exercise?.exercise?.attendee?.leadMentor;
              const bootcampMentor =
                exercise?.exercise?.attendee?.attendee?.leadMentor;
              const moduleCoMentor = exercise?.exercise?.attendee?.coMentor;
              const bootcampCoMentor =
                exercise?.exercise?.attendee?.attendee?.coMentor;
              const moduleId = exercise?.module?.id;

              const reviewer = exercise?.exercise?.reviewer;
              const submittedDate = exercise?.exercise?.dateSubmitted;
              const reviewedDate = exercise?.exercise?.dateReviewed;
              const score = exercise?.exercise?.score;
              const status = exercise?.exercise?.status;
              const attendeeDeleted =
                attendee?.deleted || exercise?.exercise?.attendee?.deleted;
              const userDropped =
                exercise?.exercise?.attendee?.status === DROPPED ||
                exercise?.exercise?.attendee?.attendee?.status === DROPPED;
              const userSeperated =
                exercise?.exercise?.attendee?.status === SEPARATED ||
                exercise?.exercise?.attendee?.attendee?.status === SEPARATED;
              const statusUpdatedDate =
                exercise?.exercise?.attendee?.statusUpdatedDate ||
                exercise?.exercise?.attendee?.attendee?.statusUpdatedDate;
              const deletedStatus =
                exercise?.exercise?.attendee?.status === DROPPED ||
                exercise?.exercise?.attendee?.status === SEPARATED
                  ? exercise?.exercise?.attendee?.status
                  : exercise?.exercise?.attendee?.attendee?.status ===
                      DROPPED ||
                    exercise?.exercise?.attendee?.attendee?.status === SEPARATED
                  ? exercise?.exercise?.attendee?.attendee?.status
                  : null;
              const moduleName = exercise?.module?.name || "-";
              const moduleStatus = exercise?.module?.status;
              const exerciseStatus = exerciseStatusChip(status);
              const attendanceChip = attendanceStatusChip(attendanceStatus);
              const isSessionSpoc = sessionPermission?.spoc === true;
              const isAddCommentAllowed = !(
                userSeperated ||
                userDropped ||
                attendeeDeleted
              );

              return (
                <TableRow className="table-body-row" key={index}>
                  {!isAttendee ? (
                    <TableCell className="padding-left-1">
                      <UserTableAvatar
                        imgAlt={attendee?.fullName}
                        imgSrc={attendee?.profilePictureUrl}
                        href={attendee?.employeeCode}
                        label={attendee?.fullName}
                        subLabel={attendee?.email}
                        deleted={
                          userSeperated || userDropped || attendeeDeleted
                        }
                        tooltip={
                          userDropped || userSeperated
                            ? NON_ACTIVE_ATTENDEE(
                                getStatus(attendeeStatuses, deletedStatus),
                                dayjs(statusUpdatedDate)
                                  ?.tz(tz)
                                  ?.format("DD MMM YYYY")
                              )
                            : null
                        }
                      />
                    </TableCell>
                  ) : null}
                  <TableCell className="padding-left-1">
                    <BootstrapTooltip title="View Session">
                      <Typography variant="font_12" color="secondary">
                        <Link
                          to={`/bootcamp/${exercise?.bootcamp?.id}/module/${exercise?.module?.id}/sessions/${exercise?.exercise?.session?.id}`}
                        >
                          <u>{sessionName}</u>
                        </Link>
                      </Typography>
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell align="left" className="padding-left-1">
                    <BootstrapTooltip title="View Module">
                      <Typography variant="font_12" color="secondary">
                        <Link
                          to={`/bootcamp/${exercise?.bootcamp?.id}/module/${exercise?.module?.id}`}
                        >
                          <u>{moduleName}</u>
                        </Link>
                      </Typography>
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell className="padding-left-1">
                    <BootstrapTooltip title="View Bootcamp">
                      <Typography variant="font_12" color="secondary">
                        <Link
                          to={`/`}
                          state={{
                            propsToPass: {
                              bootcampName: bootcampName,
                              bootcampId: exercise?.bootcamp?.id,
                            },
                          }}
                        >
                          <u>{bootcampName}</u>
                        </Link>
                      </Typography>
                    </BootstrapTooltip>
                  </TableCell>
                  <TableCell className="padding-left-1">
                    <UserModuleBootcamp
                      module={moduleMentor}
                      moduleId={moduleId}
                      bootcamp={bootcampMentor}
                    />
                  </TableCell>
                  <TableCell className="padding-left-1">
                    <UserModuleBootcamp
                      module={moduleCoMentor}
                      moduleId={moduleId}
                      bootcamp={bootcampCoMentor}
                    />
                  </TableCell>
                  <TableCell align="left" className="padding-left-1">
                    {attendanceStatus ? (
                      <Chip
                        className="same-size-chip"
                        label={attendanceChip?.name}
                        variant={attendanceChip?.theme}
                      />
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell className="padding-left-1">
                    <Chip
                      className="same-size-chip"
                      label={exerciseStatus?.name}
                      variant={exerciseStatus?.theme}
                    />
                  </TableCell>
                  <TableCell className="padding-left-1">
                    <Typography
                      variant="font_12"
                      color={score ? "secondary" : null}
                    >
                      {score ? (
                        <>
                          {score}/{maxExerciseScore}
                        </>
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell className="padding-left-1">
                    <Typography variant="font_12">
                      {dayjs(exercise?.exercise?.session?.startDate)?.format(
                        "DD/MM/YYYY"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell className="padding-left-1" l>
                    <Typography variant="font_12">
                      {dayjs(dueDate)?.format("DD/MM/YYYY")}
                    </Typography>
                  </TableCell>
                  <TableCell className="padding-left-1">
                    <Typography variant="font_12">
                      {submittedDate
                        ? dayjs(submittedDate)?.format("DD/MM/YYYY")
                        : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell align="left" className="padding-left-1">
                    <Typography variant="font_12">
                      {reviewedDate
                        ? dayjs(reviewedDate)?.format("DD/MM/YYYY")
                        : "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <UserTableAvatar
                      imgAlt={reviewer?.fullName}
                      imgSrc={reviewer?.profilePictureUrl}
                      href={reviewer?.employeeCode}
                      label={reviewer?.fullName}
                      subLabel={reviewer?.email}
                      deleted={reviewer?.deleted}
                    />
                  </TableCell>
                  <TableCell>
                    <Box>
                      <ExerciseActions
                        status={status}
                        exercise={exercise}
                        isAttendee={isAttendee}
                        handleCommentOpen={(exerciseId) =>
                          handleCommentOpen(
                            exerciseId,
                            isSessionSpoc,
                            isAddCommentAllowed
                          )
                        }
                        handleSubmitOpen={() =>
                          handleSubmitOpen({ exerciseId, score: +score })
                        }
                        submitReviewOpen={() =>
                          submitReviewOpen({ exerciseId, maxExerciseScore })
                        }
                        handleEditReviewModalOpen={() =>
                          handleEditReviewModalOpen({
                            exerciseId,
                            score: +score,
                            maxExerciseScore,
                          })
                        }
                        handleReopenModalOpen={() =>
                          handleReopenModalOpen({ exerciseId })
                        }
                        exerciseId={exerciseId}
                        moduleStatus={moduleStatus}
                        attendeeDeleted={attendeeDeleted}
                        userSeperated={userSeperated}
                        userDropped={userDropped}
                        isBootcampCompleted={isBootcampCompleted}
                        handleEditExerciseTabOpen={handleEditExerciseTabOpen}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container className="table-pagination-container">
        <Typography variant="font_16">
          Total {getExercisesData?.data?.totalResults} Records
        </Typography>
        <TablePagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
        />
      </Grid>
    </>
  );
};

export default ExercisesTable;
