import React from "react";
import "./userDrawer.scss";
import {
  Avatar,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Link,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { cross_default_icon } from "../../constants/icons";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../store/security";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";
import { getNewersWorldProfileUrl } from "../../utils/utils";

const UserDrawer = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const getUserData = useSelector((state) => state?.security?.loggedInUser);
  const userData = {
    "Newer ID": getUserData?.employeeCode,
    "Business Unit": getUserData?.businessUnit?.name,
    Competency: getUserData?.competency?.name,
    Mobile: getUserData?.mobileNumber,
    Email: getUserData?.email,
  };
  return (
    <Drawer
      className="user-drawer"
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <Grid container className="user-drawer-container">
        <Box>
          <Grid item md={12} className="user-drawer-avatar-card">
            <IconButton className="close-icon-container" onClick={onClose}>
              <BootstrapTooltip title="Close Info">
                <Box
                  className="close-drawer-icon"
                  sx={{ backgroundImage: `url(${cross_default_icon})` }}
                ></Box>
              </BootstrapTooltip>
            </IconButton>
            <Link
              target="_blank"
              href={getNewersWorldProfileUrl(getUserData?.employeeCode)}
            >
              <Avatar
                src={getUserData?.profilePictureUrl}
                alt={getUserData?.fullName}
                className="card-avatar"
              />
            </Link>
            <Typography
              variant="font_16_bold_700"
              className="text-bold"
              mb={0.5}
            >
              {getUserData?.fullName}
            </Typography>
            <Typography variant="font_14" mb={1}>
              {getUserData?.designation?.name}
            </Typography>
          </Grid>

          <TableContainer className="user-drawer-table-container">
            <Table size="medium">
              {Object.entries(userData)?.map(([key, value], index) => (
                <TableRow key={index}>
                  <TableCell className="user-drawer-table-cells">
                    <Typography variant="font_14">{key}</Typography>
                  </TableCell>
                  <TableCell className="user-drawer-table-cells" align="left">
                    <Typography variant="font_14_bold">{value}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
        </Box>
        <Button
          className="drawer-logout-btn"
          variant="contained"
          onClick={() => dispatch(logoutUser())}
        >
          <Typography variant="font_12_bold"></Typography>
          Log Out
        </Button>
      </Grid>
    </Drawer>
  );
};

export default UserDrawer;
