import { apiVersion } from "../constants/constants";
import ApiService from "../utils/api-service";

export function getUsersApi(data) {
  let apiUrl = `${apiVersion}/users?max=20`;

  if (data?.offset !== undefined) {
    apiUrl += `&offset=${data.offset * 20}`;
  }

  if (
    data?.nameOrEmail !== null &&
    data?.nameOrEmail !== undefined &&
    data?.nameOrEmail !== ""
  ) {
    apiUrl += `&nameOrEmail=${data.nameOrEmail}`;
  }

  if (data?.BU !== null && data?.BU !== undefined) {
    apiUrl += `&businessUnitId=${data.BU}`;
  }

  if (data?.competency !== null && data?.competency !== undefined) {
    apiUrl += `&competencyIds=${data.competency}`;
  }

  if (
    data?.designation !== null &&
    data?.designation !== undefined &&
    data?.designation?.length
  ) {
    apiUrl += `&designationIds=${data.designation}`;
  }

  if (
    data?.reportingManagerNameOrEmail !== null &&
    data?.reportingManagerNameOrEmail !== undefined &&
    data?.reportingManagerNameOrEmail !== ""
  ) {
    apiUrl += `&reportingManagerNameOrEmail=${data.reportingManagerNameOrEmail}`;
  }

  if (
    data?.mentorNameOrEmail !== null &&
    data?.mentorNameOrEmail !== undefined &&
    data?.mentorNameOrEmail !== ""
  ) {
    apiUrl += `&mentorNameOrEmail=${data.mentorNameOrEmail}`;
  }

  if (
    data?.roles?.length &&
    data?.roles !== null &&
    data?.roles !== undefined
  ) {
    apiUrl += `&roles=${data.roles}`;
  }

  if (data?.sortBy) {
    apiUrl += `&sortBy=${data?.sortBy}`;
  }

  if (data?.sortOrder) {
    apiUrl += `&sortOrder=${data?.sortOrder}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };

  return ApiService(options);
}

export function getCompetenciesApi(data) {
  const options = {
    method: "GET",
    url: `${apiVersion}/competencies`,
    data: data,
  };
  return ApiService(options);
}

export function getDesignationApi(data) {
  const options = {
    method: "GET",
    url: `${apiVersion}/designations?competencyId=${data}`,
    data: data,
  };
  return ApiService(options);
}

export function deleteUserRoleApi(data) {
  const options = {
    method: "DELETE",
    url: `${apiVersion}/users/${data.userId}/roles/${data.roleName}`,
    data: data,
  };
  return ApiService(options);
}

export function addUserRoleApi(data) {
  const options = {
    method: "POST",
    url: `${apiVersion}/users/${data.userId}/roles/${data.roleName}`,
    data: data,
  };
  return ApiService(options);
}

export function syncDataApi(data) {
  const options = {
    method: "GET",
    url: `${apiVersion}/nw/middleware/dataSync`,
    data: data,
  };
  return ApiService(options);
}

export function getBussinessUnitsApi(data) {
  const options = {
    method: "GET",
    url: `${apiVersion}/businessUnits`,
    data: data,
  };
  return ApiService(options);
}


export function getFiltersForUsersApi() {
  const options = {
    method: "GET",
    url: `${apiVersion}/utilities/filtersForUsersListing`,
  };
  return ApiService(options);
}