import React, { useEffect, useState } from "react";
import "./home.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from "@mui/material";
import SimpleAutoComplete from "../../components/SimpleAutoComplete/SimpleAutoComplete";
import Loader from "../../components/Loader/Loader";
import dayjs from "dayjs";

const CloneModule = ({
  open,
  handleClose,
  handleSubmit,
  cloneModuleDetails,
  handleCloneModuleChange,
  competencyList,
  loading,
}) => {
  return (
    <>
      {loading && <Loader />}
      <Dialog
        open={open}
        onClose={handleClose}
        className="modal-drawer-container"
        PaperProps={{
          style: {
            borderRadius: "1rem",
            boxShadow: "0rem 0.6rem 3rem 0rem #OD",
            width: "27.125rem",
            zIndex: 1300,
            borderRadius: "1rem",
            margin: 0,
          },
        }}
      >
        <DialogTitle className="dialog-title">Clone Module</DialogTitle>
        <DialogContent className="dialog-content">
          <Box className="width-100">
            <SimpleAutoComplete
              label="Competency"
              placeholder="Select Competency"
              options={competencyList}
              onChange={(event, newValue) =>
                handleCloneModuleChange("selectedCompetency", newValue)
              }
              getOptionLabel={(option) => option?.name}
              value={cloneModuleDetails?.selectedCompetency}
              disableClearable={true}
              renderOption={(props, option, value) => {
                return (
                  <li
                    {...props}
                    key={props?.id}
                    className={
                      option?.id === cloneModuleDetails?.selectedCompetency?.id
                        ? "multi-tag-edit-list-selected"
                        : "multi-tag-style"
                    }
                  >
                    <Box lineHeight={1.1}>
                      <Box>{`${option?.name}`}</Box>
                    </Box>
                  </li>
                );
              }}
            />
            <SimpleAutoComplete
              label="Bootcamp"
              placeholder="Select Bootcamp"
              options={
                cloneModuleDetails?.selectedCompetency?.bootcamps?.results
              }
              onChange={(event, newValue) =>
                handleCloneModuleChange("selectedBootcamp", newValue)
              }
              getOptionLabel={(option) => option?.name}
              value={cloneModuleDetails?.selectedBootcamp}
              renderOption={(props, option, value) => {
                return (
                  <li
                    {...props}
                    key={props?.id}
                    className={
                      option?.id === cloneModuleDetails?.selectedCompetency?.id
                        ? "multi-tag-edit-list-selected"
                        : "multi-tag-style"
                    }
                  >
                    <Box lineHeight={1.3}>
                      <Box>{option?.name}</Box>
                      <Box>
                        {option?.startDate
                          ? dayjs(option?.startDate)?.format("DD/MM/YYYY")
                          : null}
                      </Box>
                    </Box>
                  </li>
                );
              }}
              disabled={!cloneModuleDetails?.selectedCompetency}
            />

            <SimpleAutoComplete
              label="Module"
              placeholder="Select Module"
              options={cloneModuleDetails?.selectedBootcamp?.modules?.results}
              onChange={(event, newValue) =>
                handleCloneModuleChange("selectedModule", newValue)
              }
              getOptionLabel={(option) => option?.name}
              value={cloneModuleDetails?.selectedModule}
              renderOption={(props, option, value) => {
                return (
                  <li
                    {...props}
                    key={props?.id}
                    className={
                      option?.id === cloneModuleDetails?.selectedCompetency?.id
                        ? "multi-tag-edit-list-selected"
                        : "multi-tag-style"
                    }
                  >
                    <Box lineHeight={1.1}>
                      <Box>{option?.name}</Box>
                      <Box>
                        {option?.startDate
                          ? dayjs(option?.startDate)?.format("DD/MM/YYYY")
                          : null}
                      </Box>
                    </Box>
                  </li>
                );
              }}
              disabled={!cloneModuleDetails?.selectedBootcamp}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "0 2rem 1.875rem" }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="secondary"
            className="add-role-btn"
          >
            <Typography variant="outlineBtnLabel">Cancel</Typography>
          </Button>
          <Button
            onClick={() => handleSubmit(cloneModuleDetails?.selectedModule)}
            variant="contained"
            color="secondary"
            className="add-role-btn"
            disabled={!cloneModuleDetails?.selectedModule}
          >
            <Typography variant="outlineBtnLabel">Clone</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CloneModule;
