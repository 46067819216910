import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  activateInactivateAssessmentApi,
  deleteAssessmentApi,
  fetchAssessmentListingApi,
  getBootcampForAssessmentApi,
  getModuleForAssessmentApi,
} from "../services/assessmentListing";
import CustomToaster from "../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../constants/constants";
import { toast } from "react-hot-toast";

const initialState = {
  assessmentListingData: [],
  bootcampData: null,
  moduleData: null,
  loading: false,
  error: null,
  assessmentListingLoader: false,
  activateInactivateAssessmentLoader: false,
  bootcampDataLoader: false,
  getModuleLoader: false,
};

export const setAssessmentListingData = createAsyncThunk(
  "setAssessmentListingData",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await fetchAssessmentListingApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const activateInactivateAssessment = createAsyncThunk(
  "activateInactivateAssessment",
  async (data, { rejectWithValue }) => {
    const { id, active } = data;
    try {
      const callApi = await activateInactivateAssessmentApi(id);
      toast.custom(
        <CustomToaster
          message={
            active
              ? "Assessment Inactivated Successfully"
              : "Assessment Activated Successfully"
          }
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAssessment = createAsyncThunk(
  "activateInactivateAssessment",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await deleteAssessmentApi(data);
      toast.custom(
        <CustomToaster
          message="Assessment Deleted Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBootcampForAssessment = createAsyncThunk(
  "getBootcampForAssessment",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getBootcampForAssessmentApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getModuleForAssessment = createAsyncThunk(
  "getModuleForAssessment",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getModuleForAssessmentApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const assessmentListing = createSlice({
  name: "assessmentListing",
  initialState,
  reducers: {
    clearassessmentListingData(state) {
      state.assessmentListingData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setAssessmentListingData.pending, (state) => {
        state.assessmentListingLoader = true;
      })
      .addCase(setAssessmentListingData.fulfilled, (state, action) => {
        state.assessmentListingLoader = false;
        state.assessmentListingData = action.payload;
      })
      .addCase(setAssessmentListingData.rejected, (state, action) => {
        state.assessmentListingLoader = false;
        state.error = action.payload;
      })
      .addCase(activateInactivateAssessment.pending, (state) => {
        state.activateInactivateAssessmentLoader = true;
      })
      .addCase(activateInactivateAssessment.fulfilled, (state, action) => {
        state.activateInactivateAssessmentLoader = false;
      })
      .addCase(activateInactivateAssessment.rejected, (state, action) => {
        state.activateInactivateAssessmentLoader = false;
        state.error = action.payload;
      })
      .addCase(getBootcampForAssessment.pending, (state) => {
        state.bootcampDataLoader = true;
      })
      .addCase(getBootcampForAssessment.fulfilled, (state, action) => {
        state.bootcampDataLoader = false;
        state.bootcampData = action.payload;
      })
      .addCase(getBootcampForAssessment.rejected, (state, action) => {
        state.bootcampDataLoader = false;
        state.error = action.payload;
      })
      .addCase(getModuleForAssessment.pending, (state) => {
        state.getModuleLoader = true;
      })
      .addCase(getModuleForAssessment.fulfilled, (state, action) => {
        state.getModuleLoader = false;
        state.moduleData = action.payload;
      })
      .addCase(getModuleForAssessment.rejected, (state, action) => {
        state.getModuleLoader = false;
        state.error = action.payload;
      });
  },
});
export const { clearassessmentListingData } = assessmentListing.actions;
export default assessmentListing.reducer;
