import React from "react";
import {
  Box,
  Button,
  Drawer,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { cross_default_icon } from "../../constants/icons";
import IconButtons from "../../components/IconButtons/IconButtons";
import SimpleAutoComplete from "../../components/SimpleAutoComplete/SimpleAutoComplete";
import dayjs from "dayjs";
import { ROLE_TYPES, font_15 } from "../../utils/utils";
import DateTimePickerComponent from "../../components/DateTimePickerComponent/DateTimePickerComponent";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";

const SessionFilter = ({
  open,
  onClose,
  filterSession,
  handleChangeFilterSession,
  handleFilterSessionSubmit,
  handleFilterSessionRemove,
  filterOptions,
  filterLoading,
}) => {
  return (
    <>
      {filterLoading && <Loader />}
      <Drawer
        className="filter-drawer-container"
        anchor="right"
        open={open}
        onClose={onClose}
      >
        <Grid container className="filter-main-container">
          <Grid item md={12} pt={2} className="drawer-input-container">
            <Box className="filter-head">
              <Typography variant="font_20" className="filter-title ">
                Filters
              </Typography>
              <IconButtons
                  handleClick={onClose}
                  height={font_15}
                  width={font_15}
                  image={cross_default_icon}
                  tooltip="Close Filter"
              />
            </Box>
            <Box className="filter-padding textfield-box">
              <TextField
                size="small"
                label="Session Name"
                color="secondary"
                fullWidth
                className="filter-inputs"
                margin="dense"
                variant="outlined"
                value={filterSession?.name}
                onChange={(e) =>
                  handleChangeFilterSession("name", e?.target?.value)
                }
              />
              <Box mb={1} mt={1} className="width-100">
                <DateTimePickerComponent
                  label="Starts On/After"
                  format="DD/MM/YYYY"
                  onChange={(e) => handleChangeFilterSession("startDate", e)}
                  value={filterSession?.startDate}
                  views={["year", "day"]}
                  maxDate={filterSession?.endDate}
                  error={false}
                  enableClear={true}
                  closeOnSelect={true}
                  viewRenderers={{
                    hours: null,
                    minutes: null,
                    seconds: null,
                  }}
                />
              </Box>
              <Box mb={1} mt={1} className="width-100">
                <DateTimePickerComponent
                  label="Starts On/before"
                  format="DD/MM/YYYY"
                  onChange={(e) => handleChangeFilterSession("endDate", e)}
                  value={filterSession?.endDate}
                  views={["year", "day"]}
                  error={false}
                  minDate={filterSession?.startDate}
                  enableClear={true}
                  closeOnSelect={true}
                  viewRenderers={{
                    hours: null,
                    minutes: null,
                    seconds: null,
                  }}
                />
              </Box>
              <Box mb={1} mt={1} className="width-100">
                <SimpleAutoComplete
                  label="Bootcamp"
                  placeholder="Select Bootcamp"
                  options={filterOptions?.bootcampIds}
                  onChange={(event, newValue) =>
                    handleChangeFilterSession("bootcampId", newValue)
                  }
                  getOptionLabel={(option) => option?.name}
                  value={filterSession?.bootcampId}
                  renderOption={(props, option, value) => {
                    return (
                      <li
                        {...props}
                        key={props?.id}
                        className={
                          option?.id === filterSession?.bootcampId?.id
                            ? "multi-tag-edit-list-selected"
                            : "multi-tag-style"
                        }
                      >
                        <Box lineHeight={1.1}>
                          <Box>{`${option?.name}`}</Box>
                        </Box>
                      </li>
                    );
                  }}
                  tooltipTitle={filterSession?.bootcampId?.name}
                />
              </Box>
              <Box mb={1} mt={1} className="width-100">
                <SimpleAutoComplete
                  label="Module"
                  placeholder="Select Module"
                  disabled={!filterSession?.bootcampId}
                  options={
                    filterOptions.bootcampIds?.find(
                      (e) => e?.id === filterSession?.bootcampId?.id
                    )?.modules?.results
                  }
                  onChange={(event, newValue) =>
                    handleChangeFilterSession("moduleId", newValue)
                  }
                  getOptionLabel={(option) => option?.name}
                  value={filterSession?.moduleId}
                  renderOption={(props, option, value) => {
                    return (
                      <li
                        {...props}
                        key={props?.id}
                        className={
                          option?.id === filterSession?.moduleId?.id
                            ? "multi-tag-edit-list-selected"
                            : "multi-tag-style"
                        }
                      >
                        <Box lineHeight={1.1}>
                          <Box>{`${option?.name}`}</Box>
                        </Box>
                      </li>
                    );
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={12} className="filter-padding filter-btn-container">
            <Button
              onClick={handleFilterSessionSubmit}
              variant="contained"
              color="secondary"
              className="filter-btn margin-right-1"
            >
              <Typography variant="font_12_bold_700">Apply Filters</Typography>
            </Button>

            <Button
              onClick={handleFilterSessionRemove}
              variant="outlined"
              color="secondary"
              className="filter-btn"
            >
              <Typography variant="font_12_bold_700">Reset</Typography>
            </Button>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};

export default SessionFilter;
