import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import {
  addAttendeeApi,
  deleteAttendeeApi,
  deleteModuleAttendeeApi,
  getAllAttendeesApi,
  getAllModuleAttendeesApi,
  getAttendeesApi,
  getBatchesApi,
  getBootcampApi,
  getCompetenciesApi,
  getDesignationApi,
  getFiltersForAttendeesApi,
  getOptionsForCreateAttendeeApi,
  getOptionsForEditAttendeeApi,
  getModuleAttendeesApi,
  getUsersApi,
  getUsersForAddAttendeeApi,
  updateAttendeeApi,
  updateModuleAttendeeApi,
} from "../../services/AttendeesPage/attendees";
import CustomToaster from "../../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../../constants/constants";

const initialState = {
  allAttendee: [],
  attendee: [],
  users: [],
  usersForEditAttendee: [],
  competencies: [],
  designation: [],
  loading: false,
  userLoading: false,
  btnLoading: false,
  error: null,
  batches: [],
  bootcamp: null,
  optionsForEditAttendee: null,
  optionsForCreateAttendee: null,
  filtersForAttendees: null,
  moduleAttendee : []
};

export const getAllAttendees = createAsyncThunk(
  "getAllAttendees",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getAllAttendeesApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAttendees = createAsyncThunk(
  "getAttendees",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getAttendeesApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUsers = createAsyncThunk(
  "getUsers",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getUsersApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUsersForEditAttendee = createAsyncThunk(
  "getUsersForEditAttendee",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getUsersForAddAttendeeApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAttendee = createAsyncThunk(
  "updateAttendee",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await updateAttendeeApi(data);
      toast.custom(
        <CustomToaster
          message="Attendee Updated Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCompetencies = createAsyncThunk(
  "getCompetencies",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getCompetenciesApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDesignation = createAsyncThunk(
  "getDesignation",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getDesignationApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAttendee = createAsyncThunk(
  "deleteAttendee",
  async ({ attendeeId }, { rejectWithValue }) => {
    try {
      const callApi = await deleteAttendeeApi(attendeeId);
      toast.custom(
        <CustomToaster
          message="Attendee Deleted Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addAttendee = createAsyncThunk(
  "addAttendee",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await addAttendeeApi(data);
      toast.custom(
        <CustomToaster
          message="Attendee Added Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBatches = createAsyncThunk(
  "getBatches",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getBatchesApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBootcamp = createAsyncThunk(
  "getBootcamp",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getBootcampApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getModuleAttendees = createAsyncThunk(
  "getModuleAttendees",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getModuleAttendeesApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteModuleAttendee = createAsyncThunk(
  "deleteModuleAttendee",
  async ({ attendeeId }, { rejectWithValue }) => {
    try {
      const callApi = await deleteModuleAttendeeApi(attendeeId);
      toast.custom(
        <CustomToaster
          message="Attendee Deleted"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateModuleAttendee = createAsyncThunk(
  "updateModuleAttendee",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await updateModuleAttendeeApi(data);
      toast.custom(
        <CustomToaster
          message="Attendee Updated"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getOptionsForCreateAttendee = createAsyncThunk(
  "getOptionsForCreateAttendee",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getOptionsForCreateAttendeeApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getOptionsForEditAttendee = createAsyncThunk(
  "getOptionsForEditAttendeeApi",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getOptionsForEditAttendeeApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFiltersForAttendees = createAsyncThunk(
  "getFiltersForAttendees",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getFiltersForAttendeesApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const attendees = createSlice({
  name: "attendees",
  initialState,
  reducers: {
    clearBatches(state) {
      state.batches = [];
    },
    clearAttendees(state) {
      state.attendee = [];
    },
    clearOptions(state) {
      state.optionsForCreateAttendee = null;
      state.optionsForEditAttendee = null;
    },
    clearFilters(state) {
      state.filtersForAttendees = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllAttendees.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllAttendees.fulfilled, (state, action) => {
        state.loading = false;
        state.allAttendee = action.payload;
      })
      .addCase(getAllAttendees.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAttendees.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAttendees.fulfilled, (state, action) => {
        state.loading = false;
        state.attendee = action.payload;
      })
      .addCase(getAttendees.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getUsers.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.userLoading = false;
        state.users = action.payload;
      })
      .addCase(getUsers.rejected, (state) => {
        state.userLoading = false;
      })
      .addCase(getUsersForEditAttendee.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(getUsersForEditAttendee.fulfilled, (state, action) => {
        state.userLoading = false;
        state.usersForEditAttendee = action.payload;
      })
      .addCase(getUsersForEditAttendee.rejected, (state) => {
        state.userLoading = false;
      })
      .addCase(updateAttendee.pending, (state) => {
        state.btnLoading = true;
      })
      .addCase(updateAttendee.fulfilled, (state) => {
        state.btnLoading = false;
      })
      .addCase(updateAttendee.rejected, (state) => {
        state.btnLoading = false;
      })
      .addCase(getCompetencies.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(getCompetencies.fulfilled, (state, action) => {
        state.userLoading = false;
        state.competencies = action.payload;
      })
      .addCase(getCompetencies.rejected, (state) => {
        state.userLoading = false;
      })
      .addCase(getDesignation.pending, (state) => {
        state.designationLoading = true;
      })
      .addCase(getDesignation.fulfilled, (state, action) => {
        state.designationLoading = false;
        state.designation = action.payload;
      })
      .addCase(getDesignation.rejected, (state) => {
        state.designationLoading = false;
      })
      .addCase(addAttendee.pending, (state) => {
        state.btnLoading = true;
      })
      .addCase(addAttendee.fulfilled, (state) => {
        state.btnLoading = false;
      })
      .addCase(addAttendee.rejected, (state) => {
        state.btnLoading = false;
      })
      .addCase(getBatches.pending, (state) => {
        state.btnLoading = true;
      })
      .addCase(getBatches.fulfilled, (state, action) => {
        state.btnLoading = false;
        state.batches = action.payload;
      })
      .addCase(getBatches.rejected, (state) => {
        state.btnLoading = false;
      })
      .addCase(getBootcamp.pending, (state) => {
        state.btnLoading = true;
      })
      .addCase(getBootcamp.fulfilled, (state, action) => {
        state.btnLoading = false;
        state.bootcamp = action.payload;
      })
      .addCase(getBootcamp.rejected, (state) => {
        state.btnLoading = false;
      })
      .addCase(getModuleAttendees.pending, (state) => {
        state.loading = true;
      })
      .addCase(getModuleAttendees.fulfilled, (state, action) => {
        state.loading = false;
        state.moduleAttendee = action.payload;
      })
      .addCase(getModuleAttendees.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getOptionsForCreateAttendee.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(getOptionsForCreateAttendee.fulfilled, (state, action) => {
        state.userLoading = false;
        state.optionsForCreateAttendee = action.payload;
      })
      .addCase(getOptionsForCreateAttendee.rejected, (state) => {
        state.userLoading = false;
      })
      .addCase(getOptionsForEditAttendee.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(getOptionsForEditAttendee.fulfilled, (state, action) => {
        state.userLoading = false;
        state.optionsForEditAttendee = action.payload;
      })
      .addCase(getOptionsForEditAttendee.rejected, (state) => {
        state.userLoading = false;
      })
      .addCase(getFiltersForAttendees.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(getFiltersForAttendees.fulfilled, (state, action) => {
        state.userLoading = false;
        state.filtersForAttendees = action.payload;
      })
      .addCase(getFiltersForAttendees.rejected, (state) => {
        state.userLoading = false;
      });
  },
});

export const { clearBatches, clearAttendees, clearOptions, clearFilters } =
  attendees.actions;
export default attendees.reducer;
