import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import {
  circle_save_change_icon,
  cross_red_icon,
  edit_active_icon,
} from "../../constants/icons";
import SimpleAutoComplete from "./../../components/SimpleAutoComplete/SimpleAutoComplete";
import Loader from "../../components/Loader/Loader";
import IconButtons from "../../components/IconButtons/IconButtons";
import {
  checkSpecialChar,
  font_13_icon,
  isValidUrlFormat,
} from "../../utils/utils";
import { useSelector } from "react-redux";
import { HelpTexts } from "../../constants/constants";

const { ENTER_VALID_URL } = HelpTexts;

const BatchEdit = ({
  open,
  onClose,
  handleBatchChange,
  batchDetails,
  handleBatchSubmit,
  errors,
  batchApiError,
  roomsList,
  roomLoading,
  editBatchName,
  handleEditBatchNameToggle,
  handleEditBatchNameCancel,
  handleEditBatchNameAccept,
  batchNameLengthError,
}) => {
  const [initialBatchDetails, setInitialBatchDetails] = useState(batchDetails);

  useEffect(() => {
    setInitialBatchDetails(batchDetails);
  }, [batchDetails?.batchId]);

  const compareFormFields = (initial, current) => {
    const fieldsToCheck = ["name", "mode", "meetingUrl", "roomId"];

    return fieldsToCheck.some((field) => {
      if (field == "name") {
        return initial[field].trim() !== current[field].trim();
      } else {
        return initial[field] !== current[field];
      }
    });
  };

  const batchDetailsChanged = compareFormFields(
    initialBatchDetails,
    batchDetails
  );

  const modeOptions = useSelector(
    (state) => state?.utility?.constants?.bootcampModes
  );
  return (
    <>
      {roomLoading && <Loader />}
      <Dialog
        className="modal-drawer-container"
        open={open}
        onClose={onClose}
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "1rem",
            boxShadow: "0rem 0.6rem 3rem 0rem #OD",
            zIndex: 1300,
            maxWidth: "29.75rem",
          },
        }}
      >
        <DialogTitle className="dialog-title">Edit Batch</DialogTitle>
        <DialogContent className="dialog-content">
          <Box className="width-100">
            {editBatchName ? (
              <Box className="batch-modal-name">
                <TextField
                  label="Name"
                  color="secondary"
                  fullWidth
                  className="filter-inputs"
                  margin="dense"
                  variant="outlined"
                  size="small"
                  required={true}
                  value={batchDetails?.name}
                  onChange={(e) => handleBatchChange("name", e?.target?.value)}
                  error={
                    !batchDetails.name ||
                    checkSpecialChar(batchDetails?.name) ||
                    !!errors?.name ||
                    batchApiError ||
                    batchNameLengthError
                  }
                  helperText={
                    (batchDetails?.name
                      ? checkSpecialChar(batchDetails?.name)
                        ? "Should not contain special Characters"
                        : batchNameLengthError
                        ? "Maximum 50 characters allowed"
                        : ""
                      : "Name is Required") || errors?.name
                  }
                  sx={{ marginRight: "1rem" }}
                />
                <IconButtons
                  tooltip="Save Batch Name"
                  width={font_13_icon}
                  height={font_13_icon}
                  image={circle_save_change_icon}
                  handleClick={() => {
                    if (!checkSpecialChar(batchDetails?.name)) {
                      handleEditBatchNameAccept();
                    }
                  }}
                  classList="padding-right-1"
                />
                <IconButtons
                  tooltip="Cancel Edit Batch Name"
                  width={font_13_icon}
                  height={font_13_icon}
                  image={cross_red_icon}
                  handleClick={handleEditBatchNameCancel}
                />
              </Box>
            ) : (
              <Box className="batch-modal-name" sx={{ marginBottom: "1rem" }}>
                <Typography>{batchDetails?.name}</Typography>
                <IconButtons
                  tooltip="Edit Batch Name"
                  width={font_13_icon}
                  height={font_13_icon}
                  image={edit_active_icon}
                  handleClick={handleEditBatchNameToggle}
                />
              </Box>
            )}
            <SimpleAutoComplete
              label="Mode"
              placeholder="Select Mode"
              options={modeOptions}
              onChange={(event, newValue) => {
                handleBatchChange("mode", newValue);
              }}
              getOptionLabel={(option) => option?.value}
              value={batchDetails?.mode}
              required={true}
              error={!!errors?.mode || batchApiError}
              helperText={errors?.mode}
              renderOption={(props, option, value) => {
                return (
                  <li
                    {...props}
                    key={props?.id}
                    className={
                      option?.value === batchDetails?.mode?.value
                        ? "multi-tag-edit-list-selected"
                        : "multi-tag-style"
                    }
                  >
                    <Box lineHeight={1.1}>
                      <Box>{`${option?.value}`}</Box>
                    </Box>
                  </li>
                );
              }}
            />
            {batchDetails?.mode?.key === "ONLINE" ? (
              <TextField
                label="Meeting URL"
                color="secondary"
                fullWidth
                className="filter-inputs"
                margin="dense"
                variant="outlined"
                size="small"
                required={true}
                value={batchDetails?.meetingUrl}
                onChange={(e) =>
                  handleBatchChange("meetingUrl", e?.target?.value)
                }
                error={
                  (batchDetails?.meetingUrl
                    ? !isValidUrlFormat(batchDetails?.meetingUrl)
                    : false) ||
                  !!errors?.meetingUrl ||
                  batchApiError
                }
                helperText={
                  (batchDetails?.meetingUrl
                    ? !isValidUrlFormat(batchDetails?.meetingUrl) &&
                      ENTER_VALID_URL
                    : false) || errors?.meetingUrl
                }
              />
            ) : null}

            {batchDetails?.mode?.key === "OFFLINE" ? (
              <SimpleAutoComplete
                label="Meeting Room"
                placeholder="Select Room"
                options={roomsList}
                onChange={(event, newValue) => {
                  handleBatchChange("roomId", newValue);
                }}
                getOptionLabel={(option) => option?.name}
                value={batchDetails?.roomId}
                required={true}
                error={!!errors?.roomId || batchApiError}
                helperText={errors?.roomId}
                renderOption={(props, option, value) => {
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        option?.id === batchDetails?.roomId?.id
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box lineHeight={1.1}>
                        <Box>{`${option?.name}`}</Box>
                      </Box>
                    </li>
                  );
                }}
              />
            ) : null}
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "0rem 2rem 1.875rem" }}>
          <Button
            onClick={onClose}
            variant="outlined"
            color="secondary"
            className="block-button"
          >
            <Typography variant="outlineBtnLabel">Cancel</Typography>
          </Button>
          <Button
            onClick={() => {
              if (
                (batchDetails?.meetingUrl
                  ? isValidUrlFormat(batchDetails?.meetingUrl)
                  : true) &&
                batchDetails?.name &&
                !checkSpecialChar(batchDetails?.name)
              ) {
                handleBatchSubmit();
              }
            }}
            variant="contained"
            color="secondary"
            className="block-button"
            disabled={!batchDetailsChanged}
          >
            <Typography variant="outlineBtnLabel">Update</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BatchEdit;
