import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createEvaluationApi,
  fetchEvaluationListingApi,
  getAssessmentByIdOnEvaluationApi,
  getBootcampByIdApi,
  getOptionsForEditEvaluationInterviewerApi,
  sendEvaluationReminderApi,
  updateAttendeeApi,
  updateEvaluationApi,
} from "../services/evaluationListing";
import CustomToaster from "../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../constants/constants";
import { toast } from "react-hot-toast";

const initialState = {
  evaluationListingData: [],
  optionsForEditEvaluationInterviewer: null,
  bootcampData: null,
  assessmentData: null,
  loading: false,
  error: null,
  evaluationListingLoader: false,
  createEvaluationLoader: false,
  sendEvaluationReminderLoading: false,
  getAssessmentLoader: false,
  updateEvaluationLoader: false,
  bootcampDataLoader: false,
  userLoading: false,
  updateAttendeeLoading: false,
};
export const getAssessmentByIdOnEvaluation = createAsyncThunk(
  "getAssessmentByIdOnEvaluation",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getAssessmentByIdOnEvaluationApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const setEvaluationListingData = createAsyncThunk(
  "setEvaluationListingData",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await fetchEvaluationListingApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBootcampById = createAsyncThunk(
  "getBootcampById",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getBootcampByIdApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createEvaluation = createAsyncThunk(
  "createEvaluation",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await createEvaluationApi(data);
      callApi?.data?.results?.length
        ? toast.custom(
            <CustomToaster
              message="Evaluation Created Successfully"
              title={TOAST_TITLE.SUCCESS}
              time={TOAST_TIME.FIVE}
              type={TOAST_TYPE.SUCCESS}
            />
          )
        : toast.custom(
            <CustomToaster
              message="No New Evaluation Created"
              title={TOAST_TITLE.SUCCESS}
              time={TOAST_TIME.FIVE}
              type={TOAST_TYPE.SUCCESS}
            />
          );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateEvaluation = createAsyncThunk(
  "updateEvaluation",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await updateEvaluationApi(data);
      toast.custom(
        <CustomToaster
          message={
            data?.type === "EDIT_EVALUATION"
              ? "Evaluation Edited Successfully"
              : data?.type === "REOPEN"
              ? "Evaluation Reopened Successfully"
              : null
          }
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const sendEvaluationReminder = createAsyncThunk(
  "sendEvaluationReminder",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await sendEvaluationReminderApi(data);
      toast.custom(
        <CustomToaster
          message="Reminder Sent Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getOptionsForEditEvaluationInterviewer = createAsyncThunk(
  "getOptionsForEditEvaluationInterviewer",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getOptionsForEditEvaluationInterviewerApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAttendee = createAsyncThunk(
  "updateAttendee",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await updateAttendeeApi(data);
      toast.custom(
        <CustomToaster
          message="Attendee Updated Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const evaluationListing = createSlice({
  name: "evaluationListing",
  initialState,
  reducers: {
    clearEvaluationListingData(state) {
      state.evaluationListingData = [];
    },
    clearOptions(state) {
      state.optionsForEditEvaluationInterviewer = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setEvaluationListingData.pending, (state) => {
        state.evaluationListingLoader = true;
      })
      .addCase(setEvaluationListingData.fulfilled, (state, action) => {
        state.evaluationListingLoader = false;
        state.evaluationListingData = action.payload;
      })
      .addCase(setEvaluationListingData.rejected, (state, action) => {
        state.evaluationListingLoader = false;
        state.error = action.payload;
      })
      .addCase(createEvaluation.pending, (state) => {
        state.createEvaluationLoader = true;
      })
      .addCase(createEvaluation.fulfilled, (state, action) => {
        state.createEvaluationLoader = false;
      })
      .addCase(createEvaluation.rejected, (state, action) => {
        state.createEvaluationLoader = false;
        state.error = action.payload;
      })
      .addCase(updateEvaluation.pending, (state) => {
        state.updateEvaluationLoader = true;
      })
      .addCase(updateEvaluation.fulfilled, (state, action) => {
        state.updateEvaluationLoader = false;
      })
      .addCase(updateEvaluation.rejected, (state, action) => {
        state.updateEvaluationLoader = false;
        state.error = action.payload;
      })
      .addCase(sendEvaluationReminder.pending, (state) => {
        state.sendEvaluationReminderLoading = true;
      })
      .addCase(sendEvaluationReminder.fulfilled, (state) => {
        state.sendEvaluationReminderLoading = false;
      })
      .addCase(sendEvaluationReminder.rejected, (state, action) => {
        state.sendEvaluationReminderLoading = false;
        state.error = action.payload;
      })
      .addCase(getAssessmentByIdOnEvaluation.pending, (state) => {
        state.getAssessmentLoader = true;
      })
      .addCase(getAssessmentByIdOnEvaluation.fulfilled, (state, action) => {
        state.assessmentData = action.payload;
        state.getAssessmentLoader = false;
      })
      .addCase(getAssessmentByIdOnEvaluation.rejected, (state, action) => {
        state.getAssessmentLoader = false;
        state.error = action.payload;
      })
      .addCase(getBootcampById.pending, (state) => {
        state.bootcampDataLoader = true;
      })
      .addCase(getBootcampById.fulfilled, (state, action) => {
        state.bootcampDataLoader = false;
        state.bootcampData = action.payload;
      })
      .addCase(getBootcampById.rejected, (state, action) => {
        state.bootcampDataLoader = false;
        state.error = action.payload;
      })
      .addCase(updateAttendee.pending, (state) => {
        state.updateAttendeeLoading = true;
      })
      .addCase(updateAttendee.fulfilled, (state) => {
        state.updateAttendeeLoading = false;
      })
      .addCase(updateAttendee.rejected, (state) => {
        state.updateAttendeeLoading = false;
      })
      .addCase(getOptionsForEditEvaluationInterviewer.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(
        getOptionsForEditEvaluationInterviewer.fulfilled,
        (state, action) => {
          state.userLoading = false;
          state.optionsForEditEvaluationInterviewer = action.payload;
        }
      )
      .addCase(getOptionsForEditEvaluationInterviewer.rejected, (state) => {
        state.userLoading = false;
      });
  },
});
export const { clearEvaluationListingData, clearOptions } =
  evaluationListing.actions;
export default evaluationListing.reducer;
