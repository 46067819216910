import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import { coming_soon_icon } from "../../constants/icons";

const CommingSoon = ({ imgWidth, imgHeight, fontSize, message }) => {
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: `${imgWidth}rem`,
            height: `${imgHeight}rem`,
            backgroundImage: `url(${coming_soon_icon})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            marginBottom: "2rem",
          }}
        ></Box>
        <Typography variant="h3" align="center" sx={{ fontWeight: 700 }}>
          {message}
        </Typography>
      </Paper>
    </>
  );
};

export default CommingSoon;
