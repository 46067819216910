import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CustomToaster from "../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../constants/constants";
import { toast } from "react-hot-toast";
import {
  getAssessmentEvaluationByIdApi,
  getBootcampByIdApi,
} from "../services/viewAssessmentEvaluation";

const initialState = {
  assessmentEvaluationData: [],
  bootcampData: null,
  loading: false,
  error: null,
  getAssessmentEvaluationLoader: false,
  createEvaluationLoader: false,
  sendEvaluationReminderLoading: false,
  updateEvaluationLoader: false,
  bootcampDataLoader: false,
};

export const getAssessmentEvaluationById = createAsyncThunk(
  "getAssessmentEvaluationById",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getAssessmentEvaluationByIdApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBootcampById = createAsyncThunk(
  "getBootcampById",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getBootcampByIdApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const viewAssessmentEvaluation = createSlice({
  name: "viewAssessmentEvaluation",
  initialState,
  reducers: {
    clearAssessmentEvaluationData(state) {
      state.assessmentEvaluationData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssessmentEvaluationById.pending, (state) => {
        state.getAssessmentEvaluationLoader = true;
      })
      .addCase(getAssessmentEvaluationById.fulfilled, (state, action) => {
        state.getAssessmentEvaluationLoader = false;
        state.assessmentEvaluationData = action.payload;
      })
      .addCase(getAssessmentEvaluationById.rejected, (state, action) => {
        state.getAssessmentEvaluationLoader = false;
        state.error = action.payload;
      })
      .addCase(getBootcampById.pending, (state) => {
        state.bootcampDataLoader = true;
      })
      .addCase(getBootcampById.fulfilled, (state, action) => {
        state.bootcampDataLoader = false;
        state.bootcampData = action.payload;
      })
      .addCase(getBootcampById.rejected, (state, action) => {
        state.bootcampDataLoader = false;
        state.error = action.payload;
      });
  },
});
export const { clearAssessmentEvaluationData } =
  viewAssessmentEvaluation.actions;
export default viewAssessmentEvaluation.reducer;
