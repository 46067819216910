import React, { useState } from "react";
import IconButtons from "../../../components/IconButtons/IconButtons";
import {
  delete_active_icon,
  delete_disabled_icon,
  disabled_msg_icon,
  edit_active_icon,
  edit_disabled_icon,
  message_active_icon,
} from "../../../constants/icons";
import { checkDateTimeStatus, font_16 } from "../../../utils/utils";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getBatchesList } from "../../../store/emailModal";
import { deleteSession } from "../../../store/SessionView/detailsTab";
import DeleteModal from "./../../../components/DeleteModal/DeleteModal";
import Constants, {
  EventStatus,
  HelpTexts,
} from "../../../constants/constants";

const { ONGOING, PAST } = EventStatus;
const {
  CONCLUDED_BOOTCAMP,
  CONCLUDED_MODULE,
  CONCLUDED_SESSION,
  ACTIVE_SESSION,
  NO_BATCHES,
  NO_PRESENTERS,
} = HelpTexts;
const { COMPLETED } = Constants;

const DetailsActionItems = ({
  handleEditSession,
  emailModalOpen,
  isModuleCompleted,
  currentBootcamp,
}) => {
  const isBootcampCompleted = currentBootcamp?.status === COMPLETED;
  const Details = useSelector((state) => {
    return state?.detailsTab;
  });
  const isSpoc = Details?.details?.data?.permission?.spoc;
  const isPresenter = Details?.details?.data?.permission?.sessionPresenter;
  const disableMsg = Details?.details?.data?.module?.batches?.totalResults;

  const sessionStatus = checkDateTimeStatus(
    Details?.details?.data?.startDate,
    Details?.details?.data?.endDate
  );
  const sessionIsConcluded = sessionStatus === PAST;
  const sessionIsActive = sessionStatus === ONGOING;
  const hasPresenter = Details?.details?.data?.presenters?.results?.some(
    (item) => item?.leadPresenter !== null || item?.coPresenter !== null
  );
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const handleClickEmail = async () => {
    if (params?.moduleId !== undefined) {
      const resultAction = await dispatch(
        getBatchesList({ moduleId: params?.moduleId })
      );
      if (getBatchesList?.fulfilled?.match(resultAction)) {
        const updatedBatches = resultAction?.payload?.data?.results;
        emailModalOpen(Details, updatedBatches);
      }
    }
  };

  //////////////////// redirect /////////////////////////////////
  const viaModule = !!params?.moduleId || false;
  const handleExercise = () => {
    if (viaModule) {
      navigate(
        `/bootcamp/${params?.bootcampId}/module/${params?.moduleId}/sessions`
      );
    } else {
      navigate(`/topic/${params?.topicId}/sessions`);
    }
  };

  /////////// Handle Delete ////////////////////////////////////
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleDeleteModalOpen = (id) => {
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
  };
  const handleDelete = async () => {
    await dispatch(deleteSession(params?.sessionId));
    handleExercise();
    setDeleteModalOpen(false);
  };

  //////////////////////////////////////////////////////////////////////////

  return (
    <>
      <Box className="flex-users">
        {(isSpoc || isPresenter) && (
          <IconButtons
            height={font_16}
            width={font_16}
            classList="margin-right-1"
            image={edit_active_icon}
            disabledImage={edit_disabled_icon}
            handleClick={() => handleEditSession(Details?.details, isSpoc)}
            tooltip="Edit Session"
            disabled={
              isBootcampCompleted || isModuleCompleted || sessionIsConcluded
            }
            disabledTooltip={
              isBootcampCompleted
                ? CONCLUDED_BOOTCAMP
                : isModuleCompleted
                ? CONCLUDED_MODULE
                : sessionIsConcluded
                ? CONCLUDED_SESSION
                : null
            }
          />
        )}
        {isSpoc && (
          <IconButtons
            width={font_16}
            height={font_16}
            image={delete_active_icon}
            disabledImage={delete_disabled_icon}
            handleClick={() => handleDeleteModalOpen(params?.sessionId)}
            tooltip="Delete Session"
            classList="margin-right-1"
            disabled={
              isBootcampCompleted ||
              isModuleCompleted ||
              sessionIsConcluded ||
              sessionIsActive
            }
            disabledTooltip={
              isBootcampCompleted
                ? CONCLUDED_BOOTCAMP
                : isModuleCompleted
                ? CONCLUDED_MODULE
                : sessionIsConcluded
                ? CONCLUDED_SESSION
                : sessionIsActive
                ? ACTIVE_SESSION
                : ""
            }
          />
        )}
        {isSpoc && (
          <IconButtons
            height={font_16}
            width={font_16}
            image={message_active_icon}
            handleClick={handleClickEmail}
            tooltip="Send Email"
            disabled={
              isBootcampCompleted ||
              isModuleCompleted ||
              sessionIsConcluded ||
              sessionIsActive ||
              disableMsg < 1 ||
              !hasPresenter
            }
            disabledImage={disabled_msg_icon}
            disabledTooltip={
              isBootcampCompleted
                ? CONCLUDED_BOOTCAMP
                : isModuleCompleted
                ? CONCLUDED_MODULE
                : sessionIsConcluded
                ? CONCLUDED_SESSION
                : sessionIsActive
                ? ACTIVE_SESSION
                : disableMsg < 1
                ? NO_BATCHES
                : !hasPresenter
                ? NO_PRESENTERS
                : null
            }
          />
        )}
      </Box>

      <DeleteModal
        open={deleteModalOpen}
        handleClose={handleDeleteModalClose}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default DetailsActionItems;
