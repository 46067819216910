import React, { useEffect, useState } from "react";
import "./assessmentMCQ.scss";
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import Timer from "./Timer";
import Question from "./Question";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Constants from "../../constants/constants";
import { updateAssessmentEvaluation } from "../../store/assessmentPlay";
import DeleteModal from "../../components/DeleteModal/DeleteModal";

const { COMPLETED } = Constants;

const ProgressBarContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  height: "1rem",
  borderRadius: "0.25rem",
  backgrounColor: "#e8f5e9",
}));

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "1rem",
  borderRadius: "0.25rem",
  "& .MuiLinearProgress-bar": {
    backgroundColor: "#05B14E",
    borderRadius: "0.25rem",
  },
}));

const ProgressBar = ({ progress }) => {
  return (
    <ProgressBarContainer className="margin-bottom-5">
      <CustomLinearProgress
        variant="determinate"
        className="custom-progressbar"
        value={progress}
      />
      <Box
        className="progress-percentage"
        sx={{
          left: progress ? `calc(${(progress * 50) / 100}%)` : "50%",
          transform: progress
            ? `translate(calc(-${(progress * 50) / 100}%), -0.12rem)`
            : "translate(-50%, -0.15rem)",
        }}
      >
        {progress ? (
          <Typography variant="font_12">{`${Math.round(
            progress
          )}% Completed`}</Typography>
        ) : (
          <Typography variant="font_12" sx={{ color: "#617E6C" }}>
            Assessment Progress
          </Typography>
        )}
      </Box>
    </ProgressBarContainer>
  );
};

const UpperSection = ({
  instruction,
  duration,
  timeLeft,
  isStarted,
  handleTimeUp,
  updatedAssessmentEvaluationStartDate,
  setAttemptAllQuestionModal,
  assessmentEvaluationStatus,
}) => {
  const handleSubmit = () => {
    setAttemptAllQuestionModal(true);
  };
  return (
    <>
      <Box className="margin-bottom-5 instructions">
        <Grid container className="upper-container">
          <Grid item md={!isStarted ? 12 : 6}>
            <Box
              className="instruction-info"
              {...(!isStarted && {
                sx: {
                  borderBottom: "0.125rem solid #d3cfd6",
                  marginBottom: "1.75rem",
                },
              })}
            >
              <Typography variant="font_12" className="grey-text">
                Instructions
              </Typography>
              <Box
                dangerouslySetInnerHTML={{ __html: instruction }}
                sx={{
                  wordBreak: "break-word",
                  whiteSpace: "pre-wrap",
                  fontSize: "0.813rem",
                  fontWeight: 400,
                }}
              />
            </Box>
          </Grid>
          <Grid
            item
            md={!isStarted ? 6 : 2}
            className={`${
              !isStarted
                ? "instruction-details-to-start"
                : "instruction-details"
            } duration-container`}
          >
            <Box className="duration-box">
              <Typography variant="font_12" className="grey-text">
                Duration
              </Typography>
              <Typography
                variant="font_14"
                color="secondary"
                className="duration"
              >
                {duration
                  ? `${duration} ${duration === 1 ? "Minute" : "Minutes"}`
                  : "-"}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            md={!isStarted ? 6 : 4}
            className={`${
              !isStarted ? "instruction-details-to-start" : ""
            } instruction-details countdown-timer`}
          >
            <Timer
              isStarted={isStarted}
              timeLimit={timeLeft || duration}
              onTimeUp={handleTimeUp}
              startTime={updatedAssessmentEvaluationStartDate}
              isCompleted={assessmentEvaluationStatus === COMPLETED}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="margin-bottom-5 mcq-top-details">
        {isStarted && (
          <Button onClick={handleSubmit} variant="contained" color="secondary">
            <Typography variant="font_12_bold_700">Submit</Typography>
          </Button>
        )}
      </Box>
    </>
  );
};

const AssessmentMCQ = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const evaluationId = +params?.evaluationId;
  const evaluation = useSelector((state) => state?.assessmentPlay);
  const assessmentEvaluation = evaluation?.evaluationData?.data;
  const assessmentEvaluationStartDate = assessmentEvaluation?.startDate;
  const updatedAssessmentEvaluation = evaluation?.updatedEvaluationData?.data;
  const updatedAssessmentEvaluationStartDate =
    updatedAssessmentEvaluation?.startDate;
  const assessmentEvaluationStatus = assessmentEvaluation?.status;
  const isAssessmentStarted = !!assessmentEvaluation?.startDate;
  const assessmentDetails = assessmentEvaluation?.assessment;
  const duration = assessmentDetails?.durationInMinutes;
  const instruction = assessmentDetails?.instruction;
  const questions = assessmentEvaluation?.questions?.results;
  const [isStarted, setIsStarted] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isFinalSubmit, setIsFinalSubmit] = useState(false);
  const [isAutoSubmit, setIsAutoSubmit] = useState(false);
  const [responses, setResponses] = useState([]);
  const [timeLeft, setTimeLeft] = useState(0);

  const savedData = questions?.map((question) => {
    const selectedOptionIds = question?.options
      ?.filter((option) => option?.chosenOption === true)
      ?.map((option) => option?.id);

    return {
      questionId: question?.id,
      optionIds: selectedOptionIds,
    };
  });

  //////////////////////// Confirmation on submit ///////////////////////

  const checkIfAllQuestionsAnswered = () => {
    return questions?.every((question) => {
      const response = responses?.find(
        (response) => response?.questionId === question?.id
      );

      return (
        response &&
        Array.isArray(response?.optionIds) &&
        response?.optionIds?.length > 0
      );
    });
  };

  const [attemptAllQuestionModal, setAttemptAllQuestionModal] = useState(false);

  const handleAttemptAllQuestionModalClose = () => {
    setAttemptAllQuestionModal(false);
  };
  const handleAssessmentSubmit = async () => {
    submitQuiz();
    setIsStarted(false);
    setAttemptAllQuestionModal(false);
  };
  //------------------------------

  useEffect(() => {
    if (savedData) {
      const filteredData = savedData?.filter(
        (data) => data?.optionIds?.length > 0
      );
      setResponses(filteredData);
    }
  }, [questions]);

  useEffect(() => {
    if (
      assessmentEvaluationStatus === COMPLETED ||
      isAutoSubmit ||
      isFinalSubmit
    ) {
      const timer = setTimeout(() => {
        window.location.href = "/";
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [assessmentEvaluationStatus, isAutoSubmit, isFinalSubmit]);

  const saveResponse = (questionId, optionId) => {
    let answeredQuestions;
    setResponses((prevResponses) => {
      const existingResponseIndex = prevResponses?.findIndex(
        (response) => response?.questionId === questionId
      );

      let updatedResponses;
      const newOptions = [].concat(optionId); // Ensure optionId is always an array
      if (existingResponseIndex !== -1) {
        updatedResponses = [...prevResponses];
        updatedResponses[existingResponseIndex] = {
          ...updatedResponses[existingResponseIndex],
          optionIds: newOptions,
          removeAnswer: newOptions?.length <= 0,
        };
      } else {
        updatedResponses = [
          ...prevResponses,
          {
            questionId,
            optionIds: newOptions,
            removeAnswer: newOptions?.length <= 0,
          },
        ];
      }
      answeredQuestions = updatedResponses;
      return updatedResponses;
    });
    dispatch(
      updateAssessmentEvaluation({
        evaluationId,
        questions: answeredQuestions,
      })
    );
  };

  const submitQuiz = async (timeOver) => {
    await dispatch(
      updateAssessmentEvaluation({
        evaluationId,
        autoSubmit: timeOver ? true : null,
        finalSubmit: timeOver ? null : true,
        questions: responses,
      })
    );
    if (timeOver) {
      setIsAutoSubmit(true);
    } else {
      setIsFinalSubmit(true);
    }
  };

  const handleTimeUp = (timeOver) => {
    submitQuiz(timeOver);
  };

  const handleStart = () => {
    setIsStarted(true);
    if (!isAssessmentStarted) {
      dispatch(updateAssessmentEvaluation({ evaluationId }));
    }
  };

  const removeAnswer = (questionId) => {
    const updatedResponses = responses?.map((response) =>
      response?.questionId === questionId
        ? { ...response, optionIds: [], removeAnswer: true }
        : response
    );
    setResponses(updatedResponses);
    dispatch(
      updateAssessmentEvaluation({
        evaluationId,
        questions: updatedResponses,
      })
    );
  };

  const calculateProgress = () => {
    const totalQuestions = questions?.length;
    const attemptedQuestions = Object.values(responses)?.filter(
      (response) =>
        Array.isArray(response?.optionIds) && response?.optionIds.length > 0
    ).length;
    return totalQuestions ? (attemptedQuestions / totalQuestions) * 100 : 0;
  };

  return (
    <>
      <Grid container className="main-container">
        <Grid item md={12} className="body-container">
          <Grid container className="table-parent-container">
            <Paper elevation={0} className="mcq-paper-container">
              <Grid className="mcq-main-container">
                <Box sx={{ display: "flex", height: "100%" }}>
                  <Box className="contained-items">
                    <UpperSection
                      instruction={instruction}
                      duration={duration}
                      timeLeft={timeLeft}
                      handleTimeUp={() => handleTimeUp(true)}
                      isStarted={isStarted}
                      updatedAssessmentEvaluationStartDate={
                        updatedAssessmentEvaluationStartDate ||
                        assessmentEvaluationStartDate
                      }
                      setAttemptAllQuestionModal={setAttemptAllQuestionModal}
                      assessmentEvaluationStatus={assessmentEvaluationStatus}
                    />
                    {assessmentEvaluationStatus !== COMPLETED && (
                      <ProgressBar progress={calculateProgress()} />
                    )}

                    {isAutoSubmit ? (
                      <Box className="start-button">
                        <Typography
                          variant="font_12_bold_700"
                          className="assessment-submitted"
                        >
                          Your assessment has been automatically submitted as
                          the time has expired. You will be redirected to the
                          homepage in 5 seconds
                        </Typography>
                      </Box>
                    ) : isFinalSubmit ? (
                      <Box className="start-button">
                        <Typography
                          variant="font_12_bold_700"
                          className="assessment-submitted"
                        >
                          Your assessment has been successfully submitted. You
                          will be redirected to the homepage in 5 seconds
                        </Typography>
                      </Box>
                    ) : !isStarted ? (
                      assessmentEvaluationStatus === COMPLETED ? (
                        <Box className="start-button">
                          <Typography
                            variant="font_12_bold_700"
                            className="assessment-submitted"
                          >
                            The assessment has already been submitted, and you
                            will be redirected to the home page in 5 seconds
                          </Typography>
                        </Box>
                      ) : (
                        <Box className="start-button">
                          <Button
                            onClick={handleStart}
                            variant="contained"
                            color="secondary"
                            className="block-button"
                            sx={{ minWidth: "12.5rem" }}
                          >
                            <Typography variant="font_12_bold_700">
                              {!isAssessmentStarted
                                ? "Start the Assessment"
                                : "Resume the Assessment"}
                            </Typography>
                          </Button>
                        </Box>
                      )
                    ) : (
                      <>
                        <Box className="mcq-section">
                          {questions?.length > 0 && (
                            <Question
                              questions={questions}
                              question={questions[currentQuestionIndex]}
                              currentQuestionIndex={currentQuestionIndex}
                              setCurrentQuestionIndex={setCurrentQuestionIndex}
                              totalQuestions={questions?.length}
                              saveResponse={saveResponse}
                              removeAnswer={removeAnswer}
                              responses={responses}
                            />
                          )}
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      <DeleteModal
        open={attemptAllQuestionModal}
        title={
          checkIfAllQuestionsAnswered()
            ? "Are you sure to submit your assessment?"
            : "Some questions are unanswered. Do you still want to submit your assessment?"
        }
        handleClose={handleAttemptAllQuestionModalClose}
        handleDelete={handleAssessmentSubmit}
        buttonText="Yes"
      />
    </>
  );
};

export default AssessmentMCQ;
