import ApiService from "../utils/api-service";

export const validateAuthToken = () => {
  return ApiService({
    method: "GET",
    url: `/auth/validateToken`,
  });
};

export const getLoggedInUser = () => {
  return ApiService({
    method: "GET",
    url: "/auth/userInfo",
  });
};