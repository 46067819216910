import React from "react";
import "./accessdenied.scss";
import { Box, Grid, Paper, Typography } from "@mui/material";
import ComingSoon from "../../components/ComingSoon/ComingSoon";
import Denied from "../../components/Denied/Denied";
import { useLocation } from "react-router-dom/dist";

const AccessDenied = () => {
  const location = useLocation();
  const isAccessDenied = location?.state?.isAccessDenied;

  return (
    <>
      <Grid container className="main-container">
        <Grid item className="header-container" md={12}>
          {/* <Typography variant="font_21_bold">Access Denied</Typography> */}
          <Box className="header-right-icons">
            {/* <IconButtons
              width={1.91}
              height={1.91}
              image={filter_active_icon}
              handleClick={handleFilterOpen}
            /> */}
          </Box>
        </Grid>
        <Grid item md={12} className="body-container">
          <Grid container className="table-parent-container">
            <Paper elevation={0} className="error-paper-container">
              <Grid container className="no-data-container">
                <Denied
                  imgHeight={10}
                  imgWidth={10}
                  message={!isAccessDenied ? "Not Found" : "Access Denied"}
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AccessDenied;
