import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import NoDataAvailable from "../../components/NoDataAvailable/NoDataAvailable";
const AssessmentQuestionOptions = ({
  open,
  assessmentQuestionsDetails,
  setAssociateQuestionsModalOpen,
  questionId,
}) => {
  const [options, setOptions] = useState([]);

  const handleClose = () => {
    setAssociateQuestionsModalOpen(false);
    setOptions([]);
  };

  useEffect(() => {
    if (open && assessmentQuestionsDetails?.totalResults) {
      const assessmentOptionDetails = assessmentQuestionsDetails?.results?.find(
        (question) => question?.id === questionId
      );
      setOptions(assessmentOptionDetails?.questionOptions?.results);
    }
  }, [open, assessmentQuestionsDetails]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minHeight: "25rem",
            minWidth: "40rem",
            zIndex: 1300,
            borderRadius: "1rem",
            boxShadow: "0px 6px 30px 0px rgba(0, 0, 0, 0.05)",
            margin: 0,
          },
        }}
      >
        <DialogTitle className="add-question-modal-title">Options</DialogTitle>
        <>
          {options?.length ? (
            <>
              <DialogContent
                sx={{
                  padding: "1rem 1.875rem 0 !important",
                  marginBottom: "1.25rem",
                }}
              >
                {options?.map((option, index) => {
                  const correct = option?.correct;

                  return (
                    <Box
                      key={index}
                      className="option-details"
                      sx={{
                        backgroundColor: correct ? "#b2eaca" : "",
                      }}
                    >
                      <Typography
                        variant="font_14"
                        color="lightGrayText.main"
                        sx={{ marginRight: "4px" }}
                      >
                        {`${index + 1})`}
                      </Typography>{" "}
                      <Typography
                        variant="font_13"
                        sx={{
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                        }}
                      >
                        {option?.optionText}
                      </Typography>
                    </Box>
                  );
                })}
              </DialogContent>
            </>
          ) : (
            <Grid
              container
              className="no-data-container"
              sx={{ height: "40rem" }}
            >
              <NoDataAvailable
                imgHeight={4}
                imgWidth={4.5}
                message="No Options Available"
              />
            </Grid>
          )}
        </>
      </Dialog>
    </>
  );
};

export default AssessmentQuestionOptions;
