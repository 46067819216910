import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Checkbox,
  Collapse,
  styled,
} from "@mui/material";
import NoDataAvailable from "../NoDataAvailable/NoDataAvailable";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const compareAssocatedQuestion = (initial, current) => {
  if (initial?.length !== current?.length) return true;
  const initialIds = initial?.sort();
  const currentIds = current?.sort();
  return !initialIds.every((id, index) => id === currentIds[index]);
};

const AssociateQuestions = ({
  handleClose,
  open,
  associateQuestionsDetails,
  associateQuestionsChange,
  handleAssociateQuestionSubmit,
  selectedIds,
  selectAllChange,
  questionsList,
  setSelectedIds,
}) => {
  const loading = useSelector((state) => {
    return state?.questionList.associateLoading;
  });

  const [saving, setSaving] = useState(false);
  const [initialAQuests, setInitialAQuests] = useState([]);
  const onSubmit = async () => {
    setSaving(true);
    await handleAssociateQuestionSubmit(selectedIds);
    setInitialAQuests([]);
    setSaving(false);
  };

  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (
      open &&
      questionsList?.length &&
      associateQuestionsDetails?.data?.totalResults
    ) {
      const associatedQuestionDetails = associateQuestionsDetails?.data.results
        ?.filter((it) => questionsList?.some((it2) => it2?.id === it?.id))
        .map((it) => it?.id);

      setSelectedIds(associatedQuestionDetails);
      setInitialAQuests(associatedQuestionDetails);
    }
  }, [open, questionsList, associateQuestionsDetails]);
  useEffect(() => {
    const allSelected =
      associateQuestionsDetails?.data?.results?.length > 0 &&
      associateQuestionsDetails.data.results.every((question) =>
        selectedIds.includes(question.id)
      );
    setSelectAll(allSelected);
  }, [selectedIds, associateQuestionsDetails]);

  const [viewMore, setViewMore] = useState(null);
  const handleViewMoreClick = (value) => {
    if (viewMore === value) {
      setViewMore(null);
    } else {
      setViewMore(value);
    }
  };

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  /////// To check change in selection in associated questions //////
  const changeInSelection = compareAssocatedQuestion(
    initialAQuests,
    selectedIds
  );

  return (
    <>
      {loading && <Loader />}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minHeight: "25rem",
            minWidth: "40rem",
            zIndex: 1300,
            borderRadius: "1rem",
            boxShadow: "0px 6px 30px 0px rgba(0, 0, 0, 0.05)",
            margin: 0,
          },
        }}
      >
        <DialogTitle className="add-question-modal-title">
          Associate Questions
        </DialogTitle>
        {!loading && (
          <>
            {associateQuestionsDetails?.data?.results?.length ? (
              <>
                {" "}
                <DialogContent
                  sx={{
                    padding: "1rem 1.875rem 0 !important",
                    marginBottom: "1.25rem",
                  }}
                >
                  <Box className="question-details-select">
                    <Checkbox
                      size="small"
                      color="secondary"
                      style={{
                        padding: 0,
                        marginRight: "0.813rem",
                      }}
                      onChange={(e) => {
                        selectAllChange(e);
                      }}
                      checked={selectAll}
                    />
                    <Typography variant="font_14" color="lightGrayText.main">
                      Select All
                    </Typography>
                  </Box>
                  {associateQuestionsDetails?.data?.results?.map(
                    (question, index) => {
                      const isChecked = selectedIds?.includes(question?.id);

                      const wordCount = question?.question?.length;
                      const countLines =
                        question?.question?.split(/\r|\r\n|\n/);
                      const lineLength = countLines?.length;

                      return (
                        <Box key={index} className="question-details">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                            }}
                          >
                            <Checkbox
                              size="small"
                              color="secondary"
                              style={{
                                padding: 0,
                                marginRight: "0.813rem",
                              }}
                              checked={isChecked}
                              onChange={() =>
                                associateQuestionsChange(question.id)
                              }
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                            }}
                          >
                            <Typography
                              variant="font_14"
                              color="lightGrayText.main"
                            >
                              {`Q${index + 1})`}
                            </Typography>

                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                flexWrap: "nowrap",
                                padding: "0 0.813rem",
                              }}
                            >
                              <Collapse
                                in={viewMore === index}
                                collapsedSize={
                                  wordCount > 130 || lineLength > 3
                                    ? 40
                                    : lineLength * 20
                                }
                              >
                                <Typography
                                  variant="font_13"
                                  sx={{
                                    whiteSpace: "pre-wrap",
                                    wordBreak: "break-word",
                                    overflowWrap: "break-word",
                                  }}
                                >
                                  {question?.question}
                                </Typography>
                              </Collapse>
                              {wordCount > 130 || lineLength > 3 ? (
                                <Box onClick={() => handleViewMoreClick(index)}>
                                  <Typography
                                    variant="font_9_bold"
                                    color="secondary"
                                    cursor="pointer"
                                    sx={{ fontWeight: 600 }}
                                  >
                                    {viewMore === index
                                      ? "VIEW LESS"
                                      : "VIEW MORE"}
                                  </Typography>
                                  <ExpandMore
                                    expand={viewMore === index}
                                    sx={{ padding: 0 }}
                                  >
                                    <ExpandMoreIcon color="secondary" />
                                  </ExpandMore>
                                </Box>
                              ) : null}
                            </Box>
                          </Box>
                        </Box>
                      );
                    }
                  )}
                </DialogContent>
                <DialogActions sx={{ padding: "0rem 2rem 1.875rem" }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleClose}
                    className="add-question-btn"
                  >
                    <Typography variant="outlineBtnLabel">Cancel</Typography>
                  </Button>
                  <Button
                    onClick={onSubmit}
                    variant="contained"
                    color="secondary"
                    className="add-question-btn"
                    disabled={!changeInSelection || saving}
                  >
                    <Typography variant="outlineBtnLabel">Save</Typography>
                  </Button>
                </DialogActions>
              </>
            ) : (
              <Grid
                container
                className="no-data-container"
                sx={{ height: "40rem" }}
              >
                <NoDataAvailable
                  imgHeight={4}
                  imgWidth={4.5}
                  message="No Questions Available"
                />
              </Grid>
            )}
          </>
        )}
      </Dialog>
    </>
  );
};

export default AssociateQuestions;
