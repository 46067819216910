import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";
import Loader from "../Loader/Loader";
const DisassociateQuestionModal = ({
  open,
  title,
  handleClose,
  handleRemove,
}) => {
  const [loader, setLoader] = useState(false);

  const onRemove = async () => {
    setLoader(true);
    await handleRemove();
    setLoader(false);
  };

  return (
    <>
      {loader && <Loader />}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minHeight: "11.563rem",
            width: "21.25rem",
            zIndex: 1300,
            borderRadius: "1rem",
            boxShadow: "0rem 0.6rem 3rem 0rem #OD",
            margin: 0,
          },
        }}
      >
        <DialogTitle className="delete-dialog-title">
          {title || "Are you sure you want to disassociate this question?"}
        </DialogTitle>
        <DialogActions className="delete-dialog-action">
          <Button
            fullWidth
            disabled={false}
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            className="addTagButtonStyle"
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disableElevation
          >
            <Typography variant="font_12_bold_700">Cancel</Typography>
          </Button>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            onClick={onRemove}
            className="addTagButtonStyle"
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disableElevation
          >
            <Typography variant="font_12_bold_700">Disassociate</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DisassociateQuestionModal;
