import React, { useState } from "react";
import "./deleteModal.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import Loader from "../Loader/Loader";
const DeleteModal = ({
  open,
  title,
  handleClose,
  handleDelete,
  buttonText,
  content,
  width,
}) => {
  const [loader, setLoader] = useState(false);

  const onDelete = async () => {
    setLoader(true);
    await handleDelete();
    setLoader(false);
  };

  return (
    <>
      {loader && <Loader />}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minHeight: width ? "auto" : "11.563rem",
            width: width ? width : !!content ? "30rem" : "21.25rem",
            zIndex: 1300,
            borderRadius: "1rem",
            boxShadow: "0rem 0.6rem 3rem 0rem #OD",
            margin: 0,
            maxHeight: "auto",
          },
        }}
      >
        <DialogTitle className="delete-dialog-title">
          {title || "Are you sure you want to delete this item?"}
        </DialogTitle>
        {!!content && (
          <DialogContent sx={{ padding: "1.25rem 2rem" }}>
            <Typography>{content?.text}</Typography>
            <List
              sx={{
                listStyleType: "disc",
                paddingLeft: "1.125rem",
                paddingBottom: content?.warning ? "1rem" : "0rem",
              }}
            >
              {content?.items?.map((item, index) => (
                <ListItem
                  key={index}
                  sx={{
                    display: "list-item",
                    padding: 0,
                  }}
                >
                  <Typography variant="font_14">{item}</Typography>
                </ListItem>
              ))}
            </List>
            <Typography sx={{ fontSize: "0.9rem" }}>
              {content?.warning}
            </Typography>
          </DialogContent>
        )}
        <DialogActions className="delete-dialog-action">
          <Button
            fullWidth
            disabled={false}
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            className="addTagButtonStyle"
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disableElevation
          >
            <Typography variant="font_12_bold_700">Cancel</Typography>
          </Button>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            onClick={onDelete}
            className="addTagButtonStyle"
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disableElevation
          >
            <Typography variant="font_12_bold_700">
              {buttonText || "Delete"}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteModal;
