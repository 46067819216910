import React from "react";
import "./AppDrawer.scss";
import {
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Popper,
  Typography,
} from "@mui/material";

import { Link } from "react-router-dom";
import { AppDrawerLinks } from "./appDrawerUtils";

const RoundAppDrawer = ({ id, open, handleClose }) => {
  return (
    <Popper
      arrow
      id={id}
      anchorEl={open}
      open={open}
      placement="bottom-end"
      disableScrollLock={true}
      hideBackdrop={true}
      disableEnforceFocus={true}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <Paper>
          <Grid
            container
            className="drawer-items-container"
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {AppDrawerLinks.map(
              ({ name, linkTo, defaultImage, hoverImage }) => {
                return (
                  <Grid
                    key={name}
                    item
                    className="drawer-items"
                    xs={2}
                    sm={4}
                    md={4}
                    onClick={handleClose}
                  >
                    <IconButton
                      href={linkTo}
                      sx={{
                        backgroundImage: `url(${defaultImage})`,
                        width: `3.125rem`,
                        height: `3.125rem`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "auto",
                        ":hover": {
                          backgroundImage: `url(${hoverImage})`,
                        },
                      }}
                      disableRipple={true}
                      target={"_blank"}
                      rel="noopener noreferrer"
                    ></IconButton>
                    <Link
                      to={linkTo}
                      className="drawer-icon-label"
                      target={"_blank"}
                      rel="noopener noreferrer"
                    >
                      <Typography variant="font_10" mt={1}>
                        {name}
                      </Typography>
                    </Link>
                  </Grid>
                );
              }
            )}
          </Grid>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

export default RoundAppDrawer;
