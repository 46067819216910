import React from "react";
import { Tooltip, styled, tooltipClasses } from "@mui/material";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#675b76",
    marginTop: 0,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#675b76",
    fontSize: "0.75rem",
  },
}));

export default BootstrapTooltip;
