import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateAuthToken } from "../../store/security";
import { Navigate } from "react-router-dom";

const CallbackAPI = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state?.security?.isUserLoggedIn);

  useEffect(() => {
    dispatch(validateAuthToken());
  }, [dispatch]);

  return <>{isLoggedIn ? <Navigate to="/" /> : <Navigate to="/login" />}</>;
};

export default CallbackAPI;