import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import {
  addCommentApi,
  deleteCommentApi,
  editCommentApi,
  getCommentListApi,
} from "../services/comments";
import CustomToaster from "../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../constants/constants";

const initialState = {
  commentsList: [],
  loading: false,
  error: null,
};

export const getCommentList = createAsyncThunk(
  "getCommentList",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getCommentListApi(data);
      return callApi;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const addComment = createAsyncThunk(
  "addComment",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await addCommentApi(data);
      return callApi;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const editComment = createAsyncThunk(
  "editComment",
  async (data, { dispatch, rejectWithValue }) => {
    const { commentId, comment, exerciseId } = data;
    try {
      const callApi = await editCommentApi({ commentId, comment });
      if (callApi) {
        await dispatch(getCommentList({ exerciseId }));
      }
      return callApi;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const deleteComment = createAsyncThunk(
  "deleteComment",
  async (data, { rejectWithValue }) => {
    const { commentId, exerciseId } = data;
    try {
      const callApi = await deleteCommentApi({ commentId });
      toast.custom(
        <CustomToaster
          message="Comment Deleted Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const commentsList = createSlice({
  name: "commentsList",
  initialState,
  reducers: {
    clearCommentsList: (state, action) => {
      state.commentsList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCommentList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCommentList.fulfilled, (state, action) => {
        state.loading = false;
        state.commentsList = action.payload;
      })
      .addCase(getCommentList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(addComment.fulfilled, (state, action) => {
        state.loading = false;
        toast.custom(
          <CustomToaster
            message="Comment Added Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      })
      .addCase(addComment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(editComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(editComment.fulfilled, (state, action) => {
        state.loading = false;
        toast.custom(
          <CustomToaster
            message="Comment Updated Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      })
      .addCase(editComment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteComment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearCommentsList } = commentsList.actions;
export default commentsList.reducer;
