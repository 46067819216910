import React from "react";
import "./listUsersTable.scss";
import {
  Box,
  Chip,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  cross_default_icon,
  cross_disabled_icon,
  down_sort_arrow_icon,
  plus_dashed_active_icon,
  plus_dashed_default_icon,
  switch_user_icon,
  up_sort_arrow_icon,
} from "../../constants/icons";
import IconButtons from "../IconButtons/IconButtons";
import TablePagination from "../TablePagination/TablePagination";
import {
  ROLE_TYPES,
  filterRolesDropDown,
  font_15,
  font_21,
  font_9,
  getAbbreviation,
  getImpersonateUserEndpoint,
} from "../../utils/utils";
import { authCookieName, env } from "../../config/config";
import Constants, {
  RolesList,
  HelpTexts,
  sortInAscending,
  sortInDescending,
} from "../../constants/constants";
import { useSelector } from "react-redux";
import UserTableAvatar from "../UserTableAvatar/UserTableAvatar";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";
const { USER } = RolesList;
const { SELF_REMOVAL_OF_ADMIN_ROLE, ALL_ROLES_ASSIGNED } = HelpTexts;
const onImpersonateLoginClick = (email) =>
  (window.location.href = getImpersonateUserEndpoint(email));

const ListUsersTable = ({
  getUsersData,
  totalPages,
  handlePageChange,
  currentPage,
  handleUserAddModalOpen,
  handleDeleteModalOpen,
  handleSorting,
  sortBy,
  orderBy,
  roleOptions,
}) => {
  const security = useSelector((state) => state?.security);
  const roles = security?.roles;
  const isAdmin = ROLE_TYPES.isAdmin(roles);

  return (
    <>
      <TableContainer className="table-container">
        <Table stickyHeader>
          <TableHead className="table-head-container">
            <TableRow className="table-head-row" sx={{ height: "2.711rem" }}>
              <TableCell
                align="left"
                sx={{ minWidth: "13rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Name & Email</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        sortBy === "name" && orderBy === "ASC" ? null : font_9
                      }
                      width={
                        sortBy === "name" && orderBy === "ASC" ? null : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "name")}
                      disabled={sortBy === "name" && orderBy === "ASC"}
                      tooltip={sortInAscending}
                    />
                    {(sortBy !== "name" || orderBy !== "DESC") && (
                      <IconButtons
                        height={
                          sortBy === "name" && orderBy === "DESC"
                            ? null
                            : font_9
                        }
                        width={
                          sortBy === "name" && orderBy === "DESC"
                            ? null
                            : font_9
                        }
                        image={down_sort_arrow_icon}
                        handleClick={() => handleSorting("DESC", "name")}
                        disabled={sortBy === "name" && orderBy === "DESC"}
                        tooltip={sortInDescending}
                      />
                    )}
                  </Box>
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "13rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Mentor</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        sortBy === "mentorName" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "mentorName" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "mentorName")}
                      disabled={sortBy === "mentorName" && orderBy === "ASC"}
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        sortBy === "mentorName" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "mentorName" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() => handleSorting("DESC", "mentorName")}
                      disabled={sortBy === "mentorName" && orderBy === "DESC"}
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "13rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Reporting Manager</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        sortBy === "reportingManagerName" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "reportingManagerName" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() =>
                        handleSorting("ASC", "reportingManagerName")
                      }
                      disabled={
                        sortBy === "reportingManagerName" && orderBy === "ASC"
                      }
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        sortBy === "reportingManagerName" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "reportingManagerName" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() =>
                        handleSorting("DESC", "reportingManagerName")
                      }
                      disabled={
                        sortBy === "reportingManagerName" && orderBy === "DESC"
                      }
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>

              <TableCell
                align="left"
                sx={{ minWidth: "8rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Competency</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        sortBy === "competencyName" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "competencyName" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() => handleSorting("ASC", "competencyName")}
                      disabled={
                        sortBy === "competencyName" && orderBy === "ASC"
                      }
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        sortBy === "competencyName" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "competencyName" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() =>
                        handleSorting("DESC", "competencyName")
                      }
                      disabled={
                        sortBy === "competencyName" && orderBy === "DESC"
                      }
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "8rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Business Unit</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        sortBy === "businessUnitName" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "businessUnitName" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() =>
                        handleSorting("ASC", "businessUnitName")
                      }
                      disabled={
                        sortBy === "businessUnitName" && orderBy === "ASC"
                      }
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        sortBy === "businessUnitName" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "businessUnitName" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() =>
                        handleSorting("DESC", "businessUnitName")
                      }
                      disabled={
                        sortBy === "businessUnitName" && orderBy === "DESC"
                      }
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>
              <TableCell
                align="center"
                sx={{ minWidth: "7rem" }}
                className="padding-left-1"
              >
                <Typography
                  variant="font_13_bold"
                  className="flex-table-sort-head"
                >
                  <Box className="table-sort-head">Designation</Box>
                  <Box className="table-sort-icon">
                    <IconButtons
                      height={
                        sortBy === "designationName" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "designationName" && orderBy === "ASC"
                          ? null
                          : font_9
                      }
                      image={up_sort_arrow_icon}
                      handleClick={() =>
                        handleSorting("ASC", "designationName")
                      }
                      disabled={
                        sortBy === "designationName" && orderBy === "ASC"
                      }
                      tooltip={sortInAscending}
                    />
                    <IconButtons
                      height={
                        sortBy === "designationName" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      width={
                        sortBy === "designationName" && orderBy === "DESC"
                          ? null
                          : font_9
                      }
                      image={down_sort_arrow_icon}
                      handleClick={() =>
                        handleSorting("DESC", "designationName")
                      }
                      disabled={
                        sortBy === "designationName" && orderBy === "DESC"
                      }
                      tooltip={sortInDescending}
                    />
                  </Box>
                </Typography>
              </TableCell>
              <TableCell
                align="left"
                sx={{ minWidth: "4rem" }}
                className="padding-left-1"
              >
                <Typography variant="font_13_bold">Roles</Typography>
              </TableCell>
              {isAdmin && (
                <TableCell
                  align="center"
                  sx={{ minWidth: "5rem", maxWidth: "5rem", width: "5rem" }}
                  className="padding-left-1 padding-right-1"
                >
                  <Typography variant="font_13_bold">Actions</Typography>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {getUsersData?.data?.results?.map(
              (
                {
                  id,
                  profilePictureUrl,
                  fullName,
                  email,
                  competency,
                  designation,
                  businessUnit,
                  roles,
                  mentor,
                  reportingManager,
                  employeeCode,
                  deleted,
                },
                index
              ) => {
                const userDeleted = deleted;
                const mentorDeleted = mentor?.deleted;
                const reportingManagerDeleted = reportingManager?.deleted;
                const rolesIds = roles?.results;
                const getRolesList = filterRolesDropDown(
                  competency,
                  roleOptions
                );
                const filterRolesList = getRolesList?.filter(
                  (e) => !rolesIds?.includes(e?.key)
                );
                return (
                  <TableRow className="table-body-row" hover key={index}>
                    <TableCell align="left" className="padding-left-1">
                      <UserTableAvatar
                        imgAlt={fullName}
                        imgSrc={profilePictureUrl}
                        href={employeeCode}
                        label={fullName}
                        subLabel={email}
                        deleted={userDeleted}
                      />
                    </TableCell>
                    <TableCell align="left" className="padding-left-1">
                      <UserTableAvatar
                        imgAlt={mentor?.fullName}
                        imgSrc={mentor?.profilePictureUrl}
                        href={mentor?.employeeCode}
                        label={mentor?.fullName}
                        subLabel={mentor?.designation?.name}
                        deleted={mentorDeleted}
                        tooltip={mentor?.email}
                      />
                    </TableCell>
                    <TableCell align="left" className="padding-left-1">
                      <UserTableAvatar
                        imgAlt={reportingManager?.fullName}
                        imgSrc={reportingManager?.profilePictureUrl}
                        href={reportingManager?.employeeCode}
                        label={reportingManager?.fullName}
                        subLabel={reportingManager?.designation?.name}
                        tooltip={reportingManager?.email}
                        deleted={reportingManagerDeleted}
                      />
                    </TableCell>
                    <TableCell align="left" className="padding-left-1">
                      <Typography variant="font_12">
                        {competency?.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className="padding-left-1">
                      <Typography variant="font_12">
                        {businessUnit?.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" className="padding-left-1">
                      <BootstrapTooltip title={designation?.name}>
                        <Typography variant="font_12" display="block">
                          {getAbbreviation(designation?.name)}
                        </Typography>
                      </BootstrapTooltip>
                    </TableCell>
                    <TableCell align="left" className="padding-left-1">
                      <Box className="user-roles-container">
                        <Box className="roles-chip-container">
                          {roles?.results?.map((role, index) => {
                            const disableTooltip =
                              ROLE_TYPES?.ADMIN?.check([role]) &&
                              isAdmin &&
                              security?.loggedInUser?.id === id;
                            return role === USER ? null : (
                              <Chip
                                key={index}
                                label={ROLE_TYPES.getLabel(role)}
                                variant="outlined"
                                size="small"
                                color="secondary"
                                className="user-roles-chip"
                                deleteIcon={
                                  <IconButtons
                                    height={0.5}
                                    width={0.5}
                                    image={cross_default_icon}
                                    handleClick={() =>
                                      handleDeleteModalOpen(id, role, fullName)
                                    }
                                    disabled={disableTooltip}
                                    disabledImage={cross_disabled_icon}
                                    disabledTooltip={SELF_REMOVAL_OF_ADMIN_ROLE}
                                    classList="user-role-cross"
                                    tooltip="Remove Role"
                                  />
                                }
                                onDelete={isAdmin}
                              />
                            );
                          })}
                        </Box>
                      </Box>
                    </TableCell>
                    {isAdmin && (
                      <TableCell
                        align="center"
                        className="padding-left-1 padding-right-1"
                      >
                        <Box
                          className={
                            authCookieName ===
                            Constants.IMPERSONATECOOKIEKEY(env)
                              ? "flex-center"
                              : "table-action-icons"
                          }
                        >
                          {isAdmin && (
                            <IconButtons
                              width={font_21}
                              height={font_21}
                              image={plus_dashed_active_icon}
                              tooltip="Add Role"
                              handleClick={() =>
                                handleUserAddModalOpen(
                                  fullName,
                                  id,
                                  roles,
                                  competency
                                )
                              }
                              disabled={!filterRolesList?.length}
                              disabledImage={plus_dashed_default_icon}
                              disabledTooltip={ALL_ROLES_ASSIGNED}
                            />
                          )}
                          {isAdmin &&
                            authCookieName !==
                              Constants.IMPERSONATECOOKIEKEY(env) &&
                            id !== security?.loggedInUser?.id && (
                              <Link
                                onClick={onImpersonateLoginClick.bind(
                                  this,
                                  email
                                )}
                              >
                                <IconButtons
                                  width={font_15}
                                  height={font_15}
                                  image={switch_user_icon}
                                  tooltip="Switch User"
                                />
                              </Link>
                            )}
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container className="table-pagination-container">
        <Typography variant="font_16">
          Total {getUsersData?.data?.totalResults} Records
        </Typography>
        <TablePagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
        />
      </Grid>
    </>
  );
};

export default ListUsersTable;
