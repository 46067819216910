import React, { useEffect, useState } from "react";
import "./topicRelatedQuestionsListOnPlayCard.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { font_13 } from "../../utils/utils";
import { down_arrow_icon, up_arrow_icon } from "../../constants/icons";
import IconButtons from "../IconButtons/IconButtons";
import { useSelector } from "react-redux";

const TopicRelatedQuestionsListOnPlayCard = ({
  evaluationQuestion,
  assessment,
  expandedId,
  setExpandedId,
  topicId,
  saveResponse,
  responses,
}) => {
  const questionEvaluations =
    useSelector((state) => state?.utility?.constants?.questionEvaluations) ||
    [];
  const priorityOrder = {
    INCORRECT: 1,
    PARTIALLY_CORRECT: 2,
    CORRECT: 3,
  };
  const sortedEvaluations = [...questionEvaluations].sort(
    (a, b) => priorityOrder[a.key] - priorityOrder[b.key]
  );

  const [selectedOption, setSelectedOption] = useState(0);
  const [commentDetails, setCommentDetails] = useState("");

  const handleSave = (questionId) => {
    const newQuestionData = {
      questionId,
      interviewerEvaluation: selectedOption,
      interviewerFeedback: commentDetails,
    };
    saveResponse(newQuestionData);
  };

  const handleRemove = (questionId) => {
    setSelectedOption(0);
    setCommentDetails("");
    saveResponse({ questionId }, true);
  };

  const handleInputCommentChange = (comment) => {
    setCommentDetails(comment);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const isExpanded = expandedId === evaluationQuestion?.id;
  const handleAccordionChange = () => {
    setExpandedId(isExpanded ? null : evaluationQuestion?.id);
  };

  useEffect(() => {
    if (isExpanded) {
      setExpandedId(null);
    }
  }, [topicId]);

  useEffect(() => {
    const savedResponse = responses?.find((response) => {
      return response?.questionId === evaluationQuestion?.id;
    });
    if (savedResponse) {
      setSelectedOption(savedResponse.interviewerEvaluation);
      setCommentDetails(savedResponse.interviewerFeedback);
    }
  }, [evaluationQuestion?.id, responses]);

  const { markPerEasyQuestion, markPerMediumQuestion, markPerHardQuestion } =
    assessment || {};

  const questionType = evaluationQuestion?.question?.difficultyLevel;
  const questionMarksObtained = evaluationQuestion?.marksObtained || 0;
  const questionTotalMarks =
    questionType === "EASY"
      ? markPerEasyQuestion
      : questionType === "MEDIUM"
      ? markPerMediumQuestion
      : questionType === "HARD"
      ? markPerHardQuestion
      : null;

  return (
    <Accordion
      className="accordion"
      expanded={isExpanded}
      onChange={handleAccordionChange}
    >
      <AccordionSummary
        className="accordion-summary"
        aria-controls="panel1-content"
        id={`panel-header-${evaluationQuestion?.id}`}
      >
        <Box className="topic-question-head-container">
          <Box className="topic-question-head-left margin-right-2">
            <Typography variant="font_14">
              {evaluationQuestion?.question?.title}
            </Typography>
          </Box>
          <Box className="topic-question-head-right">
            <Typography variant="font_12" className="margin-right-1">
              <Chip
                className="score-chip"
                label={`${questionMarksObtained}/${questionTotalMarks}`}
                variant={
                  evaluationQuestion?.marksObtained === null
                    ? "GREY"
                    : questionMarksObtained === 0
                    ? "RED"
                    : questionMarksObtained === questionTotalMarks
                    ? "GREEN"
                    : "YELLOW"
                }
              />
            </Typography>
            <IconButtons
              height={font_13}
              width={font_13}
              image={isExpanded ? up_arrow_icon : down_arrow_icon}
            />
          </Box>
        </Box>
      </AccordionSummary>

      <AccordionDetails className="accordion-details">
        <Grid className="interview-fields">
          <Grid item md={12}>
            <Box className="question-desc">
              <Typography variant="font_12" className="grey-text">
                Description
              </Typography>
              <Typography
                variant="font_13"
                sx={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                }}
              >
                {evaluationQuestion?.question?.description
                  ? evaluationQuestion?.question?.description
                  : "-"}
              </Typography>
            </Box>
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              marginBottom: "0.75rem",
            }}
          >
            <Box
              sx={{
                marginBottom: "0.75rem",
                flexBasis: "70%",
                marginRight: "1rem",
              }}
            >
              <TextField
                multiline
                minRows={5}
                label="Comment"
                color="secondary"
                fullWidth
                className="filter-inputs"
                variant="outlined"
                size="small"
                margin="dense"
                value={commentDetails}
                onChange={(e) => handleInputCommentChange(e?.target?.value)}
                required={true}
              />
            </Box>
            <Box
              sx={{
                flexBasis: "20%",
                marginRight: "1rem",
                fontSize: "0.875rem",
              }}
            >
              <RadioGroup
                className="interview-options"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                {sortedEvaluations?.map((option) => (
                  <FormControlLabel
                    className={`options single-select-options ${
                      selectedOption === option?.key ? "selected-option" : ""
                    }`}
                    key={option?.key}
                    value={option?.key}
                    control={<Radio sx={{ display: "none" }} />}
                    label={option?.value}
                  />
                ))}
              </RadioGroup>
            </Box>
            <Box
              sx={{
                flexBasis: "10%",
                marginRight: "1rem",
              }}
            >
              <Button
                onClick={() => handleSave(evaluationQuestion?.id)}
                variant="contained"
                color="secondary"
                className="save-btn"
                disabled={!commentDetails?.trim() || !selectedOption}
              >
                <Typography variant="font_12_bold_700">Save</Typography>
              </Button>
              <Button
                onClick={() => handleRemove(evaluationQuestion?.id)}
                variant="contained"
                color="secondary"
                className="save-btn"
                disabled={!commentDetails?.trim() || !selectedOption}
              >
                <Typography variant="font_12_bold_700">Remove</Typography>
              </Button>
            </Box>
          </Box>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default TopicRelatedQuestionsListOnPlayCard;
