import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import {
  addResourceApi,
  addSessionApi,
  deleteSessionApi,
  editSessionApi,
  getAllExerciseOptionsApi,
  getAllModulesApi,
  getAllTopicApi,
  getResourceBySessionIdApi,
  getSessionFilterTopicApi,
  getSessionListApi,
} from "../services/sessions";
import { deleteResourceApi, editResourceApi } from "../services/topic";
import CustomToaster from "../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../constants/constants";

const initialState = {
  moduleId: null,
  sessionList: [],
  getAllModuleOptions: [],
  getAllTopicOptions: [],
  exerciseOptions: [],
  loading: false,
  listLoading: false,
  addSessionloading: false,
  editSessionloading: false,
  error: null,
  sessionError: null,
  resources: [],
  resourcesLoading: false,
  topicLoading: false,
  topicSessionFilter: [],
  filterLoading: false,
};

export const getSessionList = createAsyncThunk(
  "getBootcamp",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getSessionListApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllModuleOptions = createAsyncThunk(
  "getAllModuleOptions",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getAllModulesApi();
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllTopicOptions = createAsyncThunk(
  "getAllTopicOptions",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getAllTopicApi();
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAllExerciseOptions = createAsyncThunk(
  "getAllExerciseOptions",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getAllExerciseOptionsApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addSession = createAsyncThunk(
  "addSession",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await addSessionApi(data);
      toast.custom(
        <CustomToaster
          message="Session Added Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editSession = createAsyncThunk(
  "editSession",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await editSessionApi(data);
      toast.custom(
        <CustomToaster
          message="Session Updated Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteResource = createAsyncThunk(
  "deleteResource",
  async (data, { dispatch, rejectWithValue }) => {
    const { getListId, offset, moduleId, topicId } = data;
    try {
      const callApi = await deleteResourceApi(getListId);
      if (callApi) {
        await dispatch(getSessionList({ offset, moduleId, topicId }));
        toast.custom(
          <CustomToaster
            message="Resource Deleted Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSession = createAsyncThunk(
  "deleteSession",
  async (data, { dispatch, rejectWithValue }) => {
    const { getListId, offset, moduleId } = data;
    try {
      const callApi = await deleteSessionApi(getListId);
      if (callApi) {
        await dispatch(getSessionList({ offset, moduleId }));
        toast.custom(
          <CustomToaster
            message="Session Deleted Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addResource = createAsyncThunk(
  "addResource",
  async (data, { dispatch, rejectWithValue }) => {
    const { json, files, offset, moduleId, topicId } = data;
    try {
      const callApi = await addResourceApi({ json, files });
      if (callApi) {
        await dispatch(getSessionList({ offset, moduleId, topicId }));
        toast.custom(
          <CustomToaster
            message="Resource Added Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getResourceBySessionId = createAsyncThunk(
  "getResourceBySessionId",
  async (id, { rejectWithValue }) => {
    try {
      const callApi = await getResourceBySessionIdApi(id);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSessionFilterTopic = createAsyncThunk(
  "getSessionFilterTopic",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getSessionFilterTopicApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editResource = createAsyncThunk(
  "editResource",
  async (data, { dispatch, rejectWithValue }) => {
    const { json, files } = data;
    try {
      const callApi = await editResourceApi({ json, files });
      if (callApi) {
        await dispatch(getResourceBySessionId(json?.id));
        toast.custom(
          <CustomToaster
            message="Resource Edited Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sessionList = createSlice({
  name: "sessionList",
  initialState,
  reducers: {
    setModuleId: (state, action) => {
      state.moduleId = action.payload;
    },
    clearSessions(state) {
      state.sessionList = [];
    },
    clearSessionResources(state) {
      state.resources = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSessionList.pending, (state) => {
        state.listLoading = true;
      })
      .addCase(getSessionList.fulfilled, (state, action) => {
        state.listLoading = false;
        state.sessionList = action.payload;
      })
      .addCase(getSessionList.rejected, (state, action) => {
        state.listLoading = false;
        state.error = action.payload;
      })
      .addCase(getAllModuleOptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllModuleOptions.fulfilled, (state, action) => {
        state.loading = false;
        state.getAllModuleOptions = action.payload;
      })
      .addCase(getAllModuleOptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAllTopicOptions.pending, (state) => {
        state.topicLoading = true;
      })
      .addCase(getAllTopicOptions.fulfilled, (state, action) => {
        state.topicLoading = false;
        state.getAllTopicOptions = action.payload;
      })
      .addCase(getAllTopicOptions.rejected, (state, action) => {
        state.topicLoading = false;
        state.error = action.payload;
      })
      .addCase(addSession.pending, (state) => {
        state.sessionError = null;
        state.addSessionloading = true;
      })
      .addCase(addSession.fulfilled, (state) => {
        state.sessionError = null;
        state.addSessionloading = false;
      })
      .addCase(addSession.rejected, (state, action) => {
        state.addSessionloading = false;
        state.sessionError = action.payload;
      })
      .addCase(editSession.pending, (state) => {
        state.sessionError = null;
        state.editSessionloading = true;
      })
      .addCase(editSession.fulfilled, (state) => {
        state.sessionError = null;
        state.editSessionloading = false;
      })
      .addCase(editSession.rejected, (state, action) => {
        state.editSessionloading = false;
        state.sessionError = action.payload;
      })
      .addCase(getAllExerciseOptions.pending, (state, action) => {
        state.loading = true;
        state.exerciseOptions = action.payload;
      })
      .addCase(getAllExerciseOptions.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getAllExerciseOptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addResource.pending, (state) => {
        state.loading = true;
      })
      .addCase(addResource.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addResource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteResource.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteResource.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteResource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteSession.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSession.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getResourceBySessionId.pending, (state) => {
        state.resourcesLoading = true;
      })
      .addCase(getResourceBySessionId.fulfilled, (state, action) => {
        state.resourcesLoading = false;
        state.resources = action.payload;
      })
      .addCase(getResourceBySessionId.rejected, (state, action) => {
        state.resourcesLoading = false;
        state.error = action.payload;
      })
      .addCase(getSessionFilterTopic.pending, (state, action) => {
        state.filterLoading = true;
      })
      .addCase(getSessionFilterTopic.fulfilled, (state, action) => {
        state.filterLoading = false;
        state.topicSessionFilter = action.payload;
      })
      .addCase(getSessionFilterTopic.rejected, (state, action) => {
        state.filterLoading = false;
        state.error = action.payload;
      })
      .addCase(editResource.pending, (state) => {
        state.loading = true;
      })
      .addCase(editResource.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(editResource.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setModuleId, clearSessions, clearSessionResources } =
  sessionList.actions;
export default sessionList.reducer;
