import _availability_icon from "../assets/icons/availability-icon.svg";
import _dashboard_default_icon from "../assets/icons/dashboard-default-icon.svg";
import _dashboard_active_icon from "../assets/icons/dashboard-active-icon.svg";
import _menu_bar_icon from "../assets/icons/menu-bar-icon.svg";
import _ttn_logo_icon from "../assets/images/ttn-logo.jpeg";
import _nw_full_logo from "../assets/images/nw-full-logo.svg";
import _nw_full_logo_png from "../assets/images/nw-full-logo-png.png";
import _nw_small_logo from "../assets/images/nw-small-logo.svg";
import _drawer_default_icon from "../assets/icons/drawer-default-icon.svg";
import _drawer_active_icon from "../assets/icons/drawer-active-icon.svg";
import _bootcamp_default_icon from "../assets/icons/bootcamp-default-icon.svg";
import _bootcamp_active_icon from "../assets/icons/bootcamp-active-icon.svg";
import _growth_default_icon from "../assets/icons/growth-default-icon.svg";
import _growth_active_icon from "../assets/icons/growth-active-icon.svg";
import _asset_default_icon from "../assets/icons/asset-default-icon.svg";
import _asset_active_icon from "../assets/icons/asset-active-icon.svg";
import _money_default_icon from "../assets/icons/money-default-icon.svg";
import _money_active_icon from "../assets/icons/money-active-icon.svg";
import _referal_default_icon from "../assets/icons/referal-default-icon.svg";
import _referal_active_icon from "../assets/icons/referal-active-icon.svg";
import _claims_default_icon from "../assets/icons/claims-default-icon.svg";
import _claims_active_icon from "../assets/icons/claims-active-icon.svg";
import _down_arrow_icon from "../assets/icons/down-arrow-icon.svg";
import _avatar_image from "../assets/images/avatar-image.png";
import _home_default_icon from "../assets/icons/home-default-icon.svg";
import _home_active_icon from "../assets/icons/home-active-icon.svg";
import _cross_default_icon from "../assets/icons/cross-default-icon.svg";
import _ttn_logo from "../assets/icons/ttn-logo.svg";
import _plus_default_icon from "../assets/icons/plus-default-icon.svg";
import _edit_default_icon from "../assets/icons/edit-default-icon.svg";
import _edit_active_icon from "../assets/icons/edit-active-icon.svg";
import _edit_disabled_icon from "../assets/icons/edit-icon.svg";
import _delete_default_icon from "../assets/icons/delete-default-icon.svg";
import _delete_active_icon from "../assets/icons/delete-active-icon.svg";
import _delete_disabled_icon from "../assets/icons/delete-disabled-icon.svg";
import _download_active_icon from "../assets/icons/download-active-icon.svg";
import _download_disabled_icon from "../assets/icons/download-disabled-icon.svg";
import _link_active_icon from "../assets/icons/link-active-icon.svg";
import _toogle_show_active_icon from "../assets/icons/toogle-show-active-icon.svg";
import _black_plus_default_icon from "../assets/icons/black_plus_default_icon.svg";
import _active_plus_icon from "../assets/icons/active-plus-icon.svg";
import _filter_active_icon from "../assets/icons/filter-active-icon.svg";
import _no_data_default_icon from "../assets/icons/no-data-default-icon.svg";
import _left_arrow_pagination_default_icon from "../assets/icons/left-arrow-pagination-default-icon.svg";
import _right_arrow_pagination_default_icon from "../assets/icons/right-arrow-pagination-default-icon.svg";
import _message_active_icon from "../assets/icons/message-active-icon.svg";
import _download_arrow_active_icon from "../assets/icons/download-arrow-active-icon.svg";
import _chat_active_icon from "../assets/icons/chat-active-icon.svg";
import _users_default_icon from "../assets/icons/users-default-icon.svg";
import _users_active_icon from "../assets/icons/users-active-icon.svg";
import _location_room_default_icon from "../assets/icons/location-room-default-icon.svg";
import _location_room_active_icon from "../assets/icons/location-room-active-icon.svg";
import _list_default_icon from "../assets/icons/list-default-image-icon.svg";
import _list_active_icon from "../assets/icons/list-active-image-icon.svg";
import _active_minus_icon from "../assets/icons/active-minus-icon.svg";
import _calander_active_icon from "../assets/icons/calander-active-icon.svg";
import _laptop_active_icon from "../assets/icons/laptop-active-icon.svg";
import _user_setting_active_icon from "../assets/icons/user-setting-active-icon.svg";
import _spoc_active_icon from "../assets/icons/spocs-active-icon.svg";
import _trainee_active_icon from "../assets/icons/trainee-active-icon.svg";
import _right_arrow_active_icon from "../assets/icons/right-arrow-active-icon.svg";
import _plus_dashed_active_icon from "../assets/icons/plus-dashed-active-icon.svg";
import _clipboard_active_icon from "../assets/icons/clipboard-active-icon.svg";
import _upload_active_icon from "../assets/icons/upload-active-icon.svg";
import _plus_active_icon from "../assets/icons/plus-active-icon.svg";
import _plus_disabled_icon from "../assets/icons/plus-disabled-icon.svg";
import _plus_active_scheduled_icon from "../assets/icons/plus-active-scheduled-icon.svg";
import _plus_active_completed_icon from "../assets/icons/plus-active-completed-icon.svg";
import _plus_active_ongoing_icon from "../assets/icons/plus-active-ongoing-icon.svg";
import _three_dot_list_active_icon from "../assets/icons/three-dot-list-active-icon.svg";
import _topic_default_icon from "../assets/icons/topic-default-icon.svg";
import _topic_active_icon from "../assets/icons/topic-active-icon.svg";
import _nw_bootcamp_logo from "../assets/icons/nw-bootcamp-logo.svg";
import _refresh_active_icon from "../assets/icons/refresh-active-icon.svg";
import _up_sort_arrow_icon from "../assets/icons/up-sort-arrow.svg";
import _down_sort_arrow_icon from "../assets/icons/down-sort-arrow.svg";
import _circle_save_change_icon from "../assets/icons/circle-save-change-icon.svg";
import _disabled_circle_save_change_icon from "../assets/icons/disabled-circle-save-change-icon.svg";
import _cross_red_icon from "../assets/icons/cross-red-icon.svg";
import _disabled_msg_icon from "../assets/icons/disabled-msg-icon.svg";
import _switch_user_icon from "../assets/icons/switch-user-icon.svg";
import _exercises_default_icon from "../assets/icons/exercises-default-icon.svg";
import _exercises_active_icon from "../assets/icons/exercises-active-icon.svg";
import _file_upload_icon from "../assets/icons/file-upload-icon.svg";
import _file_upload_disabled_icon from "../assets/icons/file-upload-disabled-icon.svg";
import _upload_files_icon from "../assets/icons/upload-files-icon.svg";
import _reopen_icon from "../assets/icons/reopen-icon.svg";
import _reopen_disabled_icon from "../assets/icons/reopen-disabled-icon.svg";
import _review_icon from "../assets/icons/review-icon.svg";
import _review_disabled_icon from "../assets/icons/review-disabled-icon.svg";
import _coming_soon_icon from "../assets/icons/coming-soon-icon.svg";
import _timesheet_default_icon from "../assets/icons/timesheet-default-icon.svg";
import _timesheet_active_icon from "../assets/icons/timesheet-active-icon.svg";
import _skill_matrix_default_icon from "../assets/icons/skill-matrix-default-icon.svg";
import _skill_matrix_active_icon from "../assets/icons/skill-matrix-active-icon.svg";
import _asc_sort_icon from "../assets/icons/asc-sort-icon.svg";
import _filter_icon from "../assets/icons/filter-icon.svg";
import _toast_success_icon from "../assets/icons/toast-success-icon.svg";
import _toast_info_icon from "../assets/icons/toast-info-icon.svg";
import _toast_warning_icon from "../assets/icons/toast-warning-icon.svg";
import _toast_error_icon from "../assets/icons/toast-error-icon.svg";
import _close_icon from "../assets/icons/toast-close-icon.svg";
import _placeholder_user_image from "../assets/images/placeholder-user-image.jpg";
import _desc_sort_icon from "../assets/icons/desc-sort-icon.svg";
import _nw_small_logo2 from "../assets/icons/nw-small-logo.svg";
import _up_arrow_icon from "../assets/icons/up-arrow-icon.svg";
import _plus_dashed_default_icon from "../assets/icons/plus-dashed-default-icon.svg";
import _purple_down_arrow_icon from "../assets/icons/purple-down-arrow-icon.svg";
import _custom_edit_icon from "../assets/icons/custom-edit-icon.svg";
import _cross_disabled_icon from "../assets/icons/cross-disabled-icon.svg";
import _associate_question_default_icon from "../assets/icons/associate-questions-default-icon.svg";
import _associate_question_disabled_icon from "../assets/icons/associate-questions-disabled-icon.svg";
import _user_module_bootcamp_icon from "../assets/icons/userModuleBootcamp.svg";
import _edit_reviewer_icon from "../assets/icons/edit-reviewer-icon.svg";
import _edit_reviewer_disabled_icon from "../assets/icons/edit-reviewer-disabled-icon.svg";
import _toggle_right_duotone_active_icon from "../assets/icons/toggle-right-duotone-active-icon.svg";
import _clone_active_icon from "../assets/icons/clone-active-icon.svg";
import _clone_disabled_icon from "../assets/icons/clone-disabled-icon.svg";
import _list_eval_icon from "../assets/icons/list-eval-icon.svg";
import _list_eval_disabled_icon from "../assets/icons/list-eval-disabled-icon.svg";
import _toggle_active_icon from "../assets/icons/toggle-active-icon.svg";
import _toggle_inactive_icon from "../assets/icons/toggle-inactive-icon.svg";
import _listing_icon from "../assets/icons/listing-icon.svg";
import _listing_disabled_icon from "../assets/icons/listing-disabled-icon.svg";
import _lock_enabled_icon from "../assets/icons/lock_enabled_icon.svg";
import _unlock_enabled_icon from "../assets/icons/unlock_enabled_icon.svg";
import _lock_disabled_icon from "../assets/icons/lock_disabled_icon.svg";
import _unlock_disabled_icon from "../assets/icons/unlock_disabled_icon.svg";
import _performance_active_icon from "../assets/icons/performance-active-icon.svg";
import _performance_default_icon from "../assets/icons/performance-default-icon.svg";

export const availability_icon = _availability_icon;
export const dashboard_default_icon = _dashboard_default_icon;
export const dashboard_active_icon = _dashboard_active_icon;
export const menu_bar_icon = _menu_bar_icon;
export const ttn_logo_icon = _ttn_logo_icon;
export const nw_full_logo = _nw_full_logo;
export const nw_full_logo_png = _nw_full_logo_png;
export const nw_small_logo = _nw_small_logo;
export const drawer_default_icon = _drawer_default_icon;
export const drawer_active_icon = _drawer_active_icon;
export const bootcamp_default_icon = _bootcamp_default_icon;
export const bootcamp_active_icon = _bootcamp_active_icon;
export const growth_default_icon = _growth_default_icon;
export const growth_active_icon = _growth_active_icon;
export const asset_default_icon = _asset_default_icon;
export const asset_active_icon = _asset_active_icon;
export const money_default_icon = _money_default_icon;
export const money_active_icon = _money_active_icon;
export const referal_default_icon = _referal_default_icon;
export const referal_active_icon = _referal_active_icon;
export const claims_default_icon = _claims_default_icon;
export const claims_active_icon = _claims_active_icon;
export const down_arrow_icon = _down_arrow_icon;
export const avatar_image = _avatar_image;
export const home_default_icon = _home_default_icon;
export const home_active_icon = _home_active_icon;
export const cross_default_icon = _cross_default_icon;
export const ttn_logo = _ttn_logo;
export const plus_default_icon = _plus_default_icon;
export const edit_default_icon = _edit_default_icon;
export const edit_active_icon = _edit_active_icon;
export const edit_reviewer_icon = _edit_reviewer_icon;
export const edit_reviewer_disabled_icon = _edit_reviewer_disabled_icon;
export const edit_disabled_icon = _edit_disabled_icon;
export const delete_default_icon = _delete_default_icon;
export const delete_active_icon = _delete_active_icon;
export const delete_disabled_icon = _delete_disabled_icon;
export const download_active_icon = _download_active_icon;
export const download_disabled_icon = _download_disabled_icon;
export const link_active_icon = _link_active_icon;
export const toogle_show_active_icon = _toogle_show_active_icon;
export const black_plus_default_icon = _black_plus_default_icon;
export const list_default_icon = _list_default_icon;
export const list_active_icon = _list_active_icon;
export const active_plus_icon = _active_plus_icon;
export const filter_active_icon = _filter_active_icon;
export const no_data_default_icon = _no_data_default_icon;
export const left_arrow_pagination_default_icon =
  _left_arrow_pagination_default_icon;
export const right_arrow_pagination_default_icon =
  _right_arrow_pagination_default_icon;
export const message_active_icon = _message_active_icon;
export const download_arrow_active_icon = _download_arrow_active_icon;
export const chat_active_icon = _chat_active_icon;
export const users_default_icon = _users_default_icon;
export const users_active_icon = _users_active_icon;
export const location_room_default_icon = _location_room_default_icon;
export const location_room_active_icon = _location_room_active_icon;
export const active_minus_icon = _active_minus_icon;
export const calander_active_icon = _calander_active_icon;
export const laptop_active_icon = _laptop_active_icon;
export const user_setting_active_icon = _user_setting_active_icon;
export const spoc_active_icon = _spoc_active_icon;
export const trainee_active_icon = _trainee_active_icon;
export const right_arrow_active_icon = _right_arrow_active_icon;
export const plus_dashed_active_icon = _plus_dashed_active_icon;
export const plus_dashed_default_icon = _plus_dashed_default_icon;
export const clipboard_active_icon = _clipboard_active_icon;
export const upload_active_icon = _upload_active_icon;
export const plus_active_icon = _plus_active_icon;
export const plus_disabled_icon = _plus_disabled_icon;
export const plus_active_ongoing_icon = _plus_active_ongoing_icon;
export const plus_active_completed_icon = _plus_active_completed_icon;
export const plus_active_scheduled_icon = _plus_active_scheduled_icon;
export const three_dot_list_active_icon = _three_dot_list_active_icon;
export const topic_default_icon = _topic_default_icon;
export const topic_active_icon = _topic_active_icon;
export const nw_bootcamp_logo = _nw_bootcamp_logo;
export const refresh_active_icon = _refresh_active_icon;
export const circle_save_change_icon = _circle_save_change_icon;
export const disabled_circle_save_change_icon =
  _disabled_circle_save_change_icon;
export const cross_red_icon = _cross_red_icon;
export const disabled_msg_icon = _disabled_msg_icon;
export const up_sort_arrow_icon = _up_sort_arrow_icon;
export const down_sort_arrow_icon = _down_sort_arrow_icon;
export const switch_user_icon = _switch_user_icon;
export const exercises_default_icon = _exercises_default_icon;
export const exercises_active_icon = _exercises_active_icon;
export const file_upload_icon = _file_upload_icon;
export const file_upload_disabled_icon = _file_upload_disabled_icon;
export const upload_files_icon = _upload_files_icon;
export const reopen_icon = _reopen_icon;
export const reopen_disabled_icon = _reopen_disabled_icon;
export const review_icon = _review_icon;
export const coming_soon_icon = _coming_soon_icon;
export const timesheet_default_icon = _timesheet_default_icon;
export const timesheet_active_icon = _timesheet_active_icon;
export const skill_matrix_default_icon = _skill_matrix_default_icon;
export const skill_matrix_active_icon = _skill_matrix_active_icon;
export const asc_sort_icon = _asc_sort_icon;
export const filter_icon = _filter_icon;
export const toast_success_icon = _toast_success_icon;
export const toast_info_icon = _toast_info_icon;
export const toast_warning_icon = _toast_warning_icon;
export const toast_error_icon = _toast_error_icon;
export const close_icon = _close_icon;
export const placeholder_user_image = _placeholder_user_image;
export const desc_sort_icon = _desc_sort_icon;
export const nw_small_logo2 = _nw_small_logo2;
export const up_arrow_icon = _up_arrow_icon;
export const purple_down_arrow_icon = _purple_down_arrow_icon;
export const custom_edit_icon = _custom_edit_icon;
export const cross_disabled_icon = _cross_disabled_icon;
export const review_disabled_icon = _review_disabled_icon;
export const associate_question_default_icon = _associate_question_default_icon;
export const associate_question_disabled_icon =
  _associate_question_disabled_icon;
export const user_module_bootcamp_icon = _user_module_bootcamp_icon;
export const toggle_right_duotone_active_icon =
  _toggle_right_duotone_active_icon;
export const clone_active_icon = _clone_active_icon;
export const clone_disabled_icon = _clone_disabled_icon;
export const list_eval_icon = _list_eval_icon;
export const list_eval_disabled_icon = _list_eval_disabled_icon;
export const toggle_active_icon = _toggle_active_icon;
export const toggle_inactive_icon = _toggle_inactive_icon;
export const listing_disabled_icon = _listing_disabled_icon;
export const listing_icon = _listing_icon;
export const lock_enabled_icon = _lock_enabled_icon;
export const unlock_enabled_icon = _unlock_enabled_icon;
export const lock_disabled_icon = _lock_disabled_icon;
export const unlock_disabled_icon = _unlock_disabled_icon;
export const performance_active_icon = _performance_active_icon;
export const performance_default_icon = _performance_default_icon;
