import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import Loader from "../../../components/Loader/Loader";
import SimpleAutoComplete from "../../../components/SimpleAutoComplete/SimpleAutoComplete";
import {
  filterOptionsWithSelectAllDesignations,
  sortByFullName,
} from "../../../utils/utils";

const ReviewerEdit = ({
  open,
  handleClose,
  competencyList,
  handleEditReviewerChange,
  editReviewerDetails,
  handleSubmit,
  loader,
}) => {
  const hasDetailsChanged = (initialDetails, currentDetails) => {
    return (
      initialDetails?.designationId?.id !== currentDetails?.designationId?.id ||
      initialDetails?.competencyId?.id !== currentDetails?.competencyId?.id ||
      initialDetails?.reviewerId?.id !== currentDetails?.reviewerId?.id
    );
  };
  const reviewerWithAllDesignation = [].concat(
    ...(editReviewerDetails?.competencyId?.designations?.results
      ?.filter((it) => !!it)
      ?.map((it) => it?.users?.results)
      ?.filter((it) => !!it) || [])
  );
  const options = editReviewerDetails?.designationId
    ? editReviewerDetails?.designationId?.users?.results
    : reviewerWithAllDesignation;

  const reviewerOptions = sortByFullName(options);

  const [initialReviewerDetails, setInitialReviewerDetails] =
    useState(editReviewerDetails);
  const [hasSetInitialDetails, setHasSetInitialDetails] = useState(false);

  useEffect(() => {
    if (!hasSetInitialDetails && editReviewerDetails?.competencyId?.id) {
      setInitialReviewerDetails(editReviewerDetails);
      setHasSetInitialDetails(true);
    }
  }, [editReviewerDetails?.competencyId?.id, hasSetInitialDetails]);

  const editReviewerDetailsChanged = hasDetailsChanged(
    initialReviewerDetails,
    editReviewerDetails
  );
  const handleCloseEditReviewer = () => {
    setHasSetInitialDetails(false);
    handleClose();
  };
  const handleSubmitEditReviewer = () => {
    setHasSetInitialDetails(false);
    handleSubmit();
  };

  return (
    <>
      {loader && <Loader />}
      <Dialog
        open={open}
        onClose={handleCloseEditReviewer}
        className="modal-drawer-container"
        PaperProps={{
          style: {
            borderRadius: "1rem",
            boxShadow: "0rem 0.6rem 3rem 0rem #OD",
            width: "27.125rem",
            zIndex: 1300,
            borderRadius: "1rem",
            margin: 0,
          },
        }}
      >
        <DialogTitle className="dialog-title">Edit Reviewer</DialogTitle>
        <DialogContent className="dialog-content">
          <Box className="width-100">
            <SimpleAutoComplete
              label="Competency"
              placeholder="Select Competency"
              options={competencyList}
              onChange={(event, newValue) =>
                handleEditReviewerChange("competencyId", newValue)
              }
              getOptionLabel={(option) => option?.name}
              value={editReviewerDetails?.competencyId}
              disableClearable={true}
              renderOption={(props, option, value) => {
                return (
                  <li
                    {...props}
                    key={props?.id}
                    className={
                      option?.id === editReviewerDetails?.competencyId?.id
                        ? "multi-tag-edit-list-selected"
                        : "multi-tag-style"
                    }
                  >
                    <Box lineHeight={1.1}>
                      <Box>{`${option?.name}`}</Box>
                    </Box>
                  </li>
                );
              }}
            />
            <SimpleAutoComplete
              label="Designation"
              placeholder="Select Designation"
              options={editReviewerDetails?.competencyId?.designations?.results}
              onChange={(event, newValue) =>
                handleEditReviewerChange("designationId", newValue)
              }
              getOptionLabel={(option) => option?.name}
              filterOptions={(options, params) =>
                filterOptionsWithSelectAllDesignations(
                  options,
                  params,
                  editReviewerDetails?.competencyId?.designations?.results
                    ?.length,
                  reviewerWithAllDesignation
                )
              }
              value={
                editReviewerDetails?.designationId
                  ? editReviewerDetails?.designationId
                  : { name: "All", all: true }
              }
              disableClearable={true}
              renderOption={(props, option, value) => {
                return (
                  <li
                    {...props}
                    key={props?.id}
                    className={
                      (option?.name === "All" &&
                        option?.name === value?.inputValue) ||
                      option?.id === editReviewerDetails?.designationId?.id
                        ? "multi-tag-edit-list-selected"
                        : "multi-tag-style"
                    }
                  >
                    <Box lineHeight={1.1}>
                      <Box>{`${option?.name}`}</Box>
                    </Box>
                  </li>
                );
              }}
            />
            <SimpleAutoComplete
              label="Reviewer"
              placeholder="Select Reviewer"
              options={reviewerOptions}
              filterOptions={(options, { inputValue }) => {
                return options.filter((option) =>
                  `${option.fullName} ${option.email}`
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                );
              }}
              onChange={(event, newValue) =>
                handleEditReviewerChange("reviewerId", newValue)
              }
              getOptionLabel={(option) => option?.fullName}
              value={editReviewerDetails?.reviewerId}
              renderOption={(props, option, value) => {
                return (
                  <li
                    {...props}
                    key={props?.id}
                    className={
                      option?.id === editReviewerDetails?.reviewerId?.id
                        ? "multi-tag-edit-list-selected"
                        : "multi-tag-style"
                    }
                  >
                    <Box lineHeight={1.1}>
                      <Box>{`${option?.fullName}`}</Box>
                      <Typography variant="caption" className="filter-title ">
                        {`${option?.email}`}
                      </Typography>
                    </Box>
                  </li>
                );
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "0rem 2rem 1.875rem" }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCloseEditReviewer}
            className="add-role-btn"
          >
            <Typography variant="outlineBtnLabel">Cancel</Typography>
          </Button>
          <Button
            onClick={handleSubmitEditReviewer}
            variant="contained"
            color="secondary"
            className="add-role-btn"
            disabled={!editReviewerDetailsChanged}
          >
            <Typography variant="outlineBtnLabel">Save</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReviewerEdit;
