import React, { useState } from "react";
import "./submitFeedbackModal.scss";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import Loader from "../Loader/Loader";
const SubmitFeedbackModal = ({
  open,
  handleClose,
  submitFeedback,
  intervieweeName,
}) => {
  const [loader, setLoader] = useState(false);

  const onSubmit = async () => {
    setLoader(true);
    await submitFeedback(interviewerFeedback);
    setInterviewerFeedback("");
    setLoader(false);
  };

  const [interviewerFeedback, setInterviewerFeedback] = useState("");
  const handleInputCommentChange = (comment) => {
    setInterviewerFeedback(comment);
  };
  const handleCancel = () => {
    setInterviewerFeedback("");
    handleClose();
  };

  return (
    <>
      {loader && <Loader />}
      <Dialog
        className="modal-drawer-container"
        open={open}
        onClose={handleCancel}
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "1rem",
            boxShadow: "0rem 0.6rem 3rem 0rem #OD",
            zIndex: 1300,
            maxWidth: "29.75rem",
          },
        }}
      >
        <DialogTitle className="dialog-title">
          {`Feedback for ${intervieweeName}`}
        </DialogTitle>
        <DialogContent
          className="dialog-content"
          sx={{ marginBottom: "0 !important" }}
        >
          <Box className="width-100">
            <Box
              sx={{
                marginBottom: "0.75rem",
              }}
            >
              <TextField
                multiline
                minRows={5}
                label="Comment"
                color="secondary"
                fullWidth
                className="filter-inputs"
                variant="outlined"
                size="small"
                margin="dense"
                value={interviewerFeedback}
                onChange={(e) => handleInputCommentChange(e?.target?.value)}
                required={true}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className="delete-dialog-action">
          <Button
            fullWidth
            disabled={false}
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
            className="addTagButtonStyle"
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disableElevation
          >
            <Typography variant="font_12_bold_700">Cancel</Typography>
          </Button>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            onClick={onSubmit}
            className="addTagButtonStyle"
            disabled={!interviewerFeedback}
            disableFocusRipple
            disableRipple
            disableTouchRipple
            disableElevation
          >
            <Typography variant="font_12_bold_700">Submit</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SubmitFeedbackModal;
