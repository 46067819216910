import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  createFilterOptions,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { cross_default_icon } from "../../constants/icons";
import IconButtons from "../../components/IconButtons/IconButtons";
import { calculateFilterCount, font_15 } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import AutoCompleteTagInput from "../../components/AutoCompleteTagInput/AutoCompleteTagInput";
import { useParams } from "react-router-dom";
import { setAssessmentQuestionsData } from "../../store/assessmentQuestions";

const AssessmentFilter = ({
  filterOpen,
  filterActivated,
  setFilterActivated,
  filterCount,
  setFilterCount,
  setFilterOpen,
  initialFilter,
  filterAssessment,
  setFilterAssessment,
}) => {
  const questionTypes = useSelector(
    (state) => state?.utility?.constants?.questionTypes
  );
  const difficultyLevels = useSelector(
    (state) => state?.utility?.constants?.difficultyLevels
  );
  const assessmentQuestions = useSelector(
    (state) => state?.assessmentQuestions
  );
  const assessmentQuestionsLoader =
    assessmentQuestions?.assessmentQuestionsLoader;

  const dispatch = useDispatch();
  const params = useParams();
  const handleFilterClose = () => {
    setFilterOpen(false);
  };
  const handleChangeFilterAssessment = (name, value) => {
    setFilterAssessment({ ...filterAssessment, [name]: value });
  };

  const handleFilterSessionSubmit = async () => {
    const newFilterCount = calculateFilterCount(filterAssessment);
    const filteredFields = {
      ...filterAssessment,
      title: filterAssessment?.title.trim(),
      type: filterAssessment?.type?.map((item) => item?.key),
      difficultyLevel: filterAssessment?.difficultyLevel?.map(
        (item) => item?.key
      ),
      showActive:
        filterAssessment.showActive === "showActive"
          ? true
          : filterAssessment.showActive === "showInactive"
          ? false
          : null,
    };
    const payload = {
      ...filteredFields,
      topicId: params?.topicId,
    };
    if (newFilterCount || filterActivated) {
      await dispatch(setAssessmentQuestionsData(payload));
      setFilterCount(newFilterCount);
      setFilterActivated(newFilterCount);
    }
    setFilterOpen(false);
  };

  const handleFilterSessionRemove = async () => {
    if (filterCount) {
      const filteredFields = {
        title: "",
        type: [],
        difficultyLevel: [],
        showActive: null,
      };
      const payload = {
        ...filteredFields,
        topicId: params?.topicId,
      };
      await dispatch(setAssessmentQuestionsData(payload));
      setFilterCount(initialFilter);
      setFilterActivated(false);
    }
    setFilterAssessment({
      ...initialFilter,
    });
    setFilterOpen(false);
  };

  return (
    <>
      {assessmentQuestionsLoader && <Loader />}
      <Drawer
        className="filter-drawer-container"
        anchor="right"
        open={filterOpen}
        onClose={handleFilterClose}
      >
        <Grid container className="filter-main-container">
          <Grid item md={12} pt={2} className="drawer-input-container">
            <Box className="filter-head">
              <Typography variant="font_20" className="filter-title ">
                Filters
              </Typography>
              <IconButtons
                handleClick={handleFilterClose}
                height={font_15}
                width={font_15}
                image={cross_default_icon}
                tooltip="Close Filter"
              />
            </Box>
            <Box className="filter-padding textfield-box">
              <TextField
                size="small"
                label="Title/Description"
                color="secondary"
                fullWidth
                className="filter-inputs"
                margin="dense"
                variant="outlined"
                value={filterAssessment?.title}
                onChange={(e) =>
                  handleChangeFilterAssessment("title", e?.target?.value)
                }
              />
              <Box>
                <FormControl component="fieldset">
                  <FormLabel
                    component="legend"
                    sx={{ color: "#2E2C41 !important", paddingTop: "0.625rem" }}
                  >
                    Status
                  </FormLabel>
                  <RadioGroup
                    aria-label="status"
                    name="status"
                    value={filterAssessment?.showActive}
                    onChange={(e) =>
                      handleChangeFilterAssessment("showActive", e.target.value)
                    }
                    sx={{ flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="showAll"
                      control={
                        <Radio
                          color="secondary"
                          size="small"
                          sx={{ padding: "0.313rem" }}
                        />
                      }
                      label="Show All"
                    />
                    <FormControlLabel
                      value="showActive"
                      control={
                        <Radio
                          color="secondary"
                          size="small"
                          sx={{ padding: "0.313rem" }}
                        />
                      }
                      label="Show Active"
                    />
                    <FormControlLabel
                      value="showInactive"
                      control={
                        <Radio
                          color="secondary"
                          size="small"
                          sx={{ padding: "0.313rem" }}
                        />
                      }
                      label="Show Inactive"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box mb={1} mt={1} className="width-100">
                <AutoCompleteTagInput
                  label="Type"
                  placeholder={
                    filterAssessment?.type?.length ? null : "Select Type"
                  }
                  options={questionTypes}
                  selectedValue={filterAssessment?.type}
                  getOptionLabel={(option) => option?.value}
                  filterOptions={(options, params) => {
                    // <<<--- inject the Select All option
                    const filter = createFilterOptions();
                    const filtered = filter(options, params);
                    if (questionTypes?.length === 0) {
                      return [{ name: "No options", all: false }];
                    }
                    return [{ value: "Select All", all: true }, ...filtered];
                  }}
                  onChange={(event, newValue) => {
                    const idCounts = newValue.reduce((counts, { key }) => {
                      counts[key] = (counts[key] || 0) + 1;
                      return counts;
                    }, {});
                    const uniqueNewValue = newValue.filter(({ key }) => {
                      return idCounts[key] === 1;
                    });
                    if (newValue.find((option) => option.all)) {
                      return handleChangeFilterAssessment(
                        "type",
                        filterAssessment?.type?.length === questionTypes?.length
                          ? []
                          : questionTypes
                      );
                    }
                    handleChangeFilterAssessment("type", uniqueNewValue);
                  }}
                  renderOption={(props, option, value) => {
                    // Check if option is "No options" and render accordingly
                    if (option.name === "No options") {
                      return (
                        <Box className="auto-complete-no-options">
                          <Box>{option?.value}</Box>
                        </Box>
                      );
                    }
                    const optionChecked =
                      (option.all &&
                        filterAssessment?.type?.length ===
                          questionTypes?.length) ||
                      filterAssessment?.type?.find(
                        (e) => e?.key === option?.key
                      ) != null;
                    return (
                      <li
                        {...props}
                        key={props?.key}
                        className={
                          optionChecked
                            ? "multi-tag-edit-list-selected"
                            : "multi-tag-style"
                        }
                      >
                        <Box className="multi-tag-input-list-padding">
                          <Checkbox
                            className="auto-complete-checkbox-list"
                            size="small"
                            checked={optionChecked}
                            color="secondary"
                          />
                          {option?.value}
                        </Box>
                      </li>
                    );
                  }}
                />
              </Box>
              <Box mb={1} mt={1} className="width-100">
                <AutoCompleteTagInput
                  label="Difficulty Level"
                  placeholder={
                    filterAssessment?.difficultyLevel?.length
                      ? null
                      : "Select Difficulty Level"
                  }
                  options={difficultyLevels}
                  selectedValue={filterAssessment?.difficultyLevel}
                  getOptionLabel={(option) => option?.value}
                  filterOptions={(options, params) => {
                    // <<<--- inject the Select All option
                    const filter = createFilterOptions();
                    const filtered = filter(options, params);
                    if (difficultyLevels?.length === 0) {
                      return [{ name: "No options", all: false }];
                    }
                    return [{ value: "Select All", all: true }, ...filtered];
                  }}
                  onChange={(event, newValue) => {
                    const idCounts = newValue.reduce((counts, { key }) => {
                      counts[key] = (counts[key] || 0) + 1;
                      return counts;
                    }, {});
                    const uniqueNewValue = newValue.filter(({ key }) => {
                      return idCounts[key] === 1;
                    });
                    if (newValue.find((option) => option?.all)) {
                      return handleChangeFilterAssessment(
                        "difficultyLevel",
                        filterAssessment?.difficultyLevel?.length ===
                          difficultyLevels?.length
                          ? []
                          : difficultyLevels
                      );
                    }
                    handleChangeFilterAssessment(
                      "difficultyLevel",
                      uniqueNewValue
                    );
                  }}
                  renderOption={(props, option, value) => {
                    // Check if option is "No options" and render accordingly
                    if (option.name === "No options") {
                      return (
                        <Box className="auto-complete-no-options">
                          <Box>{option?.value}</Box>
                        </Box>
                      );
                    }
                    const optionChecked =
                      (option.all &&
                        filterAssessment?.difficultyLevel?.length ===
                          difficultyLevels?.length) ||
                      filterAssessment?.difficultyLevel?.find(
                        (e) => e?.key === option?.key
                      ) != null;
                    return (
                      <li
                        {...props}
                        key={props?.key}
                        className={
                          optionChecked
                            ? "multi-tag-edit-list-selected"
                            : "multi-tag-style"
                        }
                      >
                        <Box className="multi-tag-input-list-padding">
                          <Checkbox
                            className="auto-complete-checkbox-list"
                            size="small"
                            checked={optionChecked}
                            color="secondary"
                          />
                          {option?.value}
                        </Box>
                      </li>
                    );
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md={12} className="filter-padding filter-btn-container">
            <Button
              onClick={handleFilterSessionSubmit}
              variant="contained"
              color="secondary"
              className="filter-btn margin-right-1"
            >
              <Typography variant="font_12_bold_700">Apply Filters</Typography>
            </Button>

            <Button
              onClick={handleFilterSessionRemove}
              variant="outlined"
              color="secondary"
              className="filter-btn"
            >
              <Typography variant="font_12_bold_700">Reset</Typography>
            </Button>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};

export default AssessmentFilter;
