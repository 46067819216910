import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDate, getDateTime } from "./../../utils/utils";
import QuestionPoolTable from "../../components/QuestionPool/QuestionPoolTable";
import QuestionTypeTable from "../../components/QuestionType/QuestionTypeTable";
import ViewAssessmentTable from "./ViewAssessmentTable";
import { setAssessmentQuestionsData } from "../../store/createAssessment";
import { useDispatch, useSelector } from "react-redux";
import { AssessmentTypes } from "../../constants/constants";

const { MCQ } = AssessmentTypes;

const ViewAssessmentInfo = ({ infoData, assessmentQuestionsData }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { assessmentId } = params;
  const [offset, setOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const assessmentTypes = useSelector(
    (state) => state?.utility?.constants?.assessmentTypes
  );

  const handlePageChange = (value) => {
    setOffset(value - 1);
    setCurrentPage(value);
  };
  const totalResult = assessmentQuestionsData?.totalResults;
  useEffect(() => {
    setTotalPages(Math.ceil(totalResult / 20));
  }, [totalResult]);

  const fetchAssessmentQuestions = async () => {
    await dispatch(
      setAssessmentQuestionsData({
        assessmentId,
        offset,
      })
    );
  };

  useEffect(() => {
    fetchAssessmentQuestions();
  }, [dispatch, offset]);

  /////////////////////////////////////////////////////////////

  const [assessmentDetails, setAssessmentDetails] = useState({
    topicQuestions: null,
  });
  useEffect(() => {
    if (infoData && assessmentTypes) {
      setAssessmentDetails({
        topicQuestions: infoData?.assessmentTopicQuestions?.results?.map(
          (item) => ({
            topic: item?.topic,
            easyQuestionsCount: item?.easyQuestionsCount,
            mediumQuestionsCount: item?.mediumQuestionsCount,
            hardQuestionsCount: item?.hardQuestionsCount,
          })
        ),
        type: assessmentTypes?.find((item) => item?.key === infoData?.type),
      });
    }
  }, [infoData, assessmentTypes]);

  return (
    <Box className="margin-bottom-5">
      <Grid container className="view-assessment-container">
        <Grid item md={7} className="view-assessment-content">
          <Box className="view-assessment-info-box">
            <Typography variant="font_12" className="grey-text">
              Description
            </Typography>
            <Typography
              variant="font_13"
              sx={{
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
                overflowWrap: "break-word",
              }}
            >
              {infoData?.description ? infoData?.description : "-"}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={5} className="view-assessment-right-info">
          <Grid container item md={12} sx={{ height: "50%" }}>
            <Grid item md={6} className="view-assessment-info-box1">
              <Box className="view-assessment-info-box">
                <Typography variant="font_12" className="grey-text">
                  Start Date
                </Typography>
                <Typography variant="font_14">
                  {getDateTime(infoData?.activeFromDate)}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} className="view-assessment-info-box2">
              <Box className="view-assessment-info-box">
                <Typography variant="font_12" className="grey-text">
                  End Date
                </Typography>
                <Typography variant="font_14">
                  {getDateTime(infoData?.activeToDate)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container item md={12} sx={{ height: "50%" }}>
            <Grid
              item
              md={infoData?.type === MCQ ? 4 : 6}
              className="view-assessment-info-box3"
            >
              <Box className="view-assessment-info-box">
                <Typography variant="font_12" className="grey-text">
                  Created By
                </Typography>
                <Typography variant="font_14">
                  {infoData?.createdBy?.fullName}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              md={infoData?.type === MCQ ? 4 : 6}
              className="view-assessment-info-box4"
            >
              <Box className="view-assessment-info-box">
                <Typography variant="font_12" className="grey-text">
                  Created On
                </Typography>
                <Typography variant="font_14">
                  {getDate(infoData?.dateCreated)}
                </Typography>
              </Box>
            </Grid>
            {infoData?.type === MCQ && (
              <Grid item md={4} className="view-assessment-info-box5">
                <Box className="view-assessment-info-box">
                  <Typography variant="font_12" className="grey-text">
                    Duration (in minutes)
                  </Typography>
                  <Typography variant="font_14">
                    {infoData?.durationInMinutes}
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container className="view-assessment-ins-container">
        <Grid item md={12} className="view-assessment-instruction">
          <Box className="view-assessment-info-box">
            <Typography variant="font_12" className="grey-text">
              Instructions
            </Typography>
            {infoData?.instruction ? (
              <Box className="insturctions-details">
                <div
                  dangerouslySetInnerHTML={{ __html: infoData?.instruction }}
                />
              </Box>
            ) : (
              "-"
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid className="assessment-fields">
        <Box
          sx={{
            margin: "1.25rem 0 0.75rem",
          }}
        >
          <QuestionPoolTable
            viewAssessment={true}
            assessmentTopicQuestions={
              infoData?.assessmentTopicQuestions?.results
            }
            assessmentDetails={assessmentDetails}
          />
        </Box>
      </Grid>
      <Grid className="assessment-fields">
        <Box
          sx={{
            marginBottom: "0.75rem",
          }}
        >
          <Box sx={{ marginBottom: "0.75rem" }}>
            <Typography variant="font_14">Score Per Question Type</Typography>
          </Box>
          <QuestionTypeTable
            selectedSessions={infoData?.sessionIds}
            assessmentDetails={infoData}
            viewAssessment={true}
          />
        </Box>
      </Grid>
      <Grid>
        <ViewAssessmentTable
          assessmentType={infoData?.type}
          assessmentQuestionsData={assessmentQuestionsData}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
        />
      </Grid>
    </Box>
  );
};

export default ViewAssessmentInfo;
