import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBatchesListApi, sendEmailApi } from "../services/emailModal";
import toast from "react-hot-toast";
import CustomToaster from "../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../constants/constants";

const initialState = {
  batches: [],
  loading: false,
  error: null,
  batchLoading: false,
  sendEmailLoading: false,
};

export const getBatchesList = createAsyncThunk(
  "getBatchesList",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getBatchesListApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendEmail = createAsyncThunk(
  "sendEmail",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await sendEmailApi(data);
      toast.custom(
        <CustomToaster
          message="Email Sent Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const emailModal = createSlice({
  name: "emailModal",
  initialState,
  reducers: {
    clearBatchesDetails: (state) => {
      state.batches = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBatchesList.pending, (state) => {
        state.batchLoading = true;
      })
      .addCase(getBatchesList.fulfilled, (state, action) => {
        state.batchLoading = false;
        state.batches = action.payload;
      })
      .addCase(getBatchesList.rejected, (state, action) => {
        state.batchLoading = false;
        state.error = action.payload;
      })
      .addCase(sendEmail.pending, (state) => {
        state.sendEmailLoading = true;
      })
      .addCase(sendEmail.fulfilled, (state) => {
        state.sendEmailLoading = false;
      })
      .addCase(sendEmail.rejected, (state, action) => {
        state.sendEmailLoading = false;
        state.error = action.payload;
      });
  },
});

export const { clearBatchesDetails } = emailModal.actions;
export default emailModal.reducer;
