import { apiVersion } from "../constants/constants";
import ApiService from "../utils/api-service";

export function getAssessmentEvaluationByIdApi(id) {
  const options = {
    method: "GET",
    url: `${apiVersion}/assessmentEvaluation/${id}`,
    data: id,
  };
  return ApiService(options);
}

export function updateAssessmentEvaluationApi(data) {
  let apiUrl = `${apiVersion}/assessmentEvaluation/play`;

  const options = {
    method: "POST",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}
