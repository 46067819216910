import { apiVersion } from "../constants/constants";
import ApiService from "../utils/api-service";

export function addBootcampApi(data) {
  const options = {
    method: "POST",
    url: `${apiVersion}/bootcamps`,
    data: data,
  };
  return ApiService(options);
}

export function editBootcampApi(data) {
  const options = {
    method: "PATCH",
    url: `${apiVersion}/bootcamps`,
    data: data,
  };
  return ApiService(options);
}

export function deleteBootcampApi(bootcampId) {
  const options = {
    method: "DELETE",
    url: `${apiVersion}/bootcamps/${bootcampId}`,
  };
  return ApiService(options);
}

export function getBootcampApi(data) {
  let apiUrl = `${apiVersion}/bootcamps?max=${data?.max ? data?.max : 20}`;

  if (data?.offset !== undefined) {
    apiUrl += `&offset=${data?.offset}`;
  }

  if (data?.name) {
    apiUrl += `&name=${data?.name}`;
  }

  if (data?.status) {
    apiUrl += `&status=${data?.status}`;
  }

  if (data?.competencyId) {
    apiUrl += `&competencyId=${data?.competencyId}`;
  }

  if (data?.startDate) {
    apiUrl += `&startDate=${data?.startDate}`;
  }

  if (data?.endDate) {
    apiUrl += `&endDate=${data?.endDate}`;
  }

  if (data?.sortBy) {
    apiUrl += `&sortBy=${data?.sortBy}`;
  }

  if (data?.sortOrder) {
    apiUrl += `&sortOrder=${data?.sortOrder}`;
  }

  if (data?.bootcampId) {
    apiUrl += `&bootcampId=${encodeURIComponent(data?.bootcampId)}`;
  }
  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };

  return ApiService(options);
}

export function getBootcampOptionsApi(data) {
  let apiUrl = `${apiVersion}/bootcamps?paginated=false&status=ACTIVE,SCHEDULED&sortBy=startDate&sortOrder=ASC`;
  if (data?.endDate) {
    apiUrl += `&endDate=${data?.endDate}`;
  }
  if (data?.sortBy) {
    apiUrl += `&sortBy=${data?.sortBy}`;
  }

  if (data?.orderBy) {
    apiUrl += `&sortOrder=${data?.sortOrder}`;
  }
  const options = {
    method: "GET",
    url: apiUrl,
  };

  return ApiService(options);
}

export function getCompetenciesApi(data) {
  const options = {
    method: "GET",
    url: `${apiVersion}/competencies`,
    data: data,
  };
  return ApiService(options);
}

export function getUsersApi({ competencyId, bootcampId }) {
  let apiUrl = `${apiVersion}/users?paginated=false&getReducedDetails=true`;

  if (competencyId) {
    apiUrl += `&competencyIds=${competencyId}`;
  }
  if (bootcampId) {
    apiUrl += `&notAttendeeInBootcamp=${bootcampId}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getModulesApi({ startDate, bootcampId }) {
  let apiUrl = `${apiVersion}/modules?paginated=false&sortBy=startDate&sortOrder=ASC`;

  if (bootcampId !== undefined) {
    apiUrl += `&bootcampId=${bootcampId}`;
  }

  if (startDate !== undefined) {
    apiUrl += `&startDate=${encodeURIComponent(startDate)}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getAllModulesApi(data) {
  const { startDate } = data;
  let apiUrl = `${apiVersion}/modules?paginated=false&statuses=ACTIVE,SCHEDULED&sortBy=startDate`;

  if (startDate !== undefined && startDate !== null) {
    apiUrl += `&startDate=${startDate}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getBootcampByIdApi({ bootcampId }) {
  let apiUrl = `${apiVersion}/bootcamps/${bootcampId}`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getCreateEditBootcampOptionsApi({ startDate, bootcampId }) {
  let apiUrl = `${apiVersion}/utilities/optionsForCreateEditBootcamp?startDate=${startDate}`;
  if(bootcampId){
    apiUrl +=  `&bootcampId=${bootcampId}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getCreateEditModuleOptionsApi({ startDate }) {
  let apiUrl = `${apiVersion}/utilities/optionsForCreateEditModule?startDate=${startDate}`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getFiltersForBootcampApi() {
  let apiUrl = `${apiVersion}/utilities/filtersForBootcampsListing`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getCloneModuleOptionsApi() {
  let apiUrl = `${apiVersion}/utilities/optionsForCloneModule`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}