import { apiVersion } from "../constants/constants";
import ApiService from "../utils/api-service";

export function getBootcampModuleViewApi(data) {
  let apiUrl = `${apiVersion}/modules/${data.moduleId}`;

  if (
    data?.bootcampId !== undefined &&
    data?.bootcampId !== null &&
    data?.bootcampId !== ""
  ) {
    apiUrl += `?bootcampId=${data?.bootcampId}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function deleteBootcampModuleViewApi(data) {
  const options = {
    method: "DELETE",
    url: `${apiVersion}/modules/${data.moduleId}`,
  };
  return ApiService(options);
}

export function getBootcampModuleBatchApi(data) {
  let apiUrl = `${apiVersion}/moduleBatches?paginated=false`;

  if (data?.moduleId !== undefined) {
    apiUrl += `&moduleId=${data?.moduleId}`;
  }

  if (data?.bootcampId !== undefined) {
    apiUrl += `&bootcampId=${data?.bootcampId}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function editBootcampModuleBatchApi(data) {
  const options = {
    method: "PATCH",
    url: `${apiVersion}/moduleBatches`,
    data: data,
  };
  return ApiService(options);
}

export function deleteBootcampModuleBatchApi(data) {
  const options = {
    method: "DELETE",
    url: `${apiVersion}/moduleBatches/${data.batchId}`,
  };
  return ApiService(options);
}

export function getBootcampApi(data) {
  let apiUrl = `${apiVersion}/bootcamps?paginated=false&sortBy=startDate`;
  if (data?.endDate) {
    apiUrl += `&endDate=${data?.endDate}`;
  }
  if (data?.moduleId) {
    apiUrl += `&moduleId=${data?.moduleId}`;
  }
  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function editBootcampModuleApi(data) {
  const options = {
    method: "PATCH",
    url: `${apiVersion}/modules`,
    data: data,
  };
  return ApiService(options);
}

export function addBatchCreateApi(data) {
  let apiUrl = `${apiVersion}/moduleBatches`;

  const options = {
    method: "POST",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function addBatchReCreateApi(data) {
  let apiUrl = `${apiVersion}/moduleBatches/generate?moduleId=${data?.moduleId}&recreate=true`;
  const options = {
    method: "POST",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function getRoomListApi(data) {
  let apiUrl = `${apiVersion}/rooms`;

  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function getBootcampByIdApi({ bootcampId }) {
  let apiUrl = `${apiVersion}/bootcamps/${bootcampId}`;

  const options = {
    method: "GET",
    url: apiUrl,
  };
  return ApiService(options);
}

export function getBatchAttendeesApi(data) {
  let apiUrl = `${apiVersion}/moduleAttendees?paginated=false&sortBy=attendeeName&notInModule=true`;

  if (data?.moduleId) {
    apiUrl += `&moduleId=${data?.moduleId}`;
  }

  if (data?.bootcampIds) {
    apiUrl += `&bootcampIds=${data?.bootcampIds}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}
