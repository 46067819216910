import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAssessmentEvaluationsApi,
  getAssessmentsFiltersDataApi,
} from "../services/assessments";

const initialState = {
  assessmentEvaluationsList: [],
  loading: false,
  filtersDataLoading: false,
  filterDataList: [],
  error: null,
};

export const getAssessmentEvaluations = createAsyncThunk(
  "getAssessmentEvaluations",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getAssessmentEvaluationsApi(data);
      return callApi;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getAssessmentsFiltersData = createAsyncThunk(
  "getAssessmentsFiltersData",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getAssessmentsFiltersDataApi(data);
      return callApi;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const assessmentEvaluations = createSlice({
  name: "assessmentEvaluations",
  initialState,
  reducers: {
    clearFilterAssessmentEvaluations: (state) => {
      state.filterDataList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssessmentEvaluations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssessmentEvaluations.fulfilled, (state, action) => {
        state.loading = false;
        state.assessmentEvaluationsList = action.payload;
      })
      .addCase(getAssessmentEvaluations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAssessmentsFiltersData.pending, (state) => {
        state.filtersDataLoading = true;
      })
      .addCase(getAssessmentsFiltersData.fulfilled, (state, action) => {
        state.filtersDataLoading = false;
        state.filterDataList = action.payload;
      })
      .addCase(getAssessmentsFiltersData.rejected, (state, action) => {
        state.filtersDataLoading = false;
        state.error = action.payload;
      });
  },
});

export const { clearFilterAssessmentEvaluations } =
  assessmentEvaluations.actions;
export default assessmentEvaluations.reducer;
