import React from "react";
import "./iconButtons.scss";
import { Box, IconButton, Typography } from "@mui/material";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";

const IconButtons = ({
  image,
  width,
  height,
  handleClick,
  tooltip,
  classList,
  disabled,
  disabledImage,
  count,
  disabledTooltip,
  style,
}) => {
  return (
    <BootstrapTooltip title={disabled ? disabledTooltip : tooltip}>
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          onClick={handleClick}
          sx={{ padding: 0, ...style }}
          className={classList}
          disabled={disabled}
        >
          <Box
            className={"icon-buttons " + (classList ? classList : "")}
            sx={{
              backgroundImage: `url(${disabled ? disabledImage : image})`,
              height: `${height}rem`,
              width: `${width}rem`,
            }}
          >
            {count > 0 && (
              <Typography variant="body2" className="floating-badge">
                {count}
              </Typography>
            )}
          </Box>
        </IconButton>
      </Box>
    </BootstrapTooltip>
  );
};

export default IconButtons;
