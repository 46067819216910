import React from "react";
import "./RoundButton.scss";
import { Box } from "@mui/material";

const RoundButton = ({
  width,
  height,
  imageDefault,
  imageHover,
  handleClick,
  customClass,
  linkTo,
}) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${imageDefault})`,
        width: `${width}rem`,
        height: `${height}rem`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
        ":hover": {
          backgroundImage: `url(${imageHover})`,
        },
      }}
      disableRipple={true}
      className={customClass}
      handleClick={handleClick}
    ></Box>
  );
};

export default RoundButton;
