import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { no_data_default_icon } from "../../constants/icons";

const NoDataAvailable = ({ imgWidth, imgHeight, fontSize, message }) => {
  return (
    <>
      <Paper
        elevation={0}
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "2rem",
        }}
      >
        <Box
          sx={{
            width: `${imgWidth}rem`,
            height: `${imgHeight}rem`,
            backgroundImage: `url(${no_data_default_icon})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            marginBottom: "2rem",
          }}
        ></Box>
        <Typography variant="font_20_bold_700" align="center">
          {message}
        </Typography>
      </Paper>
    </>
  );
};

export default NoDataAvailable;
