import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import {
  addSubmitDetailsApi,
  createAllExerciseStatusesApi,
  deleteExerciseStatusApi,
  getBatchesApi,
  getCompetencyApi,
  getExerciseStatusApi,
  getFiltersForExerciseTabApi as getFiltersForExerciseStatusTabApi,
  getOptionsForEditReviewerApi,
  getUsersApi,
  sendExerciseReminderApi,
} from "../../services/SessionView/exerciseStatusTab";
import CustomToaster from "../../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../../constants/constants";

const initialState = {
  exercise: [],
  loading: false,
  exerciseLoading: false,
  error: null,
  filterCount: 0,
  competencyIds: [],
  batchIds: null,
  apiLoading: false,
  userLoading: false,
  users: [],
  filtersForExerciseTab: null,
  addSubmitDetailsLoading: false,
  editReviewerLoading: false,
  optionsForEditReviewer: null,
  sendExerciseReminderLoading: false,
};

export const getExerciseStatus = createAsyncThunk(
  "getExerciseStatus",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getExerciseStatusApi(data);
      return callApi;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const deleteExerciseStatus = createAsyncThunk(
  "deleteExerciseStatus",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await deleteExerciseStatusApi(data);
      return callApi;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const createAllExerciseStatuses = createAsyncThunk(
  "createAllExerciseStatuses",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await createAllExerciseStatusesApi(data);

      callApi?.data?.results?.length
        ? toast.custom(
            <CustomToaster
              message={"Exercises Created Successfully"}
              title={TOAST_TITLE.SUCCESS}
              time={TOAST_TIME.FIVE}
              type={TOAST_TYPE.SUCCESS}
            />
          )
        : toast.custom(
            <CustomToaster
              message={"No New Exercises Created"}
              title={TOAST_TITLE.SUCCESS}
              time={TOAST_TIME.FIVE}
              type={TOAST_TYPE.SUCCESS}
            />
          );

      return callApi;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getCompetency = createAsyncThunk(
  "getCompetency",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getCompetencyApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBatches = createAsyncThunk(
  "getBatches",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getBatchesApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const addSubmitDetails = createAsyncThunk(
  "addSubmitDetails",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await addSubmitDetailsApi(data);
      toast.custom(
        <CustomToaster
          message={
            data?.type === "SUBMIT"
              ? "Exercise Submitted Successfully"
              : data?.type === "REVIEW"
              ? "Exercise Reviewed Successfully"
              : data?.type === "EDIT_SCORE"
              ? "Exercise Score Edited Successfully"
              : data?.type === "EDIT_REVIEWER"
              ? "Reviewer Edited Successfully"
              : data?.type === "REOPEN"
              ? "Exercise Reopened Successfully"
              : "Details Submitted Successfully"
          }
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const getUsers = createAsyncThunk(
  "getUsers",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getUsersApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getFiltersForExerciseStatusTab = createAsyncThunk(
  "getFiltersForExerciseTab",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getFiltersForExerciseStatusTabApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendExerciseReminder = createAsyncThunk(
  "sendExerciseReminder",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await sendExerciseReminderApi(data);
      toast.custom(
        <CustomToaster
          message="Reminder Sent Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getOptionsForEditReviewer = createAsyncThunk(
  "getOptionsForEditReviewer",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getOptionsForEditReviewerApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const exerciseStatusTab = createSlice({
  name: "exerciseStatusTab",
  initialState,
  reducers: {
    countExerciseFilter(state, action) {
      state.filterCount = action.payload;
    },
    clearExercise(state) {
      state.exercise = [];
    },
    clearExerciseFilters(state) {
      state.filtersForExerciseTab = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExerciseStatus.pending, (state) => {
        state.exerciseLoading = true;
      })
      .addCase(getExerciseStatus.fulfilled, (state, action) => {
        state.exerciseLoading = false;
        state.exercise = action.payload;
      })
      .addCase(getExerciseStatus.rejected, (state, action) => {
        state.exerciseLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteExerciseStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteExerciseStatus.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteExerciseStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createAllExerciseStatuses.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAllExerciseStatuses.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createAllExerciseStatuses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCompetency.pending, (state) => {
        state.apiLoading = true;
      })
      .addCase(getCompetency.fulfilled, (state, action) => {
        state.apiLoading = false;
        state.competencyIds = action.payload;
      })
      .addCase(getCompetency.rejected, (state, action) => {
        state.apiLoading = false;
        state.error = action.payload;
      })
      .addCase(getBatches.pending, (state) => {
        state.batchLoading = true;
      })
      .addCase(getBatches.fulfilled, (state, action) => {
        state.batchLoading = false;
        state.batchIds = action.payload;
      })
      .addCase(getBatches.rejected, (state, action) => {
        state.batchLoading = false;
        state.error = action.payload;
      })
      .addCase(addSubmitDetails.pending, (state) => {
        state.addSubmitDetailsLoading = true;
      })
      .addCase(addSubmitDetails.fulfilled, (state, action) => {
        state.addSubmitDetailsLoading = false;
      })
      .addCase(addSubmitDetails.rejected, (state, action) => {
        state.addSubmitDetailsLoading = false;
        state.error = action.payload;
      })
      .addCase(getUsers.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.userLoading = false;
        state.users = action.payload;
      })
      .addCase(getUsers.rejected, (state) => {
        state.userLoading = false;
      })
      .addCase(getFiltersForExerciseStatusTab.pending, (state) => {
        state.batchLoading = true;
      })
      .addCase(getFiltersForExerciseStatusTab.fulfilled, (state, action) => {
        state.batchLoading = false;
        state.filtersForExerciseTab = action.payload;
      })
      .addCase(getFiltersForExerciseStatusTab.rejected, (state, action) => {
        state.batchLoading = false;
        state.error = action.payload;
      })
      .addCase(sendExerciseReminder.pending, (state) => {
        state.sendExerciseReminderLoading = true;
      })
      .addCase(sendExerciseReminder.fulfilled, (state) => {
        state.sendExerciseReminderLoading = false;
      })
      .addCase(sendExerciseReminder.rejected, (state, action) => {
        state.sendExerciseReminderLoading = false;
        state.error = action.payload;
      })
      .addCase(getOptionsForEditReviewer.pending, (state) => {
        state.editReviewerLoading = true;
      })
      .addCase(getOptionsForEditReviewer.fulfilled, (state, action) => {
        state.editReviewerLoading = false;
        state.optionsForEditReviewer = action.payload;
      })
      .addCase(getOptionsForEditReviewer.rejected, (state) => {
        state.editReviewerLoading = false;
      });
  },
});

export const { clearExercise, countExerciseFilter, clearExerciseFilters } =
  exerciseStatusTab.actions;
export default exerciseStatusTab.reducer;
