import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";

const CustomToolbar = () => (
  <Box id="toolbar">
    <Typography className="ql-formats">
      <BootstrapTooltip title="Heading">
        <select className="ql-header" defaultValue="">
          <option value="1">Heading 1</option>
          <option value="2">Heading 2</option>
          <option value="3">Heading 3</option>
          <option value="">Normal</option>
        </select>
      </BootstrapTooltip>
    </Typography>
    <Typography className="ql-formats">
      <BootstrapTooltip title="Bold">
        <button className="ql-bold"></button>
      </BootstrapTooltip>
      <BootstrapTooltip title="Italic">
        <button className="ql-italic"></button>
      </BootstrapTooltip>
      <BootstrapTooltip title="Underline">
        <button className="ql-underline"></button>
      </BootstrapTooltip>
      <BootstrapTooltip title="Link">
        <button className="ql-link"></button>
      </BootstrapTooltip>
    </Typography>
    <Typography className="ql-formats">
      <BootstrapTooltip title="Bullet List">
        <button className="ql-list" value="bullet"></button>
      </BootstrapTooltip>
      <BootstrapTooltip title="Numbered List">
        <button className="ql-list" value="ordered"></button>
      </BootstrapTooltip>
    </Typography>
    <Typography className="ql-formats">
      <BootstrapTooltip title="Clear Formatting">
        <button className="ql-clean"></button>
      </BootstrapTooltip>
    </Typography>
  </Box>
);

export default CustomToolbar;
