import { apiVersion } from "../constants/constants";
import ApiService from "../utils/api-service";

export function getAssessmentEvaluationsApi(data) {
  let apiUrl = `${apiVersion}/assessmentEvaluation?max=20`;

  if (data?.bootcampId) {
    apiUrl += `&bootcampId=${data?.bootcampId}&assessmentBootcampId=${data?.bootcampId}`;
  }
  if (data?.moduleId) {
    apiUrl += `&moduleId=${data?.moduleId}&assessmentModuleId=${data?.moduleId}`;
  }

  if (data?.submittedById) {
    apiUrl += `&submittedById=${data?.submittedById}`;
  }

  if (data?.status) {
    apiUrl += `&status=${data?.status}`;
  }

  if (data?.offset !== undefined) {
    apiUrl += `&offset=${data?.offset * 20}`;
  }

  if (data?.sortBy !== undefined && data?.sortBy !== null) {
    apiUrl += `&sortBy=${data?.sortBy}`;
  }

  if (data?.sortOrder !== undefined && data?.sortOrder !== null) {
    apiUrl += `&sortOrder=${data?.sortOrder}`;
  }

  if(data?.asAttendee !== undefined) {
    apiUrl += `&asAttendee=${data?.asAttendee}`;
  }

  if(data?.asInterviewer !== undefined) {
    apiUrl += `&asInterviewer=${data?.asInterviewer}`;
  }

  if(data?.asMentor !== undefined) {
    apiUrl += `&asMentor=${data?.asMentor}`;
  }

  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}

export function getAssessmentsFiltersDataApi(data) {
  let apiUrl = `${apiVersion}/utilities/filtersForAssessmentEvaluationListing?paginated=false`;

  if (data?.asAttendee !== undefined) {
    apiUrl += `&asAttendee=${data?.asAttendee}`;
  }
  if (data?.asMentor !== undefined) {
    apiUrl += `&asMentor=${data?.asMentor}`;
  }
  if (data?.asInterviewer !== undefined) {
    apiUrl += `&asInterviewer=${data?.asInterviewer}`;
  }
  const options = {
    method: "GET",
    url: apiUrl,
    data: data,
  };
  return ApiService(options);
}
