import React, { useState } from "react";
import { Alert, Box, Typography } from "@mui/material";
import styles from "./messageToast.module.scss";
import { toast_warning_icon } from "../../constants/icons";

const WarningToast = ({ message, title, type }) => {
  return (
    <Alert
      severity={type}
      icon={<img src={toast_warning_icon} alt={title} width="50" height="50" />}
      className={styles.toastContainer}
    >
      <Box sx={{ fontSize: "0.875rem", fontWeight: 700 }}>{title}</Box>
      <Typography variant="font_12">{message}</Typography>
    </Alert>
  );
};

export default WarningToast;
