import React from "react";
import IconButtons from "../../../components/IconButtons/IconButtons";
import {
  download_active_icon,
  download_disabled_icon,
  filter_active_icon,
} from "../../../constants/icons";
import { Box, Button, Typography } from "@mui/material";
import { font_15, font_16 } from "../../../utils/utils";
import { useParams } from "react-router-dom";
import {
  clearExerciseFilters,
  createAllExerciseStatuses,
  getExerciseStatus,
  sendExerciseReminder,
} from "../../../store/SessionView/exerciseStatusTab";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Loader from "../../../components/Loader/Loader";
import BootstrapTooltip from "../../../components/BootstrapTooltip/BootstrapTooltip";
import Constants from "../../../constants/constants";
const { COMPLETED } = Constants;

const ExerciseActionItems = ({
  handleExerciseFilterOpen,
  currentBootcamp,
  handleCreateAllExercises,
  isHr,
  handleDownloadIconClick,
}) => {
  const Details = useSelector((state) => {
    return state?.detailsTab;
  });
  const exerciseData = useSelector((state) => {
    return state?.exerciseStatusTab;
  });
  const sendReminderLoading = exerciseData?.sendExerciseReminderLoading;
  const exerciseList = exerciseData?.exercise?.data?.results;
  const session = Details?.details?.data;
  const bootcamps = session?.module?.bootcamps?.results;
  const params = useParams();
  const dispatch = useDispatch();

  const handleSendExerciseReminder = async () => {
    await dispatch(sendExerciseReminder({ sessionId: params?.sessionId }));
  };
  const sessionIsOngoingOrCompleted = !dayjs(session?.startDate)
    .tz()
    .isAfter(dayjs().tz());

  const areAllBootcampsCompleted = (bootcamps) => {
    return bootcamps.every((bootcamp) => bootcamp.status === COMPLETED);
  };

  return (
    <>
      {sendReminderLoading && <Loader />}
      {(isHr || session?.permission?.spoc) && (
        <Box className={isHr ? "margin-right-1" : "margin-right-2"}>
          <IconButtons
            size="small"
            height={font_16}
            width={font_16}
            image={download_active_icon}
            disabledImage={download_disabled_icon}
            handleClick={handleDownloadIconClick.bind(this)}
            tooltip="Download Exercise Status Report"
            disabled={!exerciseList?.length}
            disabledTooltip={
              !exerciseList?.length &&
              `There are no Exercise Statuses for this Session`
            }
          />
        </Box>
      )}
      {(session?.permission?.spoc ||
        session?.permission?.mentor ||
        session?.permission?.exerciseReviewer) && (
        <BootstrapTooltip
          title={
            currentBootcamp?.status === COMPLETED
              ? "Bootcamp is completed"
              : !sessionIsOngoingOrCompleted
              ? "Session is scheduled"
              : !exerciseList?.length
              ? "No Exercises Available"
              : null
          }
        >
          <Box>
            <Button
              variant="outlined"
              color="secondary"
              className="action-head-btn"
              onClick={handleSendExerciseReminder}
              disabled={
                !exerciseList?.length ||
                !sessionIsOngoingOrCompleted ||
                currentBootcamp?.status === COMPLETED
              }
            >
              <Typography variant="outlineBtnLabel">send reminder</Typography>
            </Button>
          </Box>
        </BootstrapTooltip>
      )}
      {(session?.permission?.spoc || session?.permission?.sessionPresenter) && (
        <BootstrapTooltip
          title={
            areAllBootcampsCompleted(bootcamps) ? "Bootcamp is completed" : null
          }
        >
          <Box>
            <Button
              variant="outlined"
              color="secondary"
              className="action-head-btn  margin-left-1"
              onClick={handleCreateAllExercises}
              disabled={areAllBootcampsCompleted(bootcamps)}
            >
              <Typography variant="outlineBtnLabel">
                Create All Exercises
              </Typography>
            </Button>
          </Box>
        </BootstrapTooltip>
      )}
      {!session?.permission?.attendee && (
        <Box
          className="filterButton margin-left-1"
          sx={{
            border:
              exerciseData?.filterCount > 0
                ? "2px solid #de1186"
                : "1px solid #de118680",
          }}
        >
          <IconButtons
            height={font_15}
            width={font_15}
            image={filter_active_icon}
            handleClick={handleExerciseFilterOpen}
            tooltip="Add Filters"
            count={exerciseData?.filterCount}
          />
        </Box>
      )}
    </>
  );
};

export default ExerciseActionItems;
