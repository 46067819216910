import React from "react";
import { Box, Typography } from "@mui/material";

const LegendItem = ({ color, text }) => (
  <Box
    display="flex"
    alignItems="center"
    mb={1}
    sx={{ "&:first-child": { mt: 1 } }}
  >
    <Box width={17} height={17} bgcolor={color} mr={1} borderRadius={1} />
    <Typography variant="body2">{text}</Typography>
  </Box>
);

export const ExerciseStatusLegends = () => (
  <Box>
    <LegendItem color="#3087e9" text="Pending" />
    <LegendItem color="#ffaf00" text="In Review" />
    <LegendItem color="#009531" text="Reviewed" />
    <LegendItem color="#d70c0c" text="Reopened" />
  </Box>
);

export const StatusLegends = () => (
  <Box>
    <LegendItem color="#009531" text="Active" />
    <LegendItem color="#d70c0c" text="Inactive" />
  </Box>
);

export const AttendanceLegends = () => (
  <Box>
    <LegendItem color="#3087e9" text="Not Marked" />
    <LegendItem color="#009531" text="Present" />
    <LegendItem color="#d70c0c" text="Absent" />
  </Box>
);

export default LegendItem;
