import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import {
  getPresentersApi,
  getRoomListApi,
  getUsersListApi,
  updatePresenterListApi,
} from "../../services/SessionView/presentersTab";
import CustomToaster from "../../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../../constants/constants";

const initialState = {
  presenters: [],
  usersList: [],
  roomList: [],
  loading: false,
  error: null,
  userLoading: false,
};

export const getPresenters = createAsyncThunk(
  "getPresenters",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getPresentersApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUsersList = createAsyncThunk(
  "getUsersList",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getUsersListApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updatePresenterList = createAsyncThunk(
  "updatePresenterList",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await updatePresenterListApi(data);
      if (callApi) {
        toast.custom(
          <CustomToaster
            message="Presenter Details Updated Successfully"
            title={TOAST_TITLE.SUCCESS}
            time={TOAST_TIME.FIVE}
            type={TOAST_TYPE.SUCCESS}
          />
        );
      }
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRoomList = createAsyncThunk(
  "getRoomList",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getRoomListApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const presentersTab = createSlice({
  name: "presentersTab",
  initialState,
  reducers: {
    clearUserList(state) {
      state.usersList = [];
    },
    clearPresenters(state) {
      state.presenters = [];
    },
    clearRoomList(state) {
      state.roomList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPresenters.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPresenters.fulfilled, (state, action) => {
        state.loading = false;
        state.presenters = action.payload;
      })
      .addCase(getPresenters.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getRoomList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRoomList.fulfilled, (state, action) => {
        state.loading = false;
        state.roomList = action.payload;
      })
      .addCase(getRoomList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getUsersList.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(getUsersList.fulfilled, (state, action) => {
        state.userLoading = false;
        state.usersList = action.payload;
      })
      .addCase(getUsersList.rejected, (state, action) => {
        state.userLoading = false;
        state.error = action.payload;
      })
      .addCase(updatePresenterList.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePresenterList.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updatePresenterList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearPresenters, clearRoomList, clearUserList } = presentersTab.actions;
export default presentersTab.reducer;
