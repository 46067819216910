import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import {
  createAttendancesApi,
  getAttendeesApi,
  getBatchesApi,
  getCompetencyApi,
  getFiltersForAttendeesTabApi,
  markAttendanceApi,
} from "../../services/SessionView/attendeesTab";
import CustomToaster from "../../components/Toaster";
import { TOAST_TIME, TOAST_TITLE, TOAST_TYPE } from "../../constants/constants";

const initialState = {
  attendees: [],
  loading: false,
  error: false,
  filterCount: 0,
  competencyIds: [],
  batchIds: null,
  attendeesLoading: false,
  attendanceLoading: false,
  apiLoading: false,
  batchLoading: false,
  markAttendanceLoading: false,
  createAttendanceLoading: false,
  filtersForAttendeesTab: null,
};

export const getAttendees = createAsyncThunk(
  "getAttendees",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getAttendeesApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const markAttendance = createAsyncThunk(
  "markAttendance",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await markAttendanceApi(data);
      toast.custom(
        <CustomToaster
          message="Attendance Marked Successfully"
          title={TOAST_TITLE.SUCCESS}
          time={TOAST_TIME.FIVE}
          type={TOAST_TYPE.SUCCESS}
        />
      );
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCompetency = createAsyncThunk(
  "getCompetency",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getCompetencyApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBatches = createAsyncThunk(
  "getBatches",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getBatchesApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createAttendances = createAsyncThunk(
  "createAttendances",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await createAttendancesApi(data);

      callApi?.data?.results?.length
        ? toast.custom(
            <CustomToaster
              message={"Attendances Created Successfully"}
              title={TOAST_TITLE.SUCCESS}
              time={TOAST_TIME.FIVE}
              type={TOAST_TYPE.SUCCESS}
            />
          )
        : toast.custom(
            <CustomToaster
              message={"No New Attendances Created"}
              title={TOAST_TITLE.SUCCESS}
              time={TOAST_TIME.FIVE}
              type={TOAST_TYPE.SUCCESS}
            />
          );

      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFiltersForAttendeesTab = createAsyncThunk(
  "getFiltersForAttendeesTab",
  async (data, { rejectWithValue }) => {
    try {
      const callApi = await getFiltersForAttendeesTabApi(data);
      return callApi;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const attendeesTab = createSlice({
  name: "attendeesTab",
  initialState,
  reducers: {
    countFilter(state, action) {
      state.filterCount = action.payload;
    },
    clearAttendees(state) {
      state.attendees = [];
    },
    clearAttendeeTabFilters(state) {
      state.filtersForAttendeesTab = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAttendees.pending, (state) => {
        state.attendeesLoading = true;
      })
      .addCase(getAttendees.fulfilled, (state, action) => {
        state.attendeesLoading = false;
        state.attendees = action.payload;
      })
      .addCase(getAttendees.rejected, (state, action) => {
        state.attendeesLoading = false;
        state.error = action.payload;
      })
      .addCase(markAttendance.pending, (state) => {
        state.attendanceLoading = true;
      })
      .addCase(markAttendance.fulfilled, (state) => {
        state.attendanceLoading = false;
      })
      .addCase(markAttendance.rejected, (state, action) => {
        state.attendanceLoading = false;
        state.error = action.payload;
      })
      .addCase(getCompetency.pending, (state) => {
        state.apiLoading = true;
      })
      .addCase(getCompetency.fulfilled, (state, action) => {
        state.apiLoading = false;
        state.competencyIds = action.payload;
      })
      .addCase(getCompetency.rejected, (state, action) => {
        state.apiLoading = false;
        state.error = action.payload;
      })
      .addCase(getBatches.pending, (state) => {
        state.batchLoading = true;
      })
      .addCase(getBatches.fulfilled, (state, action) => {
        state.batchLoading = false;
        state.batchIds = action.payload;
      })
      .addCase(getBatches.rejected, (state, action) => {
        state.batchLoading = false;
        state.error = action.payload;
      })
      .addCase(createAttendances.pending, (state) => {
        state.createAttendanceLoading = true;
      })
      .addCase(createAttendances.fulfilled, (state, action) => {
        state.createAttendanceLoading = false;
      })
      .addCase(createAttendances.rejected, (state, action) => {
        state.createAttendanceLoading = false;
        state.error = action.payload;
      })
      .addCase(getFiltersForAttendeesTab.pending, (state) => {
        state.batchLoading = true;
      })
      .addCase(getFiltersForAttendeesTab.fulfilled, (state, action) => {
        state.batchLoading = false;
        state.filtersForAttendeesTab = action.payload;
      })
      .addCase(getFiltersForAttendeesTab.rejected, (state, action) => {
        state.batchLoading = false;
        state.error = action.payload;
      });
  },
});

export const { countFilter, clearAttendees, clearAttendeeTabFilters } =
  attendeesTab.actions;
export default attendeesTab.reducer;
