import React, { useEffect, useState } from "react";
import "./reviewModal.scss";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";

const hasDetailsChanged = (initialDetails, currentDetails) => {
  return (
    initialDetails?.score !== currentDetails?.score || currentDetails?.comment
  );
};

const ReviewModal = ({
  open,
  handleClose,
  initialExerciseDetails,
  handleInputReviewInput,
  handleSubmitReview,
  reviewDetails,
  isEditMode,
  isReopenTextField,
  isReviewMode,
}) => {
  const isLoading = useSelector((state) => state?.commentsList?.loading);
  const [loader, setLoader] = useState(false);
  const [scoreError, setScoreError] = useState("");
  const [commentError, setCommentError] = useState(false);

  const [submitDetails, setSubmitDetails] = useState({
    json: {
      status: "IN_REVIEW",
      score: null,
      comment: "",
    },
  });

  const exerciseDetailsChanged = hasDetailsChanged(
    initialExerciseDetails,
    reviewDetails
  );

  const onClose = () => {
    setScoreError(false);
    setCommentError(false);
    handleClose();
  };

  const onSubmit = async () => {
    if (!isReopenTextField) {
      setScoreError(
        !reviewDetails?.score
          ? "Score cannot be empty"
          : reviewDetails?.score > reviewDetails?.maxExerciseScore
          ? "Score cannot be higher than Max Score"
          : ""
      );
    }
    setCommentError(!reviewDetails?.comment);

    if (!scoreError && !commentError) {
      setLoader(true);
      setSubmitDetails({
        ...submitDetails,
        json: {
          ...submitDetails.json,
          status: isReopenTextField ? "REOPENED" : "REVIEWED",
          score: !isReopenTextField ? +reviewDetails?.score : null,
          comment: reviewDetails?.comment,
        },
      });
      setLoader(false);
    }
  };

  useEffect(() => {
    if (!scoreError && !commentError) {
      handleSubmitReview(submitDetails);
      handleClose();
      setScoreError(false);
      setCommentError(false);
    }
  }, [submitDetails]);

  const handleInputChange = (value, name) => {
    if (name === "score") {
      setScoreError(false);
    } else if (name === "comment") {
      setCommentError(false);
    }

    handleInputReviewInput(value, name);
  };

  return (
    <>
      <Dialog
        className="review-modal"
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            width: "22.125rem",
            zIndex: 1300,
            borderRadius: "0.625rem",
            margin: 0,
            padding: 0,
          },
        }}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <DialogTitle className="review-dialog-title">
              {isEditMode
                ? "Edit Exercise"
                : isReopenTextField
                ? "Re-Open Exercise"
                : "Review Exercise"}
            </DialogTitle>
            <DialogContent className="review-modal-content">
              {(isEditMode || isReviewMode) && (
                <Box>
                  <TextField
                    className="review-dialog-score"
                    autoFocus
                    id="score"
                    shrink={false}
                    size="small"
                    label="Score"
                    type="text"
                    fullWidth
                    margin="none"
                    value={reviewDetails?.score}
                    variant="outlined"
                    onChange={(e) => handleInputChange(e.target.value, "score")}
                    color="secondary"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{ "& .MuiTypography-root": { color: "#948B9E" } }}
                        >
                          {`/${reviewDetails?.maxExerciseScore}`}
                        </InputAdornment>
                      ),
                    }}
                    error={!!scoreError}
                    helperText={scoreError}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        input: {
                          "&::placeholder": {
                            opacity: 1,
                            color: "#2E1C41",
                          },
                        },
                      },
                    }}
                  />
                </Box>
              )}

              <TextField
                multiline
                autoFocus={isReopenTextField ? true : false}
                minRows={4}
                id="comment"
                shrink={false}
                size="small"
                label="Comment"
                type="text"
                fullWidth
                margin="none"
                variant="outlined"
                value={reviewDetails?.comment}
                onChange={(e) => handleInputChange(e?.target?.value, "comment")}
                color="secondary"
                error={commentError}
                helperText={commentError ? "Comment cannot be empty" : ""}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    textarea: {
                      "&::placeholder": {
                        opacity: 1,
                        color: "#2E1C41",
                      },
                    },
                  },
                }}
              />
            </DialogContent>
            <DialogActions className="review-dialog-action">
              <Button
                variant="outlined"
                disabled={loader}
                color="secondary"
                onClick={onClose}
                className="addTagButtonStyle"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                disableElevation
              >
                <Typography variant="outlineBtnLabel">Cancel</Typography>
              </Button>
              <Button
                fullWidth
                disabled={loader || (isEditMode && !exerciseDetailsChanged)}
                color="secondary"
                variant="contained"
                onClick={onSubmit}
                className="addTagButtonStyle"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                disableElevation
              >
                {loader ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  <Typography variant="outlineBtnLabel">Submit</Typography>
                )}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ReviewModal;
