import React, { useEffect, useState } from "react";
import "./roomModal.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { cross_default_icon } from "../../constants/icons";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import BootstrapTooltip from "../BootstrapTooltip/BootstrapTooltip";
import { hasDetailsChanged } from "../../utils/utils";

const RoomModal = ({
  open,
  handleClose,
  handleRoomDetailsChange,
  handleRoomModalSubmit,
  roomDetails,
  isEditRoom,
  isRoomError,
  roomSpecialChar,
  roomErrors,
  seatAlphabetError,
  roomNameLengthError,
}) => {
  const loader = useSelector(
    (state) => state?.locationAndRoom?.addEditRoomLoading
  );

  const [initialRoomDetails, setInitialRoomDetails] = useState(roomDetails);

  const compareFormFields = (initial, current) => {
    const fieldsToCheck = ["name", "description", "agenda"];

    return fieldsToCheck.some((field) => {
      if (field == "name") {
        return initial?.rooms[field].trim() !== current?.rooms[field].trim();
      } else {
        return initial[field] !== current[field];
      }
    });
  };

  useEffect(() => {
    setInitialRoomDetails(roomDetails);
  }, [roomDetails?.locationId]);

  const roomDetailsChanged =
    initialRoomDetails?.rooms?.name.trim() !==
      roomDetails?.rooms?.name.trim() ||
    Number(initialRoomDetails?.rooms?.seatingCapacity) !==
      Number(roomDetails?.rooms?.seatingCapacity);

  return (
    <>
      {loader && <Loader />}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "22.125rem",
            zIndex: 1300,
            borderRadius: "0.6rem",
            margin: 0,
          },
        }}
      >
        <DialogTitle className="add-room-title" sx={{ padding: "1rem 2rem 0" }}>
          <Typography variant="font_16_bold">
            {isEditRoom ? "Edit Room" : "Create Room"}
          </Typography>
        </DialogTitle>
        <DialogContent className="add-room-content">
          <TextField
            autoFocus
            id="name"
            shrink={false}
            size="small"
            label="Name"
            type="text"
            fullWidth
            margin="dense"
            value={roomDetails?.rooms?.name}
            variant="outlined"
            onChange={(e) => handleRoomDetailsChange("name", e.target.value)}
            color="secondary"
            error={
              !!roomErrors.name ||
              roomSpecialChar ||
              isRoomError ||
              roomNameLengthError
            }
            helperText={
              roomNameLengthError
                ? "Maximum 50 characters allowed"
                : roomSpecialChar
                ? "Should not contain special characters"
                : roomErrors.name
            }
            required={true}
          />
          <TextField
            id="seatingCapacity"
            shrink={false}
            size="small"
            label="Seating Capacity"
            min={0}
            type="text"
            fullWidth
            margin="dense"
            value={roomDetails?.rooms?.seatingCapacity}
            variant="outlined"
            onChange={(e) =>
              handleRoomDetailsChange("seatingCapacity", e.target.value)
            }
            color="secondary"
            disableRipple
            error={
              !!roomErrors.seatingCapacity || isRoomError || seatAlphabetError
            }
            helperText={
              seatAlphabetError
                ? "Should only contain number"
                : roomErrors.seatingCapacity
            }
            required={true}
          />
        </DialogContent>
        <DialogActions sx={{ padding: " 0rem 2rem 1.2rem" }}>
          <Button
            fullWidth
            variant="outlined"
            disabled={loader}
            color="secondary"
            onClick={handleClose}
            className="add-room-btn"
          >
            <Typography variant="font_12_bold_700">Cancel</Typography>
          </Button>
          <Button
            fullWidth
            onClick={handleRoomModalSubmit}
            variant="contained"
            color="secondary"
            className="add-room-btn"
            disabled={isEditRoom && !roomDetailsChanged}
          >
            <Typography variant="font_12_bold_700">
              {isEditRoom ? "Update" : "Save"}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RoomModal;
