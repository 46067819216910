import React, { useState } from "react";

const CopyToClipboard = ({ text, children }) => {
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState(null);

  const fallbackCopyTextToClipboard = (textToCopy) => {
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      const successful = document.execCommand("copy");
      if (successful) {
        setCopied(true);
        setError(null);
        setTimeout(() => setCopied(false), 2000);
      } else {
        setError("Copy failed");
      }
    } catch (err) {
      console.error("Fallback copy failed: ", err);
      setError("Copy not supported");
    }
    document.body.removeChild(textArea);
  };

  const handleCopy = () => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setCopied(true);
          setError(null);
          setTimeout(() => setCopied(false), 2000);
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          setError("Copy failed");
        });
    } else {
      fallbackCopyTextToClipboard(text);
    }
  };

  return (
    <div
      onClick={handleCopy}
      style={{ cursor: "pointer", display: "inline-block" }}
    >
      {children}
    </div>
  );
};

export default CopyToClipboard;
