import React, { useEffect, useState } from "react";
import "./addResourceModal.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
  styled,
  Chip,
  Box,
} from "@mui/material";
import { cross_default_icon, upload_active_icon } from "../../constants/icons";
import { font_9, isValidUrlFormat } from "../../utils/utils";
import IconButtons from "../IconButtons/IconButtons";
import Loader from "../Loader/Loader";
import { useSelector } from "react-redux";
import { HelpTexts, TOAST_TYPE } from "../../constants/constants";
import WarningToast from "../MessagePopup/WarningToast";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 2,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 2,
});

const FileItem = styled("div")({
  display: "flex",
  alignItems: "center",
  margin: "1rem 0 0.5rem 0px",
});

const { ENTER_VALID_URL } = HelpTexts;

const AddResourceModal = ({
  open,
  onClose,
  options,
  handleChange,
  value,
  handleResourceSubmit,
  resourceApiError,
  isEdit,
}) => {
  const [initialResourceDetails, setInitialResourceDetails] = useState(value);
  const compareFormFields = (initial, current) => {
    return (
      initial?.json?.name?.trim() !== current?.json?.name?.trim() ||
      (initial?.json?.format === "URL"
        ? initial?.json?.url?.trim() !== current?.json?.url?.trim()
        : current?.files !== null)
    );
  };
  useEffect(() => {
    setInitialResourceDetails(value);
  }, [value?.json?.resourceId]);

  const resourceDetailsChanged = compareFormFields(
    initialResourceDetails,
    value
  );
  const resourceOptions = useSelector(
    (state) => state?.utility?.constants?.resourceFormats
  );
  const isValidUrl = () => {
    const isValidUrl = value?.json?.url && isValidUrlFormat(value.json.url);
    return isValidUrl;
  };

  //////////////////// Form Validation ////////////////////////////////////
  const initialErrorState = {
    json: {
      name: "",
      format: "",
      url: "",
    },
    files: "",
  };
  const [errors, setErrors] = useState(initialErrorState);
  const validateFields = () => {
    const { json, files } = value;
    const newErrors = {
      name: json.name ? "" : "Name is required",
      url: json.format === "URL" ? (json.url ? "" : "URL is required") : "",
      files:
        json.format === "FILE"
          ? isEdit || (files && selectedFile)
            ? ""
            : "File is required"
          : "",
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  //////////////////////////////////////////////////////////////////////////////

  const [create, setCreating] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState("");

  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const handleOnClose = async () => {
    setErrors(initialErrorState);
    setSelectedFile(null);
    setFileError("");
    onClose();
  };

  const handleCreate = async () => {
    if (validateFields()) {
      if (
        (value?.json?.format === "URL" && isValidUrl()) ||
        (value?.json?.format === "FILE" && (isEdit || selectedFile))
      ) {
        setCreating(true);
        await handleResourceSubmit();
        setSelectedFile(null);
        setCreating(false);
      }
    }
  };
  const handleFileChange = (files) => {
    const maxSize = 4 * 1024 * 1024;
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "application/pdf",
      "application/msword",
      "text/plain",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/x-zip-compressed",
    ];

    let errorMessage = "";

    files.forEach((file) => {
      if (file !== undefined && !allowedTypes?.includes(file?.type)) {
        errorMessage = `File ${file?.name} is of an unsupported file type.`;
      } else if (file?.size > maxSize) {
        errorMessage = `File ${file?.name} exceed the maximum size.`;
      }
    });

    if (errorMessage) {
      setFileError(errorMessage);
      return;
    }
    if (files?.length > 0 && files[0] !== undefined) {
      setFileError("");
      setSelectedFile(files);
      handleChange("files", files);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files;
    setSelectedFile(file);
  };

  const handleOnNameChange = (name, value) => {
    if (value?.trim().length <= 50) {
      handleChange(name, value);
      setErrors((prev) => {
        return {
          ...prev,
          name: "",
        };
      });
    } else {
      setErrors({ ...errors, name: "Maximum 50 characters allowed" });
    }
  };

  return (
    <>
      {create && <Loader />}
      <Dialog
        open={open}
        onClose={handleOnClose}
        className="add-resource-modal"
      >
        <DialogTitle className="dialog-title">
          <Typography variant="font_16_bold">
            {`${isEdit ? `Update` : `Create`} Resource`}
          </Typography>
        </DialogTitle>
        <DialogContent className="resource-modal-dialog-content">
          <TextField
            size="small"
            autoFocus
            id="name"
            label="Name"
            variant="outlined"
            color="secondary"
            fullWidth
            type="text"
            margin="dense"
            value={value?.json?.name}
            onChange={(e) => {
              handleOnNameChange("json.name", e?.target?.value);
            }}
            error={!!errors.name || resourceApiError}
            helperText={errors.name}
          />
          <TextField
            size="small"
            color="secondary"
            select
            id="resourceType"
            label="Resource Type"
            defaultValue="URL"
            fullWidth
            margin="dense"
            value={value?.json?.format || "URL"}
            onChange={(e) => handleChange("json.format", e?.target?.value)}
            error={!!errors.format || resourceApiError}
            helperText={errors.format}
            disabled={isEdit}
          >
            {resourceOptions?.map((option) => (
              <MenuItem key={option?.value} value={option?.key}>
                {option?.value}
              </MenuItem>
            ))}
          </TextField>
          {value?.json?.format === "URL" ? (
            <TextField
              size="small"
              id="url"
              label="Enter link"
              type="link"
              fullWidth
              margin="dense"
              color="secondary"
              value={value?.json?.url}
              onChange={(e) => {
                handleChange("json.url", e?.target?.value);
                setErrors((prev) => {
                  return {
                    ...prev,
                    url: "",
                  };
                });
              }}
              error={
                (value?.json?.url ? !isValidUrl() : false) ||
                !!errors.url ||
                resourceApiError
              }
              helperText={
                (value?.json?.url ? !isValidUrl() && ENTER_VALID_URL : false) ||
                errors.url
              }
            />
          ) : (
            <>
              <Button
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                className="block-dashed-btn upload-btn"
                fullWidth
                color="lightGrayText"
                component="label"
                variant="outlined"
                startIcon={
                  <img
                    alt="upload"
                    height="20px"
                    width="20px"
                    src={upload_active_icon}
                  />
                }
              >
                <Box className="file-upload-instruction">
                  <Box>
                    <Typography variant="font_12_bold" color="contrastText">
                      Drag and Drop file or{" "}
                    </Typography>

                    <Typography variant="font_12_bold_600" color="secondary">
                      BROWSE
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="font_10">
                      Upto 4mb (jpg, jpeg, png, pdf, doc, docx, zip, txt)
                    </Typography>
                  </Box>
                </Box>
                <VisuallyHiddenInput
                  onChange={(e) => {
                    handleFileChange([e?.target?.files[0]]);
                    setErrors((prev) => {
                      return {
                        ...prev,
                        files: "",
                      };
                    });
                  }}
                  type="file"
                />
              </Button>
              {!!errors?.files ? (
                <Box className="fileError">
                  <Typography variant="font_12">{errors?.files}</Typography>
                </Box>
              ) : (
                fileError && (
                  <WarningToast
                    message={fileError}
                    title="Warning!"
                    type={TOAST_TYPE.WARNING}
                  />
                )
              )}
              {selectedFile && (
                <FileItem>
                  <Chip
                    label={selectedFile[0].name}
                    variant="outlined"
                    size="large"
                    color="secondary"
                    className="input-files-chip"
                    deleteIcon={
                      <IconButtons
                        height={font_9}
                        width={font_9}
                        image={cross_default_icon}
                        handleClick={() => handleRemoveFile()}
                        classList="user-role-cross"
                      />
                    }
                    onDelete={true}
                  />
                </FileItem>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions className="resource-modal-dialog-action">
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={handleOnClose}
            className="resource-btn"
          >
            <Typography variant="font_12_bold_700">Cancel</Typography>
          </Button>
          <Button
            onClick={handleCreate}
            variant="contained"
            color="secondary"
            className="resource-btn"
            fullWidth
            disabled={create || (isEdit && !resourceDetailsChanged)}
          >
            <Typography variant="font_12_bold_700">
              {isEdit ? `Update` : `Save`}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddResourceModal;
