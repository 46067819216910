import React from "react";
import "./locationsAndRoomCard.scss";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import IconButtons from "../IconButtons/IconButtons";
import {
  black_plus_default_icon,
  delete_active_icon,
  edit_active_icon,
  plus_active_icon,
} from "../../constants/icons";
import { Box } from "@mui/system";
import NoDataAvailable from "../NoDataAvailable/NoDataAvailable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { font_10, font_13_icon } from "./../../utils/utils";

const LocationsAndRoomCard = ({
  locationEditModal,
  roomModalOpen,
  locationName,
  roomData,
  deleteModalOpenRoom,
  deleteModalOpenLocation,
  id,
  isDeleted,
  roomEditModal,
  isAdmin,
}) => {
  return (
    <Accordion className="accordion" key={id}>
      <AccordionSummary
        className="accordion-summary"
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Box className="location-head-container">
          <Box className="location-head">
            <Typography variant="font_14">{locationName}</Typography>
          </Box>
          {isAdmin && (
            <Box>
              <IconButtons
                tooltip="Edit Location"
                width={font_13_icon}
                height={font_13_icon}
                image={edit_active_icon}
                handleClick={(event) =>
                  locationEditModal(event, id, locationName)
                }
                classList="margin-right-1"
              />
              {!isDeleted && (
                <IconButtons
                  tooltip="Delete Location"
                  width={font_13_icon}
                  height={font_13_icon}
                  image={delete_active_icon}
                  handleClick={(event) => deleteModalOpenLocation(event)}
                  id={id}
                  classList="margin-right-1"
                />
              )}
            </Box>
          )}
        </Box>
      </AccordionSummary>

      <AccordionDetails className="accordion-details">
        {roomData?.totalResults ? (
          <TableContainer className="table-container">
            <Table stickyHeader>
              <TableHead className="table-head-container">
                <TableRow className="table-head-row">
                  <TableCell
                    align="left"
                    className="padding-left-1"
                    sx={{ minWidth: "18rem", maxWidth: "18rem" }}
                  >
                    <Typography variant="font_13_bold">Name</Typography>
                  </TableCell>
                  <TableCell align="center" sx={{ minWidth: "8rem" }}>
                    <Typography variant="font_13_bold">
                      Seating Capacity
                    </Typography>
                  </TableCell>
                  {isAdmin && (
                    <TableCell align="center" sx={{ minWidth: "8rem" }}>
                      <Typography variant="font_13_bold">Actions</Typography>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {roomData?.results?.map(
                  (room, index) =>
                    !room?.deleted && (
                      <TableRow className="table-body-row" hover key={index}>
                        <TableCell align="left" className="padding-left-1">
                          <Typography
                            variant="font_12"
                            className={room?.deleted ? "room-deleted" : null}
                            sx={{ wordBreak: "break-word" }}
                          >
                            {room.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            variant="font_12"
                            className={room.deleted ? "room-deleted" : null}
                          >
                            {room?.seatingCapacity}
                          </Typography>
                        </TableCell>
                        {isAdmin && (
                          <TableCell align="center">
                            <IconButtons
                              tooltip="Edit Room"
                              width={font_13_icon}
                              height={font_13_icon}
                              image={edit_active_icon}
                              handleClick={() => roomEditModal(id, room)}
                              classList="margin-right-1"
                            />
                            {room?.deleted ? null : (
                              <IconButtons
                                tooltip="Delete Room"
                                width={font_13_icon}
                                height={font_13_icon}
                                image={delete_active_icon}
                                handleClick={(event) =>
                                  deleteModalOpenRoom(event, room)
                                }
                                id={id}
                              />
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <>
            <NoDataAvailable
              imgHeight={4}
              imgWidth={4.5}
              message="No Rooms Available"
            />
          </>
        )}
        {isAdmin && (
          <Button
            sx={{ marginTop: "1rem" }}
            fullWidth
            className="block-dashed-btn room-dashed-btn"
            color="secondary"
            image={black_plus_default_icon}
            onClick={roomModalOpen}
            startIcon={
              <IconButtons
                width={font_10}
                height={font_10}
                image={plus_active_icon}
              />
            }
          >
            <Typography variant="font_11_bold">Add Room</Typography>
          </Button>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default LocationsAndRoomCard;
