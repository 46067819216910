import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { cross_default_icon } from "../../constants/icons";
import AutoCompleteTagInput from "../../components/AutoCompleteTagInput/AutoCompleteTagInput";
import DateTimePickerComponent from "../../components/DateTimePickerComponent/DateTimePickerComponent";
import dayjs from "dayjs";
import BootstrapTooltip from "../../components/BootstrapTooltip/BootstrapTooltip";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import {
  addWorkingDays,
  getMaxDate,
  getMinDate,
  noOfWorkingDaysChanged,
  shouldDisableWeekends,
  subtractWorkingDays,
  toEndDate,
} from "../../utils/utils";
import Constants from "../../constants/constants";

const { ACTIVE, COMPLETED } = Constants;

const compareFormFields = (initial, current) => {
  const fieldsToCheck = [
    "name",
    "startDate",
    "endDate",
    "description",
    "bootcampIds",
  ];

  return fieldsToCheck.some((field) => {
    if (field === "bootcampIds") {
      if (initial[field].length !== current[field].length) return true;
      const initialIds = initial[field].map((item) => item.id).sort();
      const currentIds = current[field].map((item) => item.id).sort();
      return !initialIds.every((id, index) => id === currentIds[index]);
    } else if (field === "startDate" || field === "endDate") {
      return !dayjs(initial[field]).isSame(dayjs(current[field]));
    } else if (field === "name") {
      return initial[field]?.trim() !== current[field]?.trim();
    }
    return initial[field] !== current[field];
  });
};

const CreateBootcampModule = ({
  open,
  onClose,
  handleBootcampModuleDetailsChange,
  getBootcampData,
  handleBootcampModuleSubmit,
  bootcampModuleDetails,
  isEdit,
  bootcampModuleApiError,
  moduleSpeChar,
  attendeesListLoading,
  loader,
  bootcamp,
  bootcampModuleList,
  moduleNameLengthError,
  disabledBootcampsId,
  isModuleActive,
  handlePropogationWarningOpen,
  module,
  isCloneModule,
}) => {
  const getBootcampinfo = useSelector((state) => {
    return state?.bootcamp;
  });
  const holidaysList = useSelector(
    (state) => state?.utility?.constants?.holidays
  );
  const [moduleStartDate, setModuleStartDate] = useState(null);
  const [moduleEndDate, setModuleEndDate] = useState(null);

  const moduleOptionLoading = getBootcampinfo?.moduleOptionLoading;

  const getBootcampOptionsLoader = getBootcampinfo?.getBootcampOptionsLoader;

  const initialErrorState = {
    name: "",
    startDate: "",
    endDate: "",
    bootcampIds: "",
    startAndEndDate: "",
  };
  const [errors, setErrors] = useState(initialErrorState);

  const sortModules = (modules) => {
    if (modules) {
      let sortedModules = [...modules];
      return sortedModules.sort((module1, module2) => {
        const startDate1 = dayjs(module1?.startDate);
        const startDate2 = dayjs(module2?.startDate);
        if (startDate1.isValid() && startDate2.isValid()) {
          if (startDate1.isAfter(startDate2)) {
            return 1;
          } else if (startDate1.isBefore(startDate2)) {
            return -1;
          }
        }
        return 0;
      });
    }
  };
  let previousModule;
  let nextModule;
  let currentModule;
  sortModules(bootcampModuleList)?.some((it) => {
    if (!!currentModule) {
      nextModule = it;
      return true;
    } else if (it?.id === bootcampModuleDetails?.moduleId) {
      currentModule = it;
    } else {
      previousModule = it;
    }
    return false;
  });

  const handleBootcampModuleDateChanges = (name, value) => {
    if (name == "startDate" && isEdit) {
      const startDate =
        moduleStartDate === null
          ? bootcampModuleDetails?.startDate
          : moduleStartDate;
      if (moduleStartDate === null) {
        setModuleStartDate(bootcampModuleDetails?.startDate);
      }
    } else if (name == "endDate" && isEdit) {
      const endDate =
        moduleEndDate === null ? bootcampModuleDetails?.endDate : moduleEndDate;
      if (moduleEndDate === null) {
        setModuleEndDate(bootcampModuleDetails?.endDate);
      }
    }
    handleBootcampModuleDetailsChange(name, value);
    setErrors((prev) => {
      return {
        ...prev,
        [name]: "",
        startAndEndDate: "",
      };
    });
  };

  const validateFields = () => {
    const { name, startDate, endDate, bootcampIds } = bootcampModuleDetails;
    let newErrors = {
      name: name ? "" : "Name is required",
      startDate:
        startDate && startDate.isValid()
          ? endDate?.isValid()
            ? endDate.isSame(startDate, "day")
              ? "Start Date cannot be same as End Date"
              : endDate.isBefore(startDate, "day")
              ? "Start Date cannot be after End Date"
              : ""
            : ""
          : "Start Date is required",
      endDate: endDate && endDate?.isValid() ? "" : "End Date is required",
      bootcampIds:
        bootcampIds && bootcampIds.length > 0 ? "" : "Bootcamps are required",
    };
    if (!newErrors?.startDate && !newErrors?.endDate) {
      newErrors = {
        ...newErrors,
        ...validateDate(startDate, endDate, bootcampIds),
      };
    }
    if (newErrors?.propogationWarning?.length) {
      handlePropogationWarningOpen(newErrors?.propogationWarning);
    }
    setErrors(newErrors);
    return Object.values(newErrors).every(
      (error) => error === "" || !error?.length
    );
  };

  const doModuleDatesConflictWithSharedModules = (module, date1, date2) => {
    return (
      (!dayjs(date1).isBefore(dayjs(module?.startDate), "date") &&
        !dayjs(date1).isAfter(dayjs(module?.endDate), "date")) ||
      (!dayjs(date2).isBefore(dayjs(module?.startDate), "date") &&
        !dayjs(date2).isAfter(dayjs(module?.endDate), "date")) ||
      (!dayjs(module?.startDate).isBefore(dayjs(date1), "date") &&
        !dayjs(module?.startDate).isAfter(dayjs(date2), "date")) ||
      (!dayjs(module?.endDate).isBefore(dayjs(date1), "date") &&
        !dayjs(module?.endDate).isAfter(dayjs(date2), "date"))
    );
  };

  const validateDate = (date1, date2, bootcamps) => {
    const errors = {
      conflictErrors: [],
      propogationErrors: [],
      propogationWarning: [],
    };
    if (
      bootcamps &&
      bootcamps?.length &&
      dayjs(date1).isValid() &&
      dayjs(date2).isValid()
    ) {
      let newStartDate = dayjs(date1);
      let newEndDate = dayjs(date2);
      bootcamps
        ?.filter((it) => !(isEdit && bootcamp?.id === it?.id))
        .forEach((it) => {
          return it?.modules?.results
            ?.filter(
              (it) => !(isEdit && it?.id === bootcampModuleDetails?.moduleId)
            )
            .forEach((it2) => {
              if (
                doModuleDatesConflictWithSharedModules(
                  it2,
                  newStartDate,
                  newEndDate
                )
              ) {
                errors?.conflictErrors.push(
                  `${it2?.name} of Bootcamp ${it?.name}, Start Date: ${dayjs(
                    it2?.startDate
                  )
                    .tz()
                    .format("YYYY-MM-DD")}, End Date: ${dayjs(it2?.endDate)
                    .tz()
                    .format("YYYY-MM-DD")}`
                );
              }
            });
        });
      if (isEdit && !errors?.conflictErrors?.length) {
        const originalModule = module
          ? module
          : bootcampModuleList.find(
              (it) => it?.id === bootcampModuleDetails?.moduleId
            );
        const startDateMovedDays = noOfWorkingDaysChanged(
          originalModule?.startDate,
          newStartDate,
          holidaysList
        );
        const endDateMovedDays = noOfWorkingDaysChanged(
          originalModule?.endDate,
          newEndDate,
          holidaysList
        );

        if (startDateMovedDays < 0) {
          if (
            previousModule &&
            dayjs(previousModule?.startDate).isBefore(newStartDate) &&
            !dayjs(previousModule?.endDate).isBefore(newStartDate)
          ) {
            const newPreviousEndDate = subtractWorkingDays(
              previousModule?.endDate,
              noOfWorkingDaysChanged(
                previousModule?.endDate,
                toEndDate(newStartDate),
                holidaysList
              ),
              holidaysList
            );
            errors?.propogationWarning?.push(
              `${previousModule?.name}, Start Date: ${dayjs(
                previousModule?.startDate
              )
                .tz()
                .format("YYYY-MM-DD")}, End Date: ${newPreviousEndDate
                .tz()
                .format("YYYY-MM-DD")}`
            );
          } else {
            bootcamps.forEach((it) => {
              return it?.modules?.results
                ?.filter(
                  (it) =>
                    it?.id !== bootcampModuleDetails?.moduleId &&
                    dayjs(it?.endDate).isBefore(originalModule?.startDate)
                )
                ?.filter((it) => bootcamp?.id === it?.id)
                ?.forEach((it2) => {
                  if (
                    doModuleDatesConflictWithSharedModules(
                      it2,
                      newStartDate,
                      newEndDate
                    )
                  ) {
                    errors?.conflictErrors.push(
                      `${it2?.name} of Bootcamp ${
                        it?.name
                      }, Start Date: ${dayjs(it2?.startDate)
                        .tz()
                        .format("YYYY-MM-DD")}, End Date: ${dayjs(it2?.endDate)
                        .tz()
                        .format("YYYY-MM-DD")}`
                    );
                  }
                });
            });
          }
        }
        if (endDateMovedDays > 0) {
          let lastModuleEndDate = newEndDate;
          bootcampModuleList
            ?.filter(
              (it) =>
                it?.id !== bootcampModuleDetails?.moduleId &&
                dayjs(it?.startDate).isAfter(dayjs(originalModule?.endDate))
            )
            ?.every((moduleOfThisBootcamp) => {
              if (
                !dayjs(moduleOfThisBootcamp?.startDate).isAfter(
                  dayjs(lastModuleEndDate)
                )
              ) {
                const newModuleStartDate = addWorkingDays(
                  moduleOfThisBootcamp?.startDate,
                  endDateMovedDays,
                  holidaysList
                );
                const newModuleEndDate = addWorkingDays(
                  moduleOfThisBootcamp?.endDate,
                  endDateMovedDays,
                  holidaysList
                );
                moduleOfThisBootcamp?.bootcamps?.results
                  ?.filter((it2) => it2?.id !== bootcamp?.id)
                  .forEach((it2) => {
                    if (
                      !it2?.modules?.results
                        ?.filter(
                          (it) => it?.id !== bootcampModuleDetails?.moduleId
                        )
                        ?.every((it) =>
                          doModuleDatesConflictWithSharedModules(
                            it,
                            newModuleStartDate,
                            newModuleEndDate
                          )
                        )
                    ) {
                      errors?.propogationErrors?.push(
                        `${moduleOfThisBootcamp?.name} in Bootcamp ${it2?.name}`
                      );
                    }
                  });
                if (!errors?.propogationErrors?.length) {
                  errors?.propogationWarning?.push(
                    `${moduleOfThisBootcamp?.name}, Start Date: ${dayjs(
                      newModuleStartDate
                    )
                      .tz()
                      .format("YYYY-MM-DD")}, End Date: ${dayjs(
                      newModuleEndDate
                    )
                      .tz()
                      .format("YYYY-MM-DD")}`
                  );
                } else {
                  errors.propogationWarning = [];
                  return false;
                }
                lastModuleEndDate = newModuleEndDate;
                return true;
              } else {
                return false;
              }
            });
        }
      }
    }
    if (errors?.conflictErrors?.length === 0) {
      errors.conflictErrors = [];
    } else {
      errors.conflictErrors = [
        `Cannot ${
          isEdit ? `Edit` : `Add`
        } Module, Overlaps with following Module/s:`,
        ...errors?.conflictErrors,
      ];
    }
    if (errors?.propogationErrors?.length === 0) {
      errors.propogationErrors = [];
    } else {
      errors.propogationErrors = [
        `Cannot propogate current Module, since following modules have Conflicting Module/s in shared bootcamps:`,
        ...errors?.propogationErrors,
      ];
    }
    return errors;
  };

  const handleSubmit = () => {
    if (validateFields()) {
      handleBootcampModuleSubmit();
      setModuleStartDate(null);
      setModuleEndDate(null);
    }
  };

  const handleOnClose = () => {
    setErrors(initialErrorState);
    setModuleStartDate(null);
    setModuleEndDate(null);
    onClose();
  };

  const [initialModuleDetails, setInitialModuleDetails] = useState(
    bootcampModuleDetails
  );

  useEffect(() => {
    setInitialModuleDetails(bootcampModuleDetails);
  }, [bootcampModuleDetails.moduleId]);

  const moduleDetailsChanged = compareFormFields(
    initialModuleDetails,
    bootcampModuleDetails
  );

  // Handler function to delete the chip
  const handleChipDelete = (optionToDelete) => {
    const newBootcampIds = bootcampModuleDetails?.bootcampIds?.filter(
      (option) => option?.id !== optionToDelete?.id
    );
    handleBootcampModuleDetailsChange("bootcampIds", newBootcampIds);
  };

  return (
    <>
      {(moduleOptionLoading ||
        getBootcampOptionsLoader ||
        attendeesListLoading ||
        loader) && <Loader />}
      <Dialog
        className="modal-drawer-container"
        open={open}
        onClose={handleOnClose}
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "1rem",
            boxShadow: "0rem 0.6rem 3rem 0rem #OD",
            zIndex: 1300,
            maxWidth: "29.75rem",
          },
        }}
      >
        <DialogTitle className="dialog-title">
          {isCloneModule
            ? "Clone Module"
            : isEdit
            ? "Edit Module"
            : "Create Module"}
        </DialogTitle>
        <DialogContent
          className="dialog-content"
          sx={{ marginBottom: "0 !important" }}
        >
          <Box className="width-100">
            <Box sx={{ marginBottom: "0.75rem" }}>
              {(errors?.conflictErrors?.length > 0 ||
                errors?.propogationErrors?.length > 0) && (
                <Typography
                  sx={{
                    color: "#d32f2f",
                    fontSize: "0.75rem",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {[...errors.conflictErrors, ...errors?.propogationErrors].map(
                    (string, index) => (
                      <React.Fragment key={index}>
                        {string}
                        <br />
                      </React.Fragment>
                    )
                  )}
                </Typography>
              )}
              <TextField
                label="Name"
                color="secondary"
                fullWidth
                className="filter-inputs"
                margin="dense"
                variant="outlined"
                size="small"
                value={bootcampModuleDetails?.name}
                onChange={(e) => {
                  handleBootcampModuleDetailsChange("name", e?.target?.value);
                  setErrors((prev) => {
                    return {
                      ...prev,
                      name: "",
                    };
                  });
                }}
                required={true}
                error={
                  !!errors.name ||
                  bootcampModuleApiError ||
                  moduleSpeChar ||
                  moduleNameLengthError
                }
                helperText={
                  moduleSpeChar
                    ? "Cannot contain special characters"
                    : moduleNameLengthError
                    ? "Maximum 50 characters allowed"
                    : errors?.name
                }
              />
            </Box>
            <Box sx={{ marginBottom: "0.75rem" }}>
              <DateTimePickerComponent
                label="Start Date"
                format="DD/MM/YYYY"
                onChange={(e) => {
                  handleBootcampModuleDateChanges("startDate", e);
                }}
                value={bootcampModuleDetails?.startDate}
                required={true}
                error={!!errors.startDate || bootcampModuleApiError}
                helperText={errors?.startDate}
                views={["year", "day"]}
                minDate={getMaxDate([
                  bootcamp?.startDate,
                  previousModule?.status !== "SCHEDULED"
                    ? dayjs(previousModule?.endDate).add(1, "day")
                    : null,
                  dayjs().add(1, "day"),
                ])}
                disabled={
                  isEdit &&
                  !dayjs(bootcampModuleDetails?.startDate).isAfter(
                    dayjs(),
                    "date"
                  )
                }
                viewRenderers={{
                  hours: null,
                  minutes: null,
                  seconds: null,
                }}
                shouldDisableDate={(date) => shouldDisableWeekends(date)}
                closeOnSelect={true}
                disableHighlightToday={true}
              />
            </Box>
            <Box sx={{ marginBottom: "0.75rem" }}>
              <DateTimePickerComponent
                label="End Date"
                format="DD/MM/YYYY"
                onChange={(e) => {
                  handleBootcampModuleDateChanges("endDate", e);
                }}
                value={bootcampModuleDetails?.endDate}
                required={true}
                error={!!errors.endDate || bootcampModuleApiError}
                helperText={errors?.endDate}
                views={["year", "day"]}
                minDate={getMaxDate([
                  dayjs(bootcampModuleDetails?.startDate).add(1, "day"),
                  dayjs().add(1, "day"),
                ])}
                disabled={
                  isEdit &&
                  !dayjs(bootcampModuleDetails?.endDate).isAfter(
                    dayjs().startOf("day")
                  )
                }
                viewRenderers={{
                  hours: null,
                  minutes: null,
                  seconds: null,
                }}
                shouldDisableDate={shouldDisableWeekends}
                closeOnSelect={true}
                disableHighlightToday={true}
              />
            </Box>
            <Box sx={{ marginBottom: "0.75rem" }}>
              <TextField
                multiline
                minRows={4}
                label="Description"
                color="secondary"
                fullWidth
                className="filter-inputs"
                variant="outlined"
                size="small"
                margin="dense"
                value={bootcampModuleDetails?.description}
                onChange={(e) =>
                  handleBootcampModuleDetailsChange(
                    "description",
                    e?.target?.value
                  )
                }
              />
            </Box>
            <Box sx={{ marginBottom: "0.75rem" }}>
              <AutoCompleteTagInput
                label="Bootcamps"
                placeholder={
                  bootcampModuleDetails?.bootcampIds?.length
                    ? null
                    : "Select Bootcamp"
                }
                getOptionLabel={(option) => option.name}
                options={getBootcampData}
                selectedValue={bootcampModuleDetails?.bootcampIds}
                filterOptions={(options, params) => {
                  // <<<--- inject the Select All option
                  const filter = createFilterOptions();
                  const filtered = filter(options, params);
                  if (getBootcampData.length === 0) {
                    return [{ name: "No options", all: false }];
                  }
                  return [
                    { name: "Select All", startDate: dayjs(), all: true },
                    ...filtered,
                  ];
                }}
                onChange={(event, newValue) => {
                  const idCounts = newValue.reduce((counts, { id }) => {
                    counts[id] = (counts[id] || 0) + 1;
                    return counts;
                  }, {});

                  const uniqueNewValue = newValue.filter(({ id }) => {
                    return idCounts[id] === 1;
                  });
                  if (newValue.find((option) => option.all)) {
                    return handleBootcampModuleDetailsChange(
                      "bootcampIds",
                      bootcampModuleDetails?.bootcampIds?.length ===
                        getBootcampData?.length
                        ? []
                        : getBootcampData
                    );
                  }
                  if (isEdit && event?.keyCode === 8) {
                    const lastItem =
                      bootcampModuleDetails?.bootcampIds[
                        bootcampModuleDetails?.bootcampIds?.length - 1
                      ];
                    if (disabledBootcampsId?.includes(lastItem?.id)) {
                      return;
                    }
                  }
                  handleBootcampModuleDetailsChange(
                    "bootcampIds",
                    uniqueNewValue
                  );
                  setErrors((prev) => {
                    return {
                      ...prev,
                      bootcampIds: "",
                      startAndEndDate: "",
                    };
                  });
                }}
                required={true}
                error={!!errors.bootcampIds || bootcampModuleApiError}
                helperText={errors.bootcampIds}
                disabled={
                  isModuleActive ||
                  !dayjs(bootcampModuleDetails?.startDate).isValid()
                }
                renderOption={(props, option, { selected }) => {
                  // Check if option is "No options" and render accordingly
                  if (option.name === "No options") {
                    return (
                      <Box className="auto-complete-no-options">
                        <Box>{option?.name}</Box>
                      </Box>
                    );
                  }
                  const optionChecked =
                    (option.all &&
                      bootcampModuleDetails?.bootcampIds?.length ===
                        getBootcampData?.length) ||
                    bootcampModuleDetails?.bootcampIds?.find(
                      (e) => e?.id === option?.id
                    ) != null;
                  const optionsDisabled =
                    isEdit && disabledBootcampsId?.includes(option?.id);
                  return (
                    <li
                      {...props}
                      key={props?.id}
                      className={
                        optionsDisabled
                          ? "multi-tag-edit-list-selected-disabled"
                          : optionChecked
                          ? "multi-tag-edit-list-selected"
                          : "multi-tag-style"
                      }
                    >
                      <Box className="multi-tag-input-list-padding">
                        <Checkbox
                          className="auto-complete-checkbox-list"
                          size="small"
                          checked={optionChecked}
                          color="secondary"
                        />
                        <Box className="flex-options">
                          <Box>{option?.name}</Box>
                          {option?.name === "Select All" ? null : (
                            <Box>
                              {option?.startDate
                                ? dayjs(option?.startDate)?.format("DD/MM/YYYY")
                                : null}
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </li>
                  );
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const showCrossIcon = !(
                      isEdit && disabledBootcampsId?.includes(option?.id)
                    );
                    return (
                      <Chip
                        {...getTagProps(index)}
                        key={index}
                        label={option?.name}
                        onDelete={
                          isEdit
                            ? showCrossIcon
                              ? () => handleChipDelete(option)
                              : undefined
                            : !isEdit && option?.id !== bootcamp?.id
                            ? () => handleChipDelete(option)
                            : undefined
                        }
                      />
                    );
                  })
                }
                getOptionDisabled={(option) => {
                  return isEdit && disabledBootcampsId?.includes(option?.id);
                }}
                disableClearable={
                  !isEdit &&
                  bootcampModuleDetails?.bootcampIds?.length === 1 &&
                  bootcampModuleDetails?.bootcampIds[0]?.id === bootcamp?.id
                }
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: "0rem 2rem 1.875rem" }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleOnClose}
            className="block-button"
          >
            <Typography variant="outlineBtnLabel">Cancel</Typography>
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="secondary"
            className="block-button"
            disabled={isEdit && !moduleDetailsChanged}
          >
            <Typography variant="outlineBtnLabel">
              {isEdit ? "Update" : "Save"}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateBootcampModule;
