import React from "react";
import "./reportDownloadModal.scss";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  createFilterOptions,
} from "@mui/material";
import AutoCompleteTagInput from "../AutoCompleteTagInput/AutoCompleteTagInput";

const ReportDownloadModal = ({
  open,
  title,
  handleClose,
  bootcampList,
  handleBootcampChange,
  selectedBootcamps,
  handleDownloadFile,
}) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        className="modal-drawer-container"
        PaperProps={{
          style: {
            height: "auto",
            width: "27.5rem",
            zIndex: 1300,
            borderRadius: "1rem",
            boxShadow: "0px 6px 30px 0px rgba(0, 0, 0, 0.05)",
            margin: 0,
          },
        }}
      >
        <DialogTitle className="dialog-title">
          {title || "Select Bootcamps"}
        </DialogTitle>
        <DialogContent className="dialog-content">
          <AutoCompleteTagInput
            label="Bootcamps"
            placeholder={selectedBootcamps?.length ? null : "Select Bootcamp"}
            options={bootcampList}
            selectedValue={selectedBootcamps}
            getOptionLabel={(option) => `${option?.name}`}
            filterOptions={(options, params) => {
              // <<<--- inject the Select All option
              const filter = createFilterOptions();
              const filtered = filter(options, params);
              if (bootcampList.length === 0) {
                return [{ name: "No options", all: false }];
              }
              return [{ name: "Select All", all: true }, ...filtered];
            }}
            onChange={(event, newValue) => {
              const idCounts = newValue.reduce((counts, { id }) => {
                counts[id] = (counts[id] || 0) + 1;
                return counts;
              }, {});
              const uniqueNewValue = newValue.filter(({ id }) => {
                return idCounts[id] === 1;
              });
              if (newValue.find((option) => option.all)) {
                return handleBootcampChange(
                  selectedBootcamps?.length === bootcampList?.length
                    ? []
                    : bootcampList
                );
              }
              handleBootcampChange(uniqueNewValue);
            }}
            required={true}
            error={!selectedBootcamps?.length}
            helperText={!selectedBootcamps?.length ? "Atleast 1 Bootcamp must be selected" : ""}
            renderOption={(props, option, value, { selected }) => {
              const optionChecked =
                (option.all &&
                  selectedBootcamps?.length === bootcampList?.length) ||
                selectedBootcamps?.find((e) => e?.id === option?.id) != null;
              return (
                <li
                  {...props}
                  key={props?.id}
                  style={{
                    paddingBottom: 1,
                    paddingTop: 1,
                  }}
                  className={
                    optionChecked
                      ? "multi-tag-edit-list-selected"
                      : "multi-tag-style"
                  }
                >
                  <Checkbox
                    size="small"
                    sx={{
                      marginRight: 0.2,
                      marginTop: "0.2rem",
                      padding: "0.063rem 0.438rem",
                      "& .MuiSvgIcon-root": {
                        fontSize: 15,
                      },
                    }}
                    checked={optionChecked}
                    color="secondary"
                  />
                  <Box>
                    <Typography variant="font_16">{`${option?.name}`}</Typography>
                  </Box>
                </li>
              );
            }}
          />
        </DialogContent>
        <DialogActions sx={{ padding: "0rem 2rem 1.875rem" }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClose}
            className="download-file-btn"
          >
            <Typography variant="outlineBtnLabel">Cancel</Typography>
          </Button>
          <Button
            onClick={handleDownloadFile.bind(this)}
            variant="contained"
            color="secondary"
            className="download-file-btn"
            disabled={!selectedBootcamps?.length}
          >
            <Typography variant="outlineBtnLabel">Download</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReportDownloadModal;
