import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import ReactQuill from "react-quill";
import { useSelector } from "react-redux";
import "./topicModal.scss";
import Loader from "../../components/Loader/Loader";
import CustomToolbar from "../../components/CustomToolbar/CustomToolbar";

const TopicInputModal = ({
  open,
  handleClose,
  topicDetails,
  handleTopicDataChange,
  handleSubmitTopic,
  isEditTopic,
  errors,
  apiError,
  checkLimitedSpecialChar,
  isCloneTopic,
}) => {
  const submitLoader = useSelector((state) => state?.topic.loading);
  const [inlineError, setInlineError] = useState(false);

  const handleTopicChange = (name, value) => {
    if (name === "name" && value?.trim().length <= 50) {
      handleTopicDataChange(name, value);
      setInlineError(false);
    } else {
      setInlineError("Maximum 50 characters allowed");
    }
  };

  const handleTopicClose = () => {
    handleClose();
    setInlineError(false);
  };

  const [initialTopicDetails, setInitialTopicDetails] = useState(topicDetails);

  useEffect(() => {
    setInitialTopicDetails(topicDetails);
  }, [topicDetails?.id]);

  const compareFormFields = (initial, current) => {
    const fieldsToCheck = ["name", "description", "agenda"];

    return fieldsToCheck.some((field) => {
      if (field !== "agenda") {
        return initial[field].trim() !== current[field].trim();
      } else {
        return initial[field] !== current[field];
      }
    });
  };

  const topicDetailsChanged = compareFormFields(
    initialTopicDetails,
    topicDetails
  );

  return (
    <>
      {submitLoader && <Loader />}

      <Dialog
        open={open}
        onClose={handleTopicClose}
        className="modal-drawer-container"
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: "1rem",
            boxShadow: "0rem 0.6rem 3rem 0rem #OD",
            zIndex: 1300,
          },
        }}
      >
        <DialogTitle className="dialog-title">
          {isCloneTopic
            ? "Clone Topic"
            : isEditTopic
            ? "Edit Topic"
            : "Create Topic"}
        </DialogTitle>

        <DialogContent className="dialog-content">
          <Box className="width-100">
            <TextField
              autoFocus
              id="name"
              label="Name"
              type="text"
              fullWidth
              size="small"
              color="secondary"
              variant="outlined"
              margin="dense"
              value={topicDetails?.name}
              onChange={(e) => handleTopicChange("name", e?.target?.value)}
              className="text-input"
              error={
                !!errors.name ||
                apiError ||
                inlineError ||
                checkLimitedSpecialChar(topicDetails?.name)
              }
              helperText={
                errors.name ||
                inlineError ||
                (checkLimitedSpecialChar(topicDetails?.name) &&
                  "Should not contain special Characters")
              }
              required={true}
            />

            <TextField
              id="description"
              label="Description"
              type="text"
              fullWidth
              color="secondary"
              variant="outlined"
              size="small"
              margin="dense"
              multiline
              minRows={2}
              maxRows={6}
              value={topicDetails?.description}
              onChange={(e) =>
                handleTopicDataChange("description", e?.target?.value)
              }
              className="description-text-input"
              error={!!errors.description || apiError}
              helperText={errors.description}
              required={true}
            />
            <Box mb={1}>
              <Typography
                color={!!errors.agenda || apiError ? "error" : "offline"}
                variant="font_14"
                mb={1}
              >
                Agenda *
              </Typography>
            </Box>

            <CustomToolbar />
            <ReactQuill
              className="topic-quill"
              theme="snow"
              value={topicDetails?.agenda}
              onChange={(value) => handleTopicDataChange("agenda", value)}
              placeholder="Enter Text"
              modules={{
                toolbar: {
                  container: "#toolbar",
                },
              }}
              bounds={".topic-quill"}
            />
            {!!errors.agenda || apiError ? (
              <Typography
                sx={{ paddingLeft: "1rem" }}
                variant="font_12"
                color="error"
              >
                {errors.agenda}
              </Typography>
            ) : null}
          </Box>
          {isCloneTopic && (
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      size="small"
                      checked={topicDetails?.cloneAssessmentQuestions}
                      onChange={(e) =>
                        handleTopicDataChange(
                          "cloneAssessmentQuestions",
                          e.target.checked
                        )
                      }
                    />
                  }
                  label="Clone Assessment Questions"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      size="small"
                      checked={topicDetails?.cloneExerciseQuestions}
                      onChange={(e) =>
                        handleTopicDataChange(
                          "cloneExerciseQuestions",
                          e.target.checked
                        )
                      }
                    />
                  }
                  label="Clone Exercise Questions"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      size="small"
                      checked={topicDetails?.cloneResources}
                      onChange={(e) =>
                        handleTopicDataChange(
                          "cloneResources",
                          e.target.checked
                        )
                      }
                    />
                  }
                  label="Clone Resources"
                />
              </FormGroup>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: "0rem 2rem 1.875rem" }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleTopicClose}
            className="block-button"
          >
            <Typography variant="font_12_bold_700">Cancel</Typography>
          </Button>
          <Button
            onClick={handleSubmitTopic}
            variant="contained"
            className="block-button"
            color="secondary"
            fullWidth
            disabled={isEditTopic && !topicDetailsChanged}
          >
            <Typography variant="font_12_bold_700">
              {isCloneTopic ? "Clone" : isEditTopic ? "Update" : "Save"}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TopicInputModal;
