import React from "react";
import "./dashboard.scss";
import { Box, Grid, Paper, Typography } from "@mui/material";
import ComingSoon from "../../components/ComingSoon/ComingSoon";

const Dashboard = () => {
  return (
    <>
      <Grid container className="main-container">
        <Grid item className="header-container" md={12}>
          <Typography variant="font_21_bold">Dashboard</Typography>
          <Box className="header-right-icons">
            {/* <IconButtons
              width={1.91}
              height={1.91}
              image={filter_active_icon}
              handleClick={handleFilterOpen}
            /> */}
          </Box>
        </Grid>
        <Grid item md={12} className="body-container">
          <Grid container className="table-parent-container">
            <Paper elevation={0} className="dashboard-paper-container">
              <Grid container className="no-data-container">
                <ComingSoon
                  imgHeight={10}
                  imgWidth={10}
                  message="COMING SOON"
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
