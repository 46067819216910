import React from "react";
import "./networkError.scss";
import { Box, Button, Grid, Typography } from "@mui/material";
import { ttn_logo_icon } from "../../constants/icons";

const NetworkError = () => {
  return (
    <Grid container className="network-error-page-container">
      <Grid item className="network-error-card">
        <Box
          className="ttn-image"
          sx={{ backgroundImage: `url(${ttn_logo_icon})` }}
        ></Box>
        <Typography className="network-error-card-text" variant="h4">
          Welcome to Bootcamp Portal!
        </Typography>
        <Button className="network-error-card-button" variant="contained">
          Connection refused or server not reachable
        </Button>
      </Grid>
    </Grid>
  );
};

export default NetworkError;
